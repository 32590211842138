import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { GridToolbar } from '@mui/x-data-grid-premium';
import { calendarPickerClasses } from '@mui/x-date-pickers';
import { Resizable } from 're-resizable';
import * as React from 'react';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useGetAccessorialCodesQuery, useLazyGetSelectedOrderDetailsQuery, useUpdateDriversOnOrderMutation } from '../../../features/Dispatch/DispatchSlice';
import { detachDriverUtility } from "./OrderDetails/DetachCases";

const OnboardVehicle = (props) => {  
    const [getDetails, result] = useLazyGetSelectedOrderDetailsQuery();
    const [updateDriversOnOrder] = useUpdateDriversOnOrderMutation();
    const [showPopup, setShowPopup] = useState(false);
    const [pickupChecked, setPickupChecked] = useState(false);
    const [dropoffChecked, setDropoffChecked] = useState(false);
    const [clickedID, setClickedID] = useState(null);
    const [clickedPickup, setClickedPickup] = useState(null);
    const [clickedDropoff, setClickedDropoff] = useState(null);
    const { data: accessorialCodesResponse, isLoading: accessorialCodesLoading} = useGetAccessorialCodesQuery();

    const newRows = props.onBoardOrders?.map((data) => {
        return {
            id: data?.orderId,
            Status: data?.orderStatus,
            // ReadyTime: data?.readyDate,
            ReadyTime: data?.readyDate
            ? new Date(data?.readyDate).toLocaleString('en-US', { 
                hour: '2-digit', 
                minute: '2-digit', 
                hour12: true, 
                year: 'numeric', 
                month: '2-digit', 
                day: '2-digit' 
              })
            : '',
            // A: "",
            St: data?.dropoffStreetNumber,
            StreetName: data?.dropoffStreetName,
            City: data?.dropoffCity,
            Pcs: data?.pieces,
            pickUpDriverNumber: data?.pickUpDriverNumber,
            dropOffDriverNumber: data?.dropOffDriverNumber || null,
            ...data?.accessorialCodesForOrder,
        }
    })

    const detachDriver = async (e) => {
        e.stopPropagation();
        const pickUp = pickupChecked ? null : clickedPickup;
        const dropOff = dropoffChecked ? null : clickedDropoff;
        await updateDriversOnOrder({orderId: clickedID, divisionId: props.divisionId, pickupDriverNumber: pickUp, dropOffDriverNumber: dropOff, orderStatusID: 3});
        const result = await props.getSelectedDriverDetails({divisionId: props.divisionId, driverNumber: props.selectedDriver?.driverNumber});
        props.setOnBoardOrders(result?.data?.data?.onBoardedOrderDetails);
        await props.fetchRegionalOrders(props.divisionId);
        await props.setSelectedDriver(result.data.data)
        detachDriverUtility({ clickedID, selectedDriver: props.selectedDriver, setOrdersTable:props.setOrdersTable, pickupChecked, dropoffChecked,status:3 })
        setShowPopup(false);
        setPickupChecked(false);
        setDropoffChecked(false);
        setClickedID(null);
        setClickedPickup(null);
        setClickedDropoff(null);
    }

    const baseColumns  = [
        { field: 'Action', headerName: 'Action', width: 90,
        renderCell: (data) => {
            const onClick = async (e) => {
              e.stopPropagation(); 
              const result = await getDetails({divisionId: props.divisionId, orderId: data.row.id})
              if (result.data.statusCode === 200)
                props.setSelectedOrder(result.data.data);
                props.setOrdersTable(props.assignedOrders);
                props.setShowAssignedOrders(true);
            };
            return (
                <Button onClick={onClick}>Details</Button>
            )
          },    
        },
        { field: 'Status', headerName: 'Status', width: 100,
        renderCell: (data) => {
            const onClick = async (e) => {
              e.stopPropagation();
              const DTO = {orderId: data.row.id, divisionId: props.divisionId, pickupDriverNumber: data.row.pickUpDriverNumber, dropOffDriverNumber: data.row.dropOffDriverNumber, orderStatusID: 4}
              await updateDriversOnOrder(DTO);
              const result = await props.getSelectedDriverDetails({divisionId: props.divisionId, driverNumber: props.selectedDriver?.driverNumber});
              await props.fetchDrivers(props.divisionId);
              props.setOnBoardOrders(result?.data?.data?.onBoardedOrderDetails);
            };
            return (
                <Form.Check type="checkbox" onClick={onClick} />
            )
          },    
        },
        {
            field: 'Detach',
            headerName: 'Detach',
            width: 65,
            renderCell: (data) => {
                const onClick = async (e) => {
                    console.log("CLICKED");
                    
                e.stopPropagation();
                    setClickedID(data.row.id);
                    setClickedPickup(data.row.pickUpDriverNumber);
                    setClickedDropoff(data.row.dropOffDriverNumber);
                    setShowPopup(true);
                };
                return (
                                <Button onClick={onClick}>X</Button>
                )
    
            },
        },
        {
            field: 'ReadyTime',
            headerName: 'Ready Date & Time',
            type: calendarPickerClasses,
            width: 190,
            editable: false,
        },
        // { field: 'A', headerName: 'A', width: 40 },
        {
            field: 'St',
            headerName: 'St#',
            //type: 'number',
            width: 80,
            editable: false,
        },
        {
            field: 'StreetName',
            headerName: 'Street Name',
            width: 150,
            editable: false,
        },
        {
            field: 'City',
            headerName: 'City',
            width: 100,
            editable: false,
        },
        {
            field: 'Pcs',
            headerName: 'Pcs',
            width: 60,
            editable: false,
        },
    
    ];

    let columns = [...baseColumns]; // Clone the base columns
    if (!accessorialCodesLoading && accessorialCodesResponse?.data) {
        const accessorialColumns = accessorialCodesResponse.data.map((code) => ({
            field: code.accessorialCode,  // Using the 'accessorialCode' field from the data
            headerName: code.accessorialCode,  // Set header to the accessorialCode
            width: 120,
            editable: true,
        }));
        columns = columns.concat(accessorialColumns); // Concatenate the accessorial columns to the existing columns
    }

    return (
        <Resizable defaultSize={{ width: "auto", height: "auto" }}>
            <div className="table-back">
                <div className="table-header">
                     <h6>On Board: {props.selectedDriver?.driverNumber || ""}</h6>
                </div>
            <Box sx={{ height: 500, width: '100%'}}>
                <DataGrid
                    componentsProps={{
                        header:{className:'drag-handle cursor-move'},
                        footer:{className:'drag-handle cursor-move'},
                    }}
                    classes={{virtualScrollerContent:'drag-handle cursor-move'}}
                    rows={props.selectedDriver == null ? [] : newRows}
                    columns={columns}
                    pageSize={5}
                    rowsPerPageOptions={[1]} 
                    components={{ Toolbar: GridToolbar }}
                />
            </Box>
            <Dialog open={showPopup} onClose={() => setShowPopup(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                    <DialogTitle id="alert-dialog-title">
                                        Detach Drivers
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Form.Check type="checkbox"  style={{ marginRight: '10px' }} onClick={() => setPickupChecked(!pickupChecked)} label="Pickup Driver" />
                                                    <Form.Check type="checkbox" label="Delivery Driver" onClick={() => setDropoffChecked(!dropoffChecked)} />
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                                    <Button className="btn-secondary" style={{ marginRight: '10px' }} onClick={() => { setShowPopup(false); }}>Cancel</Button>
                                                    <Button className="btn-primary" onClick={(e) => detachDriver(e)} autoFocus> Confirm</Button>
                                                </div>
                                            </div>
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>
            </div>
        </Resizable>
    );
}
export default OnboardVehicle;