import { AddCircle, Delete, Edit, Save } from "@material-ui/icons";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useCustomer from "../../../../context/CustomerContext";
import { useAddUnitTypeMutation, useDeleteUnitTypeMutation, useGetAllUnitTypeQuery, useLazyGetDetailByIdQuery, useUpdateUnitTypeMutation } from "../../../../features/productType/productListSlice";
import { useGetListOfUnitQuery } from "../../../../features/unit/unitSlice";
import { useCustomData } from "../../../Sidebar/Permission";
import AccessorialSurcharge from "./Accessorial/AccessorialSurcharge";
import EditAccessorialSurcharge from "./Accessorial/EditAccessorial";
import ContractData from "./Contract/ContractData";
import EditContractdata from "./Contract/EditContractData";
import SaveContractdata from "./Contract/SaveContractData";
import CwtRatecard from "./CwtRateCard/CwtRatecard";
import EditCwtRatecard from "./CwtRateCard/EditCwtRatecard";
import CityDistance from "./FuelSurcharge/CityDistance";
import DistanceFuel from "./FuelSurcharge/DistanceFuel";
import EditCityDistance from "./FuelSurcharge/EditCitydistance";
import EditDistanceFuel from "./FuelSurcharge/EditDistanceFuel";
import EditRemoteLogin from "./FuelSurcharge/EditRemoteLogin";
import FuelCostTable from "./FuelSurcharge/FuelCostTable";
import RemoteLogin from "./FuelSurcharge/RemoteLogin";
import EditServiceSurcharge from "./Service/EditServiceSurcharge";
import ServiceSurcharge from "./Service/ServiceSurcharge";
import EditVehicleSurcharge from "./Vehicle/EditVehicleSurcharge";
import VehicleSurcharge from "./Vehicle/VehicleSurcharge";

const Contract = (props) => {
  const { register, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm();
  const { data , refetch: dataofUnit } = useGetListOfUnitQuery();
  const [permission] = useCustomData()
  const [addUnitType] = useAddUnitTypeMutation()
  const [getDataById, rowData] = useLazyGetDetailByIdQuery()
  const { data: getUnitType, refetch } = useGetAllUnitTypeQuery()
  const [updateUnit] = useUpdateUnitTypeMutation()
  const [deleteUnit] = useDeleteUnitTypeMutation()
  const { selected, contractInfo } = useCustomer();
  const ratecardaccount = contractInfo && contractInfo[0]
  const [showEditRate, setShowEditRate] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [isId, setIsId] = useState();
  const [open, setOpen] = useState(false);
  const [deletedItem, setDeleteItem] = useState();
  const [update, setUpdate] = useState(false);
  const { selectedCard } = props;

  const editData = rowData?.data?.data
  const handleClickOpen = (data) => {
    setOpen(true);
    setDeleteItem(data?.id)
  };
  useEffect(() => {
    if (rowData) {
      setValue('unitType', editData?.unitType)
      setValue('abbrUnit', editData?.abbrUnit)
    }
  }, [rowData])
  const showedithandler = () => {
    setShowEditRate(!showEditRate);
  }

  const onSubmit = async (data) => {
    const submitData = await addUnitType(data);
    console.log("submit", submitData);
    if (submitData?.data.statusCode === 200) {
      toast.success(submitData?.data.message, {
        position: "top-center",
      })
    }
    else if (submitData?.data.statusCode === 400) {
      toast.error(submitData?.data.message, {
        position: "top-center",
      })
    }
    reset({ unitType: "", abbrUnit: "" })
    refetch()
    dataofUnit()
  }
  const onUpdate= async (data)=>{
    const updateData = await updateUnit({ unitId: isId, ...data })
    // console.log("update",updateData);
    if(updateData?.data.statusCode === 200){
      toast.success(updateData?.data.message, {
        position: "top-center",
      })
    }
    else if(updateData?.data.statusCode === 400){
      toast.error(updateData?.data.message, {
        position: "top-center",
      })
    }
    reset({ unitType: "", abbrUnit: "" })
    refetch()
    dataofUnit()
    setUpdate(false)

  }

  const handleClose = () => {
    setOpen(false);
  };
  const deleteHandler = async () => {
    await deleteUnit(deletedItem);
    refetch()
    setOpen(false);
  }

  // if (!tableList || isLoading) return <><Spinner /></>;
  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = async (e) => {
          e.stopPropagation();

          await getDataById(data?.row?.id)
          setIsId(data?.row?.id)
          setUpdate(true)
          // setShow(true)
          // setDisable(true)
        };
        const onRemove = (data, e) => {
          e.stopPropagation();
        };

        return (
          <>
            <>
              <div className="action_btn">
                {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Update') !== -1 ?
                  <span className="edit_btn" onClick={onClick}><Edit /></span>
                  : <span className="edit_btn disable"><Edit /></span>}
                {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Delete') !== -1 ?
                  <span className="del_btn" onClick={() => handleClickOpen(data)}><Delete /></span>
                  : <span className="del_btn disable" ><Delete /></span>}
              </div>
            </>
            <>
              <div>
                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                  <DialogTitle id="alert-dialog-title">
                    Delete Confirmation
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete this row?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button className="btn-secondary" onClick={() => { setOpen(false); }}>Cancel</Button>
                    <Button className="btn-primary" onClick={deleteHandler} autoFocus> Confirm</Button>
                  </DialogActions>
                </Dialog>
              </div>
            </>
          </>
        )

      },
    },
    { field: "unitType", headerName: "Product Name", width: 200 },
    { field: "abbrUnit", headerName: "Abbr", width: 200 },

  ];



  const rows = getUnitType?.data.map((data) => {
    return {
      id: data?.unitId,
      unitType: data?.unitType,
      abbrUnit: data?.abbrUnit,
    }
  })


  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Rate Card</Accordion.Header>
          <Accordion.Body>
            <>
              {/* <AddProductTypes /> */}
              {/* Contract Info */}
              <Row className="mb-4 justify-content-between">
                <Button onClick={() => setIsShow(true)} variant="primary" style={{ width: "20%" }}>
                  Create Product
                </Button>
                <Modal
                  size="lg"
                  show={isShow}
                  onHide={() => setIsShow(false)}
                  aria-labelledby="example-modal-sizes-title-md"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-md">
                      {" "}
                      Add Product Type
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Row>
                      <Col sm={3}>
                        <div className="form-floating">
                          <Form.Control type="text" placeholder="Product Name" name="unitType" {...register('unitType', { required: 'Field is required' })} />
                          <label htmlFor="Vehicle Type">Product Name</label>
                        </div>
                      </Col>
                      <Col sm={3}>
                        <div className="form-floating">
                          <Form.Control type="text" placeholder="abbr Type" name="abbrUnit" {...register('abbrUnit', { required: 'Field is required' })} />
                          <label htmlFor="Vehicle Type">Abbreviation</label>
                        </div>
                      </Col>
                      <Col sm={3}></Col>
                      <Col sm={3}>

                        {!update ?
                          <div className="section_action_btn">
                            <div className="add_btn"><button type="button" className="btn" onClick={ handleSubmit(onSubmit)} ><AddCircle /> Add</button></div>
                          </div>
                          :
                          <div className="section_action_btn">
                            <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(onUpdate)} ><Save /> Save</button></div>
                          </div>
                        }


                      </Col>
                    </Row>
                    <div className="user-role-table" style={{ height: "500px" }}>
                      <h6>List of Product Name</h6>
                      <DataGrid rows={rows} columns={columns} pageSize={15} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} />
                    </div>
                  </Modal.Body>
                </Modal>
              </Row>
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Contract Info Section</Accordion.Header>
                  <Accordion.Body>
                    {showEditRate ?
                      <>{selectedCard ?
                        <> {permission?.find(event => event.moduleId === 40) ? <EditContractdata showedithandler={showedithandler} showEditRate={showEditRate} /> : <p className="no-access-msg">No Access Permission</p>}</> :
                        <> {permission?.find(event => event.moduleId === 40) ? <ContractData /> : <p className="no-access-msg">No Access Permission</p>}</>
                      }</>
                      : <SaveContractdata showedithandler={showedithandler} />}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Vehicle Surcharge Section</Accordion.Header>
                  <Accordion.Body>
                    {/* Vehicle surcharge section*/}
                    {selectedCard ?
                      <> {permission?.find(event => event.moduleId === 41) ? <EditVehicleSurcharge selectedCard={selectedCard}/> : <p className="no-access-msg">No Access Permission</p>}</> :
                      <> {permission?.find(event => event.moduleId === 41) ? <VehicleSurcharge /> : <p className="no-access-msg">No Access Permission</p>}</>
                    }
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Service Surcharge Section</Accordion.Header>
                  <Accordion.Body>
                    {/* Service surcharge section*/}
                    {selectedCard ?
                      <> {permission?.find(event => event.moduleId === 42) ? <EditServiceSurcharge selectedCard={selectedCard}/> : <p className="no-access-msg">No Access Permission</p>}</> :
                      <> {permission?.find(event => event.moduleId === 42) ? <ServiceSurcharge /> : <p className="no-access-msg">No Access Permission</p>}</>
                    }
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Accessorial Charge Section</Accordion.Header>
                  <Accordion.Body>
                    {/* Accessorial Charge section*/}
                    {selectedCard ?
                      <> {permission?.find(event => event.moduleId === 43) ? <EditAccessorialSurcharge selectedCard={selectedCard}/> : <p className="no-access-msg">No Access Permission</p>}</> :
                      <> {permission?.find(event => event.moduleId === 43) ? <AccessorialSurcharge /> : <p className="no-access-msg">No Access Permission</p>}</>}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>CWT Rate Card Section</Accordion.Header>
                  <Accordion.Body>
                    {/* CWT Rate Card Section*/}
                    {selectedCard ?
                      <> {permission?.find(event => event.moduleId === 44) ? <EditCwtRatecard selectedCard={selectedCard}/> : <p className="no-access-msg">No Access Permission</p>}</> :
                      <> {permission?.find(event => event.moduleId === 44) ? <CwtRatecard /> : <p className="no-access-msg">No Access Permission</p>}</>
                    }
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Fuel Surcharge Section</Accordion.Header>
                  <Accordion.Body className="p-0">
                  {(contractInfo.FuelSurchargeMethod === "Select" || !contractInfo.FuelSurchargeMethod) && 
                    <><p>Please choose a Fuel Surcharge Method in the Contract Section!</p></>
                  }
                  {(contractInfo.FuelSurchargeMethod === 2 || contractInfo.FuelSurchargeMethod === '2') && 
                    <>
                    {selectedCard ?
                      <> {permission?.find(event => event.moduleId === 45) ? <EditCityDistance selectedCard={selectedCard}/> : <p className="no-access-msg">No Access Permission</p>}</> :
                      <> {permission?.find(event => event.moduleId === 45) ? <CityDistance /> : <p className="no-access-msg">No Access Permission</p>}</>
                    }

                    {selectedCard ?
                      <> {permission?.find(event => event.moduleId === 45) ? <EditDistanceFuel selectedCard={selectedCard}/> : <p className="no-access-msg">No Access Permission</p>}</> :
                      <> {permission?.find(event => event.moduleId === 45) ? <DistanceFuel /> : <p className="no-access-msg">No Access Permission</p>}</>
                    }

                    {selectedCard ?
                      <> {permission?.find(event => event.moduleId === 45) ? <FuelCostTable edit={true} /> : <p className="no-access-msg">No Access Permission</p>}</> :
                      <> {permission?.find(event => event.moduleId === 45) ? <FuelCostTable edit={false} /> : <p className="no-access-msg">No Access Permission</p>}</>
                    } </>
                  }

                  {(contractInfo.FuelSurchargeMethod === 1 || contractInfo.FuelSurchargeMethod === '1') && <>
                    {selectedCard ?
                      <> {permission?.find(event => event.moduleId === 45) ? <EditRemoteLogin selectedCard={selectedCard}/> : <p className="no-access-msg">No Access Permission</p>}</> :
                      <> {permission?.find(event => event.moduleId === 45) ? <RemoteLogin /> : <p className="no-access-msg">No Access Permission</p>}</>
                    }
                    </> }
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  )
}
export default Contract;
