import React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import {useAddDispatcherNotesMutation,useLazyGetSelectedOrderDetailsQuery} from  '../../../../../features/Dispatch/DispatchSlice';

const DispatcherNotes = ({ setSelectedOrder, selectedOrder, divisionId }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [addDispatcherNotes] = useAddDispatcherNotesMutation();
    const [noteText, setNoteText] = useState(""); // State to store textarea input
    const [triggerGetSelectedOrderDetails, { data: selectedOrderDetails } ]= useLazyGetSelectedOrderDetailsQuery();

    console.log(selectedOrder);
    const dispatchNotes = selectedOrder?.dispatcherNotes;
    useEffect(() => {
        if (!dispatchNotes) {
            setIsEdit(true);
            setNoteText("");
        } else {
            setIsEdit(false);
            setNoteText(dispatchNotes);
        }
    }, [dispatchNotes]);

    const handleNoteChange = (e) => {
        setNoteText(e.target.value); // Update state with the value of the textarea
        //console.log(e.target.value);
    };

    const SubmitHandler = async () => {
        const data = {
            orderNo: selectedOrder?.orderNumber,
            dispatcherNotes: noteText,
        }
        //console.log(data);
        const result = await addDispatcherNotes(data);
        if (result?.data?.statusCode === 200) {
            const order = await triggerGetSelectedOrderDetails({
                divisionId: divisionId,
                orderId: selectedOrder.orderId,
            });
            setSelectedOrder(order.data.data);
        }        
        setIsEdit(false);

    }

    return (
        <>
            <Row>
                <Col sm={9}>
                </Col>
                <Col sm={3}>   
                {isEdit ?               
                   ( <Button variant="primary" className={"save-btn"} onClick={SubmitHandler}>{"Save"}</Button> ):(
                    <Button variant="primary" className={"save-btn"} onClick={() => setIsEdit(!isEdit)}>{"Edit"}</Button>
                )}   
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label></Form.Label>
                        {/* <Form.Label>Dispatcher Notes</Form.Label> */}
                        <Form.Control 
                            as="textarea" 
                            rows={10} 
                            value={noteText} // Bind textarea to state
                            onChange={handleNoteChange} // Update state on change
                            readOnly={!isEdit}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={9}>
                </Col>
               
            </Row>
        </>
    );
}

export default DispatcherNotes;