export const detachDriverUtility = ({ clickedID, selectedDriver, setOrdersTable, pickupChecked, dropoffChecked, status }) => {
    setOrdersTable((prevTable) =>
        prevTable
            .map((data) => {
                if (pickupChecked && dropoffChecked && data.orderId === clickedID) {
                    return null; 
                }

                if (pickupChecked && !dropoffChecked && status === 2) {
                    if (data.pickUpDriver === selectedDriver.driverNumber && data.orderId === clickedID) {
                        return { ...data };
                    }
                }
                if (pickupChecked && !dropoffChecked && status === 3) {
                    if (data.pickUpDriver === selectedDriver.driverNumber && data.orderId === clickedID) {
                        return { ...data, pickUpDriver: null };
                    }
                }
                if(!pickupChecked && dropoffChecked){
                    if (data.deliveryDriver === selectedDriver.driverNumber && data.pickUpDriver == null && data.orderId === clickedID) {
                        return  null ;
                    }
                }

                if (!pickupChecked && dropoffChecked) {
                    if (data.deliveryDriver === selectedDriver.driverNumber && data.pickUpDriver != null && data.orderId === clickedID) {
                        return { ...data, deliveryDriver: null };
                    }
                }

                return data;
            })
            .filter(Boolean)
    );
};