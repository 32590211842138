import { Button, Form, Row, Col, FloatingLabel, ToastBody } from "react-bootstrap"
import { Save } from "@material-ui/icons"
import RateCard from "./RateCard";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useForm } from 'react-hook-form';
import { Controller } from "react-hook-form";
import { useGetCountryQuery, useLazyGetStateQuery } from "../../../features/countryState/countryStateSlice";
import { useGetCityQuery } from "../../../features/city/citySlice";
import { useAddSwitchTypeQuery, useGetDispatcherListQuery, useGetReportTypeQuery, useUpdateInterlinerMutation } from "../../../features/Interliners/InterlinersFieldsSlice";
import { useGetPayrollCycleQuery, useGetDriverNumberQuery } from "../../../features/admin/employee/employeeSlice";
import InputMask from "react-input-mask";
import { useAddInterlinerMutation, useGetInterlinerAgentQuery } from "../../../features/Interliners/InterlinersFieldsSlice";
import { useEffect } from "react";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import postalCodeMasking from "../../Customer/PostalCodeMasking";
import CountryProvince from "../../CountryProvince";


const AddInterliners = ({ rateCardSection, interlinerDetails, interlinerRowData, getInterlinerRowData, bb }) => {
    const { country, selectState, setSelectState, handlecountry, province, handleCity, city, selectCity, setSelectCity } = CountryProvince()
    const { refetch } = useGetInterlinerAgentQuery()
    const { register, handleSubmit, formState: { errors }, reset, control, setValue } = useForm();
    const [addInterliner] = useAddInterlinerMutation()
    const { data: interliner } = useAddSwitchTypeQuery()
    const { data: reportType } = useGetReportTypeQuery()
    const { data: payrollCycle } = useGetPayrollCycleQuery()
    const { data: driverlist } = useGetDriverNumberQuery()
    const [updateDatas] = useUpdateInterlinerMutation()
    const [postal, setPostal] = useState(interlinerRowData?.postalCode)
    const [partnerAgent, setPartnerAgent] = useState(false);

  const handleInputChange = () => {
    setPartnerAgent(!partnerAgent);
  };

  console.log('partnerAgent', partnerAgent);

    useEffect(() =>{
        reset(interlinerRowData)
    },[interlinerRowData])

    useEffect(() => {
        if (interlinerRowData) {
            setValue("companyname", interlinerRowData?.companyName)
            setValue("streetNumber", interlinerRowData?.streetNumber)
            setValue("streetName", interlinerRowData?.streetName)
            setValue("unit", interlinerRowData?.unit)
            setValue("postalCode", interlinerRowData?.postalCode)
            setValue("phoneExtension", interlinerRowData?.phoneExtension)
            setValue("fax", interlinerRowData?.fax)
            setValue("primaryNumber", interlinerRowData?.primaryNumber)
            setValue("secondaryNumber", interlinerRowData?.secondaryNumber)
            setValue("tollFreeNo", interlinerRowData?.tollFreeNo)
            setValue("hireDate", moment(interlinerRowData?.hireDate).utc().format('YYYY-MM-DD'))
            setValue("cdnpars", interlinerRowData?.cdnpars)
            setValue("usapaps", interlinerRowData?.usapaps)
            setValue("dockName", interlinerRowData?.dockName)
            setValue("ourAccount", interlinerRowData?.ourAccount)
            setValue("insurenceInfo", interlinerRowData?.insurenceInfo)
            setValue("wsaibAccountNumber", interlinerRowData?.wsaibAccountNumber)
            setValue("wsibFirm", interlinerRowData?.wsibFirm)
            setValue("wsibFirmRenewalDate", moment(interlinerRowData?.wsibFirmRenewalDate).utc().format('YYYY-MM-DD'))
            setValue("email", interlinerRowData?.email)
            setValue("wsibFirmRenewalDate", moment(interlinerRowData?.wsibFirmRenewalDate).utc().format('YYYY-MM-DD'))
            setValue("ccEmialAddress", interlinerRowData?.ccEmialAddress)
            setValue("driverId", interlinerRowData?.driverId)
            setValue("switchtypeId", interlinerRowData?.switchtypeId)
            setValue("countryId", interlinerRowData?.countryId)
            setValue("cityID", interlinerRowData?.cityID)
            setValue("renewalDate", moment(interlinerRowData?.renewalDate).utc().format('YYYY-MM-DD'))
            setValue("reportTypeID", interlinerRowData?.reportTypeID)
            setValue("insuranceInfo", interlinerRowData?.insuranceInfo)
            setValue("provineStateID", interlinerRowData?.provineStateID)
            setValue("partnerAgent", interlinerRowData?.partnerAgent)
            setValue("csaApprovedDriver", interlinerRowData?.csaApprovedDriver)
            setValue("emailFTPRouting", interlinerDetails?.emailFTPRouting)
            setValue("payrollCycleId", interlinerRowData?.payrollCycleId)
            setValue("disPathcherId", interlinerRowData?.disPathcherId)
            setValue("alphaCode", interlinerRowData?.alphaCode)
        }
    }, [interlinerRowData])



    const onSubmit = async (data) => {
        data.interlinersRateCardDTOs = rateCardSection
        data.disPathcherId = 1
        console.log('data', data)
        const submitData = await addInterliner(data)
        if (submitData?.data?.statusCode === 200) {
            toast.success(submitData?.data?.message, {
                position: "top-center",
            })
            reset({
                driverId: "", companyname: "", switchtypeId: "", partnerAgent: "", csaApprovedDriver: "", streetNumber: "", streetName: "", unit: "", cityID: "", postalCode: "",
                phoneExtension: "", fax: "", primaryNumber: "", secondaryNumber: "", tollFreeNo: "", hireDate: "", payrollCycleId: "", cdnpars: "", usapaps: "", dockName: "", ourAccount: "",
                insuranceInfo: "", renewalDate: "", wsaibAccountNumber: "", wsibFirm: "", wsibFirmRenewalDate: "", emailFTProuting: "", email: "", ccEmialAddress: "", CountryId: "",
                ProvineStateID: "", reportTypeID: "", alphaCode: ""
            })
            refetch()
            setPostal("")
        }
    }

    const onUpdate = async (data) => {
        data.updateinterlinersRateCardDTOs = bb
        const updateData = await updateDatas({ interlinersId: interlinerRowData?.interlinersId, payNumber: interlinerRowData?.payNumber, ...data })
        if (updateData?.data?.statusCode === 200) {
            toast.success(updateData?.data?.message, {
                position: "top-center",
            })
            reset({
                driverId: "", companyname: "", switchtypeId: "", partnerAgent: "", csaApprovedDriver: "", streetNumber: "", streetName: "", unit: "", cityID: "", postalCode: "",
                phoneExtension: "", fax: "", primaryNumber: "", secondaryNumber: "", tollFreeNo: "", hireDate: "", payrollCycleId: "", cdnpars: "", usapaps: "", dockName: "", ourAccount: "",
                insuranceInfo: "", renewalDate: "", wsaibAccountNumber: "", wsibFirm: "", wsibFirmRenewalDate: "", emailFTProuting: "", email: "", ccEmialAddress: "", CountryId: "",
                ProvineStateID: "", reportTypeID: "", alphaCode: ""
            })
            refetch()
            setPostal("")
        }
    }

    
    const handleInput = e => {
        const formattedPostalCode = postalCodeMasking(e.target.value)
        if (e.target.name === "postalCode") {
            setPostal(formattedPostalCode)
        }
    };


    const productImageField = register("CountryId", { required: 'Field is required', });
    const productImageField1 = register("ProvineStateID", { required: 'Field is required', });
    const cityField = register("cityID", { required: 'Field is required', });
    return (
        <>
            <fieldset className="user-role-form mt-4 mb-3">
                <label className="label-heading">Add Interliners/Agent</label>
                {
                    !interlinerRowData ?
                        <div className="save-btn">
                            <Button onClick={handleSubmit(onSubmit)}><Save /> Save</Button>
                        </div>
                        :
                        <div className="save-btn">
                            <Button onClick={handleSubmit(onUpdate)}><Save /> Update</Button>
                        </div>
                }
                {/* Form field */}
                <>
                    <Row>
                        <Col sm={2}>
                            <Form.Group controlId="country">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select driver number">
                                    <Form.Select aria-label="Floating label select example" name="driverId" {...register('driverId', { required: 'Field is required', })}>
                                        <option value="">Select</option>
                                        <>
                                            {
                                                driverlist?.data?.map((data, index) => (
                                                    <option key={index} value={data?.empDriverId} selected={data?.empDriverId === interlinerRowData?.driverId ? true : false}>{data?.driverNumber}</option>
                                                ))
                                            }
                                        </>
                                    </Form.Select>
                                </FloatingLabel>
                                <p className="error-message">{errors.driverId?.message}</p>
                            </Form.Group>

                        </Col>
                        <Col sm={2}>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Company name" name="companyname" {...register('companyname', { required: 'Field is required', })} defaultValue={interlinerDetails?.companyName} />
                                <label htmlFor="Company name">Company name</label>
                                <p className="error-message">{errors.companyname?.message}</p>
                            </div>
                        </Col>
                        <Col sm={2}>
                            <Form.Group controlId="country">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select switch interliner type">
                                    <Form.Select aria-label="Floating label select example" name="switchtypeId" {...register('switchtypeId', { required: 'Field is required', })}>
                                        <option value="">Select</option>
                                        <>
                                            {interliner?.data?.map((data, index) => (
                                                <option key={index} value={data?.switchtypeId} selected={data?.switchtypeId === interlinerRowData?.switchtypeId}>{data?.switchTypeName}</option>
                                            ))}
                                        </>
                                    </Form.Select>
                                </FloatingLabel>
                                <p className="error-message">{errors.switchtypeId?.message}</p>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" name="partnerAgent" value={partnerAgent} onClick={handleInputChange}  {...register('partnerAgent', { required: 'Field is required' })} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1" style={{ fontSize: "15px" }}>Partner Agent</label>
                            </div>
                        </Col>
                        { partnerAgent &&
                        <Col>
                            <div className="form-check form-check-inline">
                                <input type="text" id="inlineCheckbox1" name="alphaCode"  {...register('alphaCode', { required: 'Field is required' })} />
                            </div>
                        </Col> 
                        }
                        <Col>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox2" name="csaAapprovedDriver"  {...register('csaApprovedDriver', { required: 'Field is required' })} />
                                <label className="form-check-label" htmlFor="inlineCheckbox2" style={{ fontSize: "15px" }}>CSA Approved Carrier</label>
                            </div>
                        </Col>
                    </Row>
                    {/* 2nd Row */}
                    <Row className="mt-3">
                        <Col sm={2}>
                            <div className="form-floating">
                                <Form.Control type="number" autoComplete="off" placeholder="Street No." name="streetNumber" {...register('streetNumber', { required: 'Field is required', pattern: (/^[0-9]+$/) })}
                                    defaultValue={interlinerDetails?.streetNumber} min="1" />
                                <label htmlFor="Street No.">Street No.</label>
                                <p className="error-message">{errors.streetNumber?.message}</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Street Name" name="streetName" {...register('streetName', { required: 'Field is required', })} defaultValue={interlinerDetails?.streetName} />
                                <label htmlFor="Street Name">Street Name</label>
                                <p className="error-message">{errors.streetName?.message}</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="number" autoComplete="off" placeholder="Unit" name="unit" {...register('unit', { required: 'Field is required', })}
                                    defaultValue={interlinerDetails?.unit} min="1" />
                                <label htmlFor="Unit">Unit</label>
                                <p className="error-message">{errors.unit?.message}</p>
                            </div>
                        </Col>
                        <Col>
                            <Form.Group controlId="country">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select Country">
                                    <Form.Select aria-label="Floating label select example" name="CountryId" {...productImageField} onClick={(e) => { productImageField.onChange(e); handlecountry(e); }}>
                                        <option value={interlinerRowData?.interlinerId ? interlinerRowData?.countryId : ""}>Select</option>
                                        <>
                                            {country?.data?.map((data, index) => (
                                                <option key={index} value={data?.countryId}
                                                    selected={data?.countryId === interlinerRowData?.countryId ? true : false}>
                                                    {data?.countryname}</option>
                                            ))}
                                        </>
                                    </Form.Select>
                                </FloatingLabel>
                                <p className="error-message">{errors.CountryId?.message}</p>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="province">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select Province">
                                    <Form.Select aria-label="Floating label select example" name="provineStateID" {...productImageField1} onChange={(e) => { productImageField1.onChange(e); setSelectState(e.target.value);handleCity(e) }}>
                                        <option value={interlinerRowData?.provineStateID ? interlinerRowData?.provineStateID : ""}>{interlinerRowData?.provineState || "Select"}</option>
                                        <>
                                            {
                                                province?.data?.map((data, index) => (
                                                    <option key={index} value={data?.provineStateID} selected={data?.provineStateID === interlinerDetails?.provineStateID ? true : false}>{data?.provineSateName}</option>
                                                ))
                                            }
                                        </>
                                    </Form.Select>
                                </FloatingLabel>
                                <p className="error-message">{errors.provineStateID?.message}</p>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="City">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select City">
                                    <Form.Select aria-label="Floating label select example" name="cityID" value={selectCity || ""} {...cityField} onChange={(e) => {cityField.onChange(e);setSelectCity(e.target.value)}}>
                                        <option value={interlinerRowData?.cityID ? interlinerRowData?.cityID : ""}>Select</option>
                                        {city?.data?.map((data, index) => (
                                            <option key={index} value={data?.id} selected={data?.id === interlinerRowData?.cityID}>{data?.city}</option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                                <p className="error-message">{errors.cityID?.message}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* 3rd row */}
                    <Row className="mt-3">
                        <Col sm={2}>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Postal Code" name="postalCode" value={postal} {...register('postalCode', { required: " Field is required" })}                              
                                 onChange={(e) => handleInput(e)}/>
                                <label htmlFor="Postal Code">Postal Code</label>
                                <p className="error-message">{errors.postalCode?.message}</p>
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="form-floating">
                                <Form.Control type="number" autoComplete="off" placeholder="Phone Extension" name="phoneExtension" {...register('phoneExtension',
                                    { required: " Field is required", maxLength: { value: 5, message: "Maximum length is 5 characters"}})}
                                    maxLength="5" />
                                <label htmlFor="Phone Extension">Phone Extension</label>
                                <p className="error-message">{errors.phoneExtension?.message}</p>
                            </div>
                        </Col>
                        <Col sm={2}>
                            <div className="form-floating">
                                <Controller type="number" placeholder="Fax"
                                    name="fax"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <InputMask mask="(999) - 999 9999" value={value || interlinerDetails?.fax || ""} onChange={onChange}>
                                            {(inputProps) => (
                                                <input
                                                    {...inputProps}
                                                    type="tel"
                                                    className="form-control"
                                                    placeholder="Fax"
                                                    name="fax"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                    {...register("fax", { required: 'Field is required', minLength: { value: 4, message: 'Please enter 10 digit contact number' } })} />
                                <label htmlFor="contact">Fax</label>
                            </div>
                            {errors.fax?.message && (<p className="error-message">{" * " + errors.fax?.message}</p>)}
                        </Col>
                        <Col sm={2}>
                            <div className="form-floating">
                                <Controller type="number" placeholder="Primary Number"
                                    name="primaryNumber"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <InputMask mask="(999) - 999 9999" value={value || interlinerDetails?.primaryNumber || ""} onChange={onChange}>
                                            {(inputProps) => (
                                                <input
                                                    {...inputProps}
                                                    type="tel"
                                                    className="form-control"
                                                    placeholder="Primary Number"
                                                    name="primaryNumber"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                    {...register("primaryNumber", { required: 'Field is required', minLength: { value: 4, message: 'Please enter 10 digit contact number' } })} />
                                <label htmlFor="contact">Primary Number</label>
                            </div>
                            {errors.primaryNumber?.message && (<p className="error-message">{" * " + errors.primaryNumber?.message}</p>)}
                        </Col>
                        <Col sm={2}>
                            <Col>
                                <div className="form-floating">
                                    <Controller type="number" placeholder="Primary Number"
                                        name="secondaryNumber"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <InputMask mask="(999) - 999 9999" value={value || interlinerDetails?.secondaryNumber || ""} onChange={onChange}>
                                                {(inputProps) => (
                                                    <input
                                                        {...inputProps}
                                                        type="tel"
                                                        className="form-control"
                                                        placeholder="Secondary Number"
                                                        name="secondaryNumber"
                                                    />
                                                )}
                                            </InputMask>
                                        )}
                                        {...register("secondaryNumber", { required: 'Field is required', minLength: { value: 4, message: 'Please enter 10 digit contact number' } })} />
                                    <label htmlFor="contact">Secondary Number</label>
                                </div>
                                {errors.secondaryNumber?.message && (<p className="error-message">{" * " + errors.secondaryNumber?.message}</p>)}
                            </Col>
                        </Col>
                        <Col sm={2}>
                            <Col>
                                <div className="form-floating">
                                    <Controller type="number" placeholder="Toll Free No."
                                        name="tollFreeNo"
                                        control={control}
                                        render={({ field: { onChange, value } }) => (
                                            <InputMask mask="(999) - 999 9999" value={value || interlinerDetails?.tollFreeNo || ""} onChange={onChange}>
                                                {(inputProps) => (
                                                    <input
                                                        {...inputProps}
                                                        type="tel"
                                                        className="form-control"
                                                        placeholder="Toll Free No."
                                                        name="tollFreeNo"
                                                    />
                                                )}
                                            </InputMask>
                                        )}
                                        {...register("tollFreeNo", { required: 'Field is required', minLength: { value: 4, message: 'Please enter 10 digit contact number' } })} />
                                    <label htmlFor="contact">Toll Free No.</label>
                                </div>
                                {errors.tollFreeNo?.message && (<p className="error-message">{" * " + errors.tollFreeNo?.message}</p>)}
                            </Col>
                        </Col>
                    </Row>
                    {/* 4th Row */}
                    <Row className="mt-3">
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="date" autoComplete="off" placeholder="Hire date" name="hireDate" {...register('hireDate', { required: 'Field is required', })} />
                                <label htmlFor="Hire date">Hire date</label>
                                <p className="error-message">{errors.hireDate?.message}</p>
                            </div>
                        </Col>
                        <Col>
                            <Form.Group controlId="City">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select pay-cycle">
                                    <Form.Select aria-label="Floating label select example" name="payrollCycleId" {...register('payrollCycleId', { required: !interlinerRowData && 'Field is required', })}>
                                        <option value="">Select</option>
                                        <>
                                            {payrollCycle?.data?.map((data, index) => (
                                                <option key={index} value={data?.payrollCycleId} selected={data?.payrollCycleId === interlinerRowData?.payrollCycleId ? true : false}>{data?.payrollCyclename}</option>
                                            ))}

                                        </>
                                    </Form.Select>
                                </FloatingLabel>
                                <p className="error-message">{errors.payrollCycleId?.message}</p>
                            </Form.Group>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="number" autoComplete="off" placeholder="CDN P.A.R.S" name="cdnpars" {...register('cdnpars', { required: 'Field is required' })} />
                                <label htmlFor="CDN P.A.R.S">CDN P.A.R.S</label>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="number" autoComplete="off" placeholder="USA P.A.P.S" name="usapaps" {...register('usapaps', { required: 'Field is required' })} />
                                <label htmlFor="USA P.AP.S">USA P.AP.S</label>
                            </div>
                        </Col>
                    </Row>
                    {/* 5th row */}
                    <Row className="mt-3">
                        <Col sm={3}>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Dock name" name="dockName" {...register('dockName', { required: ' Field is required' })} />
                                <label htmlFor="dockname">Dock name</label>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div className="form-floating">
                                <Form.Control type="number" autoComplete="off" placeholder="Our account at the carrier" name="ourAccount" {...register('ourAccount', { required: ' Field is required' })} />
                                <label htmlFor="dockname">Our account at the carrier</label>
                            </div>
                        </Col>
                    </Row>
                    {/* 6th row */}
                    <fieldset className="user-role-form mt-3" style={{ background: "#e1e1e1" }}>
                        <Row className="3">
                            <Col sm={8}>
                                <div className="form-floating">
                                    <Form.Control type="text" autoComplete="off" placeholder="Insurance info" name="insurenceInfo" {...register('insurenceInfo', { required: 'Field is required', })} />
                                    <label htmlFor="Insurance info">insurance Info</label>
                                </div>
                            </Col>
                            <Col sm={4}>
                                <div className="form-floating">
                                    <Form.Control type="date" autoComplete="off" placeholder="Renewal date" name="renewalDate" {...register('renewalDate', { required: 'Field is required', })} />
                                    <label htmlFor="Renewal date">Renewal date</label>
                                </div>
                            </Col>
                        </Row>
                    </fieldset>
                    {/* 7th row */}
                    <fieldset className="user-role-form mt-3" style={{ background: "#e1e1e1" }}>
                        <Row className="3">
                            <Col>
                                <div className="form-floating">
                                    <Form.Control type="number" autoComplete="off" placeholder="WSIB Account Number" name="wsaibAccountNumber" {...register('wsaibAccountNumber', {})} />
                                    <label htmlFor="WSIB Account Number">WSIB Account Number</label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-floating">
                                    <Form.Control type="text" autoComplete="off" placeholder="WSIB Firm" name="wsibFirm" {...register('wsibFirm', {})} defaultValue={interlinerDetails?.wsibFirm} />
                                    <label htmlFor="WSIB Firm">WSIB Firm</label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-floating">
                                    <Form.Control type="date" autoComplete="off" placeholder="WSIB Renewal Date" name="wsibFirmRenewalDate" {...register('wsibFirmRenewalDate', {})} defaultValue={interlinerDetails?.wsibFirmRenewalDate} />
                                    <label htmlFor="WSIB Renewal Date">WSIB Renewal Date</label>
                                </div>
                            </Col>
                        </Row>
                    </fieldset>
                    {/* 8th row */}
                    <fieldset className="user-role-form mt-4 mb-3" style={{ background: "#3c65bb80" }}>
                        <label className="label-heading">Email section</label>
                        <Row>
                            <Col sm={3}>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" name="emailFTProuting" {...register('emailFTProuting', {})} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1" style={{ fontSize: "15px" }}>Ask to email FTP Routing</label>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <Form.Group controlId="drivernumber">
                                    <FloatingLabel controlId="floatingSelectGrid" label="Select report type">
                                        <Form.Select aria-label="Floating label select example" name="reportTypeID" {...register('reportTypeID', {})}>
                                            <option value="">Select</option>
                                            <>
                                                {reportType?.data?.map((data, index) => (
                                                    <option key={index} value={data.reportTypeID} selected={data?.reportTypeID === interlinerRowData?.reportTypeID}>{data?.reportTypeName}</option>
                                                ))}
                                            </>
                                        </Form.Select>
                                    </FloatingLabel>
                                </Form.Group>
                            </Col>
                            <Col>
                                <div className="form-floating">
                                    <Form.Control type="email" autoComplete="off" placeholder="Email" name="email" {...register("email", {})} />
                                    <label htmlFor="Email">Email</label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-floating">
                                    <Form.Control type="email" autoComplete="off" placeholder="CC email address" name="ccEmialAddress" {...register('ccEmialAddress', {})} />
                                    <label htmlFor="CC email address">CC email address</label>
                                </div>
                            </Col>
                        </Row>
                    </fieldset>
                    {/* Rate Card section */}
                    <RateCard rateCardSection={rateCardSection} interlinerRowData={interlinerRowData} getInterlinerRowData={getInterlinerRowData} bb={bb} />
                </>
            </fieldset>
        </>
    )
}
export default AddInterliners