import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { GridToolbar } from '@mui/x-data-grid-premium';
import { calendarPickerClasses } from '@mui/x-date-pickers';
import { Resizable } from 're-resizable';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useGetAccessorialCodesQuery, useLazyGetAssignedOrdersToDriverQuery, useLazyGetSelectedOrderDetailsQuery, useUpdateDriversOnOrderMutation } from '../../../features/Dispatch/DispatchSlice';

const OrdersTable = (props) => {
    const { data: accessorialCodes } = useGetAccessorialCodesQuery();
    const [cols, setCols] = useState([])
    const [getDetails] = useLazyGetSelectedOrderDetailsQuery();
    const [updateDriversOnOrder] = useUpdateDriversOnOrderMutation();
    const [selectedRow, setSelectedRow] = useState(null);
    const [getAssignedOrdersToDriver] = useLazyGetAssignedOrdersToDriverQuery();

    useEffect(() => {
        if (accessorialCodes) {
            const codes = accessorialCodes?.data?.map((code) => {
                return {
                    field: code?.accessorialCode,
                    headerName: code?.accessorialCode,
                    width: 80,
                    editable: true,
                }
            })
            setCols([...columns, ...codes])
        }
    }, [accessorialCodes])

    const updateDriverForOrder = async (params) => {
        if (!props.selectedDriver) return;
        const id = params.id;
        const row = rows.find((row) => row.id === id);
        const res = await getDetails({ orderId: row.id, divisionId: props.divisionId });
        const orderStatus = res?.data?.data?.orderStatus === 'Dispatched' ? 2 : 3;
        const shouldOverride = orderStatus === 2
        const field = params.field;
        const DTO = {
                orderId: id,
                divisionId: props.divisionId,
                pickupDriverNumber: field === 'DDrive' && shouldOverride
                ? props.selectedDriver.driverNumber // Override with the same as dropOffDriverNumber
                : field === 'PDriv'
                ? props.selectedDriver.driverNumber
                : params.row.PDriv || null,
                dropOffDriverNumber: field === 'DDrive' ? props.selectedDriver.driverNumber : params.row.DDrive || null,
                orderStatusID: orderStatus
            };
        
        await updateDriversOnOrder(DTO);
        const updatedFields = {};
        if (field === 'PDriv') {
            updatedFields.pickUpDriver = props.selectedDriver.driverNumber;
                 props.setOrdersTable((prevOrdersTable) =>
                prevOrdersTable.map((order) =>
                        order.orderId === id
                            ? { ...order, ...updatedFields } 
                            : order
                    ));
        } else if (field === 'DDrive') {
            if(shouldOverride) {
                updatedFields.pickUpDriver = props.selectedDriver.driverNumber;
            }
            updatedFields.deliveryDriver = props.selectedDriver.driverNumber;
            props.setOrdersTable((prevOrdersTable) =>
                prevOrdersTable
                    .filter((order) => {
                        if (order.orderId === id &&
                            props.selectedDriver.driverNumber !== order.deliveryDriver) {
                            return false;
                        }
                        return true; 
                    })
                    .map((order) =>
                        order.orderId === id
                            ? { ...order, ...updatedFields } 
                            : order
                    )
            );
        }
        
        const orders = await getAssignedOrdersToDriver({
            divisionId: props.divisionId,
            driverNumber: props.selectedDriver.driverNumber
        });
        const result = await props.getSelectedDriverDetails({divisionId: props.divisionId, driverNumber: props.selectedDriver?.driverNumber});
        props.setSelectedDriverOrders(result?.data?.data?.dispatchedOrderDetails);
        const shouldUpdateOnBoardTable = orderStatus === 3;
        if (shouldUpdateOnBoardTable) {
            if (!params.row.DDrive && field === 'PDriv') {
                props.setOnBoardOrders((prevOnBoardOrders) =>
                    prevOnBoardOrders.filter((order) => order.orderId !== id)
                );
                props.setOrdersTable((prevOrdersTable) =>
                    prevOrdersTable.filter((order) => order.orderId !== id)
                );
            } else if (params.row.DDrive && params.row.DDrive === props.selectedDriver.driverNumber) {
                props.setOnBoardOrders((prevOnBoardOrders) =>
                    prevOnBoardOrders.filter((order) => order.orderId !== id)
                );
            } else {
                props.setOnBoardOrders(orders.data.data);
            }
        }
        props.setAssignedOrders(orders.data.data);
    };

    const columns = [
        {
            field: 'ReadyTime',
            headerName: 'Ready Date & Time',
            type: calendarPickerClasses,
            width: 200,
            editable: false,
        },
        {
            field: 'BookingTime',
            headerName: 'Booking Date & Time',
            type: calendarPickerClasses,
            width: 200,
            editable: false,
        },
        {
            field: 'St',
            headerName: 'St#',
            //type: 'number',
            width: 80,
            editable: false,
        },
        {
            field: 'StreetName',
            headerName: 'Street Name',
            width: 150,
            editable: false,
        },
        {
            field: 'City',
            headerName: 'City',
            width: 120,
            editable: false,
        },
        {
            field: 'PDropNumber',
            headerName: 'Drop #',
            width: 60,

        },
        {
            field: 'Map',
            headerName: 'Map',
            width: 80,
            editable: false,
        },
        {
            field: 'Vehicle',
            headerName: 'Vehicle',
            width: 150,
            editable: false,
        },
        {
            field: 'Service',
            headerName: 'Service',
            width: 150,
            editable: false,
        },
        {
            field: 'PDriv',
            headerName: 'P-Driver',
            width: 80,
            editable: false,
        },
        {
            field: 'DDrive',
            headerName: 'D-Driver',
            width: 80,
            editable: false,
        },
        {
            field: 'DSt',
            headerName: 'St#',
            //type: 'number',
            width: 60,
            editable: false,
        },
        {
            field: 'DStreetName',
            headerName: 'Street Name',
            width: 110,
            editable: false,
        },
        {
            field: 'DCity',
            headerName: 'City',
            width: 120,
            editable: false,
        },
        {
            field: 'DDropNumber',
            headerName: 'Drop #',
            width: 60,
            
        },
        {
            field: 'DMap',
            headerName: 'Map',
            width: 40,
            editable: false,
        },
        {
            field: 'Pcs',
            headerName: 'Pcs',
            width: 40,
            editable: false,
        },
        {
            field: 'Wgt',
            headerName: 'Weight',
            width: 90,
            editable: false,
        },
        {
            field: 'Expirytime',
            headerName: 'Expiry time (min.)',
            width: 140,
            editable: false,
        },
    
    ];

    const rows = props.ordersTable?.map((order) => {
        return {
            id: order?.orderId,
            Action: 1,
            // ReadyTime: order?.readyDate,
            ReadyTime: order?.readyDate
            ? new Date(order?.readyDate).toLocaleString('en-US', { 
                hour: '2-digit', 
                minute: '2-digit', 
                hour12: true, 
                year: 'numeric', 
                month: '2-digit', 
                day: '2-digit' 
              })
            : '',
            St: order?.pickupStreetNumber,
            StreetName: order?.pickupStreetName,
            City: order?.pickupCity,
            Map: order?.map,
            ...order?.accessorialCodesForOrder,
            Vehicle: order?.vehicle,
            Service: order?.service,
            PDriv: order?.pickUpDriver,
            DDrive: order?.deliveryDriver,
            DSt: order?.dropoffStreetNumber,
            DStreetName: order?.dropoffStreetName,
            DCity: order?.dropoffCity,
            DMap: order?.dMap,
            DA: order?.dA,
            TC: order?.tc,
            HV: order?.hv,
            RS: order?.rs,
            DTG: order?.dtg,
            HZ: order?.hz,
            Pcs: order?.pcs,
            Wgt: order?.weight,
            Expirytime: order?.expiryTime,
            // BookingTime: order?.orderBookingDate
            BookingTime: order?.orderBookingDate
            ? new Date(order?.orderBookingDate).toLocaleString('en-US', { 
                hour: '2-digit', 
                minute: '2-digit', 
                hour12: true, 
                year: 'numeric', 
                month: '2-digit', 
                day: '2-digit' 
              })
            : '',
        }
    })

    const nowOrders = rows?.filter((order) => {
        const bookingTime = new Date(order?.ReadyTime);
        const currentTime = new Date();
        const timeDifference = bookingTime - currentTime;
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        return hoursDifference <= 4;
    });

    const laterOrders = rows?.filter((order) => {
        const bookingTime = new Date(order?.ReadyTime);
        const currentTime = new Date();
        const timeDifference = bookingTime - currentTime;
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        return hoursDifference > 4;
    });
    
    const visibilityJSON = localStorage.getItem("bonds_visibility")
    const visibilityModel = (visibilityJSON) ? JSON.parse(visibilityJSON) : {}
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(visibilityModel)
    const filterJSON = localStorage.getItem("bonds_filter")
    const initialFilterModel = (filterJSON) ? JSON.parse(filterJSON) : {items: []}
    const [filterModel, setFilterModel] = useState(initialFilterModel)
    

    return (
        <Resizable defaultSize={{ width: "auto", height: "auto" }}>
            <div className="table-back">
                <div className="table-header-section">
                    <div className='table-header'><h5>Orders</h5></div>
                </div>
                <div>
                    <button className="float-end" style={{fontSize: '11px', marginTop: '5px', marginRight: '10px', borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', padding: '5px'}} onClick={() =>{ 
                        props.setShowAssignedOrders(false)
                        props.setSelectedOrder(null)
                        }}>Exit</button>
                </div>
                <Box sx={{ height: 500, width: '100%' }}>
                    <DataGrid
                        onCellClick={(cell) => updateDriverForOrder(cell)}
                        rows={props.nowLater === 'now' ? nowOrders : laterOrders}
                        columns={cols}
                        pageSize={10}
                        rowsPerPageOptions={[1]}
                        components={{ Toolbar: GridToolbar }}
                        onRowClick={async (r) => {
                            setSelectedRow(r.row.id);                            
                            const result = await getDetails({ orderId: r.row.id, divisionId: props.divisionId });
                            props.setSelectedOrder(result.data.data);
                        }}
                        getRowClassName={(params) => {
                            return params.id === selectedRow ? 'selected-row' : '';
                        }}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) => {
                            localStorage.setItem("bonds_visibility", JSON.stringify(newModel))
                            setColumnVisibilityModel(newModel)
                        }}
                        filterModel={filterModel}
                        onFilterModelChange={(newModel) => {
                            localStorage.setItem("bonds_filter", JSON.stringify(newModel))
                            setFilterModel(newModel)
                        }}
                    />
                </Box>
            </div>
        </Resizable>
    );
}
export default OrdersTable;