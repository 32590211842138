import { Delete, Edit, Save } from "@material-ui/icons";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useCustomer from "../../../context/CustomerContext";
import { useAddCustomerCityDistanceAfterEditMutation, useDeleteCityDistanceDataMutation, useUpdateCityDistanceDataMutation } from "../../../features/cityDistance/cityDistanceSlice";
import { useModifyHistoryMutation } from "../../../features/customer/history/historySlice";
import CountryProvince from "../../CountryProvince";
import { useCustomData } from "../../Sidebar/Permission";
import useDeleteData from "../useDeleteData";

const EditCityDistance = () => {
    const { setSelectState, handlecountry, province, handleCity, city, handleProvince, newHandleCountry } = CountryProvince()
    const { contractInfo } = useCustomer();
    const ratecardaccount = contractInfo && contractInfo[0]
    const [permission] = useCustomData()
    const [toProvince, setToProvince] = useState([]);
    const [fromProvince, setFromProvince] = useState([]);
    const cityList = city?.data.map((a) => { return { name: a.city, id: a.id } });
    const toCityList = cityList;
    const fromCityList = cityList;
    const [toCountryList, setToCountryList] = useState([]);
    const [fromCountryList, setFromCountryList] = useState([]);
    const [toSelection, setToSelection] = useState([]);
    const [fromSelection, setFromSelection] = useState([]);
    const [updateCityDistance] = useUpdateCityDistanceDataMutation()
    const [deleteCityDistance] = useDeleteCityDistanceDataMutation()
    const [cityAfterEdit] = useAddCustomerCityDistanceAfterEditMutation()
    const [handleClose, handleOpen, open, deleteData] = useDeleteData(deleteCityDistance)
    const [modifyHistory] = useModifyHistoryMutation()
    const { handleAccount, singleAccount, cityDistanceList } = useCustomer();
    const account = singleAccount && singleAccount[0];
    const [isEditItem, setIsEditItem] = useState(null);
    const [cityDistance, setCityDistance] = useState({})
    const [showDistanceSecondFields, setShowDistanceSecondFields] = useState(false);
    const [editId, setEditId] = useState('')
    const [prev, setPrev] = useState({});

    useEffect(() => {
        const handleSelection = async (selection, setProvince, setCountry, cityKey, provinceKey, countryKey) => {
            if (selection.length > 0) {
                const pList = await handleProvince(selection[0].id);
                setProvince(pList.data);
                setCityDistance(cityDistance => ({ ...cityDistance, [cityKey]: selection[0].id, }));
                if (pList.data.data.length === 1) {
                    const selectedProvince = pList.data.data[0];
                    const cList = await newHandleCountry(selectedProvince.provineStateID);
                    setCountry(cList.data);
                    setCityDistance(cityDistance => ({
                        ...cityDistance,
                        [provinceKey]: selectedProvince.provineStateID,
                        [countryKey]: cList.data.data[0]?.countryId || null,
                    }));
                }
            } else {
                setProvince([]);
                setCountry([]);
            }
        };
        handleSelection(fromSelection, setFromProvince, setFromCountryList, 'fromCity', 'fromProvince', 'fromCountry');
        handleSelection(toSelection, setToProvince, setToCountryList, 'toCity', 'toProvince', 'toCountry');
    }, [fromSelection, toSelection]);

    const setCountry = async (e) => {
        const findByidFromProvince = fromProvince?.data?.find(p => p.provineStateID === Number(e.target.value));
        const findByidToProvince = toProvince?.data?.find(p => p.provineStateID === Number(e.target.value));
        if (e.target.value !== 'Select') {
            let cList;
            if (e.target.name === "fromProvince") {
                cList = await newHandleCountry(findByidFromProvince.provineStateID);
                setFromCountryList(cList.data);
            }
            else if (e.target.name === "toProvince") {
                cList = await newHandleCountry(findByidToProvince.provineStateID);
                setToCountryList(cList.data);
            }
        }
        else {
            if (e.target.name === "fromProvince") {
                setFromCountryList([]);
            }
            else {
                setToCountryList([]);
            }
        }
    }

    const getProvinceNameById = (id) => {
        const ret = province.data.find(p => p.provineStateID == id);
        return ret ? ret.provineSateName : null;
    };

    const Cityupdate = (e) => {
        setCityDistance(cityDistance => ({ ...cityDistance, [e.target.name]: e.target.value }));

        if (e.target.name === "fromProvince") {
            setCityDistance(cityDistance => ({ ...cityDistance, fromProvinceName: getProvinceNameById(e.target.value) }));
        }
        else if (e.target.name === "toProvince") {
            setCityDistance(cityDistance => ({ ...cityDistance, toProvinceName: getProvinceNameById(e.target.value) }));
        }

    }

    const EditcityItem = async (citydistanceID) => {
        setEditId(citydistanceID)
        let newEditItem = cityDistanceList.find((data) => {
            return data.citydistanceID === citydistanceID
        });
        setCityDistance(newEditItem);
        setShowDistanceSecondFields(true)
        setIsEditItem(citydistanceID);
        setFromSelection(newEditItem.fromCityName ? [{ name: newEditItem.fromCityName, id: newEditItem.fromCity }] : []);
        setToSelection(newEditItem.toCityName ? [{ name: newEditItem.toCityName, id: newEditItem.toCity }] : []);
        setPrev(newEditItem);
        if (newEditItem.fromProvince) {
            const fromCountryList = await newHandleCountry(newEditItem.fromProvince);
            setFromCountryList(fromCountryList?.data || []);
        } else {
            setFromCountryList([]);
        }
        if (newEditItem.toProvince) {
            const toCountryList = await newHandleCountry(newEditItem.toProvince);
            setToCountryList(toCountryList?.data || []);
        } else {
            setToCountryList([]);
        }
    }
    const updateCitydata = {
        citydistanceID: cityDistance?.citydistanceID,
        fromCountry: cityDistance?.fromCountry,
        toCountry: cityDistance?.toCountry,
        fromCity: cityDistance?.fromCity,
        toCity: cityDistance?.toCity,
        fromProvince: cityDistance?.fromProvince,
        toProvince: cityDistance?.toProvince,
        miles: cityDistance?.miles,
        timeInMinutes: cityDistance?.timeInMinutes
    }
    const addCitydata = {
        rateCardID: ratecardaccount?.rateCardId,
        fromCountryId: cityDistance?.fromCountry,
        toCountryId: cityDistance?.toCountry,
        fromCityId: cityDistance?.fromCity,
        toCityId: cityDistance?.toCity,
        fromProvinceId: cityDistance?.fromProvince,
        toProvinceId: cityDistance?.toProvince,
        miles: cityDistance?.miles,
        timeInMinutes: cityDistance?.timeInMinutes
    }


    const editCityCustomerList = async () => {
        if (JSON?.stringify(prev) === JSON?.stringify(cityDistance)) {
            setTimeout(function () {
                toast.info("No Changes found on this City distance section !", {
                    position: "top-right",
                })
            });
        }
        else {
            const updateCityDistanceData = await updateCityDistance(updateCitydata)
            setCityDistance("");
            setFromSelection([]);
            setToSelection([]);
            setFromCountryList([]);
            setToCountryList([]);
            handleAccount(account?.account_No)
            if (updateCityDistanceData?.data?.statusCode === 200) {
                setTimeout(function () {
                    toast.success("City distance updated successfully !", {
                        position: "top-right",
                    })
                });
            }
        }
        if (JSON?.stringify(prev) !== JSON?.stringify(cityDistance)) {
            const modifieddata = {
                AccountNo: account.account_No,
                CustomerName: account.customer,
                Modifiedsection: "Modified the city distance section"
            }
            await modifyHistory(modifieddata)
        }
    }
    const addNewSection = async () => {
        if (Object.keys(cityDistance).length === 0 || (Object.keys(cityDistance).length === 2 && 'fromCity' in cityDistance && 'toCity' in cityDistance)) { 
            setShowDistanceSecondFields(true)
        }
        else {
            const cityDistanceAfterEdit = await cityAfterEdit(addCitydata)
            setCityDistance("");
            handleAccount(account?.account_No)
            if (cityDistanceAfterEdit?.data?.statusCode === 200) {
                toast.success("City Distance record added successfully !", {
                    position: "top-right",
                })
                setFromSelection([]);
                setToSelection([]);
            }
        }
    }

    return (
        <>
            {/* City Distance section */}
            <Row className="mb-4 mt-4">
                <Col sm={12}>
                    <fieldset className="mb-3">
                        <label className="label-heading">City Distance Table</label>
                        <Row className="mb-2 mt-2">
                            <Col sm={12}>
                                <fieldset className="pb-2">
                                    <div className="d-flex justify-content-end">
                                        <div className="section_action_btn">
                                            {cityDistance?.citydistanceID ?
                                                <div className="save_btn"><button type="button" className="btn" onClick={e => { editCityCustomerList(e); }}>Update record</button></div>
                                                :
                                                <>
                                                    {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ?
                                                        <div className="add_btn"><button type="button" className="btn" onClick={e => { addNewSection(e); }}>Add new record</button></div>
                                                        : <div className="save_btn disable"><button type="button" className="btn"><Save /></button></div>}
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {showDistanceSecondFields ?
                                        <>
                                            <Row className="mt-2 mb-3">
                                                <Col sm={3}>
                                                    <Form.Group controlId="from" className="mb-2">
                                                        <Typeahead
                                                            id='cityAutocomplete'
                                                            labelKey="name"
                                                            onChange={setFromSelection}
                                                            options={fromCityList}
                                                            placeholder="Choose from city..."
                                                            selected={fromSelection}
                                                        />
                                                    </Form.Group>

                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="Province/State" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="From Province/State">
                                                            <Form.Select aria-label="Floating label select example" name="fromProvince" onChange={(e) => {
                                                                setSelectState(e.target.value);
                                                                Cityupdate(e);
                                                                handleCity(e);
                                                                setCountry(e);
                                                            }} value={cityDistance?.fromProvince || ""} required>
                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        fromProvince?.data?.map((data, index) => (
                                                                            <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                                                                        ))

                                                                    }
                                                                </>

                                                            </Form.Select>

                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="country" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="From Country">
                                                            <Form.Select aria-label="Floating label select example" onChange={e => { handlecountry(e); Cityupdate(e) }} placeholder="From Country" name="fromCountry" value={cityDistance?.fromCountry || ""} required>

                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        fromCountryList?.data?.map((data, index) => (
                                                                            <option key={index} value={data.countryId}>{data.countryname}</option>
                                                                        ))

                                                                    }
                                                                </>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="Miles">
                                                        <FloatingLabel controlId="floatingInput" label="Miles" >
                                                            <Form.Control type="number" placeholder="Miles" name="miles" onChange={e => Cityupdate(e)} value={cityDistance?.miles || ""} min='0' />
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {/* 2nd Row */}

                                            <Row className="mt-2 mb-3">
                                                <Col sm={3}>
                                                    <Form.Group controlId="from" className="mb-2">
                                                        <Typeahead
                                                            id='cityAutocomplete'
                                                            labelKey="name"
                                                            onChange={setToSelection}
                                                            options={toCityList}
                                                            placeholder="Choose to city..."
                                                            selected={toSelection}
                                                        />
                                                    </Form.Group>

                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="Province/State" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="To Province/State">
                                                            <Form.Select aria-label="Floating label select example" name="toProvince" placeholder="To Province/State" onChange={(e) => { Cityupdate(e); setCountry(e) }} value={cityDistance?.toProvince || ""} required>
                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        toProvince?.data?.map((data, index) => (
                                                                            <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                                                                        ))
                                                                    }
                                                                </>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="country" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="To Country">
                                                            <Form.Select aria-label="Floating label select example" onChange={e => { Cityupdate(e) }} placeholder="To Country" name="toCountry" value={cityDistance?.toCountry || ""} required>

                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        toCountryList?.data?.map((data, index) => (
                                                                            <option key={index} value={data.countryId} >{data.countryname}</option>
                                                                        ))

                                                                    }
                                                                </>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="In Minutes">
                                                        <FloatingLabel controlId="floatingInput" label="In Minutes" >
                                                            <Form.Control type="number" placeholder="In Minutes" name="timeInMinutes" onChange={e => Cityupdate(e)} value={cityDistance?.timeInMinutes || ""} required min='0' />
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                        : null}
                                    {/* table */}
                                    <Row>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Action</th>
                                                    <th>From City</th>
                                                    <th>To City</th>
                                                    <th>From Province</th>
                                                    <th>To Province</th>
                                                    <th>From Country</th>
                                                    <th>To Country</th>
                                                    <th>Miles</th>
                                                    <th>In Minutes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cityDistanceList?.length > 0 ? (
                                                    cityDistanceList?.map((data) => {
                                                        return (
                                                            <tr key={data?.citydistanceID}>
                                                                <td>
                                                                    <div className="action_btn">
                                                                        {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ?
                                                                            <span className="edit_btn" onClick={() => EditcityItem(data?.citydistanceID)}><Edit /></span>
                                                                            : <span className="edit_btn disable"><Edit /></span>}
                                                                        {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Delete') !== -1 ?
                                                                            <span className="del_btn" onClick={() => handleOpen(data?.citydistanceID)}><Delete /></span>
                                                                            : <span className="del_btn disable" ><Delete /></span>}
                                                                        <div>
                                                                            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                                                                <DialogTitle id="alert-dialog-title">
                                                                                    Delete Confirmation
                                                                                </DialogTitle>
                                                                                <DialogContent>
                                                                                    <DialogContentText id="alert-dialog-description">
                                                                                        Are you sure you want to delete this row?
                                                                                    </DialogContentText>
                                                                                </DialogContent>
                                                                                <DialogActions>
                                                                                    <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                                                                                    <Button className="btn-primary" onClick={() => { deleteData(data.citydistanceID); handleClose(); }} autoFocus> Confirm</Button>
                                                                                </DialogActions>
                                                                            </Dialog>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{data.fromCityName}</td>
                                                                <td>{data.toCityName}</td>
                                                                <td>{data.fromProvinceName}</td>
                                                                <td>{data.toProvinceName}</td>
                                                                <td>{data.fromCountryName}</td>
                                                                <td>{data.toCountryName}</td>
                                                                <td>{data.miles}</td>
                                                                <td>{data.timeInMinutes}</td>
                                                            </tr>
                                                        )
                                                    })
                                                ) : (
                                                    <p>No data found </p>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </fieldset>
                            </Col>
                        </Row>



                    </fieldset>
                </Col>
            </Row>

            {/* <ToastContainer /> */}

        </>
    );
}
export default EditCityDistance;