import { Col, Row, Form, FloatingLabel, Table, InputGroup, Button, } from "react-bootstrap";
import { AddCircle } from "@material-ui/icons";
import { useState, useEffect } from "react";
import { Delete, } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../context/CustomerContext";
import { addService, editService, deleteService } from "../../../features/service/serviceDetailSlice";
import { useGetServiceQuery, useGetAllServiceQuery } from "../../../features/service/serviceDetailSlice";
import { useForm, Controller } from 'react-hook-form';
import { useCustomData } from "../../Sidebar/Permission";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';

const ServiceSurchargeOptions = ({serviceRateValue, setServiceRateValue, setHasCWT, setHasPallet}) => {
  const [permission] = useCustomData()
  const { data: serviceList } = useGetAllServiceQuery()
  const { register, handleSubmit, watch, formState: { errors }, reset, onChange, control } = useForm();
  const dispatch = useDispatch();
  const { serviceLists, vehicleLists, setTempDisplay, multiplier } = useCustomer();
  const [isDelete, setIsDelete] = useState()
  const [open, setOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [serviceData, setServiceData] = useState({})
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const [update , setUpdate]=useState()
  const handleClickOpen = (id) => { setOpen(true); setIsDelete(id) };
  const handleClose = () => { setOpen(false); };
  const [selectedData, setSelectedData] = useState([])
  const [SelectedEditData, setSelectedEditData] = useState([])
  const [isEdit, setisEdit] = useState(false)
  const [vehicles, setVehicles] = useState([]);
  const [mult, setMult] = useState(1)

  useEffect(() => {
    if (multiplier) {
      setMult(1 + multiplier/100)
    }
    else {
      setMult(1)
    }
  }, [multiplier])

  useEffect(() => {
    const types = serviceLists.map(a => a.RateType);
    if (types.includes("CWT")) {
      setHasCWT(true);
    } else {
      setHasCWT(false);
    }
    if (types.includes("PALLET")) {
      setHasPallet(true);
    } else {
      setHasPallet(false);
    }
  }, [serviceLists])

  useEffect(() => {
    const vehicleData = serviceData?.VehicleTypes?.map((d) => d?.value)
  }, [serviceData])

  const Servicehandler = (e) => {
    setServiceData(serviceData => ({ ...serviceData, [e.target.name]: e.target.value }));
  }

  useEffect(() => {
    const vals = vehicleLists.filter(
      item =>{
        const rateType = item.VehicleType.split(" ").pop();
        return rateType.slice(1,-1) === rateTypeMap[serviceRateValue];
      }).map(item => {return {value: item.VehicleType, label: item.VehicleType}})
    setVehicles(vals);
  }, [serviceRateValue])

  const handleSelect = (e) => {
    if (isEdit) {
      const mapData = e?.map((item) => item?.value
      )
      if (mapData && mapData?.length > 0) {
        setSelectedEditData(mapData)
      }
    }
    const mapData = e?.map((item) => item?.value
    )
    if (mapData && mapData?.length > 0) {
      setSelectedData(mapData)
    }
  }

  const rateTypeMap = {1: 'LOCAL', 2:'CWT', 3:'PALLET',4:"DISTANCE-BASED"}
  const rateTypeMapReverse = {'LOCAL': 1,'CWT': 2, 'PALLET': 3, "DISTANCE-BASED": 4}

  const addServiceList = (serviceData) => {
    if (!isShow) {
      setIsShow(true);
      return;
    }
    serviceData.VehicleTypes = selectedData
    serviceData.RateType = rateTypeMap[serviceRateValue];
    if (Object.keys(serviceData).length === 1) {
      setIsShow(isShow => !isShow);
    }
    else if (editingTaskIndex === null) {
      setTempDisplay(false)  
      dispatch(addService({ VehicleTypes: serviceData?.VehicleTypes, ...serviceData }));

    } else {
      serviceData.VehicleTypes = SelectedEditData
      let editingItem = serviceLists[editingTaskIndex];
      editingItem = serviceData;
      setUpdate("update")
      dispatch(editService(editingItem));
    }
    setServiceData("");
    setEditingTaskIndex(null);
    setServiceRateValue(0);
    setIsUpdate(false)
    reset({
      ServiceDescription: "", AfterHourPercent: "", CostPerKM: "", BaseRateType: '', BaseRate: '', pieceInlcuded: "", BaseRateType: "", CrossoverRateType: "", CrossoverRate: "", PieceRate: "", WeightRate: "",
      WeightIncluded: "", WeightCalcType: "", WeightRange:"", WaitingTimeUnitRate: "", WaitingtimeUnit: "", FreeWaitingTime: "", PieceRateType: "", WeightRateType: "", WaitingTimeUnitRateType: ""
    });
    setSelectedData('');
  };
  // Edit vehicle list
  const editServiceList = (id) => {
    setisEdit(true)
    const obj = []
    let newEditItem = serviceLists.find((data) => {
      return data.id === id
    });
    setServiceRateValue(rateTypeMapReverse[newEditItem.RateType]);
    newEditItem?.VehicleTypes?.map((elem, i) => {
      obj.push({ value: elem, label: elem })
    })
    newEditItem.BaseRateType === 'A' ? setStatusS(1) : newEditItem.BaseRateType === 'P' ? setStatusS(2) : setStatusS(0)
    newEditItem.CrossoverRateType === 'A' ? setStatusSc(1) : newEditItem.CrossoverRateType === 'P' ? setStatusSc(2) : setStatusSc(0)
    newEditItem.PieceRateType === 'A' ? setStatusSp(1) : newEditItem.PieceRateType === 'P' ? setStatusSp(2) : setStatusSp(0)
    newEditItem.WeightRateType === 'A' ? setStatusSw(1) : newEditItem.WeightRateType === 'P' ? setStatusSw(2) : setStatusSw(0)
    setIsUpdate(true);
    setIsShow(isShow => !isShow);
    setServiceData(newEditItem)
    setSelectedData(obj)
    setSelectedEditData(newEditItem?.VehicleTypes)
    setEditingTaskIndex(id);
    reset(newEditItem)
  }

  const [statusS, setStatusS] = useState(0);
  const [statusSc, setStatusSc] = useState(0);
  const [statusSp, setStatusSp] = useState(0);
  const [statusSw, setStatusSw] = useState(0);

  const radioHandlerS = (statusS) => { setStatusS(statusS) };
  const radioHandlerSc = (statusSc) => { setStatusSc(statusSc) };
  const radioHandlerSp = (statusSp) => { setStatusSp(statusSp) };
  const radioHandlerSw = (statusSw) => { setStatusSw(statusSw) };

  const deleteHandler = () => {
    dispatch(deleteService(isDelete)); handleClose();
  }

  return (
    <>
      {/* Service surcharge section*/}
      <Row className="mb-2 mt-2">
        <Col sm={12}>
          <fieldset className="pb-2">
            <div className="section_action_btn">
              {
                !isUpdate ?
                  <>
                    {permission.find(event => event.moduleId === 42)?.permissionType?.indexOf('Add') !== -1 ?
                      <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(addServiceList)}><AddCircle /></button></div>
                      : <div className="add_btn disable"><button type="button" className="btn"><AddCircle /></button></div>}
                  </>
                  :
                  <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(addServiceList)}><Save /></button></div>
              }
            </div>
            {isShow ?
              <>
                <Row className="mt-2 mb-3">
                  <Col sm={3}>
                    <Form.Group controlId="serviceid">
                      <FloatingLabel controlId="floatingSelectGrid" label="Service">
                        <Form.Select aria-label="Floating label select example" name="ServiceDescription" onChange={onChange}
                          {...register('ServiceDescription', { required: 'Field is required' })}>
                          <option value="">Select</option>:
                          <>
                            {
                              serviceList?.data?.map((data, index) => (
                               rateTypeMapReverse[data.rateType] === serviceRateValue && <option key={index} value={data.ServiceDescription}>{data.description}</option>
                              ))
                             
                            }
                          </>
                        </Form.Select>
                      </FloatingLabel>
                      {errors.ServiceDescription?.message && (<p className="error-message">{" * " + errors.ServiceDescription?.message}</p>)}
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Controller
                      control={control}
                      name="VehicleTypes"
                      defaultValue={selectedData || ''}
                      {...register('VehicleTypes')}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          isMulti
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              height: "47px",
                            }),
                          }}
                          placeholder="Select Vehicle"
                          name={name}
                          defaultValue={selectedData || ''}
                          ref={ref}
                          options={vehicles}
                          // value = {serviceData.VehicleTypes?.map((d)=>d.value)?.join(' , ')}
                          value={selectedData ? selectedData?.find((e) => e.value === value) : ""}
                          onChange={(e) => handleSelect(e)}
                        />
                      )}
                    />
                    {errors.VehicleTypes?.message && (<p className="error-message">{" * " + errors.VehicleTypes?.message}</p>)}

                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Afterhour">
                      <FloatingLabel controlId="floatingInput" label="After Hour Percent (%)" >
                        <Form.Control type="number" placeholder="After Hour Percent" name="AfterHourPercent" onChange={onChange}
                          {...register('AfterHourPercent', { required: 'Field is required' })} min='0' />
                      </FloatingLabel>
                      {errors.AfterHourPercent?.message && (<p className="error-message">{" * " + errors.AfterHourPercent?.message}</p>)}
                    </Form.Group>
                  </Col>
                  {serviceRateValue === 4 &&
                  <Col sm={3}>
                    <div className="form-floating">
                      <Form.Control type="number" placeholder="Cost Per KM" name="CostPerKM" onChange={onChange}
                        {...register('CostPerKM', { required: 'Field is required' })} min='0' />
                      <label>Cost Per KM</label>
                    </div>
                    {errors.CostPerKM?.message && (<p className="error-message">{" * " + errors.CostPerKM?.message}</p>)}
                  </Col> }
                </Row>
                {serviceRateValue === 1 &&
                <Row className="mt-4 mb-3">
                  <Col sm={6}>
                    <fieldset className="mb-3">
                      <label className="label-heading">Base Rate Section</label>
                      <Row>
                        <Col sm={6}>
                          <div className="fieldset-inner">
                            <label className="label-heading">Base Rate Type</label>
                            <div className="floating-checbox" onChange={e => Servicehandler(e)}>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="BaseRateType" {...register("BaseRateType",{ required: 'Field is required' })} onChange={e => radioHandlerS(1)} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="BaseRateType"{...register("BaseRateType",{ required: 'Field is required' })} onChange={e => radioHandlerS(2)} />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                              </div>
                            </div>
                          </div>
                          {errors.BaseRateType?.message && (<p className="error-message">{" * " + errors.BaseRateType?.message}</p>)}
                        </Col>
                        <Col sm={6}>
                          {statusS === 0 &&
                            <div className="btn-status">
                              <div className="form-floating">
                                <InputGroup.Text >$</InputGroup.Text>
                                <Form.Control type="text" placeholder="Base Rate" name="BaseRateActive" {...register('BaseRateActive', { required: 'Please select the options' })} disabled />
                                <label>Base Rate ($)</label>
                              </div>
                              {errors.BaseRateActive?.message && (<p className="error-message">{" * " + errors.BaseRateActive?.message}</p>)}
                            </div>
                          }
                          {statusS === 1 &&
                            <div className="btn-status">
                              <div className="form-floating">
                                <InputGroup.Text >$</InputGroup.Text>
                                <Form.Control type="number" placeholder="Base Rate" name="BaseRate" {...register('BaseRate', { required: 'Field is required' })} min='0' />
                                <label>Base Rate ($)</label>
                              </div>
                              {errors.BaseRate?.message && (<p className="error-message">{" * " + errors.BaseRate?.message}</p>)}
                            </div>
                          }
                          {statusS === 2 &&
                            <div className="btn-status">
                              <div className="form-floating">
                                <InputGroup.Text >%</InputGroup.Text>
                                <Form.Control type="number" placeholder="Base Rate" name="BaseRate"  {...register('BaseRate', { required: 'Field is required' })} min='0' />
                                <label>Base Rate (%)</label>
                              </div>
                              {errors.BaseRate?.message && (<p className="error-message">{" * " + errors.BaseRate?.message}</p>)}
                            </div>
                          }
                        </Col>
                      </Row>
                    </fieldset>
                  </Col>
                  <Col sm={6}>
                    <fieldset className="mb-3">
                      <label className="label-heading">Crossover Rate Section</label>
                      <Row>
                        <Col sm={6}>
                          <div className="fieldset-inner">
                            <label className="label-heading">Choose the crossover rate type</label>
                            <div className="floating-checbox" onChange={e => Servicehandler(e)}>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="CrossoverRateType" {...register("CrossoverRateType",{ required: 'Field is required' })} onChange={e => radioHandlerSc(1)} />
                                <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="CrossoverRateType" {...register("CrossoverRateType",{ required: 'Field is required' })} onChange={e => radioHandlerSc(2)} />
                                <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                              </div>
                            </div>
                          </div>
                          {errors.CrossoverRateType?.message && (<p className="error-message">{" * " + errors.CrossoverRateType?.message}</p>)}
                        </Col>
                        <Col sm={6}>
                          {statusSc === 0 &&
                            <div className="btn-status">
                              <div className="form-floating">
                                <InputGroup.Text>$</InputGroup.Text>
                                <Form.Control type="number" placeholder="Crossover Rate" name="CrossoverRateActive" {...register('CrossoverRateActive', { required: 'Please select the options' })} disabled />
                                <label>Crossover Rate ($)</label>
                              </div>
                              {errors.CrossoverRateActive?.message && (<p className="error-message">{" * " + errors.CrossoverRateActive?.message}</p>)}
                            </div>
                          }
                          {statusSc === 1 &&
                            <div className="btn-status">
                              <div className="form-floating">
                                <InputGroup.Text >$</InputGroup.Text>
                                <Form.Control type="number" placeholder="Crossover Rate" name="CrossoverRate" {...register('CrossoverRate', { required: 'Field is required' })} min='0' />
                                <label>Crossover Rate ($)</label>
                              </div>
                              {errors.CrossoverRate?.message && (<p className="error-message">{" * " + errors.CrossoverRate?.message}</p>)}
                            </div>
                          }
                          {statusSc === 2 &&
                            <div className="btn-status">
                              <div className="form-floating">
                                <InputGroup.Text >%</InputGroup.Text>
                                <Form.Control type="number" placeholder="Crossover Rate" name="CrossoverRate" {...register('CrossoverRate', { required: 'Field is required' })} min='0' />
                                <label>Crossover Rate (%)</label>
                              </div>
                              {errors.CrossoverRate?.message && (<p className="error-message">{" * " + errors.CrossoverRate?.message}</p>)}
                            </div>
                          }
                        </Col>
                      </Row>
                      <Row>
                      </Row>
                    </fieldset>
                  </Col>
                </Row> }
                {serviceRateValue === 1 &&
                <Row className="mt-4 mb-3">
                  <fieldset className="mb-3">
                    <label className="label-heading">Piece Rate Section</label>
                    <Row>
                      <Col sm={4}>
                        <div className="fieldset-inner">
                          <label className="label-heading">Piece Rate Type</label>
                          <div className="floating-checbox" onChange={e => Servicehandler(e)}>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="PieceRateType" {...register("PieceRateType",{ required: 'Field is required' })} onChange={e => radioHandlerSp(1)} />
                              <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="PieceRateType" {...register("PieceRateType",{ required: 'Field is required' })} onChange={e => radioHandlerSp(2)} />
                              <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                            </div>
                          </div>
                        </div>
                        {errors.PieceRateType?.message && (<p className="error-message">{" * " + errors.PieceRateType?.message}</p>)}
                      </Col>
                      <Col sm={4}>
                        {statusSp === 0 &&
                          <div className="btn-status">
                            <div className="form-floating">
                              <InputGroup.Text >$</InputGroup.Text>
                              <Form.Control type="text" placeholder="Piece Rate" name="PieceRateActive" {...register('PieceRateActive', { required: 'Please select the options' })} disabled />
                              <label>Piece Rate ($)</label>
                            </div>
                            {errors.PieceRateActive?.message && (<p className="error-message">{" * " + errors.PieceRateActive?.message}</p>)}
                          </div>
                        }
                        {statusSp === 1 &&
                          <div className="btn-status">
                            <div className="form-floating">
                              <InputGroup.Text >$</InputGroup.Text>
                              <Form.Control type="number" placeholder="Piece Rate" name="PieceRate" {...register('PieceRate', { required: 'Field is required' })} min='0' />
                              <label>Piece Rate ($)</label>
                            </div>
                            {errors.PieceRate?.message && (<p className="error-message">{" * " + errors.PieceRate?.message}</p>)}
                          </div>
                        }
                        {statusSp === 2 &&
                          <div className="btn-status">
                            <div className="form-floating">
                              <InputGroup.Text>%</InputGroup.Text>
                              <Form.Control type="number" placeholder="Piece Rate" name="PieceRate" {...register('PieceRate', { required: 'Field is required' })} min='0' />
                              <label>Piece Rate (%)</label>
                            </div>
                            {errors.PieceRate?.message && (<p className="error-message">{" * " + errors.PieceRate?.message}</p>)}
                          </div>
                        }
                      </Col>
                      <Col sm={4}>
                        <div className="form-floating">
                          <Form.Control type="number" placeholder="Piece Included" name="PieceIncluded" {...register('PieceInlcuded', { required: 'Field is required' })} min='0' />
                          <label>Piece Included</label>
                        </div>
                        {errors.PieceInlcuded?.message && (<p className="error-message">{" * " + errors.PieceInlcuded?.message}</p>)}
                      </Col>
                    </Row>
                  </fieldset>
                </Row> }

                {serviceRateValue === 1 &&
                <Row className="mt-4 mb-3">
                  <fieldset className="mb-3">
                    <label className="label-heading">Weight Rate Section</label>
                    <Row>
                      <Col sm={3}>
                        <div className="fieldset-inner">
                          <label className="label-heading">Weight Rate Type</label>
                          <div className="floating-checbox" onChange={e => Servicehandler(e)}>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="WeightRateType" {...register("WeightRateType",{ required: 'Field is required' })} onChange={e => radioHandlerSw(1)} />
                              <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="WeightRateType" {...register("WeightRateType",{ required: 'Field is required' })} onChange={e => radioHandlerSw(2)} />
                              <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                            </div>
                          </div>
                        </div>
                        {errors.WeightRateType?.message && (<p className="error-message">{" * " + errors.WeightRateType?.message}</p>)}
                      </Col>
                      <Col sm={3}>
                        {statusSw === 0 &&
                          <div className="btn-status">
                            <div className="form-floating">
                              <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                              <Form.Control type="number" placeholder="Weight Rate" name="WeightRateActive"  {...register('WeightRateActive', { required: 'Please select the options' })} disabled />
                              <label>Weight Rate ($)</label>
                            </div>
                            {errors.WeightRateActive?.message && (<p className="error-message">{" * " + errors.WeightRateActive?.message}</p>)}
                          </div>
                        }
                        {statusSw === 1 &&
                          <div className="btn-status">
                            <div className="form-floating">
                              <InputGroup.Text>$</InputGroup.Text>
                              <Form.Control type="number" placeholder="Weight Rate" name="WeightRate" {...register('WeightRate', { required: 'Field is required' })} min='0' />
                              <label>Weight Rate ($)</label>
                            </div>
                            {errors.WeightRate?.message && (<p className="error-message">{" * " + errors.WeightRate?.message}</p>)}
                          </div>
                        }
                        {statusSw === 2 &&
                          <div className="btn-status">
                            <div className="form-floating">
                              <InputGroup.Text>%</InputGroup.Text>
                              <Form.Control type="number" placeholder="Weight Rate" name="WeightRate" {...register('WeightRate', { required: 'Field is required' })} min='0' />
                              <label>Weight Rate (%)</label>
                            </div>
                            {errors.WeightRate?.message && (<p className="error-message">{" * " + errors.WeightRate?.message}</p>)}
                          </div>
                        }
                      </Col>
                      <Col sm={2}>
                        <div className="form-floating">
                          <Form.Control type="text" placeholder="Weight Included" name="WeightIncluded" {...register('WeightIncluded', { required: 'Field is required' })} required min='0' />
                          <label>Weight Included</label>
                        </div>
                        {errors.WeightIncluded?.message && (<p className="error-message">{" * " + errors.WeightIncluded?.message}</p>)}
                      </Col>
                      <Col sm={2}>
                                                <div className="form-floating">
                                                    <Form.Control type="number" placeholder="Weight Range" name="WeightRange"
                                                        {...register('WeightRange', { required: 'Field is required' })} required min='0' />
                                                    <label htmlFor="WeightRange">Weight Range</label>
                                                </div>
                                                {errors.WeightRange?.message && (<p className="error-message">{" * " + errors.WeightRange?.message}</p>)}
                                            </Col>
                                            <Col sm={2}>
                                                <div className="form-floating">
                                                    <Form.Select aria-label="Floating label select example" name="WeightCalcType"  {...register('WeightCalcType', { required: 'Field is required' })}>
                                                        <option>Select</option>
                                                        <option value="Total">Total</option>
                                                        <option value="Average">Average</option>
                                                    </Form.Select>
                                                    <label htmlFor="WeightCalcType">Weight Calc Type</label>
                                                </div>
                                                {errors.WeightCalcType?.message && (<p className="error-message">{" * " + errors.WeightCalcType?.message}</p>)}
                                            </Col>
                    </Row>
                  </fieldset>
                </Row> }
              </>
              : null}
            {/* table */}
            <Row>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Service Rate Type</th>
                    <th>Service</th>
                    <th>Vehicle Type</th>
                    <th>After Hour Percent</th>
                    <th>Cost Per KM</th>
                    <th>Base Rate Type</th>
                    <th>Base Rate</th>
                    <th>Crossover Rate Type</th>
                    <th>Crossover Rate</th>
                    <th>Piece Rate Type</th>
                    <th>Piece Rate</th>
                    <th>Piece Included</th>
                    <th>Weight Rate Type</th>
                    <th>Weight Rate</th>
                    <th>Weight Included</th>
                    <th>Weight Range</th>
                    <th>Weight Calc Type</th>
                  </tr>
                </thead>
                <tbody>
                  {serviceLists?.map((data, index) => (
                    <tr key={data?.id}>
                      <td>
                        <div className="action_btn">
                          {permission.find(event => event.moduleId === 42)?.permissionType?.indexOf('Update') !== -1 ?
                            <span className="edit_btn" onClick={() => editServiceList(data.id)}><Edit /></span> : <span className="edit_btn disable" ><Edit /></span>}
                          {permission.find(event => event.moduleId === 42)?.permissionType?.indexOf('Delete') !== -1 ?
                            <span className="del_btn" onClick={() => handleClickOpen(data.id)}><Delete /></span> : <span className="del_btn disable"><Delete /></span>}
                          <div>
                            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                              <DialogTitle id="alert-dialog-title">
                                Delete Confirmation
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  Are you sure you want to delete this row?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                                <Button className="btn-primary" onClick={deleteHandler} autoFocus> Confirm</Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        </div>
                      </td>
                      <td>{data.RateType}</td>
                      <td>{data.ServiceDescription}</td>
                      <td>{data?.VehicleTypes.join(' , ')}</td>
                      {/* <td>{update === "update"? data?.VehicleTypes?.map((d)=>d.value).join(' , '): data.VehicleTypes?.join(' , ')}</td> */}
                      <td>{data.AfterHourPercent}</td>
                      <td>{data.CostPerKM}</td>
                      <td>{data.BaseRateType}</td>
                      <td style={{color: mult !== 1 ? "red" : "black"}}>{data.BaseRate ? (data.BaseRateType === 'A' ? (data.BaseRate * mult).toFixed(2) : data.BaseRateType === 'P'? (data.BaseRate * mult) :data.BaseRate):""}</td>
                      <td>{data.CrossoverRateType}</td>
                      <td style={{color: mult !== 1 ? "red" : "black"}}>{data.CrossoverRate ? (data.CrossoverRateType === 'A' ? (data.CrossoverRate * mult).toFixed(2) : data.CrossoverRateType === 'P'? (data.CrossoverRate * mult) :data.CrossoverRate):""}</td>
                      <td>{data.PieceRateType}</td>
                      <td style={{color: mult !== 1 ? "red" : "black"}}>{data.PieceRate ? (data.PieceRateType === 'A' ? (data.PieceRate * mult).toFixed(2) : data.PieceRateType === 'P'? (data.PieceRate * mult) :data.PieceRate):""}</td>
                      <td>{data.PieceInlcuded}</td>
                      <td>{data.WeightRateType}</td>
                      <td style={{color: mult !== 1 ? "red" : "black"}}>{data.WeightRate ? (data.WeightRateType === 'A' ? (data.WeightRate * mult).toFixed(2) : data.WeightRateType === 'P'? (data.WeightRate * mult) :data.WeightRate):""}</td>
                      <td>{data.WeightIncluded}</td>
                      <td>{data.WeightRange}</td>
                      <td>{data.WeightCalcType}</td>

                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          </fieldset>
        </Col>
      </Row>
      {/* <ToastContainer/> */}
    </>
  );
}
export default ServiceSurchargeOptions;