import { AddCircle, Delete, Edit, Save } from "@material-ui/icons";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { createCertificate, deleteCertificateList, editCertificate, useAddCertificationMutation, useDeleteCertificateMutation, useGetCertificateQuery, useLazyGetCertificationDetailsQuery, useUpdateCertificateMutation } from "../../../features/admin/employee/employeeSlice";
import { useGetCountryQuery } from "../../../features/countryState/countryStateSlice";
import CountryProvince from "../../CountryProvince";
import Spinner from "../../Spinner";

const Certifications = ({ certificateLists, employeeData, isLoading, iddd, getEmployeeDetails }) => {
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const { selectState, setSelectState, handlecountry, province } = CountryProvince()
    const dispatch = useDispatch()
    const { data: country } = useGetCountryQuery()
    const { data: getCertificate, refetch } = useGetCertificateQuery()
    const [addCertification] = useAddCertificationMutation()
    const [getCertificationDetails, result] = useLazyGetCertificationDetailsQuery()
    const getCertificationData = result?.data?.data
    const [cData, setCData] = useState([])
    const [updateCertificate] = useUpdateCertificateMutation()
    const [deleteCertificate] = useDeleteCertificateMutation()
    const [addCertificate, setAddCertificate] = useState('')
    const [list, setList] = useState([])
    const [isShow, setIsShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [certificateId, setCertificateId] = useState()
    const handleClickOpen = () => { setOpen(true); };
    const handleClose = async () => { setOpen(false); };
    const [certificate, setCertificate] = useState('')
    const [editingTaskIndex, setEditingTaskIndex] = useState(null);
    const abc = employeeData?.employeeCertificate ? employeeData?.employeeCertificate : []
    const [selectCountry, setSelectCountry] = useState("");
    const [isEditMode, setIsEditMode] = useState(false);
    useEffect(() => {
        reset(getCertificationData)
        setCData(getCertificationData)
    }, [getCertificationData, reset])

    useEffect(() =>{
        if(cData){
            // Introduce a small delay to allow the state to update fully before resetting form values
            setTimeout(() => {
                setValue("certificateExpiryDate", moment(cData?.certificateExpiryDate).format('YYYY-MM-DD'));
                setSelectCountry(cData?.certificateIssueCountryName || "");
                setSelectState(cData?.certificateIssueProvineStateName || "");
            }, 50); // Adjust this value if needed
        }
    },[cData, setSelectState, setValue])

    const bb = [...certificateLists, ...abc]
    // Add Certification 
    const addCertificateList = async (addCertificate) => {
        if (!addCertificate) {
            return
        }
        let certificateData = {
            "certificatename": addCertificate
        }
        setList([...list, addCertificate])
        await addCertification(certificateData)
        refetch()
        setIsEditMode(false);
    }

    useEffect(() => {

    }, )
    const onSubmit = async (certificate) => {
        // Ensure status defaults to false if not selected
        certificate.status = certificate.status || false;
        setCertificate(certificate);
        const countryMapping = country?.data?.find(data => data.countryname === certificate.certificateIssueCountryName);
        const provinceMapping = province?.data?.find(data => data.provineSateName === certificate.certificateIssueProvineStateName);
        const certificateMapping = getCertificate?.data?.find(data => data.certificatename === certificate.certificateAddName);
        const updatedCertificate = {
            ...certificate,
            certificateIssueCountryId: countryMapping?.countryId,
            certificateIssueProvineStateID: provinceMapping?.provineStateID,
            CertificateAddId: certificateMapping?.certificateId,
            certificateExpiryDate: moment(certificate.certificateExpiryDate).format('YYYY-MM-DD') 
        };
        const storedInDataBase = abc.find(item => item.certificateId === certificate.certificateId);
        if (Object.keys(updatedCertificate).length === 0) {
            setIsShow(isShow => !isShow);
        }

        if(!isEditMode){
            dispatch(createCertificate(updatedCertificate))  
        }
        if(storedInDataBase) {
                await updateCertificate({ certificateId: cData?.certificateId, ...updatedCertificate }).then(() => {
                    getEmployeeDetails(iddd)
                })
        } else {
                let editingItem = certificateLists[editingTaskIndex]
                editingItem = updatedCertificate
                dispatch(editCertificate(editingItem))
        }
            setIsEditMode(false)
            setEditingTaskIndex(null)
            reset({ 
            // certificateAddId: "", 
            // certificateIssueCountryId: "", 
            // certificateIssueProvineStateID: "", 
            certificateAddName: false,
            certificateIssueCountryName: "",
            certificateIssueProvineStateName: "",
            identificationNumber: "", 
            certificateExpiryDate: "", 
            status: "" 
        })
        setSelectState("");
        setSelectCountry("");
        setCData("")
        setIsEditMode(false)

    };

    // Edit vehicle list
    const editCertificateList = (certificateId) => {
        let newEditItem = certificateLists.find((data) => {
            return data.certificateId === certificateId
        });
        setCertificate(newEditItem)
        setEditingTaskIndex(certificateId);
        setIsEditMode(true); 
       // Introduce a small delay to ensure state is fully updated before resetting form values
        setTimeout(() => {
            reset(newEditItem);
        }, 50); // Adjust the timeout duration as necessary
    }

    const deleteCertificateData = async (deleteId) => {
        await deleteCertificate(deleteId);
        getEmployeeDetails(iddd)
    }
    // const changeHandler = (e) => { setCertificate(certificate => ({ ...certificate, [e.target.name]: e.target.value }));}
    const countryField = register("certificateIssueCountryName", {required: 'Field is required'});
    const provinceField = register("certificateIssueProvineStateName", {required: 'Field is required', validate: value => value !== "Select" || "Field is required"});
    isLoading && <Spinner />
    return (
        <>
      
            <Row className="mt-2" >
                {/* Add certifcation sections */}
                <Row>
                    <Col sm={7}></Col>
                    <Col sm={5}>
                        <Row>
                            <Col sm={7}>
                                <div className="form-floating">
                                    <Form.Control type="text" autoComplete="off" placeholder="Enter a certification name" name="Enter a certification name"
                                        onChange={(e) => { setAddCertificate(e.target.value) }} value={addCertificate} />
                                    <label htmlFor="Enter a certification name" >Enter a certification name</label>
                                </div>
                            </Col>
                            <Col sm={5}>
                                <Button variant=" btn-secondary" onClick={() => { addCertificateList(addCertificate); setAddCertificate("") }} ><AddCircle /> Add Certifications</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Col sm={12}>
                    <fieldset className="mt-4 mb-3">
                        <label className="label-heading">Select certifications type</label>
                        {/* add certification section */}
                        <div className="multi-certification-add">
                            <div className="certification-section d-flex">
                                <h6>Please select the certification type : </h6>
                                <div className="certification-name">
                                    {getCertificate?.data.map((data, index) => (
                                        <div className="form-check form-check-inline" key={index}>
                                            <input className="form-check-input" type="radio" id="inlineCheckbox1" name="certificateAddName" value={data.certificatename} key={data.certificateId === cData?.certificateAddId}
                                                {...register('certificateAddName', {required: 'Field is required'})} defaultChecked={data.certificateId === cData?.certificateAddId } />
                                            <label className="form-check-label" htmlFor="inlineCheckbox1" style={{ fontSize: "15px" }}>{data?.certificatename}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="section_action_btn">
                                {!isEditMode ? (
                                    <div className="add_btn">
                                        <button type="button" className="btn" onClick={handleSubmit(onSubmit)}>
                                            <AddCircle /> {/* This represents the + button */}
                                        </button>
                                    </div>
                                ) : (
                                    <div className="save_btn">
                                        <button type="button" className="btn" onClick={handleSubmit(onSubmit)}>
                                            <Save /> {/* This represents the Save button */}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* List of certication */}
                        {!isShow ?           
                            <fieldset className="user-role-form mt-4 mb-3" style={{ background: "#3c65bb80" }}>
                                <label className="label-heading">Add Certification</label>
                                <Row>
                                    <Col>
                                        <Form.Group controlId="country">
                                            <FloatingLabel controlId="floatingSelectGrid" label="Select Country">
                                                {/* <Form.Select aria-label="Floating label select example" name="certificateIssueCountryId" {...countryField} onClick={(e) => { countryField.onChange(e); handlecountry(e); }}> */}
                                                <Form.Select aria-label="Floating label select example" name="certificateIssueCountryName" {...countryField} value={selectCountry} onChange={(e) => { countryField.onChange(e); handlecountry(e); setSelectCountry(e.target.value); }}>
                                                <option value="">Select</option>
                                                    {
                                                        country?.data?.map((data, index) => (
                                                            // <option key={index} value={data.countryId} selected={data?.countryname === cData?.certificateIssueCountryName ? true : false}>{data.countryname}</option>
                                                            <option key={index} value={data.countryname} selected={data?.countryname === cData?.certificateIssueCountryName ? true : false}>{data.countryname}</option>
                                                        ))

                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                            <p className="error-message">{errors.certificateIssueCountryName?.message}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group controlId="province">
                                            <FloatingLabel controlId="floatingSelectGrid" label="Select Province">
                                                {/* <Form.Select aria-label="Floating label select example" name="certificateIssueProvineStateID" value={selectState} {...provinceField} onChange={(e) => { provinceField.onChange(e); setSelectState(e.target.value); }}> */}
                                                <Form.Select aria-label="Floating label select example" name="certificateIssueProvineStateName" value={selectState} {...provinceField} onChange={(e) => { provinceField.onChange(e); setSelectState(e.target.value); }}>
                                                    <option value={cData?.certificateIssueProvineStateName ? cData?.certificateIssueProvineStateName : cData?.certificateIssueProvineStateName}>{cData?.certificateIssueProvineStateName || "Select"}</option>
                                                    {
                                                        province?.data?.map((data, index) => (
                                                            // <option key={index} value={data.provineStateID} selected={data?.provineStateID === cData?.certificateIssueProvineStateName ? true : false}>{data.provineSateName}</option>
                                                            <option key={index} value={data.provineSateName} selected={data?.provineStateID === cData?.certificateIssueProvineStateName ? true : false}>{data.provineSateName}</option>
                                                        ))

                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                            <p className="error-message">{errors.certificateIssueProvineStateName?.message}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <div className="form-floating">
                                            <Form.Control type="number" autoComplete="off" placeholder="Identification number" name="identificationNumber" {...register('identificationNumber', {required: 'Field is required'})} />
                                            <label htmlFor="Identification number">Identification number</label>
                                            <p className="error-message">{errors.identificationNumber?.message}</p>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="form-floating">
                                            <Form.Control type="date" autoComplete="off" placeholder="Expiry date" name="certificateExpiryDate"  {...register('certificateExpiryDate', {required: 'Field is required'})} defaultValue={moment(cData?.certificateExpiryDate).utc().format('YYYY-MM-DD')} // Use defaultValue for initial load
            min={new Date().toISOString().split('T')[0]} // Prevent selecting past dates
            onChange={(e) => setValue("certificateExpiryDate", e.target.value)} />
                                            <label htmlFor="Expiry date">Expiry date</label>
                                            <p className="error-message">{errors.certificateExpiryDate?.message}</p>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="status-btn">
                                            <div className="form-check form-switch status-toggle">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" name="status" {...register('status', {})} />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Status</label>

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </fieldset>
                            : null}
                    </fieldset>
                </Col>
                <Col sm={12}>

                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Action</th>
                                <th>Certificate Name</th>
                                <th>Country</th>
                                <th>Province Issue</th>
                                <th>Identification Number</th>
                                <th>Expiry Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bb?.length > 0 ? (
                                <>
                                    {bb?.map((data) => {
                                        const storedInDataBase = abc.find(item => item.certificateId === certificateId);
                                        const shouldDoApiCall = abc.find(item => item.certificateId === data.certificateId); 
                                        return <tr key={data.certificateId}>
                                            <td>
                                                <div className="action_btn">
                                                <span className="view_btn" onClick={() => {
                                                    setCertificateId(data.certificateId)
                                                    if(shouldDoApiCall) {
                                                        getCertificationDetails(data.certificateId)
                                                        setIsEditMode(true)
                                                    } else {
                                                        editCertificateList(data.certificateId)
                                                    }
                                                }}><Edit /></span>
                                                        <span className="del_btn" onClick={() => {
                                                                setCertificateId(data.certificateId)
                                                                handleClickOpen()
                                                            }}><Delete /></span>                                                        
                                                    <>
                                                        <div>
                                                            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                                                <DialogTitle id="alert-dialog-title">
                                                                    Delete Confirmation
                                                                </DialogTitle>
                                                                <DialogContent>
                                                                    <DialogContentText id="alert-dialog-description">
                                                                        Are you sure you want to delete this row?
                                                                    </DialogContentText>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                                                                    {storedInDataBase ?
                                                                        <Button className="btn-primary" onClick={() => { deleteCertificateData(certificateId); handleClose(); }} autoFocus> Confirm</Button>
                                                                        : <Button className="btn-primary" onClick={() => {
                                                                                dispatch(deleteCertificateList(certificateId)); handleClose();
                                                                             }}> Confirm</Button>}
                                                                </DialogActions>
                                                            </Dialog>
                                                        </div>
                                                    </>
                                                </div>
                                            </td>
                                            {cData?.certificateId ? 
                                            <>
                                            <td>{data.certificateAddName}</td>
                                            <td>{data.certificateIssueCountryName}</td>
                                            <td>{data.certificateIssueProvineStateName}</td>
                                            <td>{data.identificationNumber}</td>
                                            {/* <td>{new Date(data?.certificateExpiryDate).toLocaleDateString()}</td> */}
                                            <td>{moment(data.certificateExpiryDate).utc().format('YYYY-MM-DD')}</td>
                                            <td>{data.status === false || data.status === "false" ? "false" : data.status.toString()}</td>
                                            </>
                                            :
                                            <>
                                            <td>{data.certificateAddName === cData?.certificateAddName ? data?.certificateAddNmae : data.certificateAddName}</td>
                                            <td>{data.certificateIssueCountryName === cData?.certificateIssueCountryName ? data?.certificateIssueCountryId : data.certificateIssueCountryName}</td>
                                            <td>{data.certificateIssueProvineStateName === cData?.certificateIssueProvineStateName ? data?.certificateIssueProvineStateID : data.certificateIssueProvineStateName}</td>
                                            <td>{data.identificationNumber}</td>
                                             {/* <td>{new Date(data?.certificateExpiryDate).toLocaleDateString()}</td> */}
                                             <td>{moment(data.certificateExpiryDate).utc().format('YYYY-MM-DD')}</td>
                                             <td>{data.status === false || data.status === "false" ? "false" : data.status.toString()}</td>
                                            </>
                                            }
                                        </tr>
})}
                                </>
                            ) : (
                                <p>No data found </p>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    )
}
export default Certifications;