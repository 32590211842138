//  Error validation msg

export default function Validatecustomer(addressData,contractInfo) {
    let errors = {};
    if (!addressData.AccountType) {
      errors.AccountType = 'Please select an option';
    } 
    if (!addressData.OfficeHour) {
      errors.OfficeHour = 'Field is required';
    } 
    if (!addressData.CloseTime) {
      errors.CloseTime = 'Field is required';
    }
    if (addressData.OfficeHour && addressData.CloseTime && addressData.CloseTime.toLocaleTimeString('en-GB') <= addressData.OfficeHour.toLocaleTimeString('en-GB')) {
      errors.CloseTime = 'Close time must be after open time';
    } 
    if (!addressData.CustomerFrequencyVolumeID) {
      errors.CustomerFrequencyVolumeID = 'Please select an option';
    } 
    if (!addressData.CountryId) {
      errors.CountryId = 'Please select an option';
    } 
    if (!addressData.ProvineStateID) {
      errors.ProvineStateID = 'Please select an option';
    } 
    if (!addressData.Customer) {
      errors.Customer = 'Customer Name is required';
    // } else if (!/^([a-zA-Z]{3,}|[a-zA-Z]{3,}\s{1}[a-zA-Z]{3,}|[a-zA-Z]{3,}\s{1}[a-zA-Z]{3,}\s{1}[a-zA-Z]{3,})$/g.test(addressData.Customer)) {
    //   errors.Customer = 'Username is invalid';
    } else if (addressData.Customer.length < 3) {
      errors.Customer = 'Please enter minimum 3 characters';
    }
    if (!addressData.Street) {
      errors.Street = 'Field is required';
    } else if (!/^[0-9]*$/g.test(addressData.Street)) {
      errors.Street = 'Enter a valid input';
    }
    if (!addressData.StreetName) {
      errors.StreetName = 'Street Name is required';
    } else if (!/^([a-zA-Z]+|[a-zA-Z]+\s{1}[a-zA-Z]{1,}|[a-zA-Z]+\s{1}[a-zA-Z]{3,}\s{1}[a-zA-Z]{1,})$/g.test(addressData.StreetName)) {
      errors.StreetName = 'Street name is invalid';
    }
    // if (!addressData.Unit) {
    //   errors.Unit = 'Field is required';
    // } else if (!/^[0-9]*$/g.test(addressData.Unit)) {
    //   errors.Unit = 'Enter a valid input';
    // }
    if (!addressData.EmailAddress) {
      errors.EmailAddress = 'Email address is required';
    } else if (!/\S+@\S+\.\S+/.test(addressData.EmailAddress)) {
      errors.EmailAddress = 'Email address is invalid';
    }
    if (!addressData.CityId) {
      errors.CityId = 'Please select an option';
    }
    if (!addressData.PostalCode) {
      errors.PostalCode = 'Field is required';
    }
    // if (!addressData.Tittle) {
    //   errors.Tittle = 'Field is required';
    // } else if (!/^([a-zA-Z]+|[a-zA-Z]+\s{1}[a-zA-Z]{1,}|[a-zA-Z]+\s{1}[a-zA-Z]{3,}\s{1}[a-zA-Z]{1,})$/g.test(addressData.Tittle)) {
    //   errors.Tittle = 'Please enter a valid data';
    // }
    if (!addressData.Phone) {
      errors.Phone = 'Field is required';
    }
    if (!addressData.Contact) {
      errors.Contact = 'Contact Name is required';
    } else if (!/^([a-zA-Z]+|[a-zA-Z]+\s{1}[a-zA-Z]{1,}|[a-zA-Z]+\s{1}[a-zA-Z]{3,}\s{1}[a-zA-Z]{1,})$/g.test(addressData.Contact)) {
      errors.Contact = 'Please enter valid name';
    }
    if (!addressData.Fax) {
      errors.Fax = 'Field is required';
    }
    if (!addressData.Phone_Ext) {
      errors.Phone_Ext = 'Field is required';
    } else if (!/^\d+$/g.test(addressData.Phone_Ext)) {
      errors.Phone_Ext = 'Phone extension must contain only numbers';
    }
    return errors;
  };