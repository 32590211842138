import { Col, Row, Form, FloatingLabel, Table, Button } from "react-bootstrap";
import { AddCircle } from "@material-ui/icons";
import { useState, useEffect } from "react";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAddFuelCostMutation, useGetAllFuelListQuery, useUpdateFuelCostMutation, useDeleteFuelCostMutation } from "../../../../../features/FuelCost/FuelCostSlice";
import { useForm } from "react-hook-form";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";


const FuelCostTable = () => {
  const { register, handleSubmit, formState: { errors }, reset, setValue, watch } = useForm();
  const [addFuel] = useAddFuelCostMutation()
  const [editFuel] = useUpdateFuelCostMutation()
  const [deleteFuel] = useDeleteFuelCostMutation()
  const { data: tableListData, refetch } = useGetAllFuelListQuery()
  const [isUpdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => { setOpen(false); };
  const [fuelData, setFuelData] = useState([])
  const [deleteID, setDeleteID] = useState(null)

  useEffect(() => {
      setFuelData(fuelData)
      reset(fuelData)
  },[fuelData])

  const onSubmit = async (data) => {
    const addData = await addFuel({ ...data })
    if (addData.data?.statusCode === 400) {
      toast.error(addData?.data.message, {
        position: "top-center",
      })
      return;
    } else if (addData.data?.statusCode === 200) {
      toast.success("Data added successfully", {
        position: "top-center",
      })

    }
    reset({ effectiveDate: '', costPerGallon: '' })
    refetch()
  };

  const onEdit = async (data) => {
    const result = await editFuel({ ...data })
    if (result.data?.statusCode === 400) {
      toast.error(result?.data.message, {
        position: "top-center",
      })
      return;
    } else if (result.data?.statusCode === 200) {
      toast.success("Data updated successfully", {
        position: "top-center",
      })
    }
    reset({ effectiveDate: '', costPerGallon: '' })
    refetch()
    setIsUpdate(false)
  }

  const deleteHandler = async () => {
    const result = await deleteFuel(deleteID)
    if (result.data?.statusCode === 400) {
      toast.error(result?.data.message, {
        position: "top-center",
      })
      return;
    } else if (result.data?.statusCode === 200) {
      toast.success("Data deleted successfully", {
        position: "top-center",
      })
    }
    refetch()
    setOpen(false);
  }


  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = (e) => {
          e.stopPropagation();
          setIsUpdate(true)
          let newEditItem = tableListData.data.find((row) => {
              return row.fuelCostId === data.row.id;
          });
          setFuelData(newEditItem);
          reset(fuelData)
        };
        const onRemove = (e) => {
          e.stopPropagation();
          // handleClickOpen();
          setOpen(true);
          setDeleteID(data.row.id)
        };
        return (
          <>
            <div>
              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                  Delete Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this row?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                  <Button className="btn-primary" onClick={() => deleteHandler()} autoFocus> Confirm</Button>
                </DialogActions>
              </Dialog>
            </div>
            <div className="action_btn">
              <span className="edit_btn" onClick={onClick}><Edit /></span>
              <span className="del_btn" onClick={onRemove}><Delete /></span>
            </div>
          </>
        )
      },
    },
    { field: "effectiveDate", headerName: "Effective Date", width: 200 },
    { field: "costPerGallon", headerName: "Cost Per Gallon", width: 200 },
  ];

  const rows = tableListData?.data.length >= 0 && tableListData?.data.map((data) => {
    return {
      id: data?.fuelCostId,
      effectiveDate: data?.effectiveDate,
      costPerGallon: data?.costPerGallon
    }
  })




  return (
    <>

      {/* Remote login info */}
      <Row className="mb-4 mt-4">
        <Col sm={4}>
          <fieldset className="less_load_table">
            <label className="label-heading">Fuel Cost Table</label>
            <>
              <Row>
                <Col sm={12} className="justify-content-between">
                  <div className="section_action_btn">
                    {!isUpdate ?
                      <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(onSubmit)}><AddCircle /></button></div>
                      :
                      <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(onEdit)}><Save /></button></div>
                    }
                  </div>
                </Col>

                <Row className="mt-2 mb-3">
                  <Col sm={12} className="mb-2">
                    <Form.Group controlId="Date">
                      <FloatingLabel controlId="floatingInput" label="Date" >
                        <Form.Control type="date" placeholder="Date" name="effectiveDate" {...register("effectiveDate")} />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Form.Group controlId="ToCost">
                      <FloatingLabel controlId="floatingInput" label="Fuel Charge" >
                        <Form.Control type="number" placeholder="Fuel Charge" name="costPerGallon" {...register("costPerGallon")} />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="user-role-table" style={{ height: "500px", background: "white" }}>
                  {/* <h6>List of Accessorial</h6> */}
                  <DataGrid rows={rows} columns={columns} pageSize={15} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} />
                </div>

              </Row>
            </>
          </fieldset>
        </Col>




      </Row>
    </>
  );
}
export default FuelCostTable;