import { React, memo } from "react";
import { Col, Row, Form, FloatingLabel, Table, InputGroup, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../context/CustomerContext";
import { useModifyHistoryMutation } from "../../../features/customer/history/historySlice";
import { useAddVehicleSurchargeAfterEditMutation, useGetVehicleQuery } from "../../../features/vehicle/vehicleDetailSlice";
import { useUpdateVehicleDataMutation, useDeleteVehicleDataMutation } from "../../../features/vehicle/vehicleDetailSlice";
import { useGetAllVehicleRateTypeQuery } from "../../../features/vehicle/vehicledespSlice";
import { useRef } from "react";
import { useForm, Controller } from 'react-hook-form';
import { useCustomData } from "../../Sidebar/Permission";
import useDeleteData from "../useDeleteData";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const EditVehicleSurcharge = () => {
    const { contractInfo } = useCustomer();
    const ratecardaccount = contractInfo && contractInfo[0]
    const [permission] = useCustomData()
    const { register, handleSubmit, watch, formState: { errors }, reset, onChange, control } = useForm();
    let subscribe = watch();
    const { data: vehicleList } = useGetVehicleQuery()
    const { data: getVehicleType, refetch } = useGetAllVehicleRateTypeQuery()
    const [updateVehicle] = useUpdateVehicleDataMutation()
    const [deleteVehicle] = useDeleteVehicleDataMutation()
    const [modifyHistory] = useModifyHistoryMutation()
    const [handleClose, handleOpen, open, deleteData] = useDeleteData(deleteVehicle)
    const [addVehicleSurchargeAfterEdit] = useAddVehicleSurchargeAfterEditMutation()
    const { vehicleSurchargeList, singleAccount, handleAccount } = useCustomer();
    const account = singleAccount && singleAccount[0];
    const [message, setMessage] = useState("Modified the vehicle surcharge section")
    const [isEditItem, setIsEditItem] = useState(null);
    const [vehicleData, setVehicleData] = useState({});
    const [showSecondFields, setShowSecondFields] = useState(false);
    const [editId, setEditId] = useState('')
    const [selected, setSelected] = useState({});
    const prevCount = usePrevious(vehicleData);
    const [forceRender, setForceRender] = useState(0)

    useEffect(() => {
        setVehicleData(vehicleData)
        reset(vehicleData)
    }, [vehicleData])

    // const Vehicleupdate = (e) => { setVehicleData(vehicleData => ({ ...vehicleData, [e.target.name]: e.target.value })) }

    const EditvehicleItem = (rateCardVehicleID) => {
        setEditId(rateCardVehicleID)
        let newEditItem = vehicleSurchargeList.find((data) => {
            return data.rateCardVehicleID === rateCardVehicleID
        });
        const vehicleType = getVehicleType.data.find((data) => data.vehicleType.split(" ")[0] === newEditItem.vehicleType.split(" ")[0]);
 
        setSelected(vehicleType);
        setVehicleData(newEditItem);
        setShowSecondFields(showSecondFields => !showSecondFields)
        setIsEditItem(rateCardVehicleID);
        reset(vehicleData)
    }

    const editCustomerList = async (vehicleData) => {
        if (JSON?.stringify(prevCount) === JSON?.stringify(vehicleData)) {
            setVehicleData("")
            setTimeout(function () {
                toast.info("No Changes found on this vehicle surcharge section !", {
                    position: "top-right",
                })
            });
        }
        else {
                const updateVehicleData = await updateVehicle(vehicleData)
                if (updateVehicleData.data.statusCode === 200) {
                    setTimeout(function () {
                        toast.success("Vehicle surcharge section updated successfully !", {
                            position: "top-right",
                        })
                    });
                }
                setVehicleData("");
                handleAccount(account?.account_No)
            
        }
        if (JSON?.stringify(prevCount) !== JSON?.stringify(vehicleData)) {
            const modifieddata = {
                AccountNo: account.account_No,
                CustomerName: account.customer,
                Modifiedsection: message
            }
            await modifyHistory(modifieddata)
        }
        reset({
            baseRateType: '', baseRate: '', vehicleType: '', pieceInlcuded: "", baseRateType: "", crossoverRateType: "", crossoverRate: "", pieceRate: "", weightRate: "",
            weightIncluded: "", weightCalcType: "",weightRange:"", waitingTimeUnitRate: "", waitingtimeUnit: "", freeWaitingTime: "", pieceRateType: "", weightRateType: "",
             waitingTimeUnitRateType: "" , afterHourPercent:""
        });
        setSelected({});
        setShowSecondFields(false)
    }

    /// Add new section
    const addNewSection = async (data) => {
        if (Object.keys(data).length === 0 || !data.vehicleType) {
            setShowSecondFields(true)
        }
        else if (vehicleSurchargeList.some((d) => d.vehicleType === data.vehicleType)) {
            toast.warn("Vehicle Added Already !", {
                position: "top-center",
            })
            return
        }
        else {
            const addDataOnUpdate = await addVehicleSurchargeAfterEdit({ rateCardID: ratecardaccount?.rateCardId, ...data })
            handleAccount(account?.account_No)
            if (addDataOnUpdate?.data?.statusCode === 200) {
                toast.success("Vehicle surcharge section Added successfully !", {
                    position: "top-right",
                })
            }
        }
        console.log(data)
        reset({
            baseRateType: '', baseRate: '', vehicleType: '', pieceInlcuded: "", baseRateType: "", crossoverRateType: "", crossoverRate: "", pieceRate: "", weightRate: "",
            weightIncluded: "", weightCalcType: "",weightRange:"", waitingTimeUnitRate: "", waitingtimeUnit: "", freeWaitingTime: "", pieceRateType: "", weightRateType: "", 
            waitingTimeUnitRateType: "" , afterHourPercent:""
        });
        setVehicleData("");
        setSelected({})
    }

    return (
        <>
            {/* Vehicle surcharge section*/}
            <Row className="mb-2 mt-2">
                <Col sm={12}>
                    <fieldset className="pb-2">
                        <div className="d-flex justify-content-end">
                            <div className="section_action_btn ms-1">
                                <>
                                    {vehicleData.rateCardVehicleID ?
                                        <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(editCustomerList)}>Update record</button></div>
                                        :
                                        <>
                                            {permission.find(event => event.moduleId === 41)?.permissionType?.indexOf('Add') !== -1 ?
                                                <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(addNewSection)}>Add new record</button></div>
                                                : <div className="add_btn disable"><button type="button" className="btn">Add new record</button></div>
                                            }
                                        </>

                                    }
                                </>
                            </div>
                        </div>
                        {showSecondFields ?
                            <div className="form-bg">
                                <Row className="mt-2 mb-3">
                                    {/* <Col sm={6}>
                                        <Form.Group controlId="Vehicletype">
                                            <FloatingLabel controlId="floatingSelectGrid" label="Vehicle Type">
                                                <Form.Select aria-label="Floating label select example" name="vehicleType"
                                                    {...register('vehicleType', { required: 'Field is required' })} >
                                                    <option value="">Select</option>
                                                    <>
                                                        {
                                                            vehicleList?.data?.map((data, index) => (
                                                                <option key={index} value={data?.vehicleType}>{data?.vehicleType}</option>
                                                            ))

                                                        }
                                                    </>
                                                </Form.Select>
                                            </FloatingLabel>
                                            {errors.vehicleType?.message && (<p className="error-message">{" * " + errors.vehicleType?.message}</p>)}
                                        </Form.Group>
                                    </Col> */}
                                    <Col sm={6}>
                                        <Controller
                                            name="vehicleType"
                                            control={control}
                                            rules={{
                                                required: "Field is requried"
                                            }}
                                            render={({ field, fieldState: { error } }) => {
                                                const { onChange, value, ref } = field;
                                                return (
                                                    <>
                                                        <Autocomplete
                                                            value={
                                                                value
                                                                    ? getVehicleType?.data.find((option) => {
                                                                        return value === option.vehicleType;
                                                                    }) ?? null
                                                                    : null
                                                            }
                                                            isOptionEqualToValue={(option, value) => option?.vehicleType === value?.vehicleType}
                                                            getOptionLabel={(option) => option?.vehicleType || ""}
                                                            onChange={(event, newValue) => {setSelected(newValue);
                                                                onChange(newValue ? newValue.vehicleType : null);
                                                            }}
                                                            id="controllable-states-demo"
                                                            options={getVehicleType?.data}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Select Vehicle Type"
                                                                    inputRef={ref}
                                                                />
                                                            )}
                                                        />
                                                        {error ? (
                                                            <span style={{ color: "red" }}>{error.message}</span>
                                                        ) : null}
                                                    </>
                                                );
                                            }}
                                        />
                                    </Col>
                                    {selected?.rateType &&
                                    <Col sm={3}>
                                        <Form.Group controlId="Afterhour">
                                            <FloatingLabel controlId="floatingInput" label="After Hour Percent (%)" >
                                                <Form.Control type="number" placeholder="After Hour Percent" name="afterHourPercent" onChange={onChange}
                                                    {...register('afterHourPercent', { required: 'Field is required' })} min='0' />
                                            </FloatingLabel>
                                            {errors.afterHourPercent?.message && (<p className="error-message">{" * " + errors.afterHourPercent?.message}</p>)}
                                        </Form.Group>
                                    </Col> }
                                </Row>
                                {selected?.rateType === 'LOCAL' &&
                                <Row className="mt-2 mb-3">
                                    <Col sm={6}>
                                        <fieldset className="mb-3">
                                            <label className="label-heading">Base Rate Section</label>
                                            <Row>
                                                <Col sm={6}>
                                                    <div className="fieldset-inner">
                                                        <label className="label-heading">Choose the base rate type</label>
                                                        <div className="floating-checbox">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="baseRateType" {...register("baseRateType",{ required: 'Field is required' })} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="baseRateType" {...register("baseRateType",{ required: 'Field is required' })} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {errors.baseRateType?.message && (<p className="error-message">{" * " + errors.baseRateType?.message}</p>)}
                                                </Col>
                                                <Col sm={6}>
                                                    {subscribe?.baseRateType === "A" ? (
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Base Rate" name="baseRate"  {...register('baseRate', { required: 'Field is required' })} min='0' />
                                                                <label htmlFor="Baserate">Base Rate ($)</label>
                                                            </div>
                                                            {errors.baseRate?.message && (<p className="error-message">{" * " + errors.baseRate?.message}</p>)}
                                                        </div>
                                                    ) : subscribe?.baseRateType === "P" ? (
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Base Rate" name="baseRate"  {...register('baseRate', { required: 'Field is required' })} min='0' />
                                                                <label htmlFor="Baserate">Base Rate (%)</label>
                                                            </div>
                                                            {errors.baseRate?.message && (<p className="error-message">{" * " + errors.baseRate?.message}</p>)}
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="btn-status">
                                                                <div className="form-floating">
                                                                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                    <Form.Control type="number" placeholder="Base Rate" name="baseRate"  {...register('baseRate', { required: 'Field is required' })} min='0' />
                                                                    <label htmlFor="Baserate">Base Rate ($)</label>
                                                                </div>
                                                                {errors.baseRate?.message && (<p className="error-message">{" * " + errors.baseRate?.message}</p>)}
                                                            </div>
                                                        </>
                                                    )
                                                    }

                                                </Col>
                                            </Row>
                                        </fieldset>
                                    </Col> 
                                    <Col sm={6}>
                                        <fieldset className="mb-3">
                                            <label className="label-heading">Crossover Rate Section</label>
                                            <Row>
                                                <Col sm={6}>
                                                    <div className="fieldset-inner">
                                                        <label className="label-heading">Choose the crossover rate type</label>
                                                        <div className="floating-checbox">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="crossoverRateType" {...register("crossoverRateType",{ required: 'Field is required' })} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="crossoverRateType" {...register("crossoverRateType",{ required: 'Field is required' })} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {errors.crossoverRateType?.message && (<p className="error-message">{" * " + errors.crossoverRateType?.message}</p>)}
                                                </Col>
                                                <Col sm={6}>
                                                    {subscribe?.crossoverRateType === "A" ? (
                                                        <>
                                                            <div className="btn-status">
                                                                <div className="form-floating">
                                                                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                    <Form.Control type="number" placeholder="Crossover Rate" name="crossoverRate" {...register('crossoverRate', { required: 'Field is required' })} min='0' />
                                                                    <label htmlFor="CrossoverRate">Crossover Rate ($)</label>
                                                                </div>
                                                                {errors.crossoverRate?.message && (<p className="error-message">{" * " + errors.crossoverRate?.message}</p>)}
                                                            </div>
                                                        </>
                                                    ) : subscribe?.crossoverRateType === "P" ? (
                                                        <>
                                                            <div className="btn-status">
                                                                <div className="form-floating">
                                                                    <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                                    <Form.Control type="number" placeholder="Crossover Rate" name="crossoverRate" {...register('crossoverRate', { required: 'Field is required' })} min='0' />
                                                                    <label htmlFor="CrossoverRate">Crossover Rate (%)</label>
                                                                </div>
                                                                {errors.crossoverRate?.message && (<p className="error-message">{" * " + errors.crossoverRate?.message}</p>)}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="btn-status">
                                                                <div className="form-floating">
                                                                    <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                    <Form.Control type="number" placeholder="Crossover Rate" name="crossoverRate" {...register('crossoverRate', { required: 'Field is required' })} min='0' />
                                                                    <label htmlFor="CrossoverRate">Crossover Rate ($)</label>
                                                                </div>
                                                                {errors.crossoverRate?.message && (<p className="error-message">{" * " + errors.crossoverRate?.message}</p>)}
                                                            </div>
                                                        </>
                                                    )
                                                    }
                                                </Col>
                                            </Row>
                                        </fieldset>
                                    </Col> 
                                </Row> }
                                {selected?.rateType === 'LOCAL' &&
                                <Row className="row m-0">
                                    <fieldset className="mb-3">
                                        <label className="label-heading">Piece Rate Section</label>
                                        <Row>
                                            <Col sm={4}>
                                                <div className="fieldset-inner">
                                                    <label className="label-heading">Choose the piece rate type</label>
                                                    <div className="floating-checbox">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="pieceRateType"  {...register("pieceRateType",{ required: 'Field is required' })} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="pieceRateType"  {...register("pieceRateType",{ required: 'Field is required' })} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {errors.pieceRateType?.message && (<p className="error-message">{" * " + errors.pieceRateType?.message}</p>)}
                                            </Col>
                                            <Col sm={4}>
                                                {subscribe?.pieceRateType === "A" ? (
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Piece Rate" name="pieceRate"  {...register('pieceRate', { required: 'Field is required' })} min='0' />
                                                            <label htmlFor="PieceRate">Piece Rate ($)</label>
                                                        </div>
                                                        {errors.pieceRate?.message && (<p className="error-message">{" * " + errors.pieceRate?.message}</p>)}
                                                    </div>
                                                ) : subscribe?.pieceRateType === "P" ? (
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Piece Rate" name="pieceRate" {...register('pieceRate', { required: 'Field is required' })} min='0' />
                                                            <label htmlFor="PieceRate">Piece Rate (%)</label>
                                                        </div>
                                                        {errors.pieceRate?.message && (<p className="error-message">{" * " + errors.pieceRate?.message}</p>)}
                                                    </div>
                                                ) : (
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Piece Rate" name="pieceRate"  {...register('pieceRate', { required: 'Field is required' })} min='0' />
                                                            <label htmlFor="PieceRate">Piece Rate ($)</label>
                                                        </div>
                                                        {errors.pieceRate?.message && (<p className="error-message">{" * " + errors.pieceRate?.message}</p>)}
                                                    </div>
                                                )
                                                }

                                            </Col>
                                            <Col sm={4}>
                                                <div className="form-floating">
                                                    <Form.Control type="number" placeholder="Piece Included" name="PieceInlcuded"   {...register('pieceInlcuded', { required: 'Field is required' })} min='0' />
                                                    <label htmlFor="PieceInlcuded">Piece Included</label>
                                                </div>
                                                {errors.PieceInlcuded?.message && (<p className="error-message">{" * " + errors.PieceInlcuded?.message}</p>)}
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Row> }
                                { selected?.rateType === 'LOCAL' &&
                                <Row className="row m-0">
                                    <fieldset className="mb-3">
                                        <label className="label-heading">Weight Rate Section</label>
                                        <Row>
                                            <Col sm={3}>
                                                <div className="fieldset-inner">
                                                    <label className="label-heading">Choose the weight rate type</label>
                                                    <div className="floating-checbox">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="weightRateType" {...register("weightRateType",{ required: 'Field is required' })} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="weightRateType" {...register("weightRateType",{ required: 'Field is required' })} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {errors.weightRateType?.message && (<p className="error-message">{" * " + errors.weightRateType?.message}</p>)}
                                            </Col>
                                            <Col sm={3}>
                                                {subscribe?.weightRateType === "A" ? (
                                                    <>
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Crossover Rate" name="weightRate" {...register('weightRate', { required: 'Field is required' })} min='0' />
                                                                <label htmlFor="weightRate">Weight Rate ($)</label>
                                                            </div>
                                                            {errors.weightRate?.message && (<p className="error-message">{" * " + errors.weightRate?.message}</p>)}
                                                        </div>
                                                    </>
                                                ) : subscribe?.weightRateType === "P" ? (
                                                    <>
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Crossover Rate" name="weightRate" {...register('weightRate', { required: 'Field is required' })} min='0' />
                                                                <label htmlFor="weightRate">Weight Rate (%)</label>
                                                            </div>
                                                            {errors.weightRate?.message && (<p className="error-message">{" * " + errors.weightRate?.message}</p>)}
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Crossover Rate" name="weightRate" {...register('weightRate', { required: 'Field is required' })} min='0' />
                                                                <label htmlFor="weightRate">Weight Rate ($)</label>
                                                            </div>
                                                            {errors.weightRate?.message && (<p className="error-message">{" * " + errors.weightRate?.message}</p>)}
                                                        </div>
                                                    </>
                                                )
                                                }
                                            </Col>
                                            <Col sm={2}>
                                                <div className="form-floating">
                                                    <Form.Control type="number" placeholder="Weight Included" name="weightIncluded"  {...register('weightIncluded', { required: 'Field is required' })} required min='0' />
                                                    <label htmlFor="WeightIncluded">Weight Included</label>
                                                </div>
                                                {errors.weightIncluded?.message && (<p className="error-message">{" * " + errors.weightIncluded?.message}</p>)}
                                            </Col>
                                            <Col sm={2}>
                                                <div className="form-floating">
                                                    <Form.Control type="number" placeholder="Weight Range" name="weightRange"
                                                        {...register('weightRange', { required: 'Field is required' })} required min='0' />
                                                    <label htmlFor="WeightRange">Weight Range</label>
                                                </div>
                                                {errors.weightRange?.message && (<p className="error-message">{" * " + errors.weightRange?.message}</p>)}
                                            </Col>
                                            <Col sm={2}>
                                                <div className="form-floating">
                                                    <Form.Select aria-label="Floating label select example" name="weightCalctype"  {...register('weightCalctype', { required: 'Field is required' })}>
                                                        <option>{subscribe?.weightCalcType || "Select"}</option>
                                                        <option value="Total">Total</option>
                                                        <option value="Average">Average</option>
                                                    </Form.Select>
                                                    <label htmlFor="WeightCalctype">Weight Calc Type</label>
                                                </div>
                                                {errors.weightCalctype?.message && (<p className="error-message">{" * " + errors.weightCalctype?.message}</p>)}
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Row> }
                                {selected?.rateType === 'LOCAL' &&
                                <Row className="row m-0">
                                    <fieldset className="mb-3">
                                        <label className="label-heading">Waiting Time Unit Section</label>
                                        <Row>
                                            <Col sm={3}>
                                                <div className="fieldset-inner">
                                                    <label className="label-heading">Choose the waiting time unit rate</label>
                                                    <div className="floating-checbox">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="waitingTimeUnitRateType" {...register("waitingTimeUnitRateType",{ required: 'Field is required' })} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="waitingTimeUnitRateType" {...register("waitingTimeUnitRateType",{ required: 'Field is required' })} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {errors.waitingTimeUnitRateType?.message && (<p className="error-message">{" * " + errors.waitingTimeUnitRateType?.message}</p>)}
                                            </Col>
                                            <Col sm={3}>
                                                {subscribe?.waitingTimeUnitRateType === "A" ? (
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Waiting Time Unit Rate" name="waitingTimeUnitRate"   {...register('waitingTimeUnitRate', { required: 'Field is required' })} min='0' />
                                                            <label htmlFor="WaitingTimeUnitRate" style={{ fontSize: "13px" }}>Waiting Time Unit Rate ($)</label>
                                                        </div>
                                                        {errors.waitingTimeUnitRate?.message && (<p className="error-message">{" * " + errors.waitingTimeUnitRate?.message}</p>)}
                                                    </div>
                                                ) : subscribe?.waitingTimeUnitRateType === "P" ? (
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Waiting Time Unit Rate" name="waitingTimeUnitRate"   {...register('waitingTimeUnitRate', { required: 'Field is required' })} min='0' />
                                                            <label htmlFor="WaitingTimeUnitRate" style={{ fontSize: "13px" }}>Waiting Time Unit Rate (%)</label>
                                                        </div>
                                                        {errors.waitingTimeUnitRate?.message && (<p className="error-message">{" * " + errors.waitingTimeUnitRate?.message}</p>)}
                                                    </div>
                                                ) : (
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Waiting Time Unit Rate" name="waitingTimeUnitRate"   {...register('waitingTimeUnitRate', { required: 'Field is required' })} min='0' />
                                                            <label htmlFor="WaitingTimeUnitRate" style={{ fontSize: "13px" }}>Waiting Time Unit Rate ($)</label>
                                                        </div>
                                                        {errors.waitingTimeUnitRate?.message && (<p className="error-message">{" * " + errors.waitingTimeUnitRate?.message}</p>)}
                                                    </div>
                                                )
                                                }
                                            </Col>
                                            
                                            <Col sm={3}>
                                                <div className="form-floating">
                                                    <Form.Control type="number" placeholder="Waiting Time Unit" name="waitingtimeUnit"   {...register('waitingtimeUnit', { required: 'Field is required' })} required />
                                                    <label htmlFor="WaitingTimeUnit">Waiting Time Unit</label>
                                                </div>
                                                {errors.waitingtimeUnit?.message && (<p className="error-message">{" * " + errors.waitingtimeUnit?.message}</p>)}
                                            </Col>
                                            <Col sm={3}>
                                                <Form.Group controlId="Free Waiting time">
                                                    <FloatingLabel controlId="floatingInput" label="Free Waiting time" >
                                                        <Form.Control type="number" placeholder="Free Waiting time" name="freeWaitingTime" {...register('freeWaitingTime', { required: 'Field is required' })} required />
                                                    </FloatingLabel>
                                                    {errors.freeWaitingTime?.message && (<p className="error-message">{" * " + errors.freeWaitingTime?.message}</p>)}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Row> }
                            </div>
                            : null}
                        {/* table */}
                        <Row>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th>Vehicle Type</th>
                                        <th>After Hour percent</th>
                                        <th>BaseRateType</th>
                                        <th>BaseRate</th>
                                        <th>CrossoverRateType</th>
                                        <th>CrossoverRate</th>
                                        <th>Piece rateType</th>
                                        <th>Piece rate</th>
                                        <th>Piece Included</th>
                                        <th>Weight Rate Type</th>
                                        <th>Weight Rate</th>
                                        <th>Weight Included</th>
                                        <th>Weight Range</th>
                                        <th>Weight Calc Type</th>
                                        <th>Waiting Time Unit Rate Type</th>
                                        <th>Waiting time unit rate</th>
                                        <th>Waiting time unit</th>
                                        <th>Free Waiting time</th>
                                       
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {vehicleSurchargeList?.length > 0 ? (
                                        vehicleSurchargeList.map((data, index,) => {
                                            return (
                                                <tr key={data?.rateCardVehicleID}>
                                                    <td >
                                                        <div className="action_btn">
                                                            {permission.find(event => event.moduleId === 41)?.permissionType?.indexOf('Update') !== -1 ?
                                                                <span className="edit_btn" onClick={() => EditvehicleItem(data?.rateCardVehicleID)}><Edit /></span>
                                                                : <span className="edit_btn disable"><Edit /></span>}
                                                            {permission.find(event => event.moduleId === 41)?.permissionType?.indexOf('Delete') !== -1 ?
                                                                <span className="del_btn" onClick={() => handleOpen(data?.rateCardVehicleID)}><Delete /></span>
                                                                : <span className="del_btn disable"><Delete /></span>}
                                                            <div>
                                                                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                                                    <DialogTitle id="alert-dialog-title">
                                                                        Delete Confirmation
                                                                    </DialogTitle>
                                                                    <DialogContent>
                                                                        <DialogContentText id="alert-dialog-description">
                                                                        Deleting this vehicle will impact records in the Vehicle and Service surcharge sections associated with it. 
                                                                        Are you sure you want to proceed with the deletion?
                                                                        </DialogContentText>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                                                                        <Button className="btn-primary" onClick={() => { deleteData(); handleClose(); }} autoFocus> Confirm</Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{data.vehicleType}</td>
                                                    <td>{data.afterHourPercent}</td>
                                                    <td>{data.baseRateType}</td>
                                                    <td>{data.baseRate ? (data.baseRateType === 'A' ? (data.baseRate).toFixed(2) : data.baseRateType === 'P'? (data.baseRate) :data.baseRate):""}</td>
                                                    <td>{data.crossoverRateType}</td>
                                                    <td>{data.crossoverRate ? (data.crossoverRateType === 'A' ? (data.crossoverRate).toFixed(2) : data.crossoverRateType === 'P'? (data.crossoverRate) :data.crossoverRate):""}</td>
                                                    <td>{data.pieceRateType}</td>
                                                    <td>{data.pieceRate ? (data.pieceRateType === 'A' ? (data.pieceRate).toFixed(2) : data.pieceRateType === 'P'? (data.pieceRate) :data.pieceRate):""}</td>
                                                    <td>{data.pieceInlcuded}</td>
                                                    <td>{data.weightRateType}</td>
                                                    <td>{data.weightRate ? (data.weightRateType === 'A' ? (data.weightRate).toFixed(2) : data.weightRateType === 'P'? (data.weightRate) :data.weightRate):""}</td>
                                                    <td>{data.weightIncluded}</td>
                                                    <td>{data.weightRange}</td>
                                                    <td>{data.weightCalcType}</td>
                                                    <td>{data.waitingTimeUnitRateType}</td>
                                                    <td>{data.waitingTimeUnitRate ? (data.waitingTimeUnitRateType === 'A' ? (data.waitingTimeUnitRate).toFixed(2) : data.waitingTimeUnitRateType === 'P'? (data.waitingTimeUnitRate) :data.waitingTimeUnitRate):""}</td>
                                                    <td>{data.waitingtimeUnit}</td>
                                                    <td>{data.freeWaitingTime}</td>
                                                    
                                                    
                                                </tr>
                                            )
                                        })

                                    ) : (
                                        <p>No data found </p>
                                    )}
                                </tbody>
                            </Table>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
            {/* <ToastContainer /> */}
        </>
    );
}
export default memo(EditVehicleSurcharge);