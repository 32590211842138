import { apiSlice } from "../../app/services/authServices";


export const customerSlice = apiSlice.injectEndpoints({
    tagTypes:['Customer'],
    endpoints:builder => ({
      getCustomer: builder.query({
        query: () => ({
          url: 'Customer/GetListOfCustomer',
          method: 'GET',
          providesTags: ['Customer'],
        }),
      }),
        createCustomer: builder.mutation({
          query: (body) => ({
            url: 'Customer/AddCustomer',
            method: 'POST',
            body,
          }),
          invalidatesTags: ['Customer']
        }),
        createCustomerNameAddress: builder.mutation({
          query: (body) => ({
            url: 'Customer/AddNameAddress',
            method: 'POST',
            body,
          }),
          invalidatesTags: ['Customer']
        }),
        createCustomerContractPricing: builder.mutation({
          query: (body) => ({
            url: 'Customer/AddRateCard',
            method: 'POST',
            body,
          }),
          invalidatesTags: ['Customer']
        }),
        getCustomerByID: builder.query({
          query: (account) => ({
            url: `Customer/GetCustomerDetailsByID?AccountNo=${account}`,
            method: 'GET',
            providesTags: ['Customer'],
          }),
        }),
        deleteCustomer:builder.mutation({
          query: (account_No) => ({
            url: `Customer/DeleteCustomer?AccountNo=${account_No}`,
            method: 'POST',
          }),
          invalidatesTags: ['Customer']
        }),
        favoriteCustomer:builder.mutation({
          query: (account_No) => ({
            url: `Customer/FavoriteProfile?AccountNo=${account_No}`,
            method: 'POST',
          }),
          invalidatesTags: ['Customer']
        }),
        createSpecialAddress: builder.mutation({
          query: (body) => ({
            url: 'Customer/AddSpecialAddress',
            method: 'POST',
            body,
          }),
          invalidatesTags: ['Customer']
        }),
        editSpecialAddress: builder.mutation({
          query: ({ body, accountNo }) => ({
            url: `Customer/EditSpecialAddress?AccountNo=${accountNo}`,
            method: 'POST',
            body,
          }),
          invalidatesTags: ['Customer']
        }),
        deleteSpecialAddress: builder.mutation({
          query: (rateCardServiceLocationOverrideId) => ({
            url: `Customer/DeleteSpecialAddress?RateCardServiceLocationOverrideId=${rateCardServiceLocationOverrideId}`,
            method: 'POST',
          }),
          invalidatesTags: ['Customer']
        })
      }),
})

export const { useGetCustomerQuery, useCreateCustomerMutation, useCreateCustomerNameAddressMutation, 
  useCreateCustomerContractPricingMutation, useLazyGetCustomerByIDQuery, useDeleteCustomerMutation , 
  useFavoriteCustomerMutation, useCreateSpecialAddressMutation, useDeleteSpecialAddressMutation, 
  useEditSpecialAddressMutation} = apiSlice
