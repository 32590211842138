import { Col, Row, Form, FloatingLabel,Table,Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useState, useEffect} from "react";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../../../context/CustomerContext";
import { useUpdateDistanceFuelDataMutation, useDeleteDistanceFuelDataMutation,useAddFuelDistanceAfterEditMutation,addDistanceFuel, editDistanceFuel } from "../../../../../features/distanceFuel/distanceFuelSlice";
import { useModifyHistoryMutation } from "../../../../../features/customer/history/historySlice";
import { useRef } from "react";
import { useCustomData } from "../../../../Sidebar/Permission";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const EditDistanceFuel = (props)=>{
  const [permission] = useCustomData()
  const [updateDistanceFuel] = useUpdateDistanceFuelDataMutation()
  const [deleteDistanceFuel] = useDeleteDistanceFuelDataMutation()
  const [addFuelAfterEdit] = useAddFuelDistanceAfterEditMutation()
  const [modifyHistory] = useModifyHistoryMutation()
  const {handleAccount, singleAccount, distanceFuelLists,getDefaultCard} = useCustomer();
  const account= singleAccount && singleAccount[0];
  const [message, setMessage] = useState("Modified the distance fuel section")
  const [isEditItem, setIsEditItem ] = useState(null);
  const [isDelete, setIsDelete] = useState();
  const [fuelDistance, setFuelDistance] = useState({})
  const [showFuelSecondFields, setShowFuelSecondFields] = useState(false);
  const [editId,setEditId]=useState('');
  const [fuelOpen, setFuelOpen] = useState(false);
  const handleFuelOpen = (data) => {setFuelOpen(true); setIsDelete(data);};
  const handleFuelClose = () => {setFuelOpen(false);};
  const prevCount = usePrevious(fuelDistance);
  const dispatch = useDispatch();
  const [isUpdate, setIsUpdate] = useState(false);

  const Fuelupdate=(e)=>{setFuelDistance(fuelDistance => ({...fuelDistance,[e.target.name]:e.target.value }))}
  
  const EditfuelItem = (distanceFuelID) => {
    setIsUpdate(true);
    setEditId(distanceFuelID)
    let newEditItem = distanceFuelLists.find((data) =>{
      return data.DistanceFuelID      === distanceFuelID
    });

  setFuelDistance(newEditItem);
  setShowFuelSecondFields(showFuelSecondFields => !showFuelSecondFields)
  setIsEditItem(distanceFuelID);
}

// Update record
  const editfuelCustomerList = async ()=>{
    if (JSON?.stringify(prevCount) === JSON?.stringify(fuelDistance)) {
      setTimeout(function () {
        toast.info("No Changes found on this distance fuel section !", {
            position: "top-right",
        })
    });
    }
    else {
      const updateDistanceFuelData = await updateDistanceFuel({...fuelDistance, distanceFuelID: fuelDistance.DistanceFuelID})
      setFuelDistance("");
      //handleAccount(account.account_No)
      if (updateDistanceFuelData.data.statusCode === 200) {
        setTimeout(function () {
          toast.success("Distance fuel section updated successfully !", {
            position: "top-right",
          })
        });
        
        dispatch(editDistanceFuel(fuelDistance));
        setIsUpdate(false);
      }
    }
    if (JSON?.stringify(prevCount) !== JSON?.stringify(fuelDistance)) {
      const modifieddata = {
        //AccountNo: account.account_No,
        //CustomerName: account.customer,
        Modifiedsection: message
      }
      await modifyHistory(modifieddata)
    }
}

// Add new record
const addNewRecord = async () => {
  if (Object.keys(fuelDistance).length === 0) {
    setShowFuelSecondFields(true)
  }
  else {
    const updateDistanceFuel = await addFuelAfterEdit({ rateCardID: props.selectedCard, ...fuelDistance })
    setFuelDistance("");
    //handleAccount(account.account_No)
    if (updateDistanceFuel?.data?.statusCode === 200) {
      toast.success("Distance fuel record added successfully !", {
        position: "top-right",
      })
      setFuelDistance("");
      //const mapData = {...fuelDistance, FromCost: fuelDistance.FromCost, ToCost: fuelDistance.ToCost, Surcharge: fuelDistance.Surcharge}
      dispatch(addDistanceFuel(fuelDistance));
      getDefaultCard(props.selectedCard)
      //handleAccount(account.account_No)
    }
  }
}


  const deletefuelCustomerList = async ()=>{
    const deleteFuelRecord = await deleteDistanceFuel(isDelete)
        if (deleteFuelRecord.data.statusCode === 200) {
            setTimeout(function () {
                toast.success("Distance fuel record deleted successfully !", {
                    position: "top-right",
                })
            });
            getDefaultCard(props.selectedCard)
        }
        // handleAccount(account?.account_No)
        handleFuelClose();
}
    return(
        <>
          {/* Distance fuel surcharge table */}
          <Row className="mb-4 mt-4">
            <Col sm={12}>
              <fieldset className="mb-3">
                <label className="label-heading">Distance Fuel Table</label>
                  <Row className="mb-2 mt-2">
                    <Col sm={12}>
                      <fieldset className="pb-2">
                      <div className="d-flex justify-content-end">
                        <div className="section_action_btn">
                      {/* {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ? 
                          <div className="save_btn"><button type="button" className="btn" onClick={e=> {editfuelCustomerList(e);}}><Save/></button></div>
                          :<div className="save_btn disable"><button type="button" className="btn"><Save/></button></div> }
                        </div>
                        { showFuelSecondFields ?  */}
                        {isUpdate ?
                        <div className="save_btn"><button type="button" className="btn" onClick={e => { editfuelCustomerList(e); }}>Update record</button></div>
                        :
                        <>
                          {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ?
                            <div className="add_btn"><button type="button" className="btn" onClick={e => { addNewRecord(e); }}>Add new record</button></div>
                            : <div className="save_btn disable"><button type="button" className="btn"><Save /></button></div>}
                        </>
                      }
                      </div>
                      </div>
                      {showFuelSecondFields ?
                    <>
                    <Row className="mt-2 mb-3">
                      <Col sm={4}>
                        <Form.Group controlId="FromCost">
                        <FloatingLabel controlId="floatingInput" label="From Cost" >
                        <Form.Control type="number" placeholder="From Cost" name="FromCost" value={fuelDistance?.FromCost || ""} onChange={e=>Fuelupdate(e)} required min='0'/>
                        </FloatingLabel>    
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group controlId="ToCost">
                        <FloatingLabel controlId="floatingInput" label="To Cost" >
                        <Form.Control type="number" placeholder="To Cost" name="ToCost" value={fuelDistance?.ToCost || ""}  onChange={e=>Fuelupdate(e)}  required min='0' />
                        </FloatingLabel>    
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group controlId="Surcharge">
                        <FloatingLabel controlId="floatingInput" label="Surcharge" >
                        <Form.Control type="number" placeholder="Surcharge" name="Surcharge" value={fuelDistance?.Surcharge || ""}  onChange={e=>Fuelupdate(e)}  required min='0'/>
                        </FloatingLabel>    
                        </Form.Group>
                      </Col>
                    </Row>
            </>
      : null }
      {/* table */}
<Row>
<Table striped bordered hover>
  <thead>
    <tr>
      <th>Action</th>
      <th>From Cost</th>
      <th>To Cost</th>
      <th>Surcharge</th>
    </tr>
  </thead>
  <tbody>
  {distanceFuelLists?.length > 0 ? (
      distanceFuelLists?.map((data) =>{
        return(
        <tr key={data?.distanceFuelID}>
        <td>
          <div className="action_btn">
          {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ? 
            <span className="edit_btn" onClick={() =>EditfuelItem(data.DistanceFuelID)}><Edit /></span>
            :  <span className="edit_btn disable"><Edit /></span>}
            {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Delete') !== -1 ? 
            <span className="del_btn" onClick={() => handleFuelOpen(data?.DistanceFuelID)}><Delete /></span>
            : <span className="del_btn disable"><Delete /></span> }
            <div>
      <Dialog open={fuelOpen} onClose={handleFuelClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
        <DialogTitle id="alert-dialog-title">
        Delete Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this row?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="btn-secondary" onClick={handleFuelClose}>Cancel</Button>
          <Button className="btn-primary" onClick={() => {deletefuelCustomerList();}} autoFocus> Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
            </div>
        </td>
          <td>{data.FromCost}</td>
          <td>{data.ToCost}</td>
          <td>{data.Surcharge}</td>

        </tr>
      )})
      ) : (
        <p>No data found </p>
         )}
  </tbody>
</Table>
  </Row>
</fieldset>
</Col>
</Row>
</fieldset>
</Col>
</Row>

{/* <ToastContainer />
 */}
</>
    );
}
export default EditDistanceFuel;