import { AddCircle, Delete, Edit, Save } from "@material-ui/icons";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import useCustomer from "../../../../../context/CustomerContext";
import { addDistanceFuel, deleteDistanceFuel, editDistanceFuel } from "../../../../../features/distanceFuel/distanceFuelSlice";
import { useCustomData } from "../../../../Sidebar/Permission";

const DistanceFuel = () => {
    const [permission] = useCustomData()
    const dispatch = useDispatch();
    const { distanceFuelLists } = useCustomer();
    const [open, setOpen] = useState(false);
    const [isShow, setIsShow] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [distanceFuel, setDistanceFuel] = useState({})
    const [editingTaskIndex, setEditingTaskIndex] = useState(null);
    const [isDelete, setIsDelete] = useState(null);
    const handleClickOpen = (id) => { setOpen(true); setIsDelete(id) };
    const handleClose = () => { setOpen(false); };
    const Distancefuelhandler = (e) => { setDistanceFuel(distanceFuel => ({ ...distanceFuel, [e.target.name]: e.target.value })) }


    // Add and update vehicle list 
    const addDistanceFuelList = () => {
        if (Object.keys(distanceFuel).length === 0) {
            setIsShow(isShow => !isShow);
        }
        else if (editingTaskIndex === null) {
            dispatch(addDistanceFuel(distanceFuel));
        } else {
            let editingItem = distanceFuelLists[editingTaskIndex];
            editingItem = distanceFuel;
            dispatch(editDistanceFuel(editingItem));
        }
        setDistanceFuel("");
        setEditingTaskIndex(null);
        setIsUpdate(false)
    }

    // Edit vehicle list
    const editDistanceFuelList = (id) => {
        let newEditItem = distanceFuelLists.find((data) => {
            return data.id === id
        });
        setIsUpdate(true);
        setIsShow(isShow => !isShow);
        setDistanceFuel(newEditItem)
        setEditingTaskIndex(id);
    }


    return (
        <>
            <Row className="mb-4 mt-4">
                <Col sm={12}>
                    <fieldset className="mb-3">
                        <label className="label-heading">Distance Fuel Surcharge Table</label>
                        <Row className="mb-2 mt-2">
                            <Col sm={12}>
                                <fieldset className="pb-2">
                                    <div className="section_action_btn">
                                        {!isUpdate ?
                                            <>
                                                {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Add') !== -1 ?
                                                    <div className="add_btn"><button type="button" className="btn" value="adddistancefuel" onClick={e => { addDistanceFuelList(e); }}><AddCircle /></button></div>
                                                    : <div className="add_btn disable"><button type="button" className="btn" value="adddistancefuel"><AddCircle /></button></div>}
                                            </>
                                            :
                                            <div className="save_btn"><button type="button" className="btn" value="adddistancefuel" onClick={e => { addDistanceFuelList(e); }}><Save /></button></div>
                                        }
                                    </div>
                                    {isShow ?
                                        <>
                                            <Row className="mt-2 mb-3">
                                                <Col sm={4}>
                                                    <Form.Group controlId="FromCost">
                                                        <FloatingLabel controlId="floatingInput" label="From Cost" >
                                                            <Form.Control type="number" placeholder="From Cost" name="FromCost" onChange={e => Distancefuelhandler(e)} value={distanceFuel.FromCost || ''} min='0' />
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Group controlId="ToCost">
                                                        <FloatingLabel controlId="floatingInput" label="To Cost" >
                                                            <Form.Control type="number" placeholder="To Cost" name="ToCost" onChange={e => Distancefuelhandler(e)} value={distanceFuel.ToCost || ''} min='0' />
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={4}>
                                                    <Form.Group controlId="Surcharge">
                                                        <FloatingLabel controlId="floatingInput" label="Surcharge" >
                                                            <Form.Control type="number" placeholder="Surcharge" name="Surcharge" onChange={e => Distancefuelhandler(e)} value={distanceFuel.Surcharge || ''} min='0' />
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                        : null}
                                    {/* table */}
                                    <Row>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Action</th>
                                                    <th>From Cost</th>
                                                    <th>To Cost</th>
                                                    <th>Surcharge</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {distanceFuelLists.map((data) => (
                                                    <tr key={data.id}>
                                                        <td>
                                                            <div className="action_btn">
                                                                <span className="edit_btn" onClick={() => editDistanceFuelList(data.id)}><Edit /></span>
                                                                <span className="del_btn" onClick={() => handleClickOpen(data.id)}><Delete /></span>
                                                                <div>
                                                                    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                                                        <DialogTitle id="alert-dialog-title">
                                                                            Delete Confirmation
                                                                        </DialogTitle>
                                                                        <DialogContent>
                                                                            <DialogContentText id="alert-dialog-description">
                                                                                Are you sure you want to delete this row?
                                                                            </DialogContentText>
                                                                        </DialogContent>
                                                                        <DialogActions>
                                                                            <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                                                                            <Button className="btn-primary" onClick={() => { dispatch(deleteDistanceFuel(isDelete)); handleClose(); }} autoFocus> Confirm</Button>
                                                                        </DialogActions>
                                                                    </Dialog>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{data.FromCost}</td>
                                                        <td>{data.ToCost}</td>
                                                        <td>{data.Surcharge}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </fieldset>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
        </>

    );
}
export default DistanceFuel;