import React from 'react';
import { useState,useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { useLazyGetListOfAccessorialByCustomerQuery } from "../../../../../../src/features/order/orderSlice";
import {useEditSelectedOrderAccessorialsMutation, useLazyGetSelectedOrderDetailsQuery} from "../../../../../../src/features/Dispatch/DispatchSlice";
import { toast } from 'react-toastify';

const AddAccessorial = ({ setSelectedOrder, selectedOrder,divisionId  }) => {
    const [fetchAccessorial, { data, isLoading, isError }] = useLazyGetListOfAccessorialByCustomerQuery();
    const [editAccessorials, { isLoading: isSaving }] = useEditSelectedOrderAccessorialsMutation();

    const [selectedAccessorials, setSelectedAccessorials] = useState([]);
    const [selectedAccessorialsBox2, setSelectedAccessorialsBox2] = useState([]);
    const [selectedAccessorialsBox3, setSelectedAccessorialsBox3] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [triggerGetSelectedOrderDetails, { data: selectedOrderDetails } ]= useLazyGetSelectedOrderDetailsQuery();

    // Fetch accessorials when the selected order changes
    useEffect(() => {
        if (selectedOrder?.accountNo) {
            fetchAccessorial(selectedOrder?.accountNo);
        }
    }, [selectedOrder, fetchAccessorial]);

    useEffect(() => {
        if (data?.data && selectedOrder?.pickupAccessorialCodes) {
            const preSelectedAccessorials = data.data
                .filter(accessorial => selectedOrder.pickupAccessorialCodes.includes(accessorial.accessorialDescription))
                .map(accessorial => accessorial.accessorialID);
            setSelectedAccessorials(preSelectedAccessorials);
        }
        if (data?.data && selectedOrder?.dropoffAccessorialCodes) {
            const preSelectedAccessorialsBox2 = data.data
                .filter(accessorial => selectedOrder.dropoffAccessorialCodes.includes(accessorial.accessorialDescription))
                .map(accessorial => accessorial.accessorialID);
            setSelectedAccessorialsBox2(preSelectedAccessorialsBox2);
        }
        if (data?.data && selectedOrder?.orderLevelAccessorialCodes) {
            const preSelectedAccessorialsBox3 = data.data
                .filter(accessorial => selectedOrder.orderLevelAccessorialCodes.includes(accessorial.accessorialDescription))
                .map(accessorial => accessorial.accessorialID);
            setSelectedAccessorialsBox3(preSelectedAccessorialsBox3);
        }
    }, [data, selectedOrder]);

    useEffect(() => {
        const commonAccessorials = selectedAccessorials.filter(accessorial => selectedAccessorialsBox2.includes(accessorial));
        setSelectedAccessorialsBox3(commonAccessorials);
    }, [selectedAccessorials, selectedAccessorialsBox2]);

    // Handle checkbox selection
    const handleCheckboxChange = (accessorialID) => {
        setSelectedAccessorials(prevSelected => {
            if (prevSelected.includes(accessorialID)) {
                // If already selected, remove it
                return prevSelected.filter(id => id !== accessorialID);
            } else {
                // If not selected, add it
                return [...prevSelected, accessorialID];
            }
        });
    };

    const handleCheckboxChangeBox2 = (accessorialID) => {
        setSelectedAccessorialsBox2(prevSelected => {
            if (prevSelected.includes(accessorialID)) {
                return prevSelected.filter(id => id !== accessorialID);
            } else {
                return [...prevSelected, accessorialID];
            }
        });
    }
    const handleCheckboxChangeBox3 = (accessorialID) => {
        setSelectedAccessorialsBox3(prevSelected => {
            if (prevSelected.includes(accessorialID)) {
                return prevSelected.filter(id => id !== accessorialID);
            } else {
                return [...prevSelected, accessorialID];
            }
        });
    }

    const handleSave = async () => {       
        const result = await editAccessorials({
                orderId: selectedOrder.orderId,
                PickupAccessorialCodes: selectedAccessorials,
                DropoffAccessorialCodes: selectedAccessorialsBox2,
                OrderLevelAccessorialCodes: selectedAccessorialsBox3,
            });
        if (result?.data?.statusCode === 200) {
            toast.success("Accessorial updated successfully",{
                position: "top-center",
              })
            const order = await triggerGetSelectedOrderDetails({
                divisionId: divisionId,
                orderId: selectedOrder.orderId,
            });
            setSelectedOrder(order.data.data);
        }
        setIsEdit(false); 
    };

    return (
        <>
            <Row className="mb-4">
                <Col sm={8}>
                </Col>
                <Col sm={4}>
                { isEdit?          
                <Button variant="primary" className={"save-btn"} onClick={handleSave} >{"Save"}</Button>                
                :
                <Button variant="primary" className={"save-btn"} onClick={() => setIsEdit(!isEdit)}>{"Edit"}</Button>
                }
                </Col>
            </Row>

            <Row>
                <Col sm ={6}>
                <div className="email-notify">
                        <div className="email-heading">
                            <h6>Pickup Accessorials</h6>
                        </div>
                    {!isLoading && data?.data && (
                        <Form>
                            {data.data.map(accessorial => (
                                <div className="checkbox-section">
                                <Form.Group key={accessorial.accessorialID} controlId={`accessorial-${accessorial.accessorialID}`}>
                                    <Form.Check
                                        type="checkbox"
                                        label={accessorial.accessorialDescription}
                                        checked={selectedAccessorials.includes(accessorial.accessorialID)}
                                        onChange={() => handleCheckboxChange(accessorial.accessorialID)}
                                        disabled={!isEdit}
                                    />
                                </Form.Group>
                                </div>
                            ))}
                        </Form>
                    )}
                </div>
                </Col>

                <Col sm={6}>
                    <div className="email-notify">
                        <div className="email-heading">
                            <h6>Drop off Accessorials</h6>
                        </div>
                        {!isLoading && data?.data && (
                            <Form>
                                {data.data.map(accessorial => (
                                    <div className="checkbox-section" key={`box2-${accessorial.accessorialID}`}>
                                        <Form.Group controlId={`accessorial-box2-${accessorial.accessorialID}`}>
                                            <Form.Check
                                                type="checkbox"
                                                label={accessorial.accessorialDescription}
                                                checked={selectedAccessorialsBox2.includes(accessorial.accessorialID)}
                                                onChange={() => handleCheckboxChangeBox2(accessorial.accessorialID)}
                                                disabled={!isEdit}
                                            />
                                        </Form.Group>
                                    </div>
                                ))}
                            </Form>
                        )}
                    </div>
                </Col>
     
                <Col sm={12}>
                    <div className="email-notify">
                        <div className="email-heading">
                            <h6>Order Accessorials</h6>
                        </div>
                        {!isLoading && data?.data && (
                            <Form>
                                {data.data.map(accessorial => (
                                    <div className="checkbox-section" key={`box3-${accessorial.accessorialID}`}>
                                        <Form.Group controlId={`accessorial-box3-${accessorial.accessorialID}`}>
                                            <Form.Check
                                                type="checkbox"
                                                label={accessorial.accessorialDescription}
                                                checked={selectedAccessorialsBox3.includes(accessorial.accessorialID)}
                                                // onChange={() => handleCheckboxChangeBox3(accessorial.accessorialID)}
                                                //disabled={!isEdit}
                                                disabled
                                            />
                                        </Form.Group>
                                    </div>
                                ))}
                            </Form>
                        )}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default AddAccessorial;
