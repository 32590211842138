import { Col, Row, Form, FloatingLabel, Button } from "react-bootstrap";
import { Save } from "@material-ui/icons";
import 'react-toastify/dist/ReactToastify.css';
import useCustomer from "../../../context/CustomerContext";
import { useCustomData } from "../../Sidebar/Permission";

const EditContractdata = ({ showEditRate, showedithandler }) => {
  const [permission] = useCustomData()
  const { contractInfo } = useCustomer();
  const ratecardaccount = contractInfo && contractInfo[0];

  return (
    <>
      {/* Contract info  section*/}
      {contractInfo?.length > 0 ? (
        <>
          <Row className="mb-2 mt-2">
            <Col sm={12}>
              <fieldset >
                <div className="button-section" style={{ justifyContent: "end" }}>
                  {permission.find(event => event.moduleId === 40)?.permissionType?.indexOf('Update') !== -1 ?
                    <Button className="edit-btn" onClick={e => { showedithandler(e); }} style={{ width: "135px", paddingRight: "12px" }}>
                      Edit{" "}
                      <span>
                        <Save />
                      </span>
                    </Button>
                    : <Button className="edit-btn disable" style={{ width: "135px", paddingRight: "12px" }}>
                      Edit{" "}
                      <span>
                        <Save />
                      </span>
                    </Button>}
                </div>
                <>
                  <Row className="mt-2 mb-3">
                    <Col sm={3}>
                      <Form.Group controlId="fuelsurcharge">
                        <FloatingLabel controlId="floatingSelectGrid" label="Fuel Surcharge Method">
                          <Form.Select aria-label="Floating label select example" name="fuelSurchargeMethod" value={ratecardaccount?.fuelSurchargeMethod} required>
                            <option>Select</option>
                            <option value="1">General</option>
                            <option value="2">Distance</option>

                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="Distancetype">
                        <FloatingLabel controlId="floatingSelectGrid" label="Distance Type">
                          <Form.Select aria-label="Floating label select example" name="distanceType" value={ratecardaccount?.distanceType} required >
                            <option>Select</option>
                            <option value="Z">Zone</option>
                            <option value="R">Region</option>
                          </Form.Select>

                        </FloatingLabel>
                      </Form.Group>
                    </Col>

                    <Col sm={3}>
                      <Form.Group controlId="Lbs/Linear FTn">
                        <FloatingLabel controlId="floatingInput" label="Lbs/Linear FT(lb)" style={{ fontSize: "13px" }} >
                          <Form.Control type="number" placeholder="Lbs/Linear FT" name="LbsPerLinearFT" value={ratecardaccount?.lbsPerLinearFT} required />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="Lbs/Cu FT">
                        <FloatingLabel controlId="floatingInput" label="Lbs/Cu FT(lb)" >
                          <Form.Control type="number" placeholder="Lbs/Cu FT" name="LbsPerCuFT" value={ratecardaccount?.lbsPerCuFT} required />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-3">
                    <Col sm={3}>
                      <Form.Group controlId="Max Height Start">
                        <FloatingLabel controlId="floatingInput" label="Max Height Start(in)" >
                          <Form.Control type="number" placeholder="Max Height Start" name="Maxheightstart" value={ratecardaccount?.maxHeightStart} required />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="Max Height Override">
                        <FloatingLabel controlId="floatingInput" label="Max Height Override(in)" >
                          <Form.Control type="number" placeholder="Max Height Override" name="MaxHeightOverride" value={ratecardaccount?.maxHeightOverride} required />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="Long Freight Threshold">
                        <FloatingLabel controlId="floatingInput" label="Long Fright Threshold(ft)" >
                          <Form.Control type="number" placeholder="Long Fright Threshold" name="LongFreightThreshold" value={ratecardaccount?.longFreightThreshold} required />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                    <Col sm={3}>
                      <Form.Group controlId="Long Freight Amount">
                        <FloatingLabel controlId="floatingInput" label="Long Freight Amount($)" >
                          <Form.Control type="number" placeholder="Long Freight Amount" name="LongFreightAmount" value={ratecardaccount?.longFreightAmount || ''} required />
                        </FloatingLabel>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-3 mb-3">
                    <Col sm={12}>
                      <Form.Control
                        as="textarea"
                        placeholder="Description"
                        value={ratecardaccount?.description || ""}
                        style={{ height: '100px' }}
                      />
                    </Col>
                  </Row>
                </>
              </fieldset>
            </Col>
          </Row>
        </>
      ) : (
        <Row className="mb-2 mt-2">
          <Col sm={12}>
            <p className="text-center">No data Found</p>
          </Col>
        </Row>
      )}
    </>
  );
}
export default EditContractdata;