import React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
// import useOrder from "../../../../../context/OrderContext";
import { useGetOrderNotificationQuery,useUpdateStatusNotificationMutation } from "../../../../../features/order/orderSlice";


const AutoStatus = ({ selectedOrder, orderNotifData }) => {
    // const { pickInfo, dropInfo, accountInfo } = useOrder();
    const [updateStatusNotification] = useUpdateStatusNotificationMutation();
    const { data: selectedOrderDetails, refetch } = useGetOrderNotificationQuery(selectedOrder?.orderNumber, {
        skip: !selectedOrder?.orderNumber, // Skip the query if the orderNumber is not available
    });
    const [isEdit, setIsEdit] = useState(false);

    console.log(orderNotifData);
    
    const [emailValue, setEmailValue] = useState(false);
    const [consignorValue, setConsignorValue] = useState(false);
    const [consigneeValue, setConsigneeValue] = useState(false);
    const [tagsSendToEmail, setTagsSendToEmail] = useState('');
    
    const consignor = selectedOrder?.pickupContactEmail;
    const consignee = selectedOrder?.dropoffContactEmail;
    const headAccount = selectedOrder?.emailAddress;

    useEffect(() => {
        if (orderNotifData) {
            setEmailValue(orderNotifData.data.statusSendToHeadAccount);
            setConsignorValue(orderNotifData.data.statusSendToConsignor);
            setConsigneeValue(orderNotifData.data.statusSendToConsignee);
            setTagsSendToEmail(orderNotifData.data.statusSendToEmail);
        }
    }, [orderNotifData]);


    const SubmitHandler = async () => {
        const data = {
            orderNumber: selectedOrder?.orderNumber,
            statusSendToHeadAccount: emailValue ? true : false,
            statusSendToConsignor: consignorValue ? true : false,
            statusSendToConsignee: consigneeValue ? true : false,
            statusSendToEmail: tagsSendToEmail,
        }
        console.log(data);
        const result = await updateStatusNotification(data);
        if (result?.data?.statusCode === 200) {
            await refetch();
        }        
        setIsEdit(!isEdit);

    }
    

    const handleChange = event => {
        if (isEdit) {
            if (event.target.name === "headaccount") {
                if (event.target.checked) {
                    setEmailValue(event.target.value);
                }
                else {
                    setEmailValue(false)
                }
            }
            else if (event.target.name === "consignor") {
                if (event.target.checked) {
                    setConsignorValue(event.target.value);
    
                }
                else {
                    setConsignorValue(false)
    
                }
            }
            else if (event.target.name === "consignee") {
                if (event.target.checked) {
                    setConsigneeValue(event.target.value);
    
                }
                else {
                    setConsigneeValue(false)
    
                }
            }
        }
    };
    const addTags = (event, type) => {
        if (event.target.value !== "") {
            switch (type) {
                case 'sendToEmail':
                    setTagsSendToEmail(event.target.value);
                    break;
                default:
                    break;
            }
            event.target.value = "";
        }
    };
    const handleInputChange = (event) => {
        setTagsSendToEmail(event.target.value);
    };

    
    return (
        <>
        <Row className="mb-4">
            <Col sm={8}>
            </Col>
            <Col sm={3}>
            { isEdit?          
                <Button variant="primary" className={"save-btn"} onClick={SubmitHandler}>{"Save"}</Button>                
                :
                <Button variant="primary" className={"save-btn"} onClick={() => setIsEdit(!isEdit)}>{"Edit"}</Button>
            }

                
            </Col>
        </Row>
            <Row >
                <Col sm={12}>
                    <div className="email-notify">
                        <div className="email-heading">
                            <h6>Status Notification</h6>
                        </div>
                        <div className="checkbox-section">
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Send to head account"  name="headaccount" value={headAccount} checked={emailValue} onChange={handleChange} />
                                {emailValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({selectedOrder?.emailAddress})</label> : ""}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Send to consignor (Shipper)" name="consignor" value={consignor} checked={consignorValue} onChange={handleChange} />
                                {consignorValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({selectedOrder?.pickupContactEmail})</label> : ""}
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Send to consignee (Receiver)" name="consignee" value={consignee} checked={consigneeValue} onChange={handleChange} />
                                {consigneeValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({selectedOrder?.dropoffContactEmail})</label> : ""}
                            </Form.Group>   
                        </div>
                    </div>
                </Col>
                </Row>
            <Row>
            <p style={{ color: 'red' }}><em>Please enter individual email addresses, separated by a semicolon (;) with no spaces. 
            Example: abc@z.com;xyz@q.com</em></p>
                <Col sm={12}>
                    <div className="tags-input">
                        <input
                            value={tagsSendToEmail}
                            onInput={handleInputChange}
                            placeholder="Enter Email Address"
                            className="email-input"
                            disabled={!isEdit} 
                        />
                    </div>
                </Col>
                {/* {isEdit &&
                 <Button variant="primary" className="email-btn" type="submit" name="submit" onClick={SubmitHandler}> Submit </Button> 
                } */}
            </Row>
        </>
       
        
    )
}

export default AutoStatus;