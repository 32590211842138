import { React, memo } from "react";
import { Col, Row, Form, FloatingLabel,} from "react-bootstrap";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addContract } from "../../../../../features/contractInfo/contractInfoSlice";
import useCustomer from "../../../../../context/CustomerContext";


const ContractData = () => {
  const {errors,setErrors,contractInfo, setContractInfo} = useCustomer()
  const dispatch = useDispatch();
    // const [contractInfo, setContractInfo] = useState({}); 
    const contractHandler = (e) => {      
      setContractInfo(contractInfo => ({ ...contractInfo, [e.target.name]: e.target.value }))  
      const timer = setTimeout(() =>{
        dispatch(addContract({ ...contractInfo, [e.target.name]: e.target.value })) 
      },1000);

      return()=>clearTimeout(timer)
     }

  return (

                <>
                  <Row className="mb-2 mt-2">
                    <Col sm={12}>
                      <fieldset >
                        <Row className="mt-2 mb-3">
                          <Col sm={3}>
                            <div className="form-floating">
                              <Form.Select aria-label="Floating label select example" name="FuelSurchargeMethod" onChange={(e) => { contractHandler(e); }}
                               className={`input ${errors.FuelSurchargeMethod && 'is-danger'}`} value={contractInfo.FuelSurchargeMethod || ''} required>
                                <option>Select</option>
                                <option value="1">General</option>
                                <option value="2">Distance</option>
                              </Form.Select>
                              <label htmlFor="FuelSurchargeMethod">Fuel Surcharge Method</label>
                            </div>
                            {errors.FuelSurchargeMethod && (<p className="help is-danger">{errors.FuelSurchargeMethod}</p>)}
                          </Col>
                          <Col sm={3}>
                            <div className="form-floating">
                              <Form.Select aria-label="Floating label select example" name="DistanceType" onChange={(e) => { contractHandler(e); }}
                                className={`input ${errors.DistanceType && 'is-danger'}`} value={contractInfo.DistanceType || ''} required>
                                <option>Select</option>
                                <option value="z">Zone</option>
                                <option value="r">Region</option>
                              </Form.Select>
                              <label htmlFor="DistanceType">Distance Type</label>
                            </div>
                            {errors.DistanceType && (<p className="help is-danger">{errors.DistanceType}</p>)}
                          </Col>
                              <Col sm={3}>
                                <div className="form-floating">
                                  <Form.Control type="number" placeholder="Lbs/Linear FT" name="LbsPerLinearFT" onChange={(e) => { contractHandler(e); }}
                                   className={`input ${errors.LbsPerLinearFT && 'is-danger'}`} value={contractInfo.LbsPerLinearFT || ''} required />
                                  <label htmlFor="lbsperlinearft">LbsPerLinearFT(lb)</label>
                                  {errors.LbsPerLinearFT && (<p className="help is-danger">{errors.LbsPerLinearFT}</p>)}
                                </div>
                              </Col>
                              <Col sm={3}>
                                <div className="form-floating">
                                  <Form.Control type="number" placeholder="Lbs/Cu FT" name="LbsPerCuFT" onChange={(e) => { contractHandler(e); }}
                                    className={`input ${errors.LbsPerCuFT && 'is-danger'}`} value={contractInfo.LbsPerCuFT || ''} required />
                                  <label htmlFor="lbspercft">LbsPerCuFT(lb)</label>
                                  {errors.LbsPerCuFT && (<p className="help is-danger">{errors.LbsPerCuFT}</p>)}
                                </div>
                              </Col>
                           
                        </Row>
                        <Row className="mt-2 mb-3">
                          <Col sm={3}>
                            <div className="form-floating">
                              <Form.Control type="number" placeholder="Max Height Start" name="MaxHeightStart" onChange={(e) => { contractHandler(e); }}
                               className={`input ${errors.MaxHeightStart && 'is-danger'}`} value={contractInfo.MaxHeightStart || ''} required />
                              <label>Max Height Start(in)</label>
                              {errors.MaxHeightStart && (<p className="help is-danger">{errors.MaxHeightStart}</p>)}
                            </div>
                          </Col>
                          <Col sm={3}>
                            <div className="form-floating">
                              <Form.Control type="number" placeholder="Max Height Override" name="MaxHeightOverride" onChange={(e) => { contractHandler(e); }}
                                className={`input ${errors.MaxHeightOverride && 'is-danger'}`} value={contractInfo.MaxHeightOverride || ''} required />
                              <label>Max Height Override(in)</label>
                              {errors.MaxHeightOverride && (<p className="help is-danger">{errors.MaxHeightOverride}</p>)}
                            </div>
                          </Col>
                          <Col sm={3}>
                            <div className="form-floating">
                              <Form.Control type="number" placeholder="Long Fright Threshold" name="LongFreightThreshold" onChange={(e) => { contractHandler(e); }}
                                className={`input ${errors.LongFreightThreshold && 'is-danger'}`} value={contractInfo.LongFreightThreshold || ''} required />
                              <label>Long Freight Threshold(ft)</label>
                              {errors.LongFreightThreshold && (<p className="help is-danger">{errors.LongFreightThreshold}</p>)}
                            </div>
                          </Col>
                          <Col sm={3}>
                            <Form.Group controlId="Long Freight Amount">
                              <FloatingLabel controlId="floatingInput" label="Long Freight Amount($)" >
                                <Form.Control type="number" placeholder="Long Freight Amount" name="LongFreightAmount" onChange={e => contractHandler(e)} className={`input ${errors.Longfrighttamount && 'is-danger'}`} value={contractInfo.LongFreightAmount || ''} required />
                              </FloatingLabel>
                              {errors.Longfrighttamount && (
                                <p className="help is-danger">{errors.Longfrighttamount}</p>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mt-3 mb-3">
                          <Col sm={12}>
                            <Form.Control as="textarea" placeholder="Description" style={{ height: '100px' }} name="Description" onChange={e => contractHandler(e)} className={`input ${errors.Description && 'is-danger'}`} value={contractInfo.Description || ''} />
                          </Col>
                        </Row>

                      </fieldset>
                    </Col>
                  </Row>
                </>

  );
}
export default memo(ContractData);