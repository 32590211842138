import React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import {useAddOrderNotesMutation,useLazyGetSelectedOrderDetailsQuery} from  '../../../../../features/Dispatch/DispatchSlice';

const OrderNotes = ({ setSelectedOrder, selectedOrder,divisionId }) => {
    const [accountInformationNotes, setAccountInformationNotes] = useState('');
    const [pickupInformationNotes, setPickupInformationNotes] = useState('');
    const [dropInformationNotes, setDropInformationNotes] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [addNotes] = useAddOrderNotesMutation();
    const [triggerGetSelectedOrderDetails, { data: selectedOrderDetails } ]= useLazyGetSelectedOrderDetailsQuery();

    useEffect(() => {
        if (selectedOrder) {
            setAccountInformationNotes(
                selectedOrder?.accountInformationNotes ?? ''  
            );
            setPickupInformationNotes(
                selectedOrder?.pickupInformationNotes ?? ''   
            );
            setDropInformationNotes(
                selectedOrder?.dropoffInformationNotes ?? ''  
            );
        }
    }, [selectedOrder]);

    console.log(selectedOrder);
    const SubmitHandler = async () => {
        const data = {
            MLOrderInformation: {
                orderNo: selectedOrder?.orderNumber, 
                
            },
            mLOrderAccountInformation:{
                LocationNotes: accountInformationNotes, // Notes for account information
            },
            mLOrderPickupInformation: {
                LocationNotes: pickupInformationNotes, // Notes for pickup information
            },
            mLOrderDropupInformation: {
                LocationNotes: dropInformationNotes, // Notes for drop information
            },
        }
        console.log(data);
        const result = await addNotes(data);
        if (result?.data?.statusCode === 200) {
            const order = await triggerGetSelectedOrderDetails({
                divisionId: divisionId,
                orderId: selectedOrder.orderId,
            });
            setSelectedOrder(order.data.data);
        }        
        setIsEdit(false);

    }

    return (
        <>
            <Row>
                <Col sm={9}>
                </Col>
                <Col sm={3}>   
                {isEdit ?               
                   ( <Button variant="primary" className={"save-btn"} onClick={SubmitHandler}>{"Save"}</Button> ):(
                    <Button variant="primary" className={"save-btn"} onClick={() => setIsEdit(true)}>{"Edit"}</Button>
                )}   
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Account Information Notes</Form.Label>
                        <Form.Control as="textarea" rows={3} value={accountInformationNotes} onChange={(e) => setAccountInformationNotes(e.target.value)} disabled={!isEdit}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Pickup Information Notes</Form.Label>
                        <Form.Control as="textarea" rows={3} value={pickupInformationNotes} onChange={(e) => setPickupInformationNotes(e.target.value)} disabled={!isEdit} />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Drop Information Notes</Form.Label>
                        <Form.Control as="textarea" rows={3} value={dropInformationNotes} onChange={(e) => setDropInformationNotes(e.target.value)} disabled={!isEdit} />
                    </Form.Group>
                </Col>
            </Row>
        </>
    );
}

export default OrderNotes;