import { Box, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { BiArrowToLeft, BiArrowToRight } from 'react-icons/bi';
import { toast } from 'react-toastify';
import { useLazyDownloadPdfQuery, useSaveAssignedOrdersToDriverByPriorityMutation } from '../../../features/Dispatch/DispatchSlice';
import DraggableDataGrid from './TestDraggableRow';


const ManualRouting = (props) => {
    const [priorityRoute, setPriorityRoute] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [currentPriority, setCurrentPriority] = useState(1);
    const [ordersRows, setOrdersRows] = useState([]);
    const [saveAssignedOrdersToDriverByPriority] = useSaveAssignedOrdersToDriverByPriorityMutation();
    const [downloadPdf] = useLazyDownloadPdfQuery();
    const [isModified, setIsModified] = useState(false);

    useEffect(() => {
        if (props.ordersList.data && props.ordersList.data.data.length > 0) {
            const rows = props.ordersList?.data?.data?.map((order) => {
                return {
                    id: order?.orderNumber,
                    ReadyTime: order?.readyTime,
                    A: order?.a,
                    St: order?.pickupStreetNo,
                    StreetName: order?.pickupStreetName,
                    City: order?.pickupCity,
                    Pcs: order?.pcs,
                    OrderStatus: order?.orderStatus,
                    Priority: order?.pickupPriority,
                    DeliverStreetNumber: order?.dropoffStreetNo,
                    DeliverStreetName: order?.dropoffStreetName,
                    DeliverCity: order?.dropoffCity,
                    DPriority: order?.dropoffPriority,
                    OrderNumber: order?.orderNumber,
                }
            })
            const newRows = props.assignedOrders?.filter((order) => !rows.some((row) => row.id === order?.orderNumber));
            const maxPriority = Math.max(...rows.map((order) => order.Priority));
            const maxDPriority = Math.max(...rows.map((order) => order.DPriority));
            setCurrentPriority(Math.max(maxPriority, maxDPriority) + 1);
            const mappedRows = newRows.map((order) => {
                return {
                    id: order?.orderNumber,
                    ReadyTime: order?.readyDate,
                    A: order?.a,
                    St: order?.pickupStreetNumber,
                    StreetName: order?.pickupStreetName,
                    City: order?.pickupCity,
                    Pcs: order?.pcs,
                    OrderStatus: order?.orderStatus,
                    DeliverStreetNumber: order?.dropoffStreetNumber,
                    DeliverStreetName: order?.dropoffStreetName,
                    DeliverCity: order?.dropoffCity,
                    DPriority: order?.dropoffPriority,
                    OrderNumber: order?.orderNumber,
                }
            })
            setOrdersRows(mappedRows);
            setPriorityRoute(rows);
        }
        else if (props.assignedOrders) {
            const rows = props.assignedOrders?.map((order) => {
                return {
                    id: order?.orderId,
                    ReadyTime: order?.readyDate,
                    A: order?.a,
                    St: order?.pickupStreetNumber,
                    StreetName: order?.pickupStreetName,
                    City: order?.pickupCity,
                    Pcs: order?.pcs,
                    OrderStatus: order?.orderStatus,
                    DeliverStreetNumber: order?.dropoffStreetNumber,
                    DeliverStreetName: order?.dropoffStreetName,
                    DeliverCity: order?.dropoffCity,
                    DPriority: order?.dropoffPriority,
                    OrderNumber: order?.orderNumber,
                }
            })
            setOrdersRows(rows);
            setPriorityRoute([]);
            setCurrentPriority(1);
        }
    }, [props.assignedOrders, props.ordersList.data, props.showRouting]);

    useEffect(() => {
        setIsModified(true);
    }, [priorityRoute]);

    const columns = [
        {
            field: 'ReadyTime',
            headerName: 'Ready Time',
            type: 'number',
            width: 140,
            editable: false,
        },
        { field: 'A', headerName: 'A', width: 40 },
        {
            field: 'St',
            headerName: 'St#',
            type: 'number',
            width: 60,
            editable: false,
        },
        {
            field: 'StreetName',
            headerName: 'Street Name',
            width: 110,
            editable: false,
        },
        {
            field: 'City',
            headerName: 'City',
            width: 100,
            editable: false,
        },
        {
            field: 'Pcs',
            headerName: 'Pcs',
            width: 60,
            editable: false,
        },
    ];

    const priorityColumns = [
        {
            field: 'ReadyTime',
            headerName: 'Ready Time',
            type: 'number',
            width: 120,
            editable: false,
        },
        { field: 'A', headerName: 'A', width: 40 },
        {
            field: 'St',
            headerName: 'St#',
            type: 'number',
            width: 20,
            editable: false,
        },
        {
            field: 'StreetName',
            headerName: 'Street Name',
            width: 110,
            editable: false,
        },
        {
            field: 'City',
            headerName: 'City',
            width: 100,
            editable: false,
        },
        // {
        //     field: 'Pcs',
        //     headerName: 'Pcs',
        //     width: 60,
        //     editable: false,
        // },
        {
            field: 'Priority',
            headerName: 'Priority',
            width: 60,
            editable: true,
        },
        {
            field: 'DeliverStreetNumber',
            headerName: 'Deliver St#',
            type: 'number',
            width: 20,
            editable: false,
        },
        {
            field: 'DeliverStreetName',
            headerName: 'Deliver Street Name',
            width: 110,
            editable: false,
        },
        {
            field: 'DeliverCity',
            headerName: 'Deliver City',
            width: 100,
            editable: false,
        },
        {
            field: 'DPriority',
            headerName: 'DPriority',
            width: 100,
            editable: true,
        },
    ];

    const clearPriority = () => {
        const newPriority = priorityRoute.map((order) => {
            return { ...order, Priority: null, DPriority: null };
        });
        setPriorityRoute(newPriority);
    }

    const validateRouting = () => {
        const priorities = new Set();
        for (let i = 0; i < priorityRoute.length; i++) {
            if (priorityRoute[i].Priority && priorityRoute[i].DPriority) {
                if (priorityRoute[i].DPriority <= priorityRoute[i].Priority) {
                    return 'Delivery Priority should be greater than Pickup Priority';
                }
            }
            else if (priorityRoute[i].Priority) {
                if (priorities.has(priorityRoute[i].Priority)) {
                    return 'There is a duplicate Priority';
                }
                priorities.add(priorityRoute[i].Priority);
            }
            else if (priorityRoute[i].DPriority) {
                if (priorities.has(priorityRoute[i].DPriority)) {
                    return 'There is a duplicate DPriority';
                }
                else if (priorityRoute[i].OrderStatus === 'Dispatched') {
                    return 'A picked up order must have a priority for pickup';
                }
                priorities.add(priorityRoute[i].DPriority);
            }
            else {
                return 'Orders must have at least one priority';
            }
        }
        // check if no number is missing from sequence
        const maxPriority = Math.max(...priorities);
        if (maxPriority !== priorities.size) {
            return 'There is a missing priority number in the sequence';
        }
        return 'Validated';
    }
    
    return (
        <div>
            <Dialog 
                open={props.showRouting} 
                onClose={() => {
                    // setCurrentPriority(1)
                     props.setShowRouting(false)
                }}
                aria-labelledby="alert-dialog-title" 
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: {
                      width: '85%', // Adjust the width as needed
                      maxWidth: 'none',
                    },
                }} 
            >
                <DialogTitle id="alert-dialog-title" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>Routing</span>
                    <Button 
                        className="btn-secondary"
                        style={{ width: '10%' }}
                        onClick={clearPriority}>Clear priority
                    </Button>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div>
                        <div className="table-back">
                            <div className="table-header-section">
                            </div>
                            <div className="datatable_section" style={{ display: 'flex', justifyContent: 'space-around', height: '700px' }}>
                            <DataGrid
                                    rows={ordersRows}
                                    columns={columns}
                                    density="compact"
                                    pageSize={10}
                                    rowsPerPageOptions={[1]}
                                    onCellClick={(cell) => { setSelectedOrder(cell.row); }}
                                    onCellDoubleClick={(cell) => {
                                        if (selectedOrder.OrderStatus === 'Dispatched') {
                                            if (selectedOrder && ordersRows.some(order => order.id === selectedOrder.id)) {
                                                const newOrder = { ...selectedOrder, Priority: currentPriority };
                                                setCurrentPriority(currentPriority + 1);
                                                setPriorityRoute([...priorityRoute, newOrder]);
                                                const newOrders = ordersRows.filter((order) => order.id !== selectedOrder.id);
                                                setOrdersRows(newOrders);
                                            }
                                        }
                                        else if (selectedOrder.OrderStatus === 'Picked up') {
                                            if (selectedOrder && ordersRows.some(order => order.id === selectedOrder.id)) {
                                                const newOrder = { ...selectedOrder, DPriority: currentPriority };
                                                setCurrentPriority(currentPriority + 1);
                                                setPriorityRoute([...priorityRoute, newOrder]);
                                                const newOrders = ordersRows.filter((order) => order.id !== selectedOrder.id);
                                                setOrdersRows(newOrders);
                                            }                                        }
                                    }}
                                />
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Button 
                                        style={{marginTop: '300%', backgroundColor: 'black'}}
                                        onClick={() => 
                                            {
                                                // add a priority for all orders in the priority route
                                                const newOrders = [];
                                                let tempPriority = currentPriority;
                                                for (let i = 0; i < ordersRows.length; i++) {
                                                    if (ordersRows[i].OrderStatus === 'Dispatched') {
                                                        newOrders.push({ ...ordersRows[i], Priority: tempPriority });
                                                    }
                                                    else if (ordersRows[i].OrderStatus === 'Picked up') {
                                                        newOrders.push({ ...ordersRows[i], DPriority: tempPriority });
                                                    }
                                                    tempPriority++;
                                                }
                                                setPriorityRoute([...priorityRoute, ...newOrders]);
                                                setCurrentPriority(tempPriority);
                                                setOrdersRows([]);
                                            }
                                        }
                                    ><BiArrowToRight /></Button>
                                    <Button 
                                        style={{ backgroundColor: 'black', marginTop: '10px' }}
                                        onClick={() => 
                                            {
                                                setOrdersRows([...ordersRows, ...priorityRoute]);
                                                setPriorityRoute([]);
                                                setCurrentPriority(1);
                                            }
                                        }
                                    ><BiArrowToLeft /></Button>
                                </div>
                                <Box sx={{width: '60%', display:'flex', flexDirection:"column", height:"100%", ">div":{
                                    height:"100%",
                                }}}>
                                <DraggableDataGrid 
                                    priorityRoute={priorityRoute} 
                                    setPriorityRoute={setPriorityRoute} 
                                    setCurrentPriority={setCurrentPriority}
                                    currentPriority={currentPriority}
                                    ordersRows={ordersRows}
                                    setOrdersRows={setOrdersRows}
                                    setSelectedOrder={setSelectedOrder}
                                /></Box>
                                </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', width: '20%' }}>
                            <Button 
                                className="btn-secondary" 
                                style={{ marginRight: '10px' }}
                                onClick={async () => {
                                    const DTO = priorityRoute.map((order, index) => {
                                        return {
                                            orderNumber: order.OrderNumber,
                                            orderStatus: order.OrderStatus,
                                            driverNumber: props.driverNumber.driverNumber,
                                            divisionId: props.divisionId,
                                            pickupPriority: order.Priority,
                                            dropoffPriority: order.DPriority,
                                            routingSequence: index + 1,
                                        }
                                    });
                                    const message = validateRouting();
                                    if (message !== 'Validated') {
                                        toast.error(message, {
                                            position: "top-center",
                                        });
                                        return;
                                    }
                                    if (isModified && DTO.length > 0) {
                                        // call mutation to save data
                                        const result = await saveAssignedOrdersToDriverByPriority(DTO);
                                        if (result.data.statusCode === 200) {
                                            toast.success('Orders have been successfully routed', {
                                                position: "top-center",
                                            });
                                            // props.setShowRouting(false);
                                            // setCurrentPriority(1);
                                        }
                                        else {
                                            toast.error('There was an error routing the orders', {
                                                position: "top-center",
                                            });
                                        }
                                    }
                                    else {
                                        toast.error('No changes have been made', {
                                            position: "top-center",
                                        });
                                    }
                                }} 
                            >
                            Save</Button>
                            <Button className="btn-primary"
                                onClick={() => 
                                    { props.setShowRouting(false)
                                    // setCurrentPriority(1);
                                    }}> Exit</Button>
                            <Button 
                                onClick={async () => {
                                    // call save first
                                    const DTO = priorityRoute.map((order, index) => {
                                        return {
                                            orderNumber: order.OrderNumber,
                                            orderStatus: order.OrderStatus,
                                            driverNumber: props.driverNumber.driverNumber,
                                            divisionId: props.divisionId,
                                            pickupPriority: order.Priority,
                                            dropoffPriority: order.DPriority,
                                            routingSequence: index + 1,
                                        }
                                    });
                                    const message = validateRouting();
                                    if (message !== 'Validated') {
                                        toast.error(message, {
                                            position: "top-center",
                                        });
                                        return;
                                    }
                                    if (isModified && DTO.length > 0) {
                                        // call mutation to save data
                                        const saveResult = await saveAssignedOrdersToDriverByPriority(DTO);
                                        if (saveResult.data.statusCode === 200) {
                                            toast.success('Orders have been successfully routed', {
                                                position: "top-center",
                                            });
                                            // props.setShowRouting(false);
                                            // setCurrentPriority(1);
                                            const result = await downloadPdf({ divisionId: props.divisionId, driverNumber: props.driverNumber.driverNumber });
                                            if (result.data) {
                                                const url = URL.createObjectURL(result.data);
                                                window.open(url);
                                            } else {
                                                toast.error('There was an error downloading the PDF', {
                                                    position: "top-center",
                                                });
                                            }
                                        }
                                        else {
                                            toast.error('There was an error routing the orders', {
                                                position: "top-center",
                                            });
                                        }
                                    }
                                    else {
                                        const result = await downloadPdf({ divisionId: props.divisionId, driverNumber: props.driverNumber.driverNumber });
                                            if (result.data) {
                                                const url = URL.createObjectURL(result.data);
                                                window.open(url);
                                            } else {
                                                toast.error('There was an error downloading the PDF', {
                                                    position: "top-center",
                                                });
                                            }
                                    }
                                }
                            }>Download</Button>
                        </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ManualRouting;