import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useGetAccountInfoQuery } from '../../../../../features/account/accountInfoSlice';
import { useGetOrderNotificationQuery } from '../../../../../features/order/orderSlice';
import { useUpdateSMSMutation } from '../../../../../features/Dispatch/DispatchSlice';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SmsNotification = ({ selectedOrder, orderNotifData }) => {    
    const {data:account} = useGetAccountInfoQuery(selectedOrder.accountNo)
    const {register, getValues, handleSubmit, watch} = useForm({
        defaultValues:orderNotifData?.data || {},
    })
    const [updateSMS] = useUpdateSMSMutation()
    const {smsSendToHeadAccount,smsSendToConsignor,smsSendToConsignee} = getValues()
    const { refetch } = useGetOrderNotificationQuery(selectedOrder?.orderNumber, {
        skip: !selectedOrder?.orderNumber, // Skip the query if the orderNumber is not available
    });
    const [isEdit, setIsEdit] = useState(false);

    const SubmitHandler = handleSubmit(async (formData) => {
        const data = {
            ...orderNotifData?.data, 
            ...formData,     
            orderNumber: selectedOrder?.orderNumber,
        };
    
        try {
            const response = await updateSMS(data); 
            if (response?.data?.statusCode === 200) {
                toast.success(response?.data?.data?.message || "SMS updated successfully", {
                    position: "top-center",
                });
                refetch(); 
            }
        } catch (error) {
            console.error("Error updating SMS:", error);
            toast.error("An error occurred while updating SMS");
        }
    
        setIsEdit(false);
    });
    const headAccountPhoneNumber = account?.data?.getListofQuoteAccountInformation?.[0]?.phone;
    return (
        <>
        <Row className="mb-4" style={{justifyContent:'flex-end'}}>
            <Col sm={3} >
            { isEdit?          
                <Button variant="primary" className={"save-btn"} onClick={SubmitHandler}>Save</Button>                
                :
                <Button variant="primary" className={"save-btn"} onClick={() => setIsEdit(!isEdit)}>Edit</Button>
            }
            </Col>
        </Row>
            <Row>
                <Col sm={12}> 
                       <div className="email-notify">
                             <div className="email-heading">
                                <h6>SMS Notification</h6>
                            </div>
                            <div className="checkbox-section" style={{pointerEvents:isEdit ? "all" : "none"}}>
                               <Form.Group className="mb-3" controlId="formBasicCheckbox" >
                                 <Form.Check type="checkbox" label="Send to head account" {...register('smsSendToHeadAccount')} checked={watch('smsSendToHeadAccount')}/>
                                       {smsSendToHeadAccount ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({headAccountPhoneNumber})</label> : null}
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" label="Send to consignor (Shipper)" {...register('smsSendToConsignor')} checked={watch("smsSendToConsignor")}/>
                                        {smsSendToConsignor ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({selectedOrder.pickupPhoneNumber})</label> : null}
                                    </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox" >
                                    <Form.Check type="checkbox" label="Send to consignee (Receiver)" {...register('smsSendToConsignee')} checked={ watch("smsSendToConsignee")}/>
                                        {smsSendToConsignee ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({selectedOrder.dropoffPhoneNumber})</label> : null}
                                </Form.Group>
                            </div>
                        </div>
                </Col>
            </Row>
            <Row>
            <p style={{ color: 'red' }}><em>Please enter individual Phone Number, separated by a semicolon (;) with no spaces. 
            Example: 4257894568;6478952689</em></p>
                <Col sm={12}>
                    <div className="tags-input">
                        <input
                           {...register("phoneNumber")}
                            placeholder="Enter Phone Number"
                            className="email-input"
                            disabled={!isEdit}
                        />
                    </div>
                </Col>
            </Row>
        </>
        
    );
}

export default SmsNotification;