import { Row, Col, Form, FloatingLabel} from "react-bootstrap";
import { useGetGenderCodeQuery, useGetUSAccessTypeQuery, useGetTravelDocumentQuery } from "../../../features/admin/employee/employeeSlice";
import { useGetCountryQuery} from "../../../features/countryState/countryStateSlice";
import moment from "moment";
import CountryProvince from "../../CountryProvince";
import { useState,useEffect } from "react";

const Ace = ({register, errors,employeeData,usaDriver }) => {
    const {selectState, setSelectState, handlecountry, province} = CountryProvince()
    const {data:getGenderCode} = useGetGenderCodeQuery();
    const {data: getUSAccessType} = useGetUSAccessTypeQuery()
    const {data: getTravelDocument} = useGetTravelDocumentQuery()
    const { data: country } = useGetCountryQuery()
    const countryField = register("TravelDocumentIssueCountryId", { });
    const provinceField = register("TravelDocumentIssueProvineStateID", { });
    const [selectedCountry, setSelectedCountry] = useState("");

    const [expiryDate, setExpiryDate] = useState(moment(employeeData?.expiryDate).utc().format('YYYY-MM-DD') || "");
    const [documentExpiryDate, setDocumentExpiryDate] = useState(moment(employeeData?.documentExpirydate).utc().format('YYYY-MM-DD') || "");

    useEffect(() => {
        // Initialize selectedCountry with the value from employeeData when viewing a record
        if (employeeData?.travelDocumentIssueCountryId) {
            setSelectedCountry(employeeData.travelDocumentIssueCountryId);
        }
        setExpiryDate(moment(employeeData?.expiryDate).utc().format('YYYY-MM-DD') || "");
        setDocumentExpiryDate(moment(employeeData?.documentExpirydate).utc().format('YYYY-MM-DD') || "");
    }, [employeeData]);

    useEffect(() => {
        if (employeeData?.travelDocumentIssueProvineStateID) {
            setSelectState(employeeData.travelDocumentIssueProvineStateID);
        }
    }, [employeeData, province]);

    const handleCountryChange = (e) => {
        const countryId = e.target.value;
        setSelectedCountry(countryId);
        handlecountry(e); // Ensure this updates the provinces based on the selected country
        countryField.onChange(e); // Update form value
    };

    
    return (
        <>
            <Row className="mt-2" >
                <Col sm={12}>
                    {/* First row */}
                    <Row>
                        <Col>
                        <Form.Group controlId="drivernumber">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select gender code">
                                    <Form.Select aria-label="Floating label select example" name="GenderId" {...register('GenderId', { required: usaDriver ? 'Field is required' : false })} >
                                        <option value={employeeData?.genderId ? employeeData?.genderId : ""}>Select</option>
                                    {getGenderCode?.data?.map((data,index) =>(
                                        <option key={index} value={data?.genderId} selected={data.genderId === employeeData?.genderId ? true : false}>{data?.genderName}</option>
                                    ))}
                                    </Form.Select>
                                </FloatingLabel>
                                {errors.GenderId?.message && (<p className="error-message">{" * " + errors.GenderId?.message}</p>)}
                            </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group controlId="drivernumber">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select U.S access type">
                                    <Form.Select aria-label="Floating label select example" name="UsAccessId" {...register('UsAccessId', { required: usaDriver ? 'Field is required' : false })}>
                                        <option value={employeeData?.usAccessId ? employeeData?.usAccessId : ""}>Select</option>
                                       {getUSAccessType?.data.map((data, index) =>(
                                        <option key={index} value={data?.usAccessId} selected={data?.usAccessId === employeeData?.usAccessId ? true : false}>{data?.usAccessDocumentname}</option>
                                       ))}
                                    </Form.Select>
                                </FloatingLabel>
                                {errors.UsAccessId?.message && (<p className="error-message">{" * " + errors.UsAccessId?.message}</p>)}
                            </Form.Group>
                        </Col>
                        <Col>
                        <div className="form-floating">
                                <Form.Control type="number" autoComplete="off" placeholder="Country code of citizenship" defaultValue={employeeData?.citizenshipCountryCode} name="CitizenshipCountryCode" {...register('CitizenshipCountryCode', { required: usaDriver ? 'Field is required' : false })}/>
                                <label htmlFor="CitizenshipCountryCode">Country code of citizenship</label>
                                {errors.CitizenshipCountryCode?.message && (<p className="error-message">{" * " + errors.CitizenshipCountryCode?.message}</p>)}
                            </div>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="number" autoComplete="off" placeholder="ACE or proximity number" name="ACEproxmitynumber" defaultValue={employeeData?.acEproxmitynumber} {...register('ACEproxmitynumber', { required: usaDriver ? 'Field is required' : false })}/>
                                <label htmlFor="ACEproxmitynumber">ACE or proximity number</label>
                                {errors.ACEproxmitynumber?.message && (<p className="error-message">{" * " + errors.ACEproxmitynumber?.message}</p>)}
                            </div>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="date" autoComplete="off" placeholder="Expiry date" name="ExpiryDate" value={expiryDate} {...register('ExpiryDate', { required: usaDriver ? 'Field is required' : false })} onChange={(e) => setExpiryDate(e.target.value)} min={new Date().toISOString().split('T')[0]}/>
                                <label htmlFor="salestax">Expiry date</label>
                                <p className="error-message">{errors.ExpiryDate?.message}</p>
                            </div>
                        </Col>
                        <Col>
                        {/* <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" name="CSAApprovedDriver" {...register('CSAApprovedDriver', {})} defaultChecked={employeeData?.csA_ApprovedDriver}/>
                                    <label className="form-check-label" htmlFor="inlineCheckbox1" style={{fontSize:"15px"}}>CSA approved driver</label>
                                </div> */}
                        </Col>
                    </Row>
                    {/* Second Row */}
                    <p style={{color:"red", marginBottom:"10px",marginTop:"20px"}}>* For Non Canadian Citizens please enter U.S Visa# in "Travel document number" and select "30" for type of travel document. Also enter U.S for Country that issued the Travel Document.</p>
                    <fieldset className="user-role-form mb-3" style={{background:"#3c65bb80"}}>
                    <Row className="3">
                        <Col>
                        <Form.Group controlId="drivernumber">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select type of travel document">
                                    <Form.Select aria-label="Floating label select example" name="TravelTypeDocumentId" {...register('TravelTypeDocumentId', {})}>
                                        <option value={employeeData?.travelTypeDocumentId}>Select</option>
                                       {getTravelDocument?.data.map((data, index) =>(
                                        <option key={index} value={data?.travelTypeDocumentId} selected={data?.travelTypeDocumentId === employeeData?.travelTypeDocumentId ? true : false}>{data?.documentname}</option>
                                       ))}
                                    </Form.Select>
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                        <Col>
                        <div className="form-floating">
                                <Form.Control type="number" autoComplete="off" placeholder="Enter type of document number" name="DocumentNumber" defaultValue={employeeData?.documentNumber} {...register('DocumentNumber', {})}/>
                                <label htmlFor="Enter type of document number">Travel document number</label>
                            </div>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="date" autoComplete="off" placeholder="Expiry date" name="DocumentExpirydate" value={documentExpiryDate} {...register('DocumentExpirydate', {})} onChange={(e) => setDocumentExpiryDate(e.target.value)} min={new Date().toISOString().split('T')[0]}/>
                                <label htmlFor="Expiry date">Expiry date</label>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Group controlId="country">
                                <FloatingLabel controlId="floatingSelectGrid" label="Country that issues travel document">
                                    <Form.Select aria-label="Floating label select example" name="TravelDocumentIssueCountryId" {...countryField} onChange={handleCountryChange}  value={selectedCountry}>
                                        {/* <option value={employeeData?.travelDocumentIssueCountryId ? employeeData?.travelDocumentIssueCountryId : ""}>{employeeData?.travelDocumentIssueCountryId || "Select"}</option> */}
                                        <option value="">Select</option>
                                        {
                                            country?.data?.map((data, index) => (
                                                <option key={index} value={data.countryId} /*selected={data?.countryId === employeeData?.travelDocumentIssueCountryId}*/>{data.countryname}</option>
                                            ))

                                        }
                                    </Form.Select>
                                </FloatingLabel>
                                <p className="error-message">{errors.TravelDocumentIssueCountryId?.message}</p>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="province">
                                <FloatingLabel controlId="floatingSelectGrid" label="Province/State that issues travel document">
                                    <Form.Select aria-label="Floating label select example" name="TravelDocumentIssueProvineStateID" {...provinceField} value={selectState ||employeeData?.travelDocumentIssueProvineStateID || ""} onChange={(e) => { provinceField.onChange(e); setSelectState(e.target.value); }}>
                                        {/* <option value={employeeData?.travelDocumentIssueProvineStateID || "Select"}>{employeeData?.travelDocumentIssueProvineStateName || "Select"}</option> */}
                                        <option value="">Select</option>
                                        {
                                            province?.data?.map((data, index) => (
                                                // <option key={index} value={data.provineStateID} selected={data?.provineStateID === employeeData?.travelDocumentIssueProvineStateID}>{data.provineSateName}</option>
                                                <option key={index} value={data.provineStateID}> {data.provineSateName}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </FloatingLabel>
                                <p className="error-message">{errors.TravelDocumentIssueProvineStateID?.message}</p>
                            </Form.Group>
                        </Col>
                    </Row>
                    </fieldset>
                </Col>
            </Row>
        </>
    )
}
export default Ace;