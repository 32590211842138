import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Button as  MuiButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
 
const priorityColumns = [
    { field: 'drag', headerName: '', width: 20, renderCell: () => <DragIndicatorIcon />, sortable: false },
    { field: 'ReadyTime', headerName: 'Ready Time', type: 'number', width: 120, editable: false },
    { field: 'A', headerName: 'A', width: 40 },
    { field: 'St', headerName: 'St#', type: 'number', width: 20, editable: false },
    { field: 'StreetName', headerName: 'Street Name', width: 110, editable: false },
    { field: 'City', headerName: 'City', width: 100, editable: false },
    { field: 'Priority', headerName: 'Priority', width: 60, editable: true },
    { field: 'DeliverStreetNumber', headerName: 'Deliver St#', type: 'number', width: 20, editable: false },
    { field: 'DeliverStreetName', headerName: 'Deliver Street Name', width: 110, editable: false },
    { field: 'DeliverCity', headerName: 'Deliver City', width: 100, editable: false },
    { field: 'DPriority', headerName: 'DPriority', width: 100, editable: true },
];
function DraggableDataGrid(props) {
    const [rows, setRows] = useState(props.priorityRoute);
    const [draggedRowIndex, setDraggedRowIndex] = useState(null);
    const [editCell, setEditCell] = useState(null);
    const [editValue, setEditValue] = useState(''); 
    const [isOpen, setIsOpen] = useState(false);
    const [movedRowIndex, setMovedRowIndex] = useState(null);

    useEffect(() => {
        setRows(props.priorityRoute);
    }, [props.priorityRoute]);
 
    const handleDragStart = (index) => {
        setDraggedRowIndex(index);
    };
 
    const handleDragOver = (event) => {
        if (event) event.preventDefault(); 
    };
 
    const handleDrop = (index) => {
        if (draggedRowIndex === null) return;
        const updatedRows = [...rows];
        const [movedRow] = updatedRows.splice(draggedRowIndex, 1); 
        updatedRows.splice(index, 0, movedRow); 
        setRows(updatedRows); 
        props.setPriorityRoute(updatedRows);
        setDraggedRowIndex(null); 
        setMovedRowIndex(index); 
        if(movedRow.DPriority !== "" || movedRow.Priority !== "") {
            setIsOpen(true); 
        }
    };
    const handleEditCell = (rowId, field, currentValue) => {
        setEditCell({ rowId, field }); 
        setEditValue(currentValue || ''); 
    };
 
    const handleCellChange = (event) => {
        setEditValue(event.target.value); 
    };
 
    const handleCellSave = () => {
        if (!editCell) return;
        const updatedRows = rows.map((row) => {
            if (row.id === editCell.rowId) {
                return { ...row, [editCell.field]: editValue };
            }
            return row;
        });
        setRows(updatedRows); 
        props.setPriorityRoute(updatedRows); 
        setEditCell(null); 
        setEditValue(''); 
    };
 
    const handleCellKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleCellSave();
        }
    };
 
    const renderCustomRow = (params) => {
        const row = params.row || {}; 
        const index = rows.findIndex((r) => r.id === row.id);
        return (
            <div
                draggable
				onMouseDown= {() => setDraggedRowIndex(index)}
                onDragStart={() => handleDragStart(index)}
                onDragOver={(event) => handleDragOver(event)} // Ensure the event parameter is passed
                onDrop={() =>handleDrop(index)}
                style={{
                    width:"100%",
                    minHeight: "52px",
                    padding: 0,
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    flexGrow:1,
                    display: 'grid',
                    gridTemplateColumns: `repeat(${priorityColumns.length}, 1fr)`,
                }}
            >
                {priorityColumns.map((column) => {
                    const isEditing = editCell && editCell.rowId === row.id && editCell.field === column.field && column.editable;
                    return (
						<>
                        { <div
                            key={column.field}
                            style={{
                                minWidth: column.width,
                                maxWidth: column.width,
                                height: "100%", 
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start', 
                            }}  
                            onDoubleClick={async () => {
                                await props.setSelectedOrder(row);
                                if (column.field !== 'Priority' && column.field !== 'DPriority') {
                                    if ( props.priorityRoute.some(order => order.id === row.id)) {
                                        // find all orders with later priority and decrement by 1
                                        const newOrders = props.priorityRoute.map((order) => {
                                            if (row.Priority && (order.Priority > row.Priority || order.DPriority > row.Priority) && order.id !== row.id) {
                                                return { ...order, Priority: order.Priority && order.Priority - 1, DPriority: order.DPriority && order.DPriority - 1 };
                                            }
                                            if (row.DPriority && (order.DPriority > row.DPriority || order.Priority > row.DPriority) && order.id !== row.id) {
                                                return { ...order, Priority: order.Priority && order.Priority - 1, DPriority: order.DPriority && order.DPriority - 1 };
                                            }
                                            return order;
                                        });
                                        props.setCurrentPriority(props.currentPriority - 1);
                                        props.setOrdersRows([...props.ordersRows, row]);
                                        const newPriority = newOrders.filter((order) => order.id !== row.id);
                                        props.setPriorityRoute(newPriority);
                                    }
                                }
                                else {
                                    handleEditCell(row.id, column.field, row[column.field])
                                }
                            }}
                        >
                            {column.field === 'drag' ? (
                                <MuiButton sx={{display:'grid', placeItems:'center', cursor:"move !important"}} >
                                    <DragIndicatorIcon />
                                </MuiButton>
                            ) : isEditing ? (
                                <input
                                    value={editValue}
                                    onChange={handleCellChange}
                                    onKeyPress={handleCellKeyPress} 
                                    onBlur={handleCellSave} 
                                    autoFocus
									style={{width:"100%", minWidth:"40px"}}
                                />
                            ) : (
                               row[column.field] ?? null
                            )}
                        </div>}
						</>
                    );
                })}
            </div>
        );
    };
 
    const handleConfirmClear = () => {
        const updatedRows = [...rows];
        if (movedRowIndex !== null) {
            updatedRows[movedRowIndex] = {
                ...updatedRows[movedRowIndex],
                Priority: '', 
                DPriority: '', 
            };
        }
        setRows(updatedRows); 
        props.setPriorityRoute(updatedRows);
        setIsOpen(false); 
        setMovedRowIndex(null); 
    };

    return (
        <div>
             <Dialog open={isOpen}onClose={() => setIsOpen(false)}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                 <DialogTitle id="alert-dialog-title">
                   Delete Confirmation
                 </DialogTitle>
                 <DialogContent>
                   <DialogContentText id="alert-dialog-description">
                     Are you sure you want to Clear Priorities ?
                   </DialogContentText>
                 </DialogContent>
                 <DialogActions>
                   <Button color='primary' className="btn-secondary" onClick={() => setIsOpen(false)}>Cancel</Button>
                   <Button className="btn-primary" onClick={handleConfirmClear} autoFocus> Confirm</Button>
                 </DialogActions>
             </Dialog>
            <DataGrid
                rows={rows}
                columns={priorityColumns}
                density='compact'
                disableSelectionOnClick
                components={{ Row: renderCustomRow}}
                sx={{
                    width: '100%', 
                    '& .MuiDataGrid-columnHeadersInner': {
                        display: 'grid',
                        gridTemplateColumns: `repeat(${priorityColumns.length}, 1fr)`,
                        width:"100%",
                    },
                    '& .MuiDataGrid-virtualScrollerRenderZone': {
                        width: "100%",
                        textAlign:'start',
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display:'none',
                    },
                }}
            />
        </div>
    );
}
 
export default DraggableDataGrid;