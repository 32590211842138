import { AddCircle, Delete, Save } from "@material-ui/icons";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';
import { GridToolbar } from '@mui/x-data-grid-premium';
import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import useCustomer from "../../../context/CustomerContext";
import { addCwtRateCard, addCwtRateCardMultiple, deleteCwtRateCard, editCwtRateCard } from "../../../features/cwtRateCard/cwtRateCardSlice";
import UploadRateCard from "../../Admin/DefaultPricing/DefaultPricing/CwtRateCard/UploadRateCard";
import CountryProvince from "../../CountryProvince";
import { useCustomData } from "../../Sidebar/Permission";

const CwtRatecard = () => {
  const { country, selectState, setSelectState, handlecountry, province, handleCity, city, selectCity, setSelectCity, handleUniqueCity, handleProvince, newHandleCountry } = CountryProvince()
  const [permission] = useCustomData()
  const [toProvince, setToProvince] = useState([]);
  const [fromProvince, setFromProvince] = useState([]);
  const toCity = city;
  const fromCity = city;
  const [toCountryList, setToCountryList] = useState([]);
  const [fromCountryList, setFromCountryList] = useState([]);
  const dispatch = useDispatch();
  const { cwtRateCardLists, tempDisplay, setTempDisplay, multiplier } = useCustomer();
  // const [tempcwtratecardlists , settempCwtRateCardLists] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectToState, setSelectToState] = useState([]);
  const [selectToCity, setSelectToCity] = useState("Select")
  const [isDelete, setIsDelete] = useState()
  const [isShow, setIsShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const [cwtRatecardData, setCwtRatecardData] = useState({})
  const disablePallet = cwtRateCardLists?.[0]?.mLCWTRatecarditeam?.RateType === "CWT"
  const disableCWT = cwtRateCardLists?.[0]?.mLCWTRatecarditeam?.RateType === "PALLET"
  const [cwtStatus, setCwtStatus] = useState(0);
  const [idvalue, setIdValue] = useState(false);
  const [customeditfields, setCustomEditFields] = useState(false);
  const [addValue, setAddValue] = useState(false)
  const [finaldata, setFinalData] = useState([])
  const [type, setType] = useState(0);
  const cityList = city?.data.map((a) => { return { name: a.city, id: a.id } });
  const toCityList = cityList;
  const fromCityList = cityList;
  const [toSelection, setToSelection] = useState([]);
  const [fromSelection, setFromSelection] = useState([]);
  const [tempidvalue, setTempIdValue] = useState({
    fromCityvalue: "",
    fromProvvalue: "",
  })
  const [tempidto, setTempIdto] = useState({
    toCityvalue: "",
    toProvValue: ""
  })
  const CwtstatusHandler = (cwtStatus) => { setCwtStatus(cwtStatus) };

  useEffect(() => {
    if (cwtRateCardLists.length > 0) {
      if (cwtStatus === 1) {
        setType(1);
      }
      else {
        setType(2);
      }
    }
    else {
      setType(0);
    }
  }, [cwtRateCardLists])

  useEffect(() => {
    if (cwtRateCardLists.length > 0) {
      setIsShow(true);
      if (cwtRateCardLists[0].mLCWTRatecarditeam.RateType?.toUpperCase() === 'PALLET') {
        setCwtStatus(2);
      }
      else if (cwtRateCardLists[0].mLCWTRatecarditeam.RateType?.toUpperCase() === 'CWT') {
        setCwtStatus(1);
      }

    }
    else {
      setIsShow(false);
      setCwtStatus(0);
    }
  }, [cwtRateCardLists])

  const setCountry = async (e) => {
    if (e.target.value !== 'Select') {
      let cList;
      if (e.target.name === "fromprov") {
        cList = await newHandleCountry(e.target.value);
        setFromCountryList(cList.data.data);
      }
      else if (e.target.name === "toprov") {
        cList = await newHandleCountry(e.target.value);
        setToCountryList(cList.data.data);
      }
    }
    else {
      if (e.target.name === "fromprov") {
        setFromCountryList([]);
      }
      else {
        setToCountryList([]);
      }
    }
  }

  const handleClickOpen = (data) => {
    setOpen(true);
    setIsDelete(data)
  };


  const handleClose = () => { setOpen(false); };

  const deleteHandler = () => {
    dispatch(deleteCwtRateCard(isDelete));
    setIsDelete(null);
    handleClose();
    setCwtRatecardData({})
  }

  // added spaces manually to align the columns in the correct order in excel sheet
  const columnHeaders = {
    min: 'Minimum Rate',
    ftl: 'FTL Rate',
    avgWeight: 'Average Weight',
    // originCityId: 'Origin City ID',
    origincity: 'Origin City',
    // originProvinceId: 'Origin Province ID',
    originProvince: 'Origin Province',
    // originCountryId: 'Origin Country ID',
    originCountry: 'Origin Country',
    destinationcity: '     Destination City',
    // destinationProvinceId: 'Destination Province ID',
    destinationProvince: 'Destination Province',
    // destinationcityId: 'Destination City ID',
    // destinationCountryId: 'Destination Country ID',
    destinationCountry: 'Destination Country',
    p0TOLTL: '0-500',
    p500TO1000: '500-1000 ',
    p1000TO2000: '1000-2000 ',
    p2000TO5000: '2000-5000 ',
    p5000TO10000: '5000-10000 ',
    p10000TO20000: '10000-20000 ',
    p1: '       1',
    p2: '       2',
    p3: '       3',
    p4: '       4',
    p5: '       5',
    p6: '       6',
    p7: '       7',
    p8: '       8',
    p9: '       9',
    p10: '        10',
    p11: '        11',
    p12: '        12',
    p13: '        13',
    p14: '        14',
    p15: '        15',
    p16: '        16',
    p17: '        17',
    p18: '        18',
    p19: '        19',
    p20: '        20',
    p21: '        21',
    p22: '        22',
    p23: '        23',
    p24: '        24',
    p100: 'Overweight Charge 0-100',
    p200: 'Overweight Charge 100-200',
    p300: 'Overweight Charge 200-300',
    p400: 'Overweight Charge 300-400',
    p500: 'Overweight Charge 400-500',
    p600: 'Overweight Charge 500-600',
  };

  const writeToExcel = (records, fileName) => {
    //console.log(records);
    if (!records || records.length === 0) {
      return;
    }

    // List of keys to exclude from the export
    const excludeColumns = [
      'originCityId',
      'originProvinceId',
      'originCountryId',
      'destinationProvinceId',
      'destinationcityId',
      'destinationCountryId',
      'p20000TO999999'
    ];

    // Map the existing keys to meaningful headers
    const mappedRecords = records.map(record => {
      const mappedRecord = {};
      for (let key in record) {
        if (!excludeColumns.includes(key)) {
          const newKey = columnHeaders[key] || key;
          mappedRecord[newKey] = record[key];
        }
      }
      return mappedRecord;
    });

    const worksheet = XLSX.utils.json_to_sheet(mappedRecords);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}-Invalid.xlsx`);
  }

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = (e) => {
          e.stopPropagation();
          editCwtratecardList(data.id)
        };
        const onRemove = (e) => {
          e.stopPropagation();
          handleClickOpen();
          setIsDelete(data?.id)

        };
        return (
          <>
            <div>
              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                  Delete Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this row?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                  <Button className="btn-primary" onClick={() => deleteHandler()} autoFocus> Confirm</Button>
                </DialogActions>
              </Dialog>
            </div>
            <div className="action_btn">
              {/* <span className="edit_btn" onClick={onClick}><Edit /></span> */}
              <span className="del_btn" onClick={onRemove}><Delete /></span>
            </div>
          </>
        )
      },
    },
    { field: 'RateType', headerName: 'Rate Type', width: 100 },
    { field: 'fromcountry', headerName: 'From Country', width: 130 },
    { field: 'fromprov', headerName: 'From Province', width: 130 },
    { field: 'fromcity', headerName: 'From City', width: 100 },
    { field: 'tocountry', headerName: 'To Country', width: 110 },
    { field: 'toprov', headerName: 'To Province', width: 110 },
    { field: 'tocity', headerName: 'To City', width: 80 },
    { field: 'MinimumRate', headerName: 'Minimum Rate', type: 'number', width: 130 },
    { field: 'AverageWeight', headerName: 'Average Weight', type: 'number', width: 130 },
    { field: 'FTLRate', headerName: 'FTL Rate', type: 'number', width: 100 },
    { field: 'UnitRateP0TOLTL', headerName: 'Weight range(0-500)lbs', type: 'number', width: 160 },
    { field: 'UnitRateP500TO1000', headerName: 'Weight range(500-1000)lbs', type: 'number', width: 180 },
    { field: 'UnitRateP1000TO2000', headerName: 'Weight range(1000-2000)lbs', type: 'number', width: 180 },
    { field: 'UnitRateP2000TO5000', headerName: 'Weight range(2000-5000)lbs', type: 'number', width: 180 },
    { field: 'UnitRateP5000TO10000', headerName: 'Weight range(5000-10000)lbs', type: 'number', width: 180 },
    { field: 'UnitRateP10000TO20000', headerName: 'Weight range(10000-20000)lbs', type: 'number', width: 180 },
    { field: 'UnitRateP0TO1', headerName: 'Pallet range(0-1)', type: 'number', width: 150 },
    { field: 'UnitRateP1TO5', headerName: 'Pallet range(1-5)', type: 'number', width: 150 },
    { field: 'UnitRateP5TO10', headerName: 'Pallet range(5-10)', type: 'number', width: 150 },
    { field: 'UnitRateP10TO16', headerName: 'Pallet range(10-16)', type: 'number', width: 150 },
    { field: 'UnitRateP16TO21', headerName: 'Pallet range(16-21)', type: 'number', width: 150 },
    { field: 'UnitRateP21TO24', headerName: 'Pallet range(21-24)', type: 'number', width: 150 },
    { field: 'ChargeP0TO100', headerName: 'Pallet range(0-100)', type: 'number', width: 160 },
    { field: 'ChargeP100TO200', headerName: 'Pallet range(100-200)', type: 'number', width: 160 },
    { field: 'ChargeP200TO300', headerName: 'Pallet range(200-300)', type: 'number', width: 160 },
    { field: 'ChargeP300TO400', headerName: 'Pallet range(300-400)', type: 'number', width: 160 },
    { field: 'ChargeP400TO500', headerName: 'Pallet range(400-500)', type: 'number', width: 160 },
    { field: 'ChargeP500TO600', headerName: 'Pallet range(500-600)', type: 'number', width: 160 },
  ];

  const renderRateCell = (params) => {
    const value = params.value;
    const color = mult > 1 ? 'red' : 'inherit';
    return (
      <div style={{ color }}>
        {value}
      </div>
    );
  };


  const palletColumns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = (e) => {
          e.stopPropagation();
          editCwtratecardList(data.id)
        };
        const onRemove = (e) => {
          e.stopPropagation();
          handleClickOpen();
          setIsDelete(data?.id)

        };
        return (
          <>
            <div>
              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                  Delete Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this row?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                  <Button className="btn-primary" onClick={() => deleteHandler()} autoFocus> Confirm</Button>
                </DialogActions>
              </Dialog>
            </div>
            <div className="action_btn">
              {/* <span className="edit_btn" onClick={onClick}><Edit /></span> */}
              <span className="del_btn" onClick={onRemove}><Delete /></span>
            </div>
          </>
        )
      },
    },
    { field: 'origincity', headerName: 'From City', width: 100 },
    { field: 'originProvince', headerName: 'From Province', width: 130 },
    { field: 'originCountry', headerName: 'From Country', width: 130 },
    { field: 'destinationcity', headerName: 'To City', width: 100 },
    { field: 'destinationProvince', headerName: 'To Province', width: 110 },
    { field: 'destinationCountry', headerName: 'To Country', width: 110 },
    { field: 'UnitRate1', headerName: '1', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate2', headerName: '2', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate3', headerName: '3', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate4', headerName: '4', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate5', headerName: '5', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate6', headerName: '6', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate7', headerName: '7', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate8', headerName: '8', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate9', headerName: '9', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate10', headerName: '10', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate11', headerName: '11', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate12', headerName: '12', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate13', headerName: '13', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate14', headerName: '14', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate15', headerName: '15', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate16', headerName: '16', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate17', headerName: '17', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate18', headerName: '18', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate19', headerName: '19', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate20', headerName: '20', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate21', headerName: '21', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate22', headerName: '22', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate23', headerName: '23', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'UnitRate24', headerName: '24', type: 'number', width: 40, renderCell: renderRateCell },
    { field: 'ChargeP0TO100', headerName: '100', type: 'number', width: 40 },
    { field: 'ChargeP100TO200', headerName: '200', type: 'number', width: 40 },
    { field: 'ChargeP200TO300', headerName: '300', type: 'number', width: 40 },
    { field: 'ChargeP300TO400', headerName: '400', type: 'number', width: 40 },
    { field: 'ChargeP400TO500', headerName: '500', type: 'number', width: 40 },
    { field: 'ChargeP500TO600', headerName: '600', type: 'number', width: 40 },
    { field: 'AverageWeight', headerName: 'Average Weight', type: 'number', width: 130 },
    { field: 'ftl', headerName: 'FTL', type: 'number', width: 100, renderCell: renderRateCell },
  ];
  const CWTColumns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 80,
      disableClickEventBubbling: true,
      renderCell: (data) => {
        const onClick = (e) => {
          e.stopPropagation();
          editCwtratecardList(data.id)
        };
        const onRemove = (e) => {
          e.stopPropagation();
          handleClickOpen();
          setIsDelete(data?.id)

        };
        return (
          <>
            <div>
              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                  Delete Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this row?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                  <Button className="btn-primary" onClick={() => deleteHandler()} autoFocus> Confirm</Button>
                </DialogActions>
              </Dialog>
            </div>
            <div className="action_btn">
              {/* <span className="edit_btn" onClick={onClick}><Edit /></span> */}
              <span className="del_btn" onClick={onRemove}><Delete /></span>
            </div>
          </>
        )
      },
    },
    { field: 'origincity', headerName: 'From City', width: 100 },
    { field: 'originProvince', headerName: 'From Province', width: 130 },
    { field: 'originCountry', headerName: 'From Country', width: 130 },
    { field: 'destinationcity', headerName: 'To City', width: 100 },
    { field: 'destinationProvince', headerName: 'To Province', width: 110 },
    { field: 'destinationCountry', headerName: 'To Country', width: 110 },
    { field: "min", headerName: 'Minimum Rate', type: 'number', width: 100, renderCell: renderRateCell },
    { field: 'p0TOLTL', headerName: 'LTL', type: 'number', width: 100, renderCell: renderRateCell },
    { field: 'p500TO1000', headerName: '500', type: 'number', width: 100, renderCell: renderRateCell },
    { field: 'p1000TO2000', headerName: '1M', type: 'number', width: 100, renderCell: renderRateCell },
    { field: 'p2000TO5000', headerName: '2M', type: 'number', width: 100, renderCell: renderRateCell },
    { field: 'p5000TO10000', headerName: '5M', type: 'number', width: 100, renderCell: renderRateCell },
    { field: 'p10000TO20000', headerName: '10M', type: 'number', width: 100, renderCell: renderRateCell },
    { field: 'ftl', headerName: 'FTL', type: 'number', width: 100, renderCell: renderRateCell },

  ];

  const rows = finaldata?.map((data) => {
    return {
      id: data.id,
      RateType: data.RateType,
      MinimumRate: data.MinimumRate,
      AverageWeight: data.AverageWeight,
      FTLRate: data.FTLRate,
      fromcity: data.fromcity,
      fromcountry: data.fromcountry === "1" ? "CAN" : "USA",
      fromprov: data.fromprov,
      tocity: data.tocity,
      tocountry: data.tocountry === "1" ? "CAN" : "USA",
      toprov: data.toprov,
      UnitRateP0TOLTL: data.UnitRateP0TOLTL,
      UnitRateP500TO1000: data.UnitRateP500TO1000,
      UnitRateP1000TO2000: data.UnitRateP1000TO2000,
      UnitRateP2000TO5000: data.UnitRateP2000TO5000,
      UnitRateP5000TO10000: data.UnitRateP5000TO10000,
      UnitRateP10000TO20000: data.UnitRateP10000TO20000,
      UnitRateP0TO1: data.UnitRateP0TO1,
      UnitRateP1TO5: data.UnitRateP1TO5,
      UnitRateP5TO10: data.UnitRateP5TO10,
      UnitRateP10TO16: data.UnitRateP10TO16,
      UnitRateP16TO21: data.UnitRateP16TO21,
      UnitRateP21TO24: data.UnitRateP21TO24,
      ChargeP0TO100: data.ChargeP0TO100,
      ChargeP100TO200: data.ChargeP100TO200,
      ChargeP200TO300: data.ChargeP200TO300,
      ChargeP300TO400: data.ChargeP300TO400,
      ChargeP400TO500: data.ChargeP400TO500,
      ChargeP500TO600: data.ChargeP500TO600

    }
  })


  const [mult, setMult] = useState(1)

  useEffect(() => {
    if (multiplier) {
      setMult(1 + multiplier / 100)
    }
    else {
      setMult(1)
    }
  }, [multiplier])

  const testRows = cwtRateCardLists.map((element) => {
    const toCountry = element?.mLCwtRateCardCity?.toCity?.tocountry;
    const fromCountry = element?.mLCwtRateCardCity?.fromCity?.fromcountry;
    const tocountryval = toCountry === 1 || toCountry === '1' ? "CAN" : toCountry === 2 || toCountry === '2' ? "USA" : "";
    const fromcountryval = fromCountry === 1 || fromCountry === '1' ? "CAN" : fromCountry === 2 || fromCountry === '2' ? "USA" : "";
    const safeMultiply = (value, multiplier, toFixed = false) => {
      if (value == null) return null;
      const result = multiplier == null ? value : value * multiplier;
      return toFixed ? result.toFixed(2) : result;
    };

    return {
      id: element.id,
      RateType: element.mLCWTRatecarditeam?.RateType,
      min: safeMultiply(element?.MinimumRate, mult, true),
      AverageWeight: element?.AverageWeight,
      ftl: safeMultiply(element?.FTLRate, mult, true),
      originCountry: fromcountryval,
      originProvince: element?.mLCwtRateCardCity?.fromCity?.fromprov?.provineSateName,
      origincity: element?.mLCwtRateCardCity?.fromCity?.fromcity?.city,
      destinationcity: element?.mLCwtRateCardCity?.toCity?.tocity?.city,
      destinationCountry: tocountryval,
      destinationProvince: element?.mLCwtRateCardCity?.toCity?.toprov?.provineSateName,
      p0TOLTL: safeMultiply(element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP0TOLTL, mult, true),
      p500TO1000: safeMultiply(element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP500TO1000, mult, true),
      p1000TO2000: safeMultiply(element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP1000TO2000, mult, true),
      p2000TO5000: safeMultiply(element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP2000TO5000, mult, true),
      p5000TO10000: safeMultiply(element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP5000TO10000, mult, true),
      p10000TO20000: safeMultiply(element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP10000TO20000, mult, true),
      UnitRateP0TO1: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP0TO1, mult),
      UnitRate1: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate1,
      UnitRate2: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate2, mult),
      UnitRate3: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate3, mult),
      UnitRate4: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate4, mult),
      UnitRate5: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate5, mult),
      UnitRate6: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate6, mult),
      UnitRate7: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate7, mult),
      UnitRate8: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate8, mult),
      UnitRate9: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate9, mult),
      UnitRate10: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate10, mult),
      UnitRate11: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate11, mult),
      UnitRate12: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate12, mult),
      UnitRate13: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate13, mult),
      UnitRate14: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate14, mult),
      UnitRate15: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate15, mult),
      UnitRate16: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate16, mult),
      UnitRate17: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate17, mult),
      UnitRate18: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate18, mult),
      UnitRate19: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate19, mult),
      UnitRate20: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate20, mult),
      UnitRate21: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate21, mult),
      UnitRate22: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate22, mult),
      UnitRate23: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate23, mult),
      UnitRate24: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate24, mult),
      UnitRateP5TO10: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP5TO10, mult),
      UnitRateP10TO16: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP10TO16, mult),
      UnitRateP16TO21: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP16TO21, mult),
      UnitRateP21TO24: safeMultiply(element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP21TO24, mult),
      ChargeP0TO100: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP0TO100,
      ChargeP100TO200: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP100TO200,
      ChargeP200TO300: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP200TO300,
      ChargeP300TO400: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP300TO400,
      ChargeP400TO500: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP400TO500,
      ChargeP500TO600: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP500TO600,
    }
  }
  );

  useEffect(() => {
    if (tempDisplay === true) {
      if (cwtRateCardLists.length > 0) {
        let temp_value = {
          id: "",
          RateType: "",
          "MIN": "",
          AverageWeight: "",
          "FTL": "",
          "From Country": "",
          "From Province": "",
          "From City": "",
          "To City": "",
          "To Country": "",
          "To Province": "",
          "LTL": "",
          "500": "",
          "1M": "",
          "2M": "",
          "5M": "",
          "10M": "",
          "1": "",
          UnitRateP0TO1: "",
          UnitRateP1TO5: "",
          UnitRateP5TO10: "",
          UnitRateP10TO16: "",
          UnitRateP16TO21: "",
          UnitRateP21TO24: "",
          ChargeP0TO100: "",
          ChargeP100TO200: "",
          ChargeP200TO300: "",
          ChargeP300TO400: "",
          ChargeP400TO500: "",
          ChargeP500TO600: "",
        }
        cwtRateCardLists.map(element => {
          const tocountryval = element?.mLCwtRateCardCity?.toCity?.tocountry === '1' ? "CAN" : element?.mLCwtRateCardCity?.toCity?.tocountry === '2' ? "USA" : "";
          const fromcountryval = element?.mLCwtRateCardCity?.fromCity?.fromcountry === '1' ? "CAN" : element?.mLCwtRateCardCity?.fromCity?.fromcountry === '2' ? "USA" : "";
          return (
            temp_value = {
              id: element.id,
              RateType: element.mLCWTRatecarditeam?.RateType,
              "MIN": element?.MinimumRate,
              AverageWeight: element?.AverageWeight,
              "FTL": element?.FTLRate,
              "From Country": fromcountryval,
              "From Province": element?.mLCwtRateCardCity?.fromCity?.fromprov?.provineSateName,
              "From City": element?.mLCwtRateCardCity?.fromCity?.fromcity?.city,
              "To City": element?.mLCwtRateCardCity?.toCity?.tocity?.city,
              "To Country": tocountryval,
              "To Province": element?.mLCwtRateCardCity?.toCity?.toprov?.provineSateName,
              "LTL": element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP0TOLTL,
              "500": element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP500TO1000,
              "1M": element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP1000TO2000,
              "2M": element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP2000TO5000,
              "5M": element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP5000TO10000,
              "10M": element?.mLCWTRatecarditeam?.rateTypeCWT?.UnitRateP10000TO20000,
              UnitRateP0TO1: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP0TO1,
              UnitRate1: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate1,
              UnitRate2: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate2,
              UnitRate3: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate3,
              UnitRate4: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate4,
              UnitRate5: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate5,
              UnitRate6: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate6,
              UnitRate7: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate7,
              UnitRate8: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate8,
              UnitRate9: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate9,
              UnitRate10: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate10,
              UnitRate11: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate11,
              UnitRate12: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate12,
              UnitRate13: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate13,
              UnitRate14: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate14,
              UnitRate15: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate15,
              UnitRate16: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate16,
              UnitRate17: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate17,
              UnitRate18: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate18,
              UnitRate19: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate19,
              UnitRate20: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate20,
              UnitRate21: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate21,
              UnitRate22: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate22,
              UnitRate23: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate23,
              UnitRate24: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRate24,
              UnitRateP5TO10: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP5TO10,
              UnitRateP10TO16: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP10TO16,
              UnitRateP16TO21: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP16TO21,
              UnitRateP21TO24: element?.mLCWTRatecarditeam?.rateTypePallet?.UnitRateP21TO24,
              ChargeP0TO100: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP0TO100,
              ChargeP100TO200: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP100TO200,
              ChargeP200TO300: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP200TO300,
              ChargeP300TO400: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP300TO400,
              ChargeP400TO500: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP400TO500,
              ChargeP500TO600: element?.mLCWTRatecarditeam?.rateTypePallet?.ChargeP500TO600,
            }
          )
        })
        setFinalData([...finaldata, temp_value])
      }
    }
  }, [cwtRateCardLists])

  const updateCity = (e) => {
    const cityname = city?.data?.find(v => {
      if (v.id == e.target.value) {
        return v.city
      }
    })
    setCwtRatecardData(cwtRatecardData => ({ ...cwtRatecardData, [e.target.name]: cityname }));
  }

  const updateProvince = (e) => {
    const provincename = province?.data?.find(v => {
      if (v.provineStateID == e.target.value) {
        return v.provineSateName
      }
    })
    setCwtRatecardData(cwtRatecardData => ({ ...cwtRatecardData, [e.target.name]: provincename }));
  }

  useEffect(() => {
    const updateCityData = async (selection, type) => {
      if (selection.length > 0) {
        // Update city data
        setCwtRatecardData((prevState) => ({
          ...prevState,
          [`${type}city`]: { city: selection[0].name, id: selection[0].id }
        }));

        // Fetch provinces
        const pList = await handleProvince(selection[0].id);
        if (type === 'from') {
          setFromProvince(pList.data.data);
        } else {
          setToProvince(pList.data.data);
        }

        // Auto-select province if only one exists
        if (pList.data.data.length === 1) {
          const autoSelectedProvince = pList.data.data[0];
          setCwtRatecardData((prevState) => ({
            ...prevState,
            [`${type}prov`]: autoSelectedProvince
          }));

          // Fetch countries based on province
          const cList = await newHandleCountry(autoSelectedProvince.provineStateID);
          if (type === 'from') {
            setFromCountryList(cList.data.data);
          } else {
            setToCountryList(cList.data.data);
          }

          // Auto-select country if only one exists
          if (cList.data.data.length === 1) {
            const autoSelectedCountry = cList.data.data[0];
            setCwtRatecardData((prevState) => ({
              ...prevState,
              [`${type}country`]: autoSelectedCountry.countryId
            }));
          }
        }
      } else {
        // Reset province and country if no city is selected
        setCwtRatecardData((prevState) => ({
          ...prevState,
          [`${type}prov`]: null,
          [`${type}country`]: null
        }));

        if (type === 'from') {
          setFromProvince([]);
          setFromCountryList([]);
        } else {
          setToProvince([]);
          setToCountryList([]);
        }
      }
    };

    const updateData = async () => {
      await updateCityData(fromSelection, 'from');
      await updateCityData(toSelection, 'to');
    };

    updateData();
  }, [fromSelection, toSelection]);

  const cwtRatecardHandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let toCities = toCity?.data;
    if (name === "fromprov") {
      let provinces = province?.data;
      let temp_cityname = provinces?.find(v => {
        if (v.provineStateID == value) {
          return v.provineSateName
        }
      })
      setTempIdValue((prevTempIdValue) => ({
        ...prevTempIdValue,
        fromProvvalue: temp_cityname.provineSateName,
      }));
    }
    if (name === "toprov") {
      let toprovince = toProvince?.data;
      let to_provname = toprovince?.find(v => {
        if (v.provineStateID == value) {
          return v.provineSateName
        }
      })
      setTempIdto({ ...tempidto, toProvValue: to_provname.provineSateName });
    }
    if (name === "fromcity") {
      let cities = fromCity?.data;
      let from_cityname = cities?.find(v => {
        if (v.id == value) {
          return v.city
        }
      })
      setTempIdValue((prevTempIdValue) => ({
        ...prevTempIdValue,
        fromCityvalue: from_cityname.city,
      }));
    }
    if (name === "tocity") {
      const to_cityname = toCities?.find(v => v.id == value)?.city ?? "";
      setTempIdto({ ...tempidto, toCityvalue: to_cityname });
    }
    setCwtRatecardData(cwtRatecardData => ({ ...cwtRatecardData, [e.target.name]: e.target.value }));
    //   setCwtRatecardData(cwtRatecardData => ({
    //     ...cwtRatecardData, mLCwtRateCardCity: {
    //         ...cwtRatecardData.mLCwtRateCardCity, fromCity: { ...cwtRatecardData.mLCwtRateCardCity?.fromCity, [e.target.name]: e.target.value },
    //         toCity: { ...cwtRatecardData.mLCwtRateCardCity?.toCity, [e.target.name]: e.target.value }
    //     },
    //     mLCWTRatecarditeam: { ...cwtRatecardData.mLCWTRatecarditeam, rateTypeCWT: { ...cwtRatecardData.mLCWTRatecarditeam?.rateTypeCWT, [e.target.name]: e.target.value },
    //      rateTypePallet: { ...cwtRatecardData.mLCWTRatecarditeam?.rateTypePallet, [e.target.name]: e.target.value } }, [e.target.name]: e.target.value
    // }));
  }

  const addCwtratecardList = (e) => {
    if (isShow && !cwtStatus) {
      toast.error('Please select a rate type!', {
        position: "top-center",
      })
      return;
    }
    e.preventDefault();
    if (Object.keys(cwtRatecardData).length === 0 || Object.values(cwtRatecardData).some(value => value == null)) {
      setIsShow(true);
    }
    else if (editingTaskIndex === null) {
      if (cwtRatecardData.RateType === "PALLET" && cwtRatecardData.UnitRate24 == null) return
      if (cwtRatecardData.RateType === "CWT" && cwtRatecardData.UnitRateP10000TO20000 == null) return
      setTempDisplay(true)
      setIdValue(!idvalue);
      setAddValue(true)
      dispatch(addCwtRateCard(cwtRatecardData));

    } else {
      let editingItem = cwtRateCardLists[editingTaskIndex];
      editingItem = cwtRatecardData;
      dispatch(editCwtRateCard(editingItem));
    }
    setCwtRatecardData("");
    setToSelection([]);
    setFromSelection([]);
    setEditingTaskIndex(null);
    setIsUpdate(false)
    //setCwtStatus(0)
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const getCityID = async (cityName, provinceName) => {
    const result = await handleUniqueCity({ cityName, provinceName });
    return result?.data?.data;
  }

  const getProvinceID = (provinceName) => {
    let id;
    province?.data.map((p) => {
      if (p.provineSateName === provinceName) {
        id = p.provineStateID;
      }
    })
    return id;
  }

  // appending data from excel file
  const addExcelData = async (rows) => {
    const recordsList = [];
    for (let i = 0; i < rows.length; i++) {
      const row = { ...rows[i], id: i };
      const record = {
        "MinimumRate": row.min || 0,
        "FTLRate": row.ftl || 0,
        "file": "",
        "RateType": cwtStatus === 1 ? "CWT" : "Pallet",
        "AverageWeight": row.avgWeight,
        "UnitRateP0TOLTL": row.p0TOLTL || 0,
        "UnitRateP500TO1000": row.p500TO1000 || 0,
        "UnitRateP1000TO2000": row.p1000TO2000 || 0,
        "UnitRateP2000TO5000": row.p2000TO5000 || 0,
        "UnitRateP5000TO10000": row.p5000TO10000 || 0,
        "UnitRateP10000TO20000": row.p10000TO20000 || 0,
        "unitRateP0TO1": 0,
        "unitRateP1TO5": 0,
        "unitRateP5TO10": 0,
        "unitRateP10TO16": 0,
        "unitRateP16TO21": 0,
        "unitRateP21TO24": 0,
        "UnitRate1": row["p1"] || 0,
        "UnitRate2": row["p2"] || 0,
        "UnitRate3": row["p3"] || 0,
        "UnitRate4": row["p4"] || 0,
        "UnitRate5": row["p5"] || 0,
        "UnitRate6": row["p6"] || 0,
        "UnitRate7": row["p7"] || 0,
        "UnitRate8": row["p8"] || 0,
        "UnitRate9": row["p9"] || 0,
        "UnitRate10": row["p10"] || 0,
        "UnitRate11": row["p11"] || 0,
        "UnitRate12": row["p12"] || 0,
        "UnitRate13": row["p13"] || 0,
        "UnitRate14": row["p14"] || 0,
        "UnitRate15": row["p15"] || 0,
        "UnitRate16": row["p16"] || 0,
        "UnitRate17": row["p17"] || 0,
        "UnitRate18": row["p18"] || 0,
        "UnitRate19": row["p19"] || 0,
        "UnitRate20": row["p20"] || 0,
        "UnitRate21": row["p21"] || 0,
        "UnitRate22": row["p22"] || 0,
        "UnitRate23": row["p23"] || 0,
        "UnitRate24": row["p24"] || 0,
        "ChargeP0TO100": row["p100"] || 0,
        "ChargeP100TO200": row["p200"] || 0,
        "ChargeP200TO300": row["p300"] || 0,
        "ChargeP300TO400": row["p400"] || 0,
        "ChargeP400TO500": row["p500"] || 0,
        "ChargeP500TO600": row["p600"] || 0,
        "fromcity": { "id": row["originCityId"] || 1, "city": row["origincity"] },
        "fromcountry": row["originCountryId"],
        "fromprov": { "provineStateID": row["originProvinceId"], "provineSateName": row["originProvince"] },
        "tocity": { "id": row["destinationcityId"] || 1, "city": row["destinationcity"] },
        "tocountry": row["destinationCountryId"],
        "toprov": { "provineStateID": row["destinationProvinceId"], "provineSateName": row["destinationProvince"] },
      }
      recordsList.push(record);
    }
    setTempDisplay(true)
    dispatch(addCwtRateCardMultiple(recordsList));
    toast.info(`Successfully added ${rows.length} rows`, {
      position: "top-center",
    })
  }

  // Edit vehicle list
  const editCwtratecardList = (id) => {
    let newEditItem = cwtRateCardLists.find((data) => {
      return data.id === id
    });
    setCustomEditFields(true);
    setIsUpdate(true);
    setIsShow(isShow => !isShow);
    setCwtRatecardData(newEditItem);
    setEditingTaskIndex(id);
  }

  const getStateData = toProvince;
  const getToCity = toCity;

  const handletocountry = async (e) => {
    const addressData = e.target.value;
    const selectedState = await getStateData(addressData)
    setSelectToState(selectedState)
  }

  const handleToCity = async (e) => {
    const addressData = e.target.value;
    await getToCity(addressData)
    setSelectToCity("Select")
  }

  // when the cwt rate card is submitted create a new one with the previous rate type
  useEffect(() => {
    if (cwtRateCardLists.length >= 1 && Object.keys(cwtRatecardData).length === 0) {
      setCwtRatecardData(cwtRatecardData => ({ ...cwtRatecardData, "RateType": cwtRateCardLists[0].mLCWTRatecarditeam?.RateType }));
    }
  }, [cwtRatecardData])



  return (
    <>
      <Row className="mb-2 mt-2">
        <Col sm={12}>
          <fieldset className="pb-2">
            <div className="btn_section">
              <div className="section_action_btn">
                {!isUpdate ?
                  <>
                    {permission.find(event => event.moduleId === 44)?.permissionType?.indexOf('Add') !== -1 ?
                      <div className="add_btn"><button type="button" className="btn" onClick={e => { addCwtratecardList(e); }}><AddCircle /></button></div>
                      : <div className="add_btn disable"><button type="button" className="btn"><AddCircle /></button></div>}
                  </>

                  :
                  <div className="save_btn"><button type="button" className="btn" onClick={e => { addCwtratecardList(e); }}><Save /></button></div>
                }
              </div>
            </div>
            {isShow ?
              <>
                {/* Rate card item */}
                <Row className="mt-4">
                  <Col sm={12}>
                    <fieldset className="pb-2">
                      <label className="label-heading">Rate Card Item</label>
                      <>
                        <Row>
                          <Col sm={12} className="mb-3">
                            <Row>
                              <Col sm={4}><h6>Please select the rate card item type</h6></Col>
                              <Col sm={2}>
                                <div className="floating-checbox" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} name="RateType">
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" disabled={disableCWT} type="radio" id="inlineCheckbox1" value="CWT" name="RateType" checked={cwtStatus === 1} onChange={e => CwtstatusHandler(1)} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1">CWT</label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" disabled={disablePallet} type="radio" id="inlineCheckbox2" value="PALLET" name="RateType" checked={cwtStatus === 2} onChange={e => CwtstatusHandler(2)} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox2">PALLET</label>
                                  </div>
                                </div>
                              </Col>
                              {(cwtStatus !== 0) && <>
                                <Col sm={6}><UploadRateCard addExcelData={addExcelData} writeToExcel={writeToExcel} /></Col>
                                <Row>
                                  <Col sm={12}>
                                    <fieldset className="pb-2 mt-2">
                                      <label className="label-heading">CWT Rate Card City</label>
                                      <>
                                        <Row>
                                          <Col sm={6}>
                                            <fieldset className="pb-2 mt-2">
                                              <label className="label-heading">From</label>
                                              <>
                                                <Row>
                                                  <Col sm={12} className="mb-3">
                                                    <Form.Group controlId="from" className="mb-2">
                                                      <Typeahead
                                                        id='cityAutocomplete'
                                                        labelKey="name"
                                                        onChange={setFromSelection}
                                                        options={fromCityList}
                                                        placeholder="Choose from city..."
                                                        selected={fromSelection}
                                                      />
                                                    </Form.Group>
                                                    <Form.Group controlId="Province/State" className="mb-2">
                                                      <FloatingLabel controlId="floatingSelectGrid" label="From Province/State">
                                                        <Form.Select aria-label="Floating label select example" name="fromprov" onChange={(e) => { setSelectState(e.target.value); updateProvince(e); handleCity(e); setCountry(e); }} value={cwtRatecardData?.fromprov?.provineStateID || ""} required>
                                                          <option>Select</option>:
                                                          <>
                                                            {
                                                              fromProvince?.map((data) => (
                                                                <option key={data.provineStateID} value={data.provineStateID}>{data.provineSateName}</option>
                                                              ))

                                                            }
                                                          </>

                                                        </Form.Select>
                                                      </FloatingLabel>
                                                    </Form.Group>
                                                    <Form.Group controlId="country" className="mb-2">
                                                      <FloatingLabel controlId="floatingSelectGrid" label="From Country">
                                                        <Form.Select aria-label="Floating label select example" name="fromcountry" onChange={e => { handlecountry(e); cwtRatecardHandler(e) }} value={cwtRatecardData?.fromcountry || ''} required>
                                                          <option>Select</option>:
                                                          <>
                                                            {
                                                              fromCountryList?.map((data) => (
                                                                <option key={data.countryId} value={data.countryId} >{data.countryname}</option>
                                                              ))
                                                            }
                                                          </>
                                                        </Form.Select>
                                                      </FloatingLabel>
                                                    </Form.Group>
                                                  </Col>
                                                </Row>
                                              </>
                                            </fieldset>
                                          </Col>
                                          <Col sm={6}>
                                            <fieldset className="pb-2 mt-2">
                                              <label className="label-heading">To</label>
                                              <>
                                                <Row>
                                                  <Col sm={12} className="mb-3">
                                                    <Form.Group controlId="to" className="mb-2">
                                                      <Typeahead
                                                        id='cityAutocomplete'
                                                        labelKey="name"
                                                        onChange={setToSelection}
                                                        options={toCityList}
                                                        placeholder="Choose from city..."
                                                        selected={toSelection}
                                                      />
                                                    </Form.Group>
                                                    <Form.Group controlId="Province/State" className="mb-2">
                                                      <FloatingLabel controlId="floatingSelectGrid" label="To Province/State">
                                                        <Form.Select aria-label="Floating label select example" name="toprov" onChange={(e) => { setSelectState(e.target.value); updateProvince(e); handleToCity(e); setCountry(e); }} value={cwtRatecardData?.toprov?.provineStateID || ''} required>

                                                          <option>Select</option>:
                                                          <>
                                                            {
                                                              toProvince?.map((data, index) => (
                                                                <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                                                              ))

                                                            }
                                                          </>

                                                        </Form.Select>

                                                      </FloatingLabel>
                                                    </Form.Group>
                                                    <Form.Group controlId="country" className="mb-2">
                                                      <FloatingLabel controlId="floatingSelectGrid" label="To Country">
                                                        <Form.Select aria-label="Floating label select example" name="tocountry" onChange={e => { handletocountry(e); cwtRatecardHandler(e) }} value={cwtRatecardData?.tocountry || ''} required>

                                                          <option>Select</option>:
                                                          <>
                                                            {
                                                              toCountryList?.map((data, index) => (
                                                                <option key={index} value={data.countryId}>{data.countryname}</option>
                                                              ))

                                                            }
                                                          </>
                                                        </Form.Select>
                                                      </FloatingLabel>
                                                    </Form.Group>
                                                  </Col>
                                                </Row>
                                              </>
                                            </fieldset>
                                          </Col>
                                        </Row>
                                      </>
                                    </fieldset>
                                  </Col>
                                </Row>
                              </>
                              }
                            </Row>
                          </Col>
                          {cwtStatus === 1 &&
                            <Form className="cwt_form">
                              <Row className="mt-4 mb-3">
                                <Col sm={6}>
                                  <Form.Group controlId="minimumrate">
                                    <FloatingLabel controlId="floatingInput" label="CWT Minimum Rate" >
                                      <Form.Control type="text" placeholder="CWT Minimum Rate" name="MinimumRate" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.MinimumRate || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={6}>
                                  <Form.Group controlId="FTL Rate">
                                    <FloatingLabel controlId="floatingInput" label="FTL Rate" >
                                      <Form.Control type="text" placeholder="FTL Rate" name="FTLRate" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.FTLRate || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                              </Row>

                              <Row className="mt-2 range_label d-flex align-items-end">
                                <Col sm={2}>
                                  <h6>Range from (0-500) </h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRateP0TOLTL" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRateP0TOLTL || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={2}>
                                  <h6>Range from (500-1000) </h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRateP500TO1000" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRateP500TO1000 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={2}>
                                  <h6>Range from (1000-2000) </h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRateP1000TO2000" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRateP1000TO2000 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={2}>
                                  <h6>Range from (2000-5000) </h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRateP2000TO5000" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRateP2000TO5000 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={2}>
                                  <h6>Range from (5000-10000) </h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRateP5000TO10000" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRateP5000TO10000 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={2}>
                                  <h6>Range from (10000-20000) </h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRateP10000TO20000" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRateP10000TO20000 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Form>
                          }
                          {cwtStatus === 2 &&
                            <Form className="cwt_form">
                              <Row>
                                {/* <Col sm={4}>
                                  <Form.Group controlId="PalletMinimumRate">
                                    <FloatingLabel controlId="floatingInput" label="Pallet Minimum Rate" >
                                      <Form.Control type="number" placeholder="Pallet Minimum Rate" name="MinimumRate" onChange={e => {cwtRatecardHandler(e);setCustomEditFields(false)}} value={cwtRatecardData?.MinimumRate || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col> */}
                                <Col sm={6}>
                                  <Form.Group controlId="palletaverageweight">
                                    <FloatingLabel controlId="floatingInput" label="Pallet Average Weight" >
                                      <Form.Control type="text" placeholder="Pallet Average Weight" name="AverageWeight" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.AverageWeight || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={6}>
                                  <Form.Group controlId="ftlrate">
                                    <FloatingLabel controlId="floatingInput" label="FTL Rate" >
                                      <Form.Control type="number" placeholder="FTL Rate" name="FTLRate" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.FTLRate || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="mt-3 range_label">
                                <Col sm={1}>
                                  <h6>1</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate1" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate1 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>2</h6>
                                  <Form.Group controlId="UnitRate2">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate2" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate2 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>3</h6>
                                  <Form.Group controlId="UnitRate3">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate3" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate3 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>4</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate4" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate4 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>5</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate5" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate5 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>6</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate6" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate6 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>7</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate7" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate7 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>8</h6>
                                  <Form.Group controlId="UnitRate8">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate8" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate8 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>9</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate9" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate9 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>10</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate10" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate10 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>11</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate11" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate11 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>12</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate12" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate12 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="mt-3 range_label">
                                <Col sm={1}>
                                  <h6>13</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate13" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate13 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>14</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate14" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate14 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>15</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate15" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate15 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>16</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate16" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate16 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>17</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate17" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate17 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>18</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate18" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate18 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>19</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate19" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate19 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>20</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate20" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate20 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>21</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate21" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate21 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>22</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate22" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate22 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>23</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate23" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate23 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                                <Col sm={1}>
                                  <h6>24</h6>
                                  <Form.Group controlId="RangeFrom">
                                    <FloatingLabel controlId="floatingInput" label="Unit Rate" >
                                      <Form.Control type="text" placeholder="Unit Rate" name="UnitRate24" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.UnitRate24 || ''} />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row className="mt-4">
                                <Col sm={12}>
                                  <fieldset className="pb-2">
                                    <label className="label-heading">Pallet OverWeight Charge Section</label>
                                    <Row className="range_label">
                                      <Col sm={2}>
                                        <h6>Range From (0-100)</h6>
                                        <Form.Group controlId="RangeFrom">
                                          <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                            <Form.Control type="text" placeholder="Overweight Charge" name="ChargeP0TO100" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.ChargeP0TO100 || ''} />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={2}>
                                        <h6>Range From (100-200)</h6>
                                        <Form.Group controlId="RangeFrom">
                                          <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                            <Form.Control type="text" placeholder="Overweight Charge" name="ChargeP100TO200" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.ChargeP100TO200 || ''} />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={2}>
                                        <h6>Range From (200-300)</h6>
                                        <Form.Group controlId="RangeFrom">
                                          <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                            <Form.Control type="text" placeholder="Overweight Charge" name="ChargeP200TO300" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.ChargeP200TO300 || ''} />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={2}>
                                        <h6>Range From (300-400)</h6>
                                        <Form.Group controlId="RangeFrom">
                                          <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                            <Form.Control type="text" placeholder="Overweight Charge" name="ChargeP300TO400" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.ChargeP300TO400 || ''} />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={2}>
                                        <h6>Range From (400-500)</h6>
                                        <Form.Group controlId="RangeFrom">
                                          <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                            <Form.Control type="text" placeholder="Overweight Charge" name="ChargeP400TO500" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.ChargeP400TO500 || ''} />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Col>
                                      <Col sm={2}>
                                        <h6>Range From (500-600)</h6>
                                        <Form.Group controlId="RangeFrom">
                                          <FloatingLabel controlId="floatingInput" label="Overweight Charge" >
                                            <Form.Control type="text" placeholder="Overweight Charge" name="ChargeP500TO600" onChange={e => { cwtRatecardHandler(e); setCustomEditFields(false) }} value={cwtRatecardData?.ChargeP500TO600 || ''} />
                                          </FloatingLabel>
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  </fieldset>
                                </Col>
                              </Row>
                            </Form>
                          }
                        </Row>
                      </>
                    </fieldset>
                  </Col>
                </Row>
              </>
              : null}
            {/*  */}
            {/* table */}
            {cwtStatus === 1 &&
              <>
                <div className="datatable_section">
                  <DataGrid rows={testRows} columns={CWTColumns} pageSize={10} pagination components={{ Toolbar: GridToolbar }} />
                </div> </>}
            {cwtStatus === 2 &&
              <>
                <div className="datatable_section">
                  <DataGrid rows={testRows} columns={palletColumns} pageSize={10} pagination components={{ Toolbar: GridToolbar }} />
                </div> </>}
          </fieldset>
        </Col>
      </Row>
    </>
  );
}
export default CwtRatecard;