import { Delete, Edit } from '@material-ui/icons';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { Button, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAddAssetInventoryWithVehicleMutation, useDeleteInventorydataAssetMutation, useGetAllFieldsForAssetTypeQuery, useGetAssetInventoryListQuery, useGetAssetTypeListQuery, useGetAvailableAssetTypeListQuery, useLazyGetAssetsInventoryByIDQuery, useUpdateAssetsInventoryDataMutation } from '../../../features/vehicle/Assets/AssetsFieldSlice';
import { useGetVehicleNumberQuery, useGetVehicleTypeListQuery } from '../../../features/vehicle/GetVehicleFieldSlice';
import { useCustomData } from '../../Sidebar/Permission';
import Spinner from '../../Spinner';

const AssetsInventory = () => {
    const [permission] = useCustomData()
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const [fieldsValue, setFieldsValue] = useState()
    const [selectVehicle, setSelectVehicle] = useState()
    const [selectAsset, setSelectAsset] = useState()
    const [vehiclefieldsValue, setVehicleFieldsValue] = useState()
    const [vehicleName, setVehicleName] = useState()    
    const [vehicleNum, setVehicleNum] = useState()
    const [disable, setDisable] = useState(false)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [fieldArray, setFieldArray] = useState({});
    const [deleteId, setDeleteId] = useState()
    const { data: getAssetTypeList, refetch: assetTypeListRefetch } = useGetAvailableAssetTypeListQuery();
    const { data: vehicleList, refetch: VehicleListData } = useGetVehicleTypeListQuery({ refetchOnMountOrArgChange: true });
    const { data: getAllFieldsForAssetType } = useGetAllFieldsForAssetTypeQuery((fieldsValue), { refetchOnMountOrArgChange: false, skip: false, })
    const [AddAssetInventoryWithVehicle] = useAddAssetInventoryWithVehicleMutation();
    const { data: vehicleNumber } = useGetVehicleNumberQuery((vehiclefieldsValue), { refetchOnMountOrArgChange: false, skip: false })
    const [deleteInventorydataAsset] = useDeleteInventorydataAssetMutation()
    const [getAssetsInventory, assestsInventoryList] = useLazyGetAssetsInventoryByIDQuery()
    const [updateAssetsInventory] = useUpdateAssetsInventoryDataMutation()
    const assestsInventoryData = assestsInventoryList?.data?.data
    const [assestsInventory, setAssestsInventory] = useState({})
    const [show, setShow] = useState(false)
    const [isShow, setIsShow] = useState(false);
    const {data:allAssets} = useGetAssetTypeListQuery()
    const assetTypeDescription = allAssets?.data?.find((el) => (el.assetTypeId === assestsInventoryData?.assetTypeId))?.assetTypeDescription

    useEffect(() => {
        setSelectVehicle(vehicleList?.data)
        setSelectAsset(getAssetTypeList?.data)
    }, [vehicleList, getAssetTypeList])

    
    useEffect(() => {
        if (assestsInventoryData) {
            setVehicleName(assestsInventoryData?.vehicleTypeName)
        }
        setAssestsInventory(assestsInventoryData)
        reset(assestsInventory)
    }, [assestsInventoryList, assestsInventoryData, reset, assestsInventory])

    useEffect(() => {
        if (assestsInventoryData?.assetNumber) {
            setValue("Asset_Number", assestsInventoryData?.assetNumber)
        }
    }, [assestsInventoryData?.assetNumber, assestsInventoryList, setValue])

    const { data: table, isLoading, refetch } = useGetAssetInventoryListQuery();

    const tableList = table?.data;

    const assetsSelectHandler = (e) => {   
        setFieldsValue(e.target.value)
        setIsShow(true)
    }
    const handleField = (e) => {
        setFieldArray((fieldArray) => ({ ...fieldArray, [e.target.name]: e.target.value }))
    }

    const vehicleTypeHandler = (e) => {
        setVehicleFieldsValue(e.target.value)
    }
    const vehicleNumberHandler = (e) => {
        setVehicleNum(e.target.value)
    }
    const onSubmit = async (data) => {
        try {
            let array = []
            for (let key in fieldArray) {
                array.push(
                    {
                        assetFieldId: Number(key?.split("_")[1]),
                        assetFieldValue: fieldArray[key],
                    }
                )
            }
            setLoading(true)
            const newData = {
                "assetTypeId": fieldsValue,
                "assetNumber": data?.Asset_Number,
                "vehicleTypeId": vehiclefieldsValue,
                "vehicleNumber": vehicleNum,
                "additionalFieldDetail": array
            }
            const submitData = await AddAssetInventoryWithVehicle(newData)
            setLoading(false)
            setSelectVehicle([""])
            setSelectAsset([""])
            reset({ Asset_Number: "", vehicles: "" });
            refetch()
            const assetListing = await assetTypeListRefetch()
            setSelectAsset(assetListing?.data?.data)
            const vehicleListing = await VehicleListData()
            setSelectVehicle(vehicleListing?.data?.data)
            if (submitData?.data?.statusCode == 200) {
                toast.success("Asset Added to Inventory Succesfully!", {
                    position: "top-center",
                })
            }
            else if (submitData?.data?.statusCode == 400) {
                toast.error("Asset number already exists!", {
                    position: "top-center",
                })
            }
            setFieldsValue({})
            setVehicleFieldsValue({})
            setVehicleNum({})
            setIsShow(false)
            assetTypeListRefetch()
        }
        catch {
            toast.warning("An Error Occured!", {
                position: "top-center",
            })
        }
    }

    const updateAssests = async (data) => {
        try {
            let array = []
            for (let key in fieldArray) {
                array.push(
                    {
                        assetFieldId: Number(key?.split("_")[1]),
                        assetFieldValue: fieldArray[key],
                    }
                )
            }
            setLoading(true)
            const newData = {
                "assetInventoryId": assestsInventory?.assetInventoryId,
                "vehicleTypeId": assestsInventory?.vehicleTypeId,
                "vehicleNumber": assestsInventory?.vehicleNumber,
                "additionalFieldDetail": assestsInventory?.additionalFieldDetail && array
            }
            const updatedData = await updateAssetsInventory(newData)
            setLoading(false)
            refetch()
            if (updatedData?.data?.statusCode === 200) {
                toast.success("Asset Updated Succesfully!", {
                    position: "top-center",
                })
                setDisable(false)
            }
            reset({ Asset_Number: "" })
            setAssestsInventory({})
            setShow(false)
        }
        catch {
            toast.warning("An Error Occured!", {
                position: "top-center",
            })
        }
    }
    const handleClose = async () => {
        try {
            setOpen(false);
            const deleteData = await deleteInventorydataAsset(deleteId)
            if (deleteData?.data?.statusCode === 200) {
                toast.success("Asset removed from inventory successfully!", {
                    position: "top-center",
                })
            }
            refetch()
            assetTypeListRefetch()
        }
        catch {
            toast.warning("An Error Occured!", {
                position: "top-center",
            })
        }
    };

    if (!tableList || isLoading) return <><Spinner /></>;
    const columns = [
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 140,
            disableClickEventBubbling: true,
            renderCell: (data) => {
                const onClick = (e) => {
                    e.stopPropagation();
                    getAssetsInventory(data?.row?.id)                    
                    setShow(true)
                    setDisable(true)
                };
                const onRemove = (data ) => {
                    setDeleteId(data?.id)
                    setOpen(true);
                };

                return (
                    <>
                        <>
                            <div className="action_btn">
                                {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Update') !== -1 ?
                                    <span className="edit_btn" onClick={onClick}><Edit /></span>
                                    : <span className="edit_btn disable"><Edit /></span>}
                                {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Delete') !== -1 ?
                                    <span className="del_btn" onClick={() => onRemove(data)}><Delete /></span>
                                    : <span className="del_btn disable" ><Delete /></span>} 
                            </div>
                        </>
                        <>
                            <div>
                                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                    <DialogTitle id="alert-dialog-title">
                                        Delete Confirmation
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Are you sure you want to delete this row?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button className="btn-secondary" onClick={() => { setOpen(false); }}>Cancel</Button>
                                        <Button className="btn-primary" onClick={() => { handleClose(); }} autoFocus> Confirm</Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </>
                    </>
                )

            },
        },
        { field: "asset_Name", headerName: "Asset Name", width: 150 },
        { field: "Assets_Number", headerName: "Assets Number", width: 150 },
        { field: "vehicle_Name", headerName: "Vehicle Name", width: 150 },
        { field: "Vehicle_Number", headerName: "Vehicle Number", width: 150 },

    ];
    const rows = tableList && tableList?.map((data) => {
        return {
            id: data?.assetInventoryId,
            asset_Name: data?.assetTypeName,
            Assets_Number: data?.assetNumber,
            vehicle_Name: data?.vehicleTypeName,
            Vehicle_Number: data?.vehicleNumber
        }
    })

    return (
        <div>
            {loading && <Spinner />}
            <Row>
                <Col>
                    <div className="orderform mt-2">
                        <Row>
                            <Col sm={8}>
                                <Row>
                                    <Col sm={3}>
                                        <Form.Group controlId="Vehicletype">
                                            <FloatingLabel controlId="floatingSelectGrid" label="Assets">
                                                <Form.Select aria-label="Floating label select example" onChange={assetsSelectHandler} name="assets"  disabled={disable} >
                                                      <option value=""> {!!assetTypeDescription ? assetTypeDescription : "Select"} </option>
                                                <>
                                                        { 
                                                            selectAsset?.map((data) => (
                                                                <option key={data.assetTypeId} value={ data?.assetTypeId} selected={data?.assetTypeId === assestsInventory?.assetTypeId}> {!!assetTypeDescription ?assetTypeDescription : data?.assetTypeDescription} </option>
                                                            ))
                                                        }
                                                        </>
                                                </Form.Select>
                                            </FloatingLabel>
                                            <p className="error-message">{errors?.assets?.message}</p>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId="Vehicletype">
                                            <FloatingLabel controlId="floatingSelectGrid" label="Select a Vehicle type">
                                                <Form.Select aria-label="Floating label select example" name="vehicles" onChange={vehicleTypeHandler} defaultValue={vehicleName} disabled={disable}
                                                >
                                                    {!vehicleName && <option value=""> Select </option>   }
                                                    <>
                                                        {
                                                            selectVehicle?.map((data, index) => (
                                                                <option key={index} value={data?.vehicleTypeId} selected={data?.vehicleTypeId === assestsInventory?.vehicleTypeId}> {data?.vehicleTypeDescription} </option>
                                                            ))
                                                        }
                                                    </>
                                                </Form.Select>
                                            </FloatingLabel>
                                            <p className="error-message">{errors?.vehicles?.message}</p>
                                        </Form.Group>
                                    </Col>

                                    {/* //Vehicle Number DropDown */}
                                    <Col sm={3}>
                                        <Form.Group controlId="Vehicletype">
                                            <FloatingLabel controlId="floatingSelectGrid" label="Vehicle Number">
                                                <Form.Select aria-label="Floating label select example" name="vehicle_number" value={vehicleNum} onChange={vehicleNumberHandler} disabled={disable}
                                                >
                                                    <option>{assestsInventory?.vehicleNumber || "Select"}</option>:
                                                    <>
                                                        {
                                                            vehicleNumber?.data?.map((data, index) => (
                                                                <option key={index} value={data?.vehicleNumber}> {data?.vehicleNumber} </option>
                                                            ))
                                                        }
                                                    </>
                                                </Form.Select>
                                            </FloatingLabel>
                                            <p className="error-message">{errors?.vehicle_number?.message}</p>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                {!show ?
                                    <>
                                        {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Add') !== -1 ?
                                            <Button onClick={handleSubmit(onSubmit)} className='save-btn'>Submit</Button>
                                            : <Button className='save-btn disable'> Submit</Button>
                                        }
                                    </> :
                                    <>
                                        {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Update') !== -1 ?
                                            <Button onClick={updateAssests} className='save-btn'> Update</Button>
                                            : <Button className='save-btn disable'> Update</Button>
                                        }
                                    </>
                                }
                            </Col>
                        </Row>

                        <fieldset className=" mt-4 mb-3">
                            <label className="label-heading">Assets descriptions</label>
                            <div className="dynamic-input d-flex flex-wrap">
                                <div className="form-floating mr-2">
                                    <Form.Control type="number" autoComplete="off" placeholder="Asset Number" name="Asset_Number" {...register('Asset_Number', { required: 'Field is required', pattern: /^[a-zA-Z0-9]{1,10}$/ })}
                                     defaultValue={assestsInventoryData?.assetNumber}  disabled={disable} min="1" />
                                    <label htmlFor="Asset_Number">Asset Number</label>
                                    <p className="error-message">{errors.Asset_Number?.message}</p>
                                </div>
                                {isShow ?
                                    <div className="dynamic-input d-flex flex-wrap">
                                        {getAllFieldsForAssetType?.data?.fieldDetails && getAllFieldsForAssetType?.data?.fieldDetails.map((data, index) =>
                                            <div className="form-floating mr-2">
                                                <Form.Control type="text" key={index} autoComplete="off" placeholder="" name={data?.assetFieldName + "_" + data?.assetFieldId}
                                                    onChange={handleField} min="" />
                                                <label htmlFor="Item">{data?.assetFieldName}</label>
                                                <p className="error-message">{errors.Item?.message}</p>
                                            </div>
                                        )}
                                    </div>
                                    : ""}
                                {show ?
                                    <div className="dynamic-input d-flex flex-wrap">
                                        {assestsInventoryData?.additionalFieldDetail && assestsInventoryData?.additionalFieldDetail.map((data, index) =>
                                            <div className="form-floating mr-2">
                                                <Form.Control type="text" key={data?.assetFieldValue} autoComplete="off" placeholder="" name={data?.assetFieldName + "_" + data?.assetFieldId} defaultValue={data?.assetFieldValue || ""}
                                                    onChange={e => handleField(e)} min="" />
                                                <label htmlFor="Item">{data?.assetFieldName}</label>
                                                <p className="error-message">{errors.Item?.message}</p>
                                            </div>
                                        )}
                                    </div>
                                    : ""}
                            </div>
                        </fieldset>
                    </div>
                </Col>
                <Row>
                    <div className="user-role-table manageInventoryTable" style={{ height: "730px" }}>
                        <h6>Assets Inventory</h6>
                        <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }}  />
                    </div>
                </Row>
            </Row>
        </div>
    )
}

export default AssetsInventory;
