import { AddCircle, Delete, Edit, Save } from "@material-ui/icons";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch } from "react-redux";
import useCustomer from "../../../../../context/CustomerContext";
import { addCityDistance, deleteCityDistance, editCityDistance } from "../../../../../features/cityDistance/cityDistanceSlice";
import { useGetCityWithoutProvinceQuery } from "../../../../../features/countryState/countryStateSlice";
import CountryProvince from "../../../../CountryProvince";
import { useCustomData } from "../../../../Sidebar/Permission";

const CityDistance = () => {
  const [permission] = useCustomData()
  const { handleProvince, newHandleCountry, province } = CountryProvince()
  const { data: city } = useGetCityWithoutProvinceQuery()
  const dispatch = useDispatch();
  const { cityDistanceLists } = useCustomer();
  const [open, setOpen] = useState(false);
  const [selectState, setSelectState] = useState([]);
  const [selectToState, setSelectToState] = useState([]);
  const [isDelete, setIsDelete] = useState()
  const [isShow, setIsShow] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [cityDistance, setCityDistance] = useState({})
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);
  const handleClickOpen = (data) => { setOpen(true); setIsDelete(data) };
  const handleClose = () => { setOpen(false); };
  const cityList = city?.data.map((a) => { return { name: a.city, id: a.id } });
  const toCityList = cityList;
  const fromCityList = cityList;
  const [toSelection, setToSelection] = useState([]);
  const [fromSelection, setFromSelection] = useState([]);
  const [toProvince, setToProvince] = useState([]);
  const [fromProvince, setFromProvince] = useState([]);
  const [toCountry, setToCountry] = useState([]);
  const [fromCountry, setFromCountry] = useState([]);
  const [finaldata, setFinalData] = useState([])
  const [tempData, setTempData] = useState({})

  const getData = fromProvince;
  const getStateData = toProvince;

  const handlecountry = async (e) => {
    const addressData = e.target.value;
    const selectedState = await getData(addressData)
    setSelectState(selectedState)
  }

  const handletocountry = async (e) => {
    const addressData = e.target.value;
    const selectedState = await getStateData(addressData)
    setSelectToState(selectedState)
  }

  const getProvinceNameById = (id) => {
    const ret = province.data.find(p => p.provineStateID == id);
    return ret ? ret.provineSateName : null;
  };
  const getCityNameById = (id) => {
    const ret = cityList.find(c => c.id == id);
    return ret ? ret.name : null;
  };

  const Citydistancehandler = (e) => { setCityDistance(cityDistance => ({ ...cityDistance, [e.target.name]: e.target.value })) }
  // Add and update vehicle list 
  // const addCityDistanceList = (e) => {
  //   e.preventDefault();
  //   if (Object.keys(cityDistance).length === 0) {
  //     setIsShow(isShow => !isShow);
  //   }
  //   else if (editingTaskIndex === null) {
  //     dispatch(addCityDistance(cityDistance));
  //   } else {
  //     let editingItem = cityDistanceLists[editingTaskIndex];
  //     editingItem = cityDistance;
  //     dispatch(editCityDistance(editingItem));
  //   }
  //   setCityDistance("");
  //   setToSelection([]);
  //   setFromSelection([]);
  //   setEditingTaskIndex(null);
  //   setIsUpdate(false)
  // };
  const addCityDistanceList = (e) => {
    e.preventDefault();
    if (Object.keys(cityDistance).length === 0) {
      setIsShow(isShow => !isShow);
    }
    else if (editingTaskIndex === null) {
      dispatch(addCityDistance(cityDistance));
      setFinalData(prevData => [...prevData, {
        id: cityDistance.id,
        FromCountry: cityDistance.FromCountry,
        FromProvince: tempData.FromProvince,
        FromCity: tempData.FromCity,
        ToCountry: cityDistance.ToCountry,
        ToProvince: tempData.ToProvince,
        ToCity: tempData.ToCity,
        Miles: cityDistance.Miles,
        TimeInMinutes: cityDistance.TimeInMinutes
      }]);
    } else {
      let editingItem = cityDistanceLists[editingTaskIndex];
      editingItem = cityDistance;
      dispatch(editCityDistance(editingItem));

      setFinalData(prevData => prevData.map(item =>
        item.id === editingItem.id ? {
          id: editingItem.id,
          FromCountry: editingItem.FromCountry,
          FromProvince: tempData.FromProvince,
          FromCity: tempData.FromCity,
          ToCountry: editingItem.ToCountry,
          ToProvince: tempData.ToProvince,
          ToCity: tempData.ToCity,
          Miles: editingItem.Miles,
          TimeInMinutes: editingItem.TimeInMinutes
        } : item
      ));
    }
    setCityDistance("");
    setToSelection([]);
    setFromSelection([]);
    setEditingTaskIndex(null);
    setIsUpdate(false)
  };

  useEffect(() => {
    const handleSelection = async (selection, setProvince, setCountry, cityKey, provinceKey, countryKey) => {
      if (selection.length > 0) {
        const pList = await handleProvince(selection[0].id);
        setProvince(pList.data);
        setCityDistance(cityDistance => ({ ...cityDistance, [cityKey]: selection[0].id, }));
        if (pList.data.data.length === 1) {
          const selectedProvince = pList.data.data[0];
          const cList = await newHandleCountry(selectedProvince.provineStateID);
          setCountry(cList.data);
          setCityDistance(cityDistance => ({
            ...cityDistance,
            [provinceKey]: selectedProvince.provineStateID,
            [countryKey]: cList.data.data[0]?.countryId || null,
          }));
          setTempData(tempData => ({
            ...tempData,
            [provinceKey]: selectedProvince.provineStateID,
            [countryKey]: cList.data.data[0]?.countryId || null,
            [cityKey]: selection[0].id,
          }));
        }
      } else {
        setProvince([]);
        setCountry([]);
      }
    };
    handleSelection(fromSelection, setFromProvince, setFromCountry, 'FromCity', 'FromProvince', 'FromCountry');
    handleSelection(toSelection, setToProvince, setToCountry, 'ToCity', 'ToProvince', 'ToCountry');
  }, [fromSelection, toSelection]);

  const setCountry = async (e) => {
    if (e.target.value !== 'Select') {
      if (e.target.name === 'FromProvince') {
        const cList = await newHandleCountry(e.target.value);
        setTempData({ ...tempData, FromProvince: e.target.options[e.target.selectedIndex].text })
        setFromCountry(cList.data)
      }
      else {
        const cList = await newHandleCountry(e.target.value);
        setTempData({ ...tempData, ToProvince: e.target.options[e.target.selectedIndex].text })
        setToCountry(cList.data)
      }
    }
    else {
      if (e.target.name === 'FromProvince') {
        setFromCountry([])
      }
      else {
        setToCountry([])
      }
    }
  }

  // Edit vehicle list
  const editCityDistanceList = async (id) => {
    let newEditItem = cityDistanceLists.find((data) => {
      return data.id === id
    });
    setIsUpdate(true);
    setIsShow(true);
    setCityDistance(newEditItem)

    const fromCity = getCityNameById(newEditItem.FromCity);
    const toCity = getCityNameById(newEditItem.ToCity);

    setFromSelection(fromCity ? [{ name: fromCity, id: newEditItem.FromCity }] : []);
    setToSelection(toCity ? [{ name: toCity, id: newEditItem.ToCity }] : []);
    const toCountryList = await newHandleCountry(newEditItem.ToProvince);
    setToCountry(toCountryList.data);

    setEditingTaskIndex(id);
  }

  const deleteHandler = () => {
    dispatch(deleteCityDistance(isDelete));
    handleClose();
    setFinalData([])
  }


  return (
    <>
      <Row className="mb-4 mt-4">
        <Col sm={12}>
          <fieldset className="mb-3">
            <label className="label-heading">City Distance Table</label>
            <Row className="mb-2 mt-2">
              <Col sm={12}>
                <fieldset className="pb-2">
                  <div className="section_action_btn">
                    {!isUpdate ?
                      <>
                        {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Add') !== -1 ?
                          <div className="add_btn"><button type="button" className="btn" value="addcitydistance" onClick={e => { addCityDistanceList(e); }}><AddCircle /></button></div>
                          : <div className="add_btn disable"><button type="button" className="btn" value="addcitydistance"><AddCircle /></button></div>}
                      </>
                      :
                      <div className="save_btn"><button type="button" className="btn" value="addcitydistance" onClick={e => { addCityDistanceList(e); }}><Save /></button></div>
                    }
                  </div>
                  {isShow ?
                    <>
                      <Row className="mt-2 mb-3">
                        <Col sm={3}>
                          <Form.Group controlId="to">
                            <Typeahead
                              id='cityAutocomplete'
                              labelKey="name"
                              onChange={setFromSelection}
                              options={fromCityList}
                              placeholder="Choose from city..."
                              selected={fromSelection}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="Province/State">
                            <FloatingLabel controlId="floatingSelectGrid" label="From Province/State">
                              <Form.Select aria-label="Floating label select example" name="FromProvince" onChange={(e) => { setSelectState(e.target.value); Citydistancehandler(e); setCountry(e) }} value={cityDistance.FromProvince || ''} required>

                                <option>Select</option>:
                                <>
                                  {
                                    fromProvince?.data?.map((data, index) => (
                                      <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                                    ))

                                  }
                                </>

                              </Form.Select>

                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="country">
                            <FloatingLabel controlId="floatingSelectGrid" label="From Country">
                              <Form.Select aria-label="Floating label select example" name="FromCountry" onChange={e => { handlecountry(e); Citydistancehandler(e) }} value={cityDistance.FromCountry || ''} required>

                                <option>Select</option>:
                                <>
                                  {
                                    fromCountry?.data?.map((data, index) => (
                                      <option key={index} value={data.countryId}>{data.countryname}</option>
                                    ))

                                  }
                                </>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="Miles">
                            <FloatingLabel controlId="floatingInput" label="Miles" >
                              <Form.Control type="text" placeholder="Miles" name="Miles" onChange={e => Citydistancehandler(e)} value={cityDistance.Miles || ''} />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>
                      {/* 2nd Row */}
                      <Row className="mt-2 mb-3">
                        <Col sm={3}>
                          <Form.Group controlId="to">
                            <Typeahead
                              id='cityAutocomplete'
                              labelKey="name"
                              onChange={setToSelection}
                              options={toCityList}
                              placeholder="Choose to city..."
                              selected={toSelection}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="Province/State">
                            <FloatingLabel controlId="floatingSelectGrid" label="To Province/State">
                              <Form.Select aria-label="Floating label select example" name="ToProvince" onChange={(e) => { setSelectToState(e.target.value); Citydistancehandler(e); setCountry(e); }} value={cityDistance.ToProvince || ''} required>

                                <option>Select</option>:
                                <>
                                  {
                                    toProvince?.data?.map((data, index) => (
                                      <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                                    ))

                                  }
                                </>

                              </Form.Select>

                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="country">
                            <FloatingLabel controlId="floatingSelectGrid" label="To Country">
                              <Form.Select aria-label="Floating label select example" name="ToCountry" onChange={e => { handletocountry(e); Citydistancehandler(e) }} value={cityDistance.ToCountry || ''} required>

                                <option>Select</option>:
                                <>
                                  {
                                    toCountry?.data?.map((data, index) => (
                                      <option key={index} value={data.countryId}>{data.countryname}</option>
                                    ))

                                  }
                                </>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                        <Col sm={3}>
                          <Form.Group controlId="In Minutes">
                            <FloatingLabel controlId="floatingInput" label="In Minutes" >
                              <Form.Control type="text" placeholder="In Minutes" name="TimeInMinutes" onChange={e => Citydistancehandler(e)} value={cityDistance.TimeInMinutes || ''} required />
                            </FloatingLabel>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                    : null}
                  {/* table */}
                  <Row className="mt-3">
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>From City</th>
                          <th>To City</th>
                          <th>From Province</th>
                          <th>To Province</th>
                          <th>From Country</th>
                          <th>To Country</th>
                          <th>Miles</th>
                          <th>Minutes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cityDistanceLists?.map((data) => (
                          <tr key={data.id}>
                            <td>
                              <div className="action_btn">
                                {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ?
                                  <span className="edit_btn" onClick={() => editCityDistanceList(data.id)}><Edit /></span> : <span className="edit_btn disable" ><Edit /></span>}
                                {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Delete') !== -1 ?
                                  <span className="del_btn" onClick={() => handleClickOpen(data?.id)}><Delete /></span> : <span className="del_btn disable" onClick={() => handleClickOpen(data?.id)}><Delete /></span>}
                                <div>
                                  <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                    <DialogTitle id="alert-dialog-title">
                                      Delete Confirmation
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText id="alert-dialog-description">
                                        Are you sure you want to delete this row?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                                      <Button className="btn-primary" onClick={() => { deleteHandler() }} autoFocus> Confirm</Button>
                                    </DialogActions>
                                  </Dialog>
                                </div>
                              </div>
                            </td>
                            <td>{getCityNameById(data.FromCity)}</td>
                            <td>{getCityNameById(data.ToCity)}</td>
                            <td>{getProvinceNameById(data.FromProvince)}</td>
                            <td>{getProvinceNameById(data.ToProvince)}</td>
                            <td>{data?.FromCountry == "1" ? "CAN" : "USA"}</td>
                            <td>{data?.ToCountry == "1" ? "CAN" : "USA"}</td>
                            <td>{data.Miles}</td>
                            <td>{data.TimeInMinutes}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Row>
                </fieldset>
              </Col>
            </Row>
          </fieldset>
        </Col>
      </Row>
    </>

  );
}
export default CityDistance;