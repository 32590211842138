import { apiSlice } from "../../app/services/authServices";

export const dispatchSlice = apiSlice.injectEndpoints({
  tagTypes:["Dispatch"],
  endpoints: builder => ({
    getDriverNumberList: builder.query({
      query: (divisionID) => ({
        url: `SameDayDispatch/GetDriverNumber${divisionID ? `?divisionId=${divisionID}` : ''}`,
        method: 'GET',
      }),
    }),
    getDivisionList: builder.query({
      query: () => ({
        url: 'SameDayDispatch/GetDivisionList',
        method: 'GET',
      }),
    }),
    getAllActiveOpenOrders: builder.query({
      query: (divisionID) => ({
        url: `SameDayDispatch/GetAllActiveOpenOrders?DivisionId=${divisionID}`,
        method: 'GET',
      }),
    }),
    getDriversFromBoard: builder.query({
      query: (divisionID) => ({
        url: `SameDayDispatch/GetDriversFromBoard?divisionId=${divisionID}`,
        method: 'GET',
      }),
    }),
    addDriverToBoard: builder.mutation({
      query: ({ divisionID, driverNumber }) => ({
        url: `SameDayDispatch/AddDriverToBoard?DivisionId=${divisionID}&DriverNumber=${driverNumber}`,
        method: 'POST',
      }),
    }),
    getAllUnits: builder.query({
      query: () => ({
      url: 'SameDayDispatch/GetAllUnits',
      method: 'GET',
      }),
    }),
    getAccessorialCodes: builder.query({
      query: () => ({
        url: 'SameDayDispatch/GetAccessorialCodes',
        method: 'GET',
      }),
    }),
    addUnitToDriver: builder.mutation({
      query: ({ driverNumber, unitNumber, trailerNumber }) => ({
        url: `SameDayDispatch/AddUnitToDriver?DriverNumber=${driverNumber}&UnitNumber=${unitNumber}&TrailerNumber=${trailerNumber}`,
        method: 'POST',
      }),
    }),
    getAllSelectedDriverDetails: builder.query({
      query: ({ divisionId, driverNumber }) => ({
      url: `SameDayDispatch/GetAllSelectedDriverDetails?divisionId=${divisionId}&driverNumber=${driverNumber}`,
      method: 'GET',
      }),
    }),
    addDriverToOpenOrder: builder.mutation({
      query: (DTO) => ({
      url: 'SameDayDispatch/AddDriverToOpenOrder',
      method: 'POST',
      body: DTO,
      }),
    }),
    getAssignedOrdersToDriver: builder.query({
      query: ({ divisionId, driverNumber }) => ({
        url: `SameDayDispatch/GetAssignedOrdersToDriver?divisionId=${divisionId}&driverNumber=${driverNumber}`,
        method: 'GET',
      }),
    }),
    getSelectedOrderDetails: builder.query({
      query: ({ divisionId, orderId }) => ({
        url: `SameDayDispatch/GetSelectedOrderDetails?divisionId=${divisionId}&orderId=${orderId}`,
        method: 'GET',
      }),
    }),
    updateDriversOnOrder: builder.mutation({
      query: (DTO) => ({
      url: 'SameDayDispatch/UpdateDriversOnOrder',
      method: 'POST',
      body: DTO,
      }),
    }),
    updateDriverCellInBoard: builder.mutation({
      query: ({ divisionId, driverNumber, updatedFieldId }) => ({
      url: `SameDayDispatch/UpdateDriverCellInBoard?divisionId=${divisionId}&driverNumber=${driverNumber}&updatedFieldId=${updatedFieldId}`,
      method: 'POST',
      }),
    }),
    getTrailersForAssignedUnit: builder.query({
      query: (unitNumber) => ({
        url: `SameDayDispatch/GetTrailersForAssignedUnit?unitNumber=${unitNumber}`,
        method: 'GET',
      }),
    }),
    removeTrailerFromUnit: builder.mutation({
      query: ({ divisionId, driverNumber, unitNumber, trailerNumber }) => ({
      url: `SameDayDispatch/RemoveTrailerFromUnit?divisionId=${divisionId}&driverNumber=${driverNumber}&unitNumber=${unitNumber}&trailerNumber=${trailerNumber}`,
      method: 'POST',
      }),
    }),
    addDispatcherNotes: builder.mutation({
      query: (data) => ({
        url: 'SameDayDispatch/SaveDispatcherNotes',
        method: 'POST',
        body: data
      }),
    }),
    addOrderNotes: builder.mutation({
      query: (data) => ({
        url: 'SameDayDispatch/SaveNotes',
        method: 'POST',
        body: data
      }),
    }),
    editSelectedOrderDetails: builder.mutation({
      query: (data) => ({
        url: 'SameDayDispatch/EditSelectedOrderDetails',
        method: 'POST',
        body: data
      }),
    }),
    editSelectedOrderAccessorials: builder.mutation({
      query: (data) => ({
        url: 'SameDayDispatch/EditSelectedOrderAccessorial',
        method: 'POST',
        body: data
      }),
    }),
    getAssignedOrdersToDriverByPriority: builder.query({
      query: ({ divisionId, driverNumber }) => ({
        url: `SameDayDispatch/GetAssignedOrdersToDriverByPriority?divisionId=${divisionId}&driverNumber=${driverNumber}`,
        method: 'GET',
      }),
    }),
    SaveAssignedOrdersToDriverByPriority: builder.mutation({
      query: (DTO) => ({
        url: 'SameDayDispatch/SaveAssignedOrdersToDriverByPriority',
        method: 'POST',
        body: DTO,
      }),
    }),
    DownloadPdf: builder.query({
      query: ({ divisionId, driverNumber }) => ({
        url: `SameDayDispatch/DownloadPdf/download?divisionId=${divisionId}&driverNumber=${driverNumber}`,
        method: 'GET',
        responseHandler: (response) => response.blob(),
      }),
    }),
    updateSMS: builder.mutation({
      query: (data) => ({
        url: 'SameDayDispatch/UpdateSMS',
        method: 'POST',
        body: data
      }),
    }),
  })
})

export const { useUpdateSMSMutation,useLazyGetDriverNumberListQuery ,useGetDivisionListQuery, useUpdateRateCardMutation, useLazyGetAllActiveOpenOrdersQuery, 
  useLazyGetDriversFromBoardQuery, useAddDriverToBoardMutation, useGetAllUnitsQuery, useGetAccessorialCodesQuery, useAddUnitToDriverMutation, 
  useLazyGetAllSelectedDriverDetailsQuery, useAddDriverToOpenOrderMutation, useLazyGetAssignedOrdersToDriverQuery, useLazyGetSelectedOrderDetailsQuery, 
  useUpdateDriversOnOrderMutation, useUpdateDriverCellInBoardMutation, useLazyGetTrailersForAssignedUnitQuery, useRemoveTrailerFromUnitMutation, 
  useAddDispatcherNotesMutation, useAddOrderNotesMutation, useEditSelectedOrderDetailsMutation,useEditSelectedOrderAccessorialsMutation,
  useLazyGetAssignedOrdersToDriverByPriorityQuery, useSaveAssignedOrdersToDriverByPriorityMutation, useLazyDownloadPdfQuery } = apiSlice;
