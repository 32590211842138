import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/services/authServices";
import { forEach } from "lodash";

const initialState = {
    list: []
};

export const cwtApiSlice = apiSlice.injectEndpoints({
    tagTypes:['Cwt'],
    endpoints:builder => ({ 
        updateCwtData: builder.mutation({
            query(data) {
                const {cwtRateCardId, ...body} = data
                return {
                    url: `Customer/EditCwtratecardSection?CWTRateCardId=${cwtRateCardId}`,
                  method: "POST",
                  body
                };
              },
          }), 
          deleteCwtData: builder.mutation({
            query : (cwtRateCardId) => ({
                  url: `Customer/DeleteCwtratecardSection?CWTRateCardId=${cwtRateCardId}`,
                  method: "POST",
              }),
          }),  
          addCwtDataAfterEdit: builder.mutation({
            query: (body) => ({
              url: `/Customer/AddCustomerCwtRateCardAfterEdit`,
              method: "POST",
              body,
            }),
          }),
          uploadCwtData: builder.mutation({ 
            query(data) {
                const {rateCardId, ...body} = data
                return {
                    url: `Customer/UploadExcelFile?RateCardID=${rateCardId}`,
                  method: "POST",
                  body
                };
              },
          }),
          uploadData: builder.mutation({
            query: ({body, fileName}) => ({
              url: `Customer/UploadFile?file=${fileName}`,
              method: "POST",
              body,
            }),
          }),
          addDefaultPricingRateCard: builder.mutation({
            query: (body) => ({
              url: `Admin/AddDefaultRatecard`,
              method: "POST",
              body,
            })
          }),
          getDefaultPricingRateCard: builder.query({
            query: (id) => ({
              url: `Admin/GetDefaultRatecardByID?RatecardId=${id}`,
              method: "GET",
            })
          }),
          getDefaultPricingRateCardList: builder.query({
            query: () => ({
              url: `Admin/GetListOfDefaultRateCards`,
              method: "GET",
            })
          }),
          deleteDefaultRatecard: builder.mutation({
            query: (ratecardId) => ({
              url: `Admin/DeleteDefaultRatecard?RatecardId=${ratecardId}`,
              method: "POST",
            }),
          }),
          addCwtRateCardAfterCreation: builder.mutation({
            query: (body) => ({
              url: `Customer/AddCwtRateCardAfterCreation`,
              method: "POST",
              body
            }),
          }),
    })    
})

export const {useUpdateCwtDataMutation, useDeleteCwtDataMutation, useUploadCwtDataMutation, 
  useAddCwtDataAfterEditMutation, useUploadDataMutation, useAddDefaultPricingRateCardMutation, 
  useLazyGetDefaultPricingRateCardQuery, useGetDefaultPricingRateCardListQuery, useDeleteDefaultRatecardMutation,
 useAddCwtRateCardAfterCreationMutation } = apiSlice


const cwtRateCardSlice = createSlice({
    name: "cwtRateCardDetail",
    initialState,
    reducers: {
        addCwtRateCard: (state, action) => {
            const lists = {
              id: new Date().getTime().toString(),
              list: action.payload,
            };
            state.list.push(lists);
          },
        deleteCwtRateCard: (state,action) => {
            state.list = state.list.filter(
                (data) => data.id !== action.payload
            )
        },
        editCwtRateCard : (state, action) => {
            const currentLists = Array.from(state.list);
            const filterData = state.list.findIndex((list) => list.id === action.payload.id);
            currentLists[filterData] = {
                id:action.payload.id,
                list:action.payload
            }
            return { ...state, list: currentLists };
        },
        addCwtRateCardMultiple: (state, action) => {
          action.payload.forEach(record => {
            const lists = {
              id: crypto.randomUUID(),
              list: record
            };
            state.list.push(lists);
          })
        },
        resetCwtRateCard: () => initialState,
    },
})

export const { addCwtRateCard, editCwtRateCard, deleteCwtRateCard, addCwtRateCardMultiple, resetCwtRateCard } = cwtRateCardSlice.actions

export default cwtRateCardSlice.reducer