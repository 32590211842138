import { Col, Row, Form, FloatingLabel, Table, Button } from "react-bootstrap";
import { AddCircle } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { toast } from 'react-toastify';
import useCustomer from "../../../context/CustomerContext";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { addFuelCost, editFuelCost, deleteFuelCost, useGetAllFuelListQuery, useAddCustomerFuelCostAfterEditMutation,
    useEditCustomerFuelCostMutation, useDeleteCustomerFuelCostMutation } from "../../../features/FuelCost/FuelCostSlice";

const FuelCostTable = () => {
    const dispatch = useDispatch();
    const [isUpdate, setIsUpdate] = useState(false);
    const [fuelCost, setFuelCost] = useState({})
    const [editingTaskIndex, setEditingTaskIndex] = useState(null);
    const { data: tableListData, refetch } = useGetAllFuelListQuery()
    const [addFuelCostAfterEdit] = useAddCustomerFuelCostAfterEditMutation()
    const [editFuelCostAfterEdit] = useEditCustomerFuelCostMutation()
    const [deleteFuelCostAfterEdit] = useDeleteCustomerFuelCostMutation()
    const [fuelOpen, setFuelOpen] = useState(false);
    const [isDelete, setIsDelete] = useState()
    const {fuelCostLists, setTempDisplay, setFuelCostLists, contractInfo, singleAccount, handleAccount } = useCustomer();
    const account = singleAccount && singleAccount[0];
    const fuelCosthandler = (e) => { setFuelCost(fuelCost => ({ ...fuelCost, [e.target.name]: e.target.value })) }
    
    const beforeEditDelete =  async (e) => {
        if (e.target.name === "fuelCost-confirm") {
            if (!contractInfo[0]?.rateCardId) {
                const newArr = fuelCostLists.filter(item => item.id !== isDelete);
                setFuelCostLists(newArr);
                setFuelOpen(false)
                setFuelCost("")
                return;
            }
            const result = await deleteFuelCostAfterEdit({ fuelCostId: isDelete, rateCardId: contractInfo[0].rateCardId })
            if (result.data?.statusCode === 400) {
                toast.error(result?.data.message, {
                    position: "top-center",
                })
                return;
            } else if (result.data?.statusCode === 200) {
                toast.success("Data deleted successfully", {
                    position: "top-center",
                })
                const newArr = fuelCostLists.filter(item => item.fuelCostId !== isDelete);
                setFuelCostLists(newArr);
                setFuelOpen(false)
                setFuelCost("")
            }
        }
    }

    // useEffect(() => {
    //     if (tableListData?.data) {
    //         const mappedData = tableListData?.data.map((data) => { return { id: data?.fuelCostId, effectiveDate: data?.effectiveDate, rate: data?.costPerGallon } })
    //         setFuelCostLists(mappedData)
    //     }
    // }, [tableListData])

    const handleFuelClose = () => { setFuelOpen(false); };

    const deleteHandler = (data) => {
        setIsDelete(data)
        setFuelOpen(true)
    }

    // create function similar to addFuelCostList but doesn't call the backend, only updates the UI
    const addFuelCostNoRateCard = () => {
        if (Object.keys(fuelCost).length === 0) {
            toast.info("Please fill the form", {
                position: "top-center",
            })
        }
        else if (editingTaskIndex === null) {
            setTempDisplay(false)
            const id = new Date().getTime().toString()
            setFuelCost(fuelCost => {
                const updatedFuelCost = {...fuelCost, id: id};
                return updatedFuelCost;
            });
            setFuelCostLists([...fuelCostLists, { ...fuelCost, id: id, isMatchingGlobalRecord: false }]);
        } else {
            let editingItem = fuelCostLists.find(item => item.id === editingTaskIndex);
            editingItem = fuelCost;
            const newFuelCostList = fuelCostLists.map((item, index) => {
            if (item.id === editingTaskIndex) {
                return { ...editingItem, isMatchingGlobalRecord: false };
            }
            else {
                return item;
            }
            })
            setFuelCostLists(newFuelCostList);
        }
        setFuelCost("");
        setEditingTaskIndex(null);
        setIsUpdate(false)
    }


    const addFuelCostList = async () => {
        if (Object.keys(fuelCost).length === 0) {
          toast.info("Please fill the form", {
            position: "top-center",
          })
        }
        else if (editingTaskIndex === null) {
          setTempDisplay(false)
          const id = new Date().getTime().toString()
            setFuelCost(fuelCost => {
                const updatedFuelCost = {...fuelCost, id: id};
                return updatedFuelCost;
            });
            const result = await addFuelCostAfterEdit({ ...fuelCost, isDeleted: 0, isMatchingGlobalRecord: false, rateCardId: contractInfo[0].rateCardId })
            handleAccount(account?.account_No)
            if (result.data?.statusCode === 400) {
                toast.error(result?.data.message, {
                    position: "top-center",
                })
                return;
            } else if (result.data?.statusCode === 200) {
                toast.success("Data added successfully", {
                    position: "top-center",
                })
                setFuelCostLists([...fuelCostLists, { ...fuelCost, id: id, isMatchingGlobalRecord: false }]);
            }
        } else {
            let editingItem = fuelCostLists.find(item => item.fuelCostId === editingTaskIndex);
            editingItem = fuelCost;
            const newFuelCostList = fuelCostLists.map((item, index) => {
            if (item.fuelCostId === editingTaskIndex) {
                return { ...editingItem, isMatchingGlobalRecord: false };
            }
            else {
                return item;
            }
            })
            const result = await editFuelCostAfterEdit({ ...fuelCost, isMatchingGlobalRecord: false, rateCardId: contractInfo[0].rateCardId })
            if (result.data?.statusCode === 400) {
                toast.error(result?.data.message, {
                    position: "top-center",
                })
                return;
            } else if (result.data?.statusCode === 200) {
                toast.success("Data updated successfully", {
                    position: "top-center",
                })
                setFuelCostLists(newFuelCostList);
            }
        }
        setFuelCost("");
        setEditingTaskIndex(null);
        setIsUpdate(false)
    }

    const editFuelCostList = (id) => {
        if (!contractInfo[0]?.rateCardId) {
            let newEditItem = fuelCostLists.find((data) => {
                return data.id === id
            });
            setIsUpdate(true);
            setFuelCost(newEditItem)
            setEditingTaskIndex(id);
            return;
        }
        let newEditItem = fuelCostLists.find((data) => {
          return data.fuelCostId === id
        });
        setIsUpdate(true);
        setFuelCost(newEditItem)
        setEditingTaskIndex(id);
    }
    
    return(    
        <Col sm={4}>
            <fieldset className="less_load_table">
                <label className="label-heading">Fuel Cost</label>
                <Col sm={12} className="justify-content-between">
                <div className="section_action_btn">
                    {!isUpdate ?
                    <div className="add_btn"><button type="button" className="btn" onClick={e => { contractInfo[0]?.rateCardId ? addFuelCostList(e) : addFuelCostNoRateCard(e); }}><AddCircle /></button></div>
                    :
                    <div className="save_btn"><button type="button" className="btn" onClick={e => { contractInfo[0]?.rateCardId ? addFuelCostList(e) : addFuelCostNoRateCard(e); }}><Save /></button></div>
                    }
                </div>
                </Col>
                <>
                <Row>

                    <Row className="mt-2 mb-3">
                    <Col sm={12} className="mb-2">
                        <Form.Group controlId="Date">
                        <FloatingLabel controlId="floatingInput" label="Date" >
                            <Form.Control type="date" placeholder="Date" name="effectiveDate" onChange={e => fuelCosthandler(e)} value={fuelCost?.effectiveDate || ''} />
                        </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col sm={12}>
                        <Form.Group controlId="ToCost">
                        <FloatingLabel controlId="floatingInput" label="Fuel Charge" >
                            <Form.Control type="number" placeholder="Fuel Charge" name="rate" onChange={e => fuelCosthandler(e)} value={fuelCost?.rate || ''} />
                        </FloatingLabel>
                        </Form.Group>
                    </Col>
                    </Row>

                    <Col sm={12}>
                    <Row>
                        <Table bordered hover responsive>
                        <thead>
                            <tr>
                            <th>Action</th>
                            <th>Date</th>
                            <th>Fuel Charge</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fuelCostLists?.map((data) => (
                            <tr key={data?.fuelCostId} style={{ color: data?.isMatchingGlobalRecord === false ? 'red' : 'black' }}>
                                <td>
                                <div className="action_btn">
                                    <span className="edit_btn" 
                                        onClick={() => {
                                            if (contractInfo[0]?.rateCardId) {
                                                editFuelCostList(data?.fuelCostId)
                                            } else{
                                                editFuelCostList(data?.id)
                                            }
                                        }}><Edit /></span>
                                    <span className="del_btn" 
                                        onClick={() => {
                                            if (contractInfo[0]?.rateCardId) { 
                                                deleteHandler(data?.fuelCostId)
                                            }
                                            else {
                                                deleteHandler(data?.id)
                                            }
                                        }}
                                            ><Delete /></span>
                                </div>
                                <Dialog open={fuelOpen} onClose={handleFuelClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                    <DialogTitle id="alert-dialog-title">
                                    Delete Confirmation
                                    </DialogTitle>
                                    <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Are you sure you want to delete this row?
                                    </DialogContentText>
                                    </DialogContent>
                                    <DialogActions >
                                    <Button className="btn-secondary" onClick={handleFuelClose}>Cancel</Button>
                                    <Button className="btn-primary" name="fuelCost-confirm" onClick={beforeEditDelete} autoFocus> Confirm</Button>
                                    </DialogActions>
                                </Dialog>
                                </td>
                                <td>{data?.effectiveDate}</td>
                                <td>{data?.rate}</td>
                            </tr>
                            ))}
                        </tbody>
                        </Table>
                    </Row>
                    </Col>
                </Row>
                </>
            </fieldset>
        </Col>
    )
}
export default FuelCostTable;