import { Delete, Edit } from "@material-ui/icons";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import useCustomer from "../../../context/CustomerContext";
import { useAddCustomerDetailsAfterEditMutation, useDeleteContactDataMutation, useUpdateContactDataMutation } from "../../../features/contactDetails/contactDetailsSlice";
import { useModifyHistoryMutation } from "../../../features/customer/history/historySlice";
import { useCustomData } from "../../Sidebar/Permission";
import useDeleteData from "../useDeleteData";

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const EditContact = () => {
    const [permission] = useCustomData();
    const { register, handleSubmit, watch, formState: { errors }, reset, control, onChange } = useForm();
    const [customerDetailsAfterEdit] = useAddCustomerDetailsAfterEditMutation()
    const { singleAccount, handleAccount } = useCustomer();
    const [updateContact] = useUpdateContactDataMutation()
    const [deleteContact] = useDeleteContactDataMutation()
    const [modifyHistory] = useModifyHistoryMutation()
    const [handleClose, handleOpen, open, deleteData] = useDeleteData(deleteContact)
    const accountData = singleAccount && singleAccount[0];
    const [contactDetail, setContactDetail] = useState({})
    const prevContact = usePrevious(contactDetail)

    useEffect(() => {
        reset(contactDetail);
    }, [contactDetail, reset]);

    const Editcontactlist = (contactID) => {
        let newcontact = accountData.mLDetailsList.find((data) => {
            return data.contactID === contactID
        });
        setContactDetail(newcontact);
    }

    const Editcontactitem = async (contactDetail) => {
        if (JSON?.stringify(prevContact) === JSON?.stringify(contactDetail)) {
            setContactDetail("");
            setTimeout(function () {
                toast.info("No Changes found on this contact details section !", {
                    position: "top-right",
                })
            });
        }
        else {
            const updateContactList = await updateContact(contactDetail)
            setContactDetail("");
            handleAccount(accountData.account_No)
            if (updateContactList.data.statusCode === 200) {
                setTimeout(function () {
                    toast.success("Contact data updated successfully !", {
                        position: "top-right",
                    })
                });
            }
        }
        if (JSON?.stringify(prevContact) !== JSON?.stringify(contactDetail)) {
            const modifieddata = {
                AccountNo: accountData.account_No,
                CustomerName: accountData.customer,
                Modifiedsection: "Modified the contact details section"
            }
            await modifyHistory(modifieddata)
        }
        reset({ emailID: '', contactNumber: '', contactName: '' });
    }

    const addNewRecords = async (data) => {
        try {
            const updateRecord = await customerDetailsAfterEdit({ accountNo: accountData.account_No, ...data })
            setContactDetail("");
            handleAccount(accountData.account_No)
            if (updateRecord?.data?.statusCode === 200) {
                toast.success("Contact data updated successfully !", {
                    position: "top-right",
                })
                reset({ emailID: '', contactNumber: '', contactName: '' });
            }
        }
        catch {
            toast.warning("An Error Occured!", {
                position: "top-center",
            })
        }
    }


    return (
        <>
            <Row className="mb-4">
                <Col sm={12}>
                    <fieldset className="pb-2">
                        <div className="d-flex justify-content-end">
                            <div className="section_action_btn">
                                <>
                                    {contactDetail?.contactID ?
                                        <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(Editcontactitem)}>Update record</button></div>
                                        :
                                        <>
                                            {permission.find(event => event.moduleId === 39)?.permissionType?.indexOf('Add') !== -1 ?
                                                <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(addNewRecords)}>Add new record</button></div>
                                                :
                                                <div className="add_btn disable"><button type="button" className="btn">Add new record</button></div>}
                                        </>
                                    }
                                </>
                            </div>
                        </div>
                        <>
                            <Row className="mt-2 mb-3">
                                <Col sm={4}>
                                    <div className="form-floating">
                                        <Form.Control type="email" placeholder="Email" name="emailID" autoComplete="off"
                                            onChange={onChange}
                                            {...register('emailID', {
                                                required: 'Field is required', pattern: {
                                                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                                    message: "Invalid email address"
                                                }
                                            })} />
                                        <label htmlFor="contact">Email ID</label>
                                    </div>
                                    {errors.emailID?.message && (<p className="error-message">{" * " + errors.emailID?.message}</p>)}
                                </Col>
                                <Col sm={4}>
                                    <div className="form-floating">
                                        <Form.Control type="text" placeholder="Contact Name" name="contactName" autoComplete="off"
                                            onChange={onChange}
                                            {...register('contactName', {
                                                required: 'Field is required',
                                                pattern: { value: /^([a-zA-Z]+|[a-zA-Z]+\s{1}[a-zA-Z]{1,}|[a-zA-Z]+\s{1}[a-zA-Z]{3,}\s{1}[a-zA-Z]{1,})$/g, message: "Please enter a valid name" }
                                            })} />
                                        <label htmlFor="contact">Contact Name</label>
                                    </div>
                                    {errors.contactName?.message && (<p className="error-message">{" * " + errors.contactName?.message}</p>)}
                                </Col>
                                <Col sm={4}>
                                    <div className="form-floating">
                                        <Controller type="text" placeholder="ContactNumber"
                                            name="contactNumber"
                                            control={control}
                                            render={({ field: { onChange, value } }) => (
                                                <InputMask mask="(999) - 999 9999" value={value || ""} onChange={onChange}>
                                                    {(inputProps) => (
                                                        <input
                                                            {...inputProps}
                                                            type="tel"
                                                            className="form-control"
                                                            placeholder="Contact number"
                                                            name="contactNumber"
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                            //  value={multiContactNumber} 
                                            //  onChange={e=> {handleInput(e); contactListhandler(e);}} 
                                            {...register("contactNumber", { required: 'Field is required', minLength: { value: 4, message: 'Please enter 10 digit contact number' } })} />
                                        <label htmlFor="contact">Contact Number</label>
                                    </div>
                                    {errors.ContactNumber?.message && (<p className="error-message">{" * " + errors.ContactNumber?.message}</p>)}
                                </Col>
                            </Row>
                            <Row>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>EmailID</th>
                                            <th>Contact Name</th>
                                            <th>Contact Number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {singleAccount?.map((data) => (
                                            <>
                                                {data?.mLDetailsList?.length > 0 ? (
                                                    <>
                                                        {data?.mLDetailsList?.map((c, index) =>
                                                            <>
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className="action_btn">
                                                                            <>
                                                                                {permission.find(event => event.moduleId === 39)?.permissionType?.indexOf('Update') !== -1 ?
                                                                                    <span className="edit_btn" onClick={() => Editcontactlist(c.contactID)}><Edit /></span>
                                                                                    : <span className="edit_btn disable"><Edit /></span>}
                                                                            </>
                                                                            <>
                                                                                {permission.find(event => event.moduleId === 39)?.permissionType?.indexOf('Delete') !== -1 ?
                                                                                    <span className="del_btn" onClick={() => { handleOpen(c.contactID) }}><Delete /></span>
                                                                                    : <span className="del_btn disable"><Delete /></span>}
                                                                            </>
                                                                            <div>
                                                                                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                                                                    <DialogTitle id="alert-dialog-title">
                                                                                        Delete Confirmation
                                                                                    </DialogTitle>
                                                                                    <DialogContent>
                                                                                        <DialogContentText id="alert-dialog-description">
                                                                                            Are you sure you want to delete this row?
                                                                                        </DialogContentText>
                                                                                    </DialogContent>
                                                                                    <DialogActions>
                                                                                        <Button className="btn-secondary" onClick={handleClose}>Cancel</Button>
                                                                                        <Button className="btn-primary" onClick={() => { deleteData(c.contactID); handleClose(); }} autoFocus> Confirm</Button>
                                                                                    </DialogActions>
                                                                                </Dialog>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>{c.emailID}</td>
                                                                    <td>{c.contactName}</td>
                                                                    <td>{c.contactNumber}</td>
                                                                </tr>
                                                            </>
                                                        )}
                                                    </>
                                                ) : (
                                                    <p>No data found </p>
                                                )}
                                            </>
                                        ))}
                                    </tbody>
                                </Table>
                            </Row>
                        </>
                    </fieldset>
                </Col>
            </Row>
        </>
    )
}
export default EditContact