import { apiSlice } from "../../app/services/authServices";


export const accountInfoApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAccountInfo: builder.query({
            query: (account_No) => ({
                url: `/Order/GetOrderAccountInformation?AccountNo=${account_No}`,
                method: 'GET',
            }),
        }),
    })
})
export const { useLazyGetAccountInfoQuery, useGetAccountInfoQuery  } = apiSlice;
