import { Col, Container, Row, Button } from "react-bootstrap";
import Wrapper from "../../Wrapper";
import EmployeeList from "./EmployeeList";
import AddEmployee from "./AddEmployee";
import { AddCircle } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import { toggleShow } from "../../../features/admin/employee/employeeSlice";
import { useLazyGetEmployeeDetailsQuery } from "../../../features/admin/employee/employeeSlice";
import { useState, useEffect } from "react";




const Employee = () => {
    const [getEmployeeDetails, Results, isLoading] = useLazyGetEmployeeDetailsQuery()
    const employeeDetails = Results?.data?.data 
    const [employeeData, setEmployeeData] = useState(employeeDetails)
    const dispatch = useDispatch()
    const show = useSelector((state) => state.employee.show)
    const toggleHandler = () => { dispatch(toggleShow()) }

    useEffect(() => {
        setEmployeeData(employeeDetails)
    }, [employeeDetails])

    return (
        <>
            <Wrapper>
                <div className="employee">
                    <Container fluid>
                        <div className="role-assignment">
                            <div className="role-assignment-name">
                                <h5>List of Employee</h5>
                            </div>
                            <div className="add-role-button d-flex">
                                <span ><Button variant="outline-primary" onClick={toggleHandler}> <AddCircle /> </Button></span>
                            </div>
                        </div>
                        <Row>
                            <Col sm={12}>
                                {show ?
                                    <AddEmployee  employeeData={employeeData} getEmployeeDetails={getEmployeeDetails} isLoading={isLoading} setEmployeeData={setEmployeeData} />
                                    : null}
                                <EmployeeList employeeData={employeeData} getEmployeeDetails={getEmployeeDetails} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Wrapper>
        </>
    );
}
export default Employee;