import { AddCircle, Delete, Visibility, Edit } from '@material-ui/icons';
import React, { useRef, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { Image } from "@material-ui/icons";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DisableEditClick from '../RoleManagement/UserRole/DisableEditClick';
import { useAddFieldDataMutation, useGetAllFieldsQuery, useVehicleSubmitDatafieldMutation } from '../../../features/vehicle/AddFieldVehicleSlice';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLazyGetAllFieldsForVehicleTypeQuery, useGetVehicleTypeListQuery, useUpdateVehicleTypeWithFieldsMutation,useDeleteVehicleMutation } from '../../../features/vehicle/GetVehicleFieldSlice';
import Spinner from '../../Spinner';
import { useCustomData } from '../../Sidebar/Permission';
import { updateData } from '@syncfusion/ej2/grids';

const AddVehicleType = () => {
    const [permission] = useCustomData();
    const [open, setOpen] = useState(false);
    const [editField, setEditField] = useState('')
    const [inputText, setInputText] = useState('');
    const [list, setList] = useState([]);
    const [file, setFile] = useState();
    const [disable, setDisable] = useState(false);
    const [checkedValue, setCheckedValue] = useState([]);
    const [inputFieldData, setInputFieldData] = useState([]);
    const [checkedData, setCheckedData] = useState({})
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [newCheck, setNewCheck] = useState({})
    const handleClickOpen = () => { setOpen(true); };
    //const handleClose = () => { setOpen(false); };
    const { register, setValue, handleSubmit, formState: { errors }, reset, } = useForm();
    const [addFieldData, result] = useAddFieldDataMutation();
    const { data: fieldsData, refetch: getData } = useGetAllFieldsQuery();
    const [vehicleSubmitDatafield, submitResult] = useVehicleSubmitDatafieldMutation();
    const { data: vehicleList, isLoading, refetch } = useGetVehicleTypeListQuery({ refetchOnMountOrArgChange: true });
    const tableList = vehicleList?.data
    const [setRowId, rowDatas] = useLazyGetAllFieldsForVehicleTypeQuery()
    const rowId = rowDatas?.data?.data?.vehicleTypeId
    const isComponentMounted = useRef(true)
    const [updateVehicleTypeWithFields] = useUpdateVehicleTypeWithFieldsMutation();
    const [deleteId, setDeleteId] = useState()
    const [ deleteVehicle ] = useDeleteVehicleMutation()

    useEffect(() => {
        setLoading(true)
        setInputFieldData(fieldsData?.data)
        setLoading(false)
    }, [fieldsData, list])

    const addList = async (inputText) => {
        if (!inputText) {
            return
        }
        setList([...list, inputText])
        setLoading(true)
        let newdata = {
            "fieldName": inputText
        }
        await addFieldData(newdata)
        setLoading(false)
        getData()
    }
    // const checkHandler = (e, listItem) => {
    //     if (e.target.checked) {
    //         setCheckedData((p) => ({ ...p, [e.target.name]: true }))
    //         setCheckedValue((p) => ([...p, { fieldId: listItem?.fieldId }]))
    //     }
    //     else {
    //         setCheckedData((p) => ({ ...p, [e.target.name]: false }))
    //         let filterData = checkedValue.filter((data) => data?.fieldId != listItem?.fieldId);
    //         setCheckedValue(filterData)
    //     }
    // }

    const checkHandler = (e, listItem) => {
        let updatedCheckedData = { ...checkedData }; 
        let updatedCheckedValue = [...checkedValue];
    
        if (e.target.checked) {
            // Set the item as checked
            updatedCheckedData[e.target.name] = true;
    
            // Only add unique field IDs
            if (!updatedCheckedValue.some((item) => item.fieldId === listItem?.fieldId)) {
                updatedCheckedValue.push({ fieldId: listItem?.fieldId });
            }
        } else {
            // Set the item as unchecked
            updatedCheckedData[e.target.name] = false;
    
            // Remove the unchecked field from the list
            updatedCheckedValue = updatedCheckedValue.filter(
                (data) => data?.fieldId !== listItem?.fieldId
            );
        }
    
        // Update both state variables
        setCheckedData(updatedCheckedData);
        setCheckedValue(updatedCheckedValue);
    };
    
    const allowedTypes = ["image/jpeg", "image/png"];
    function handleChange(e) {
        try {
            const img = (e.target.files[0]);
            if (img.size > 10e5) {
                toast.info("Please upload a file smaller than 1 MB", {
                    position: "top-center",
                })
                // setFile(null);
            }
            else {

                setFile(img);
                return;
            }
        }
        catch {
            toast.warning("An Error Occured!", {
                position: "top-center",
            })
        }

    }
    const handleClose = async () => {
        try {
            setOpen(false);
            //console.log(deleteId)
            const deleteData = await deleteVehicle(deleteId)
            //console.log(deleteData)
            if (deleteData?.data?.statusCode === 200) {
                toast.success("Vehicle deleted successfully!", {
                    position: "top-center",
                })
            }
            refetch()
        }
        catch {
            toast.warning("An Error Occured!", {
                position: "top-center",
            })
        }
    };
    // useEffect(() => {
    //     setCheckedData({})
    //     setValue("vehicle_type", rowDatas?.data?.data?.vehicleTypeDescription)
    //     rowDatas?.data?.data?.imagePath && setFile(rowDatas?.data?.data?.imagePath)
    //     rowDatas?.data?.data?.fieldDetailsDTO.length > 0 && rowDatas?.data?.data?.fieldDetailsDTO?.map((d) => {
    //         if (d?.fieldName === null) return;
    //         setNewCheck((p) => ({ ...p, [d?.fieldName]: d?.fieldId }))
    //         setCheckedData((p) => ({ ...p, [d?.fieldName]: true }))
    //     })
    // }, [rowDatas]);

    useEffect(() => {
        if (rowDatas?.data?.data) {
            // Reset the state before setting new values
            setCheckedData({});
            setCheckedValue([]);
            setNewCheck({});
    
            // Set the vehicle type description and image path
            setValue("vehicle_type", rowDatas?.data?.data?.vehicleTypeDescription);
            rowDatas?.data?.data?.imagePath && setFile(rowDatas?.data?.data?.imagePath);
    
            // Map through the field details and set the checkbox states
            if (rowDatas?.data?.data?.fieldDetailsDTO?.length > 0) {
                const newCheckedData = {};
                const newCheckedValues = [];
                const newCheckMap = {};
    
                rowDatas?.data?.data?.fieldDetailsDTO.forEach((d) => {
                    if (d?.fieldName !== null) {
                        newCheckedData[d?.fieldName] = true;
                        newCheckedValues.push({ fieldId: d?.fieldId });
                        newCheckMap[d?.fieldName] = d?.fieldId;
                    }
                });
    
                setCheckedData(newCheckedData);
                setCheckedValue(newCheckedValues);
                setNewCheck(newCheckMap);
            }
        }
    }, [rowDatas, setValue]);

    useEffect(() => {
        let arr = []
        for (const id of Object.values(newCheck)) {
            arr.push({ "fieldId": id })
        }
        setCheckedValue(arr)
    }, [newCheck, rowDatas])

    const onSubmit = async (data) => {
        try {
            setLoading(true)
            let arr2 = []
            for (let key1 in checkedData) {
                if (checkedData[key1]) {
                    arr2.push({ [key1]: newCheck[key1] })
                }
            }
            const newData = {
                "vehicleTypeDescription": data?.vehicle_type,
                "ImagePathDescription": file,
                "fieldDetails": checkedValue
            }
            let formdata = new FormData();
            formdata.append("vehicleTypeDescription", data?.vehicle_type);
            formdata.append("ImagePathDescription", file);
            formdata.append("fieldDetails", JSON.stringify(checkedValue));
            const SubmitVehicle = await vehicleSubmitDatafield(formdata);
            setLoading(false)
            setFile(null)
            refetch()
            setCheckedData({})
            setCheckedValue([]);
            reset({ vehicle_type: "", checkedValue: "" });
            if (SubmitVehicle?.data?.statusCode === 200) {
                toast.success("Vehicle added successfully!", {
                    position: "top-center",
                })
            }
            else if (SubmitVehicle?.data?.statusCode === 400) {
                toast.error(SubmitVehicle?.data?.data, {
                    position: "top-center",
                })
            }
            
        }
        catch {
            toast.warning("An Error Occured!", {
                position: "top-center",
            })
        }

    }

    const updateVehicle = async (data) => {
        try {
            setLoading(true)
            let arr2 = []
            for (let key1 in checkedData) {
                if (key1 == null) return;
                if (checkedData[key1] != null) {
                    arr2.push({ fieldId: newCheck[key1] })
                }
            }
            const updateData = {
                "vehicleTypeId": rowId,
                "ImagePathDescription": file,
                "vehicleTypeDescription": data?.vehicle_type,
                "fieldDetails": checkedValue
            }
            const fieldData = checkedValue.filter((data) => data?.fieldId)
            let formdata1 = new FormData();
            formdata1.append("vehicleTypeDescription", data?.vehicle_type);
            formdata1.append("vehicleTypeId", rowId);
            formdata1.append("ImagePathDescription", file);
            formdata1.append("fieldDetails", JSON.stringify(fieldData));
            const updatedData = await updateVehicleTypeWithFields(formdata1)
            setFile(null)
            reset({ vehicle_type: "", checkedValue: "" });
            setCheckedData({})
            setNewCheck({})
            setShow(false)
            refetch()
            setLoading(false)
            if (updatedData?.data?.statusCode === 200) {
                toast.success("Vehicle updated Succesfully!", {
                    position: "top-center",
                })
                setDisable(false)
            }
        }
        catch {
            toast.warning("An Error Occured!", {
                position: "top-center",
            })
        }
    }
    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }
    if (!tableList || isLoading) return <><Spinner /></>;

    const columns = [
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 140,
            disableClickEventBubbling: true,
            renderCell: (data) => {
                const onClick = (data) => {
                    //e.stopPropagation();
                    //e.stopPropagation();
                    setCheckedValue([]); // Clear the previously checked values
                    setCheckedData({});
                    setRowId(data?.id)
                    setEditField(data?.row?.Vehicle)
                    refetch()
                    setShow(true)
                    setDisable(true)
                };
                const onRemove = (data) => {
                    setDeleteId(data?.id)
                    setOpen(true);
                };

                return (
                    <>
                        <>
                            <div className="action_btn">
                            
                                {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Update') !== -1 ?
                                    <span className="edit_btn" onClick={() => onClick(data)}><Edit /></span>
                                    : <span className="edit_btn disable"><Edit /></span>
                                } 

                                {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Delete') !== -1 ?
                                        <span className="del_btn" onClick={() => onRemove(data)}><Delete /></span> :
                                        <span className="del_btn disable"><Delete /></span>}
                            </div>
                        </>
                        <>
                        <div>
                                <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                    <DialogTitle id="alert-dialog-title">
                                        Delete Confirmation
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Are you sure you want to delete this row?
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button className="btn-secondary" onClick={() => { setOpen(false); }}>Cancel</Button>
                                        <Button className="btn-primary" onClick={() => { handleClose(); }} > Confirm</Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </>
                    </>
                )

            },
        },
        { field: "Vehicle", headerName: "Vehicle", width: 250 },
    ];

    const rows = tableList?.map((data) => {
        return {
            id: data?.vehicleTypeId,
            Vehicle: data?.vehicleTypeDescription,
        }
    })

    return (
        <>
            {loading && <Spinner />}
            <Row>
                <Col>
                    <div className="orderform mt-2">
                        <Row>
                            <Col sm={8}>
                                <Row>
                                    <Col sm={2}>
                                        <div className="image-upload-section inventory">
                                            <div className="employee-image-upload img-vehicle">{file ? (
                                                <img
                                                    key={file}
                                                    src={typeof file === "string" ? file : URL?.createObjectURL(file)}
                                                />
                                            ) : (
                                                <Image />
                                            )}</div>
                                            <div className="upload-btn"> <p >Upload Image</p></div>
                                            <input type="file" onChange={handleChange} className="image-upload-btn" accept=".jpg,.png" />
                                        </div>
                                    </Col>

                                    <Col sm={3}>
                                        <div className="form-floating">
                                            <Form.Control type="text" autoComplete="off" defaultValue={editField} disabled={disable} placeholder="Add a Vehicle Type" name="vehicle_type" {...register('vehicle_type', { required: 'Field is required', pattern: /^[^-\s][a-zA-Z0-9_\s-]+$/ })} />
                                            <label htmlFor="Item">Add a Vehicle Type</label>
                                            <p className="error-message">{errors?.vehicle_type?.message}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={2}></Col>
                            <Col sm={2}>
                                {!show ?
                                    <>
                                        {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Add') !== -1 ?
                                            <Button onClick={handleSubmit(onSubmit)} className='save-btn'> Submit</Button>
                                            : <Button className='save-btn disable'> Submit</Button>

                                        }
                                    </> :

                                    <>
                                        {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Update') !== -1 ?
                                            <Button onClick={handleSubmit(updateVehicle)} className='save-btn'> Update</Button>
                                            : <Button className='save-btn disable'> Update</Button>

                                        }
                                    </>


                                }
                            </Col>
                        </Row>
                        <fieldset className="mt-4">
                            <label className="label-heading">Add Field You Would Like To Add For The Item Descriptions</label>
                            {/* Add certifcation sections */}
                            <Row>
                                <Col sm={9}></Col>
                                <Col sm={3}>
                                    <Row>
                                        <Col sm={8}>
                                            <div className="form-floating">
                                                <Form.Control type="text" autoComplete="off" placeholder="Add more field" name="Addmorefield"
                                                    onChange={(e) => { setInputText(e.target.value) }} value={inputText} />
                                                <label htmlFor="Add more field">Add more field</label>
                                            </div>
                                        </Col>
                                        <Col sm={4}>
                                            {permission.find(event => event.moduleId === 33)?.permissionType?.indexOf('Add') !== -1 ?
                                                <Button className="btn btn-secondary" onClick={() => { addList(inputText); setInputText(""); }} ><AddCircle /> Add </Button>
                                                :
                                                <Button className="btn btn-secondary disable"><AddCircle /> Add </Button>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {/* Checkbox section */}
                            <div className="mt-4 discriptionBox">
                                {inputFieldData && inputFieldData?.map((listItem, id) => {
                                    return (
                                        <div key={id} className="form-check form-check-inline boxHoverNew">
                                            <div className="form-group">
                                                <input className="form-check-input" key={checkedData[listItem?.fieldName]} checked={checkedData[listItem?.fieldName]} type="checkbox" value={listItem?.fieldId} name={listItem?.fieldName} onClick={(e) => checkHandler(e, listItem)} />
                                                <label className="form-check-label" htmlFor="inlineCheckbox1"> {listItem?.fieldName}</label>
                                            </div>
                                        </div>
                                    )
                                }
                                )}
                            </div>
                        </fieldset>
                    </div>
                </Col>
                <Row>
                    {tableList.length > 0 ? (
                        <div className="user-role-table manageInventoryTable" style={{ height: "730px" }}>
                            <h6>Add Vehicle Type</h6>

                            <DataGrid rows={rows} columns={columns} pageSize={10} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }}  />
                        </div>
                    ) : (
                        <div className="user-role-table">
                            <h6>List of Employee</h6>
                            <p style={{ textAlign: "center", marginTop: "20px", }}>No data found </p>
                        </div>
                    )}
                </Row>

            </Row>
        </>
    )
}

export default AddVehicleType;
