import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { GridToolbar } from '@mui/x-data-grid-premium';
import ViewUserAssignedRole from "./ViewUserAssignedRole";
import { useGetRegisterUserQuery, useDeleteRegisterUserMutation, useLazyGetRegisteredUserPermissionQuery, useChangeUserRoleMutation } from "../../../../features/admin/role/createRoleSlice";
import Spinner from "../../../Spinner"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DisableEditClick from "./DisableEditClick";
import { DeleteUserRole } from "./DeleteUserRole";
import { ActivateAccount } from "./DeleteUserRole";



export default function AssignedUserRole() {
  const { data, isLoading, refetch } = useGetRegisterUserQuery()
  const userRegister = data?.data
  const [deleteRegisterUser,] = useDeleteRegisterUserMutation();
  const [changeUserRole] = useChangeUserRoleMutation();
  const [getUsers, results] = useLazyGetRegisteredUserPermissionQuery()
  const accessUserName = results?.originalArgs
  const userRole = results?.data?.data?.userRole
  const userData = results?.data?.data?.permissionList
  const updateUser = results?.data?.data
  if (!data || isLoading) return <><Spinner /></>;

  const columns = [
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 120,
      disableClickEventBubbling: true,
      renderCell: (data) => {

        const onClick = (e) => {
          getUsers(data.row.email)
        }
        const onRemove = async() => {
          await deleteRegisterUser({ username: data.row.email, status: data.row.isActive });
          if (data?.row?.isActive === false) {
            setTimeout(function () {
              toast.success("Activate the account successfully !", {
                position: "top-center",
              })
            });
          } else if ((data?.row?.isActive === true)) {
            setTimeout(function () {
              toast.info("Deactivate the account !", {
                position: "top-center",
              })
            });
          }
        };

        const onDisableClick = () => {
          setTimeout(function () {
            toast.info("Please activate the account to show the details !", {
              position: "top-center",
            })
          }, 1000);
        }

        const onChangeUserRole = async(e) =>{
          await changeUserRole({ username: data.row.email, newRole:e.role})
        }

        return (
          <>
            <div className="action_btn">
              {data.row.isActive !== false ?
                <span className="edit_btn">
                  <ViewUserAssignedRole onEditClick={onClick} userData={userData} accessUserName={accessUserName} userRole={userRole} onChangeUserRole={onChangeUserRole} updateUser={updateUser}/>
                  </span> :
                <span className="edit_btn"><DisableEditClick onDisableClick={onDisableClick} /> </span>}
                 {data.row.isActive !== false ?
              <span className="del_btn"><DeleteUserRole onDeleteClick={onRemove} refetch={refetch} /></span>
              : <span className="del_btn"><ActivateAccount onDeleteClick={onRemove} refetch={refetch} /></span>}
            </div>
          </>
        )
      },
    },
    { field: "email", headerName: "Email", width: 200 },
    { field: "userRole", headerName: "Role", width: 200 },
    { field: "isActive", headerName: "Active", width: 160 ,
    cellClassName: (params) => (
      params.row.isActive === true ? 'active' : 'inactive'
  )
},
    { field: "createdBy", headerName: "Created By", width: 240 },
    { field: "createdDate", headerName: "Created Date", width: 180 },
    { field: "modifiedBy", headerName: "Modified By", width: 240 },
    { field: "modifiedDate", headerName: "Modified Date", width: 180 },

  ];
  // const rows =[]
  const rows = userRegister?.map((data) => {
    return {
      id: data?.userID,
      email: data?.email,
      userRole: data?.userRole,
      isActive: data?.isActive,
      createdBy: data?.createdBy,
      createdDate: new Date(data?.createdDate).toLocaleDateString(),
      modifiedBy: data?.modifiedBy,
      modifiedDate: new Date(data?.modifiedDate).toLocaleDateString(),
    }
  })


  return (
    <>
      <div className="user-role-table" id="data-table">
        <h6>List of assigned user role</h6>
        <DataGrid rows={rows} columns={columns} pageSize={20} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} componentsProps={{
          toolbar: { showQuickFilter: true, }, }} />
      </div>
    </>
  );
}
