import React from 'react'
import { Form } from './Form';
import styled from 'styled-components';
import { FiPlus, FiMinus } from 'react-icons/fi';
import { useState } from 'react';
import Orderbutton from './Orderbutton';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import { ListItem } from '@mui/material';
import { RadioButton } from './Radiobutton';
import useOrder from '../../../context/OrderContext';
import Draggable from 'react-draggable';

const Wrap = styled.div`
background: #fff;
color: #2d3e50;
display:flex;
justify-content:space-between;
align-item:center;
width:100%;
text-alighn:center;
cursor: pointer;
margin-bottom:10px;
    padding: 16px 0px 16px 40px;
    border-radius: 6px;
    box-shadow: 1px 1px 10px #00000020;
`;


const CustomToggle = ({ children, eventKey, handleClick }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    handleClick();
  });

  return (
    <ListItem
      onClick={decoratedOnClick}
      style={{ cursor: "pointer", paddingBottom: "0", paddingTop: "0", paddingLeft: "0" }}
    >
      {children}
    </ListItem>
  );
};


const OrderForm = () => {
  const {orderForm, radioChangeHandler,isDisabled} = useOrder();
  const [activeKey, setActiveKey] = useState(0);

  return (
    // <Draggable>
    <div className='orderform'>
      <h2>Order Entry Form</h2>
      <div className="radio-btn-container" style={{ display: "flex" }}>
        <RadioButton
          changed={radioChangeHandler}
          id="1"
          isSelected={orderForm === "Online"}
          label="Online"
          value="Online"
        />
        <RadioButton
          changed={radioChangeHandler}
          id="2"
          isSelected={orderForm === "Batch"}
          label="Batch"
          value="Batch"
        />
        <RadioButton
          changed={radioChangeHandler}
          id="3"
          isSelected={orderForm === "Contract"}
          label="Contract"
          value="Contract"
        />
        {/* <RadioButton
          changed={radioChangeHandler}
          id="4"
          isSelected={orderForm === "Quote"}
          label="Quote"
          value="Quote"
        /> */}
      </div>
      {orderForm === "Online" && (
        <Accordion defaultActiveKey={0} activeKey={activeKey} disabled={true}>
          {Form.map((item, index) => (
            <>
              <Wrap key={index}>
                <CustomToggle
                  as={Card.Header}
                  eventKey={index}
                  handleClick={() => {
                    if (activeKey === index) {
                      setActiveKey(null);
                    } else {
                      setActiveKey(index);
                    }
                  }}
                >
                  <span className='accordian-plus'>{activeKey === index ? <FiMinus /> : <FiPlus />}</span>
                  <span> {item.header} </span>
                </CustomToggle>
              </Wrap>
              <Accordion.Collapse eventKey={index} style={{ background: "#244da133", marginTop: "-10px" }}>
                <Card.Body>{item.content}</Card.Body>
              </Accordion.Collapse>
            </>
          ))}
        </Accordion>
      )}
      {orderForm === "Batch" && (
        <Accordion activeKey={activeKey}>

          {Form.map((item, index) => (
            <>
              <Wrap key={index}>
                <CustomToggle
                  as={Card.Header}
                  eventKey={index}
                  handleClick={() => {
                    if (activeKey === index) {
                      setActiveKey(null);
                    } else {
                      setActiveKey(index);
                    }
                  }}
                >
                  <span className='accordian-plus'>{activeKey === index ? <FiMinus /> : <FiPlus />}</span>
                  <span> {item.header} </span>
                </CustomToggle>
              </Wrap>
              <Accordion.Collapse eventKey={index} style={{ background: "#244da133", marginTop: "-10px" }}>
                <Card.Body>{item.content}</Card.Body>
              </Accordion.Collapse>
            </>
          ))}
        </Accordion>
      )}
      {orderForm === "Contract" && (
        <Accordion activeKey={activeKey} disabled={isDisabled}>

          {Form.map((item, index) => (
            <>
              <Wrap key={index}>
                <CustomToggle
                  as={Card.Header}
                  eventKey={index}
                  handleClick={() => {
                    if (activeKey === index) {
                      setActiveKey(null);
                    } else {
                      setActiveKey(index);
                    }
                  }}
                >
                  <span className='accordian-plus'>{activeKey === index ? <FiMinus /> : <FiPlus />}</span>
                  <span> {item.header} </span>
                </CustomToggle>
              </Wrap>
              <Accordion.Collapse eventKey={index} style={{ background: "#244da133", marginTop: "-10px" }}>
                <Card.Body>{item.content}</Card.Body>
              </Accordion.Collapse>
            </>
          ))}
        </Accordion>
      )}
      {orderForm === "Quote" && (
        <Accordion activeKey={activeKey} disabled={isDisabled}>

          {Form.map((item, index) => (
            <>
              <Wrap key={index}>
                <CustomToggle
                  as={Card.Header}
                  eventKey={index}
                  handleClick={() => {
                    if (activeKey === index) {
                      setActiveKey(null);
                    } else {
                      setActiveKey(index);
                    }
                  }}
                >
                  <span className='accordian-plus'>{activeKey === index ? <FiMinus /> : <FiPlus />}</span>
                  <span> {item.header} </span>
                </CustomToggle>
              </Wrap>
              <Accordion.Collapse eventKey={index} style={{ background: "#244da133", marginTop: "-10px" }}>
                <Card.Body>{item.content}</Card.Body>
              </Accordion.Collapse>
            </>
          ))}
        </Accordion>
      )}
      <Orderbutton></Orderbutton>
    </div>
    // </Draggable>
  );
}
export default OrderForm;