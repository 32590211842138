import React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import useOrder from "../../../../../context/OrderContext";
import { useForm, Controller } from 'react-hook-form';
import {useGetOrderNotificationQuery, useUpdateDocStatusNotificationMutation  } from "../../../../../features/order/orderSlice";

const Document = ({ selectedOrder, orderNotifData }) => {    
    const [labelAccountValue, setLabelAccountValue] = useState(false)
    const [labelConsignorValue, setLabelConsignorValue] = useState(false)
    const [labelConsigneeValue, setLabelConsigneeValue] = useState(false)
    const [tagsDocSendToEmail, setTagsDocSendToEmail] = useState([]);

    
    const [updateDocStatusNotification] = useUpdateDocStatusNotificationMutation();
    const { data: selectedOrderDetails, refetch } = useGetOrderNotificationQuery(selectedOrder?.orderNumber, {
        skip: !selectedOrder?.orderNumber, // Skip the query if the orderNumber is not available
    });

    
    const [BOLValue, setBOLValue] = useState(true);
    const [labelValue, setLabelValue] = useState(true);

    const [isEdit, setIsEdit] = useState(false);
    
    useEffect(() => {
        if (orderNotifData) {
            setLabelAccountValue(orderNotifData.data.docSendToHeadAccount);
            setLabelConsignorValue(orderNotifData.data.docSendToConsignor);
            setLabelConsigneeValue(orderNotifData.data.docSendToConsignee);
            setTagsDocSendToEmail(orderNotifData.data.docSendToEmail || []);

            setBOLValue(orderNotifData.data.isBOL);
            setLabelValue(orderNotifData.data.isLabel);
        } 
    }, [orderNotifData])

    const consignor = selectedOrder?.pickupContactEmail;
    const consignee = selectedOrder?.dropoffContactEmail;
    const headAccount = selectedOrder?.emailAddress;

    const handleChange = event => {
        if (isEdit) {
            if (event.target.name === "labelheadaccount") {
                if (event.target.checked) {
                    setLabelAccountValue(event.target.value);    
                }
                else {
                    setLabelAccountValue(false)  
                }
            }
            else if (event.target.name === "labelconsignor") {
                if (event.target.checked) {
                    setLabelConsignorValue(event.target.value);  
                }
                else {
                    setLabelConsignorValue(false)
                }
            }
            else if (event.target.name === "labelconsignee") {
                if (event.target.checked) {
                    setLabelConsigneeValue(event.target.value);    
                }
                else {
                    setLabelConsigneeValue(false)
                }
            }
        }
        
    };
    const { register, handleSubmit } = useForm({
        defaultValues: {
            BOLLABEL: ['BOL', 'LABEL'],  // Set the default selected options here
        }
    });
    const radioHandlert = (statust) => {
        if (isEdit) {
            if (statust === 2) {
                setBOLValue(!BOLValue);
            } else if (statust === 3) {
                setLabelValue(!labelValue);
            }
        }
        
    };

    const SubmitHandler = async () => {
        const data = {
            orderNumber: selectedOrder?.orderNumber,
            isBOL: BOLValue,
            isLabel: labelValue,
            docSendToHeadAccount: labelAccountValue ? true : false,
            docSendToConsignor: labelConsignorValue ? true : false,
            docSendToConsignee: labelConsigneeValue ? true : false,
            docSendToEmail: tagsDocSendToEmail,
        }
        console.log(data);
        const result = await updateDocStatusNotification(data);
        if (result?.data?.statusCode === 200) {
            await refetch();
        }        
        setIsEdit(!isEdit);
    }
    const addTags = (event, type) => {
        if (event.target.value !== "") {
            switch (type) {
                case 'docSendToEmail':
                    setTagsDocSendToEmail(event.target.value);
                    break;
                default:
                    break;
            }
            event.target.value = "";
        }
    };

    const handleInputChange = (event) => {
        setTagsDocSendToEmail(event.target.value);
    };

    return (
        <>
        <Row className="mb-4">
            <Col sm={8}>
            </Col>
            <Col sm={3}>
            { isEdit?          
                <Button variant="primary" className={"save-btn"} onClick={SubmitHandler}>{"Save"}</Button>                
                :
                <Button variant="primary" className={"save-btn"} onClick={() => setIsEdit(!isEdit)}>{"Edit"}</Button>
            }
            </Col>
        </Row>
            <Row>
                <Col sm={12}>
                    <div className="email-notify">
                        <div className="email-heading">
                            <h6>Document</h6>
                        </div>
                        <div className="fieldset-inner-email">
                            <div className="floating-checboxemail">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="BOL" name="BOLLABEL" {...register("BOLLABEL", { required: 'Field is required' })} onChange={e => radioHandlert(2)} checked={BOLValue}/>
                                    <label className="form-check-label" htmlFor="inlineCheckbox2">BOL</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="LABEL" name="BOLLABEL" {...register("BOLLABEL", { required: 'Field is required' })} onClick={() => radioHandlert(3)} checked={labelValue}/>
                                    <label className="form-check-label" htmlFor="inlineCheckbox2">Label</label>
                                </div>
                            </div>
                        </div>
                        <div className="checkbox-section">
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Send to head account" value={headAccount} onChange={handleChange} checked={labelAccountValue} name="labelheadaccount" />
                                {labelAccountValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({selectedOrder?.emailAddress})</label> : ""}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Send to consignor (Shipper)" name="labelconsignor" value={consignor} checked={labelConsignorValue} onChange={handleChange} />
                                {labelConsignorValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({selectedOrder?.pickupContactEmail})</label> : ""}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Send to consignee (Receiver)" name="labelconsignee" value={consignee} checked={labelConsigneeValue} onChange={handleChange} />
                                {labelConsigneeValue ? <label htmlFor="element" style={{ marginLeft: "26px", fontWeight: "500" }}>({selectedOrder?.dropoffContactEmail})</label> : ""}
                            </Form.Group>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
            <p style={{ color: 'red' }}><em>Please enter individual email addresses, separated by a semicolon (;) with no spaces. 
            Example: abc@z.com;xyz@q.com</em></p>
                <Col sm={12}>
                    <div className="tags-input">
                        <input
                            value={tagsDocSendToEmail}
                            onInput={handleInputChange}
                            placeholder="Enter Email Address"
                            className="email-input"
                            disabled={!isEdit}
                        />
                    </div>
                </Col>
                {/* <Button variant="primary" className="email-btn" type="submit" name="submit" onClick={SubmitHandler}> Submit </Button> */}
            </Row>
        </>
        
    );
}

export default Document;