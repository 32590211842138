import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { calendarPickerClasses } from '@mui/x-date-pickers';
import { Resizable } from 're-resizable';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useGetAccessorialCodesQuery, useLazyGetSelectedOrderDetailsQuery } from '../../../features/Dispatch/DispatchSlice';

const OpenOrder = (props) => {
	const { fetchRegionalOrders, ordersResult } = props;
	const [orders, setOrders] = useState([]);
	const { data: accessorialCodes } = useGetAccessorialCodesQuery();
	const [cols, setCols] = useState([])
	const [getDetails, singleOrderData] = useLazyGetSelectedOrderDetailsQuery();
	
	const divisionIdRef = useRef();
	useEffect(() => {
		divisionIdRef.current = props.divisionId;
	}, [props.divisionId]);
	useEffect(() => {
		const fetchData = async () => {
			if (divisionIdRef.current) {
				try {
					await fetchRegionalOrders(divisionIdRef.current);
				} catch (error) {
					console.error(error);
				}
			} else {
				setOrders([]);
			}

		};

		fetchData();
	}, [fetchRegionalOrders, props.divisionId,singleOrderData]);

	useEffect(() => {
		const intervalId = setInterval(async () => {
			if (divisionIdRef.current) {
				try {
					await fetchRegionalOrders(divisionIdRef.current);
				} catch (error) {
					console.log(error);
				}
			}
		}, 60000); // ping every 60 seconds
		return () => clearInterval(intervalId);
	}, [fetchRegionalOrders]);

	useEffect(() => {
		if (accessorialCodes) {
			const codes = accessorialCodes?.data?.map((code) => {
				return {
					field: code?.accessorialCode,
					headerName: code?.accessorialCode,
					width: 80,
				}
			})
			setCols([...columns, ...codes])
		}
	}, [accessorialCodes])


	useEffect(() => {
		if (ordersResult?.data) {
			setOrders(ordersResult?.data?.data)
		}
	}, [ordersResult])

	const assignDriver = async (cell) => {
		if(cell.field === 'DDrive' || cell.field === "PDriv"){
			const orderNumber = ordersResult?.data?.data?.find((order) => order?.orderId === cell.id)?.orderNumber;
			await props.assignDriverToOrder(cell.field, cell.id, orderNumber)
			await fetchRegionalOrders(props.divisionId);
		}
	}

	const columns = [
		{ field: 'Action', headerName: 'Action', width: 90,
		renderCell: (data) => {
			const onClick = async (e) => {
			  e.stopPropagation();
				const result = await getDetails({divisionId: divisionIdRef.current, orderId: data.row.id})
				if (result.data.statusCode === 200) {
					props.setSelectedOrder(result.data?.data);
				} 
			};
			return (
				<Button onClick={onClick}>Details</Button>
			)
		  }    
		},
		{
			field: 'ReadyTime',
			headerName: 'Ready Date & Time',
			type: calendarPickerClasses,
			width: 200,
			editable: false,
		},
		{
			field: 'BookingTime',
			headerName: 'Booking Date & Time',
			type: calendarPickerClasses,
			width: 220,
			editable: false,
		},
		{
			field: 'St',
			headerName: 'St#',
			//type: 'number',
			width: 80,
			editable: false,
		},
		{
			field: 'StreetName',
			headerName: 'Street Name',
			width: 150,
			editable: false,
		},
		{
			field: 'City',
			headerName: 'City',
			width: 120,
			editable: false,
		},
		{
			field: 'Map',
			headerName: 'Map',
			width: 80,
			editable: false,
		},
		{
			field: 'Vehicle',
			headerName: 'Vehicle',
			width: 150, 
			editable: false,
		},
		{
			field: 'Service',
			headerName: 'Service',
			width: 150,
			editable: false,
		},
		{
			field: 'PDriv',
			headerName: 'P-Driver',
			width: 80,
			editable: false,
		},
		{
			field: 'DDrive',
			headerName: 'D-Driver',
			width: 80,
			editable: false,
		},
		{
			field: 'DSt',
			headerName: 'St#',
			//type: 'number',
			width: 60,
			editable: false,
		},
		{
			field: 'DStreetName',
			headerName: 'Street Name',
			width: 110,
			editable: false,
		},
		{
			field: 'DCity',
			headerName: 'City',
			width: 120,
			editable: false,
		},
		{
			field: 'DMap',
			headerName: 'Map',
			width: 40,
			editable: false,
		},
		{
			field: 'Pcs',
			headerName: 'Pcs',
			width: 40,
			editable: false,
		},
		{
			field: 'Wgt',
			headerName: 'Weight',
			width: 90,
			editable: false,
		},
		{
			field: 'Expirytime',
			headerName: 'Expiry time (min.) ',
			width: 140,
			editable: false,
		},
	
	];

	const rows = orders?.map((order) => {
		return {
			id: order?.orderId,
			Action: 1,
			ReadyTime: order?.readyDate
			? new Date(order?.readyDate).toLocaleString('en-US', { 
				hour: '2-digit', 
				minute: '2-digit', 
				hour12: true, 
				year: 'numeric', 
				month: '2-digit', 
				day: '2-digit' 
			  })
			: '',
			St: order?.pickupStreetNumber,
			StreetName: order?.pickupStreetName,
			City: order?.pickupCity,
			Map: order?.map,
			...order?.accessorialCodesForOrder,
			Vehicle: order?.vehicle,
			Service: order?.service,
			PDrive: order?.pDrive,
			DDrive: order?.dDrive,
			DSt: order?.dropoffStreetNumber,
			DStreetName: order?.dropoffStreetName,
			DCity: order?.dropoffCity,
			DMap: order?.dMap,
			DA: order?.dA,
			TC: order?.tc,
			HV: order?.hv,
			RS: order?.rs,
			DTG: order?.dtg,
			HZ: order?.hz,
			Pcs: order?.pcs,
			Wgt: order?.weight,
			Expirytime: order?.expiryTime,
			BookingTime: order?.orderBookingDate
			? new Date(order?.orderBookingDate).toLocaleString('en-US', { 
				hour: '2-digit', 
				minute: '2-digit', 
				hour12: true, 
				year: 'numeric', 
				month: '2-digit', 
				day: '2-digit' 
			  })
			: '',
		}
	})

	const nowOrders = rows?.filter((order) => {
		const bookingTime = new Date(order?.ReadyTime);
		const currentTime = new Date();
		const timeDifference = bookingTime - currentTime;
		const hoursDifference = timeDifference / (1000 * 60 * 60);
		return hoursDifference <= 4;
	});

	const laterOrders = rows?.filter((order) => {
		const bookingTime = new Date(order?.ReadyTime);
		const currentTime = new Date();
		const timeDifference = bookingTime - currentTime;
		const hoursDifference = timeDifference / (1000 * 60 * 60);
		return hoursDifference > 4;
	});

	return (
		<Resizable defaultSize={{ width: "auto", height: "auto" }}>
			<div className="table-back">
				<div className="table-header-section">
					<div className='table-header'><h5>Open order</h5></div>
				</div>
				<Box sx={{ height: 500, width: '100%' }}>
					<DataGrid
						rows={props.nowLater === 'now' ? nowOrders : laterOrders}
						columns={cols}
						pageSize={10}
						rowsPerPageOptions={[1]}
						components={{ Toolbar: GridToolbar }}
						onCellClick={(cell) => {assignDriver(cell)}}
					/>
				</Box>
			</div>
		</Resizable>
	);
}
export default OpenOrder;