//  Error validation msg


export default function Validatecontract(contractInfo) {
    let errors = {};
    if (!contractInfo.FuelSurchargeMethod) {
      errors.FuelSurchargeMethod = 'Field is required';
    } 
    if (!contractInfo.DistanceType) {
      errors.DistanceType = 'Field is required';
    } 
    if (!contractInfo.Multiplier) {
      errors.Multiplier = 'Field is required';
    } 
    if (!contractInfo.LbsPerLinearFT) {
      errors.LbsPerLinearFT = 'Field is required';
    }
    if (!contractInfo.LbsPerCuFT) {
      errors.LbsPerCuFT = 'Field is required';
    }
    if (!contractInfo.MaxHeightStart) {
      errors.MaxHeightStart = 'Field is required';
    }
    if (!contractInfo.MaxHeightOverride) {
      errors.MaxHeightOverride = 'Field is required';
    }
    if (!contractInfo.LongFreightThreshold) {
      errors.LongFreightThreshold = 'Field is required';
    }
    if (!contractInfo.LongFreightAmount) {
      errors.LongFreightAmount = 'Field is required';
    }
    return errors;
  };