import { Save } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useCustomer from "../../../../../context/CustomerContext";
import { useUpdateContractDataDefaultPricingMutation } from "../../../../../features/contractInfo/contractInfoSlice";
import { useModifyHistoryMutation } from "../../../../../features/customer/history/historySlice";
import { useCustomData } from "../../../../Sidebar/Permission";
import { useLazyGetDefaultPricingRateCardQuery } from "../../../../../features/cwtRateCard/cwtRateCardSlice";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const SaveContractdata = ({ showedithandler }) => {
  const [permission] = useCustomData()
  const [message, setMessage] = useState("Modified the contract info section")
  const [updateContractData] = useUpdateContractDataDefaultPricingMutation()
  const [modifyHistory] = useModifyHistoryMutation()
  const { singleAccount, contractInfo, setContractInfo } = useCustomer();
  const [getDefaultRateCard] = useLazyGetDefaultPricingRateCardQuery();
  const [ratecardaccount, setRatecardaccount] = useState(contractInfo);
  const account = singleAccount && singleAccount[0]

  const Contracthandler = (e) => {
    setRatecardaccount(ratecardaccount => ({ ...ratecardaccount, [e.target.name]: e.target.value }));
    setContractInfo(data => ({ ...data, [e.target.name]: e.target.value }));
  }

  const contractInfoData = {
    account: ratecardaccount?.RateCardId,
    rateCardId: ratecardaccount?.RateCardId,
    fuelSurchargeMethod: ratecardaccount?.FuelSurchargeMethod,
    distanceType: ratecardaccount?.DistanceType,
    multiplier: ratecardaccount?.Multiplier,
    lbsPerLinearFT: Number(ratecardaccount?.LbsPerLinearFT),
    lbsPerCuFT: Number(ratecardaccount?.LbsPerCuFT),
    longFreightThreshold: Number(ratecardaccount?.LongFreightThreshold),
    longFreightAmount: Number(ratecardaccount?.LongFreightAmount),
    maxHeightStart: Number(ratecardaccount?.MaxHeightStart),
    maxHeightOverride: Number(ratecardaccount?.MaxHeightOverride),
    description: ratecardaccount?.Description
  }

  const prevCount = usePrevious(contractInfoData);
  const editContractList = async () => {
    if (prevCount == null || JSON?.stringify(prevCount) === JSON?.stringify(contractInfoData)) {
      setTimeout(function () {
        toast.info("No Changes found on this contract section !", {
          position: "top-right",
        })
      });
    }
    else {
      const updateContractInfo = await updateContractData(contractInfoData)
      if (updateContractInfo.data.statusCode === 200) {
        setTimeout(function () {
          toast.success("Contract info data updated successfully !", {
            position: "top-right",
          })
        });
      }
      await getDefaultRateCard(ratecardaccount?.RateCardId)
    }
    showedithandler();
    if (JSON?.stringify(prevCount) !== JSON?.stringify(contractInfoData)) {
      const modifieddata = {
        AccountNo: account.account_No,
        CustomerName: account.customer,
        Modifiedsection: message
      }
      await modifyHistory(modifieddata)
    }
  }

  return (
    <>
      {/* Contract info  section*/}
      {Object.keys(contractInfo).length !== 0 ? (
        <Row className="mb-2 mt-2">
          <Col sm={12}>
            <fieldset >
              <div className="button-section" style={{ justifyContent: "end" }}>
                {permission.find(event => event.moduleId === 40)?.permissionType?.indexOf('Update') !== -1 ?
                  <Button className="add-btn" onClick={e => { editContractList(account); }} style={{ width: "135px", paddingRight: "35px" }}>
                    Save{" "}<span style={{ padding: "6px" }}> <Save /></span></Button>
                  : <Button className="add-btn disable" style={{ width: "135px", paddingRight: "35px" }}>
                    Save{" "}<span style={{ padding: "6px" }}> <Save /></span></Button>}
              </div>
              <>
                <Row className="mt-2 mb-3">
                  <Col sm={3}>
                    <Form.Group controlId="fuelsurcharge">
                      <FloatingLabel controlId="floatingSelectGrid" label="Fuel Surcharge Method">
                        <Form.Select disabled={true} aria-label="Floating label select example" name="FuelSurchargeMethod" onChange={e => Contracthandler(e)} value={ratecardaccount?.FuelSurchargeMethod} required>
                          <option>Select</option>
                          <option value="1">General</option>
                          <option value="2">Distance</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Distancetype">
                      <FloatingLabel controlId="floatingSelectGrid" label="Distance Type">
                        <Form.Select disabled={true} aria-label="Floating label select example" name="DistanceType" onChange={e => Contracthandler(e)} value={ratecardaccount?.DistanceType} required >
                          <option>Select</option>
                          <option value="Z">Zone</option>
                          <option value="R">Region</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Lbs/Linear FTn">
                      <FloatingLabel controlId="floatingInput" label="Lbs/Linear FT" style={{ fontSize: "13px" }}>
                        <Form.Control type="number" placeholder="Lbs/Linear FT" name="LbsPerLinearFT" onChange={e => Contracthandler(e)} value={ratecardaccount?.LbsPerLinearFT || ''} required />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Lbs/Cu FT">
                      <FloatingLabel controlId="floatingInput" label="Lbs/Cu FT" >
                        <Form.Control type="number" placeholder="Lbs/Cu FT" name="LbsPerCuFT" onChange={e => Contracthandler(e)} value={ratecardaccount?.LbsPerCuFT || ''} required />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3">
                  <Col sm={3}>
                    <Form.Group controlId="Max Height Start">
                      <FloatingLabel controlId="floatingInput" label="Max Height Start" >
                        <Form.Control type="number" placeholder="Max Height Start" name="MaxHeightStart" onChange={e => Contracthandler(e)} value={ratecardaccount?.MaxHeightStart || ''} required />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Max Height Override">
                      <FloatingLabel controlId="floatingInput" label="Max Height Override" >
                        <Form.Control type="number" placeholder="Max Height Override" name="MaxHeightOverride" onChange={e => Contracthandler(e)} value={ratecardaccount?.MaxHeightOverride || ''} required />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Long Freight Threshold">
                      <FloatingLabel controlId="floatingInput" label="Long Fright Threshold" >
                        <Form.Control type="number" placeholder="Long Fright Threshold" name="LongFreightThreshold" onChange={e => Contracthandler(e)} value={ratecardaccount?.LongFreightThreshold || ''} required />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Long Freight Amount">
                      <FloatingLabel controlId="floatingInput" label="Long Freight Amount" >
                        <Form.Control type="number" placeholder="Long Freight Amount" name="LongFreightAmount" onChange={e => Contracthandler(e)} value={ratecardaccount?.LongFreightAmount || ''} required />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col sm={12}>
                    <Form.Control
                      as="textarea"
                      name="Description"
                      placeholder="Description"
                      style={{ height: '100px' }}
                      value={ratecardaccount?.Description || ""}
                      onChange={e => Contracthandler(e)}
                    />
                  </Col>
                </Row>
              </>
            </fieldset>
          </Col>
        </Row>
      ) : (
        <Row className="mb-2 mt-2">
          <Col sm={12}>
            <p className="text-center">No data Found</p>
          </Col>
        </Row>
      )}

    </>
  );
}
export default SaveContractdata