import { Form } from "react-bootstrap";

const NowLater = (props)=>{
    return(
<div className="now-later"  style={{marginTop:0}}>
<>
  {['radio'].map((type) => (
    <div key={`inline-${type}`}>
      <Form.Check
        inline
        label="Now"
        name="group1"
        type={type}
        id={`inline-${type}-1`}
        checked={props.nowLater === "now"}
        onClick={() => props.setNowLater("now")}
      />
      <Form.Check
        inline
        label="Later"
        name="group1"
        type={type}
        id={`inline-${type}-2`}
        checked={props.nowLater === "later"}
        onClick={() => props.setNowLater("later")}
      />
    </div>
  ))}
</>
</div>
    );
}
export default NowLater;