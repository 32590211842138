import { Col, Container, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import CancelIcon from '@mui/icons-material/Cancel';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import { Save } from "@material-ui/icons";
import useOrder from "../../../context/OrderContext";
import { useCustomData } from "../../Sidebar/Permission";



const Orderbutton = () => {
    const [permission] = useCustomData()
    const { dispatchHandler, validateaddSubmit, orderForm, holdPickuphandler, holdDropuphandler, holdDuplicatehandler, cancelHandler, buttonEnable,returnHandler } = useOrder();
    return (
        <div className="button-section">
            <Container className="mt-4">
                <Row className="justify-content-end">
                    {orderForm === "Online" && (
                        <Col className="mb-2">
                                {permission?.find(event => event.moduleId === 29)?.permissionType?.indexOf('Add') !== -1 ?
                            <Button variant="outline-primary" className="icon-button" onClick={() => {validateaddSubmit("online"); }} >< LocalShippingRoundedIcon /> Dispatch</Button>
                            :<Button variant="outline-primary" className="icon-button-disable">< LocalShippingRoundedIcon /> Dispatch</Button>
                    }
                            </Col>
                    )
                    }
                    {orderForm === "Batch" && (
                        <Col className="mb-2">
                            {permission?.find(event => event.moduleId === 29)?.permissionType?.indexOf('Add') !== -1 ?
                            <Button variant="outline-primary" className="icon-button" onClick={() => { validateaddSubmit("batch"); }}>< Save /> Save</Button>
                            : <Button variant="outline-primary" className="icon-button-disable">< Save /> Save</Button>}
                            </Col>
                    )
                    }
                    {orderForm === "Contract" && (
                        <Col className="mb-2">
                              {permission?.find(event => event.moduleId === 29)?.permissionType?.indexOf('Add') !== -1 ?
                            <Button variant="outline-primary" className="icon-button" onClick={() => { validateaddSubmit("contract"); }}>< Save /> Save</Button>
                            :<Button variant="outline-primary" className="icon-button-disable">< Save /> Save</Button>}
                            </Col>
                    )
                    }
                    {/* {orderForm === "Quote" && (
                        <Col className="mb-2">
                             {permission?.find(event => event.moduleId === 29)?.permissionType?.indexOf('Add') !== -1 ?
                            <Button variant="outline-primary" className="icon-button" onClick={() => {validateaddSubmit("online"); }}>< LocalShippingRoundedIcon /> Dispatch</Button>
                            :<Button variant="outline-primary" className="icon-button-disable">< LocalShippingRoundedIcon /> Dispatch</Button>
                    }
                            </Col>
                    )
                    } */}
                    <Col className="mb-2">
                    {permission?.find(event => event.moduleId === 29)?.permissionType?.indexOf('Add') !== -1 ?
                        <Button variant="outline-primary" className="icon-button" onClick={() => {returnHandler("returntype"); }}> <ReplyAllIcon /> Return</Button>
                        : <Button variant="outline-primary" className="icon-button-disable"> <ReplyAllIcon /> Return</Button>
                }
                        </Col>
                    <Col className="mb-2">
                    {permission?.find(event => event.moduleId === 29)?.permissionType?.indexOf('Add') !== -1 ?
                        <Button variant="outline-primary" className="icon-button" onClick={() => { validateaddSubmit("quote"); }} >< LocalShippingRoundedIcon /> Quote</Button>
                        : <Button variant="outline-primary" className="icon-button-disable" >< LocalShippingRoundedIcon /> Quote</Button> }
                        </Col>
                    <Col className="mb-2"> 
                    {permission?.find(event => event.moduleId === 29)?.permissionType?.indexOf('Add') !== -1 ?
                    <Button variant="outline-primary" className="icon-button" onClick={() => {holdPickuphandler("returntype")}} disabled={!buttonEnable}><LocationOnIcon /> Hold Pickup</Button>
                    : <Button variant="outline-primary" className="icon-button-disable"><LocationOnIcon /> Hold Pickup</Button>}
                    </Col>
                    <Col className="mb-2">
                    {permission?.find(event => event.moduleId === 29)?.permissionType?.indexOf('Add') !== -1 ?
                        <Button variant="outline-primary" className="icon-button" onClick={() => {holdDropuphandler("returntype")}} disabled={!buttonEnable}><WrongLocationIcon />Hold Dropoff</Button>
                        :<Button variant="outline-primary" className="icon-button-disable"><WrongLocationIcon />Hold Dropoff</Button>}
                        </Col>
                    <Col className="mb-2">
                    {permission?.find(event => event.moduleId === 29)?.permissionType?.indexOf('Add') !== -1 ?
                        <Button variant="outline-primary" className="icon-button" onClick={() => {holdDuplicatehandler("returntype")}} disabled={!buttonEnable}> <ContentCopyIcon /> Duplicate</Button>
                        :    <Button variant="outline-primary" className="icon-button-disable"> <ContentCopyIcon /> Duplicate</Button>}
                        </Col>
                    <Col className="mb-2">
                        <Button variant="secondary" className="icon-button-cancel" onClick={cancelHandler}> <CancelIcon /> Cancel</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default Orderbutton;