import { Save } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAddEmployeeMutation, useGetDriverNumberQuery, useGetEmployeeListQuery, useUpdateEmployeeMutation } from "../../../features/admin/employee/employeeSlice";
import CountryProvince from "../../CountryProvince";
import Ace from "./Ace";
import Certifications from "./Certifications";
import DetailsRecord from "./DetailsRecord";
import PayrollInformation from "./PayrollInformation";



const AddEmployee = ({ employeeData, getEmployeeDetails,setEmployeeData }) => {
    const dispatch = useDispatch()
    const { setSelectState } = CountryProvince()
    const certificateLists = (useSelector((state) => state.employee.certificateList)).map((el) => { return ({ certificateId: el.certificateId, ...el.certificateList }) })
    const { register, handleSubmit, formState: { errors }, reset, control, watch, getValues } = useForm();
    const { refetch: refetchEmployeeList } = useGetEmployeeListQuery();
    const { refetch: refetchDriverNumbers } = useGetDriverNumberQuery();
    const [addEmployee] = useAddEmployeeMutation()
    const [updateEmployee] = useUpdateEmployeeMutation()
    const [key, setKey] = useState("Detailsrecord")
    const [selectToState, setSelectToState] = useState("Select");
    const [className, setClassName] = useState("Select");
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [picture, setPicture] = useState(null);
    const [postal, setPostal] = useState(employeeData?.postalCode)
    const docc = employeeData?.getDocument
    const imgg = employeeData?.profileImage
    const [doc, setDoc] = useState([])
    const [imag, setImag] = useState('')
    const [usaDriver, setUsaDriver] = useState(employeeData?.usaDriver || false); // Track USA driver checkbox state

    useEffect(() =>{
        setDoc(docc)
        setImag(imgg)
        setPostal(employeeData?.postalCode)
    },[docc, imgg])

    useEffect(() =>{
        reset(employeeData)
    },[employeeData])


    const subscribe = watch()
    const iddd = employeeData?.employeeId
    const abc = employeeData?.employeeCertificate ? employeeData?.employeeCertificate : []
    const xyz = certificateLists
    const aaa = [...abc, ...xyz]

    const onSubmit = handleSubmit(async (data) => {
            console.log(data);
            const formData = new FormData();
            formData.append('EmployeeId', iddd)
            formData.append('Driverid', Number(data.Driverid));
            formData.append('Position', data.Position);
            formData.append('HireDate', data.HireDate);
            formData.append('Firstname', data.Firstname);
            formData.append('Lastname', data.Lastname);
            formData.append('StreetNo', Number(data.StreetNo));
            formData.append('StreetName', data.StreetName);
            formData.append('CountryId', Number(data.CountryId));
            formData.append('ProvineStateID', Number(data.ProvineStateID));
            formData.append('CityID', Number(data.CityID?.[0].id));
            formData.append('PostalCode', data.PostalCode);
            formData.append('PrimaryNumber', Number(data.primaryNumber?.replace(/\D/g, "")));
            formData.append('SecondaryNumber', Number(data.secondaryNumber?.replace(/\D/g, "")));
            formData.append('WorkPhone', Number(data.workPhone?.replace(/\D/g, "")));
            formData.append('USADriver', data.USADriver? data.USADriver : false);
            formData.append('Driver', data.driver? data.driver : false);
            formData.append('EmailAddress', data.EmailAddress);
            formData.append('ProfileImage', data.ProfileImage[0]);
            formData.append('WorkEmailAddress', data.WorkEmailAddress);
            for (let i = 0; i < uploadedFiles.length; i++) {
                {!employeeData?.employeeId ? 
                formData.append('Documents', uploadedFiles[i])
                :
                formData.append('UpdateDocument', uploadedFiles[i])}
            }
            formData.append('OpertorName', data.OpertorName);
            formData.append('GPSNumber', Number(data.GPSNumber));
            formData.append('InhancedLicense', data.InhancedLicense);
            //formData.append('ClassInhancedID', data.ClassInhancedID);
            formData.append('ClassdropdownCanID', data.ClassInhancedID == undefined ? 0 : data.ClassInhancedID);
            formData.append('ClassdropdownUS', data.ClassUSInhancedID == undefined ? "" : data.ClassUSInhancedID);
            formData.append('DrivingLicense', data.DrivingLicense);
            formData.append('LicenseByProvinceId', Number(data.LicenseByProvinceId));
            formData.append('LicenseByCountryId', data.LicenseByCountryId? data.LicenseByCountryId : 0);
            formData.append('DriveringLicenseExpiryDate', data.DriveringLicenseExpiryDate);
            formData.append('PayrollCycleId', Number(data.PayrollCycleId));
            formData.append('PayrollId', Number(data.PayrollId));
            formData.append('Salary', data.Salary || 0);
            formData.append('Hourly', data.Hourly  || 0);
            formData.append('StopRate', Number(data.StopRate)|| 0);
            formData.append('MileRate', data.MileRate || 0);
            formData.append('FuelRate', Number(data.FuelRate) || 0);
            formData.append('PayrollId', Number(data.PayrollId));
            formData.append('PayrollTypeId', Number(data.PayrollTypeId));
            formData.append('DateofBirth', data.DateofBirth);
            formData.append('Salestaxno', data.Salestaxno);
            formData.append('SinNo', data.SinNo);
            formData.append('CargoInsurance', Number(data.CargoInsurance));
            formData.append('Garnishment', Number(data.Garnishment));
            formData.append('Uniform', Number(data.Uniform));
            formData.append('ShareHolderLoans', Number(data.ShareHolderLoans));
            formData.append('ExtraTaxDeductions', Number(data.ExtraTaxDeductions));
            formData.append('Refundfuelcharge', Number(data.Refundfuelcharge));
            formData.append('GenderId', Number(data.GenderId));
            formData.append('UsAccessId', Number(data.UsAccessId));
            formData.append('CitizenshipCountryCode', Number(data.CitizenshipCountryCode));
            formData.append('ACEproxmitynumber', Number(data.ACEproxmitynumber));
            formData.append('ExpiryDate', data.ExpiryDate);
            formData.append('CSAApprovedDriver', data.CSAApprovedDriver);
            formData.append('TravelTypeDocumentId', Number(data.TravelTypeDocumentId) || 0);
            formData.append('DocumentNumber', Number(data.DocumentNumber));
            formData.append('DocumentExpirydate', data.DocumentExpirydate);
            formData.append('TravelDocumentIssueProvineStateID', Number(data.TravelDocumentIssueProvineStateID) || 0);
            formData.append('TravelDocumentIssueCountryId', data.TravelDocumentIssueCountryId? data.TravelDocumentIssueCountryId : 0);
            console.log(formData);
            // Filter out unwanted fields from certificate lists
            const filteredCertificates = certificateLists.map(certificate => {
                const { certificateIssueCountryName, certificateIssueProvineStateName, certificateAddName, ...rest } = certificate;
                return rest;
            });
            {!employeeData?.employeeId ? 
                formData.append('EmpoloyeeCertificates', JSON.stringify(filteredCertificates))
                : formData.append('EmpoloyeeCertificates', JSON.stringify(aaa))}
            const employeeList = employeeData?.employeeId ? await updateEmployee(formData) : await addEmployee(formData)
            if (employeeList?.data?.statusCode === 409 ) {
                toast.info("Email id already exist", {
                    position: "top-center",
                })
            }
            else if (employeeList?.data?.statusCode === 500) {
                toast.error("Adding employee failed", {
                    position: "top-center",
                })
            }
            else if (employeeList?.data?.statusCode === 200) {
                const successMessage = employeeData?.employeeId ? "Employee updated successfully" : "Employee created successfully";
    
                toast.success(successMessage, {
                    position: "top-center",
                })
                setTimeout(() => {
                    window.location.reload(false)
                  }, 5000)
                refetchEmployeeList();
                refetchDriverNumbers(); // Refetch driver numbers here   
                //refetch()       
                reset({
                    PayNo:"",Driverid: "", Position: "", HireDate: "", Firstname: "", Lastname: "", StreetNo: "", StreetName: "", CountryId: "", ProvineStateID: "",provineState:"", CityID: "", PostalCode: "", primaryNumber: "",
                    secondaryNumber: "", workPhone: "", USADriver: "", EmailAddress: "", WorkEmailAddress: "", ProfileImage: "", Documents: "", OpertorName: "", GPSNumber: "", InhancedLicense: "", ClassInhancedID: "",
                    DrivingLicense: "", LicenseByCountryId: "", LicenseByProvinceId: "", ExpiryDate: "", PayrollCycleId: "", PayrollId: "", PayrollTypeId: "", DateofBirth: "", Salestaxno: "", SinNo: "", CargoInsurance: "", Garnishment: "",
                    Uniform: "", ShareHolderLoans: "", ExtraTaxDeductions: "", Refundfuelcharge: "", GenderId: "", UsAccessId: "", CitizenshipCountryCode: "", ACEproxmitynumber: "", DocumentExpirydate: "", CSAApprovedDriver: "", TravelTypeDocumentId: "",
                    DocumentNumber: "", DocumentExpirydate: "", TravelDocumentIssueProvineStateID: "", TravelDocumentIssueCountryId: "",certificateLists:"",setSelectState:"",selectState:"", driver: false
                });
                setUploadedFiles([])
                setPicture(null);
                setSelectState("")
                setSelectToState("")
                setClassName("")
                setPostal("")
                setDoc([])
                setImag('')
                setEmployeeData('')
                dispatch(reset([]))
            }
        })
    
     
    return (
        <fieldset className="user-role-form mt-4 mb-3">
            <label className="label-heading">Add Employee/Brokers</label>
            {!employeeData?.employeeId ?
                <div className="save-btn">
                    <Button onClick={onSubmit}> AddEmployee</Button>
                </div>
                :
                <div className="save-btn">
                    <Button onClick={onSubmit}><Save /> Update</Button>
                </div>
            }
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                <Tab eventKey="Detailsrecord" title="Details Record" >
                    <DetailsRecord register={register} getValues={getValues} errors={errors} reset={reset} control={control} uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} 
                    employeeData={employeeData} picture={picture} setPicture={setPicture} getEmployeeDetails={getEmployeeDetails} iddd={iddd} selectToState={selectToState} setSelectToState={setSelectToState} className={className} setClassName={setClassName} setPostal={setPostal} postal={postal} doc={doc} imag={imag} usaDriver={usaDriver} setUsaDriver={setUsaDriver}/>
                </Tab>
                <Tab eventKey="Payrollinformation" title="Payroll Information" >
                    <PayrollInformation register={register} errors={errors} employeeData={employeeData}subscribe={subscribe}/>
                </Tab>
                <Tab eventKey="Ace" title="ACE" >
                    <Ace register={register} errors={errors} employeeData={employeeData} usaDriver={usaDriver}/>
                </Tab>
                <Tab eventKey="Certifications" title="Certifications">
                {/* <CopyCertifications employeeData={employeeData} certificateLists={certificateLists} getEmployeeDetails={getEmployeeDetails} iddd={iddd} reset={reset}/>  */}
                    <Certifications employeeData={employeeData} certificateLists={certificateLists} getEmployeeDetails={getEmployeeDetails} iddd={iddd} reset={reset} subscribe={subscribe}/>
                </Tab>
            </Tabs>
        </fieldset>

    )
}
export default AddEmployee