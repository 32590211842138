import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useEditSelectedOrderDetailsMutation, useLazyGetAllActiveOpenOrdersQuery } from '../../../../features/Dispatch/DispatchSlice';
import { useGetOrderNotificationQuery, useLazyGetVehicleByIdQuery } from '../../../../features/order/orderSlice';
import AddAccessorial from './ButtonContent/AddAccessorial';
import AutoStatus from './ButtonContent/AutoStatus';
import DispatcherNotes from './ButtonContent/DispatcherNotes';
import Document from './ButtonContent/Document';
import OrderNotes from './ButtonContent/OrderNotes';
import ButtonModals from './ButtonModals';
import SmsNotification from './ButtonContent/SmsNotification';

const OrderDetails = (props) => {
    const { setSelectedOrder, selectedOrder, divisionId } = props;
    const [modalShow, setModalShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setModalContent] = useState(null);
    const [edit, setEdit] = useState(false);
    const [editSelectedOrderDetails] = useEditSelectedOrderDetailsMutation();

    const [fetchVehicles, { data: vehicleData }] = useLazyGetVehicleByIdQuery();
    const [vehicleOptions, setVehicleOptions] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState(selectedOrder?.vehicle || '');
    const [getAllActiveOrders] = useLazyGetAllActiveOpenOrdersQuery()
    const { data: orderNotifData } = useGetOrderNotificationQuery(selectedOrder?.orderNumber, {
        skip: !selectedOrder?.orderNumber, 
    });

    useEffect(() => {
        if (edit && selectedOrder?.accountNo) {
            // Fetch vehicles when in edit mode
            fetchVehicles(selectedOrder.accountNo);
        }
    }, [edit, selectedOrder?.accountNo]);

    useEffect(() => {
        if (vehicleData && vehicleData.data) {
            // Filter out the current vehicle from the list
            const filteredVehicles = vehicleData.data.filter(vehicle => vehicle.vehicleType !== selectedOrder?.vehicle);
            setVehicleOptions(filteredVehicles);
        }
    }, [vehicleData, selectedOrder?.vehicle]);

    const handleEditDetails = (e) => {
        e.preventDefault();
        setSelectedOrder(selectedOrder => ({...selectedOrder, [e.target.name]: e.target.value}));
    };

    const editOrder = async () => {
        const updatedOrder = {
            ...selectedOrder,
            vehicle: selectedVehicle,
        };
        const result = await editSelectedOrderDetails(updatedOrder);
        if (result.data.statusCode === 200) {
            toast.success("Order updated successfully", {
                position: "top-center",
            });
            setEdit(false);
            // refresh data
            // After successful save, ensure the vehicle field shows the updated value
            setSelectedOrder(updatedOrder);
            getAllActiveOrders(divisionId)
        }
        else {
            toast.error("An error occurred!", {
                position: "top-center",
            });
        }
    }

    const formatTime = (timeString) => {
        console.log(timeString);
        if (!timeString) return '';
        if (timeString === "null") return '';
        let dateObj;
    
        // Check if the timeString contains "T", indicating ISO 8601 format
        if (timeString.includes('T')) {
            dateObj = new Date(timeString);
        } else {
            // Assuming the time is in "HH:mm" format, we create a new Date object for today with this time.
            const [hours, minutes] = timeString.split(':');
            dateObj = new Date();
            dateObj.setHours(hours);
            dateObj.setMinutes(minutes);
            dateObj.setSeconds(0);
        }
    
        // Format the time using toLocaleTimeString() to display in AM/PM format
        return dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    const handleShowModal = (title, content) => {
        setModalTitle(title);
        setModalContent(content);
        setModalShow(true);
    };
    
    const handleCloseModal = () => {
        setModalShow(false);
    };

    return (
        <div className="local-dispatch-order-details" >
            <div className="order-details-header">
                <h3 >Order #{selectedOrder?.orderNumber || ''}</h3>
            </div>
            <div className="order-content">
                <div className="form-row" style={{marginBottom: '10px'}}>
                    <div className="ready-time">
                        <label style={{marginLeft: '5px', marginRight: '5px'}}>Ready at:</label>
                        <input type="datetime-local" disabled={!edit} className="ready-date" name="readyDate" value={selectedOrder?.readyDate || ''} onChange={(e) => handleEditDetails(e)} />
                        {/* <input type="checkbox" id="autoStatus" />
                        <label htmlFor="autoStatus">Auto Status</label> */}
                    </div>
                    <div className="form-col" style={{ marginRight: '-10px'}}>
                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                            { !edit? <button style={{fontSize: '11px', marginRight: '10px', borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', padding: '5px'}} onClick={() => setEdit(true)}>Edit</button> : 
                                <button style={{fontSize: '11px', marginRight: '10px', borderRadius:'3px', color:'White', backgroundColor:'red', border: 'none', padding: '5px'}} onClick={() => editOrder()}>Save</button>}
                            <button style={{fontSize: '11px', marginRight: '10px', borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', padding: '5px'}} onClick={() => props.setSelectedOrder(null)}>Exit</button>
                        </div>
                    </div>
                </div>
                        
                {/* <div className="row">
                    <div className="col"> */}
                        {/* <label >Email:</label>
                        <input type="email" style={{marginRight: '10px', height: '50px',marginBottom: '10px'}} value={selectedOrder?.emailAddress || ''} />
                        <input type="checkbox" id="waybill" />
                        <label htmlFor="waybill" style={{marginRight: '10px'}} > Waybill</label>
                        <input type="checkbox" id="label" />
                        <label htmlFor="label" style={{marginRight: '10px'}} >Label</label> */}
                        {/* <button style={{marginLeft: '5px',marginBottom: '10px',marginRight: '10px',borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', fontSize:'18px'}}>Email</button>
                        <button style={{marginRight: '10px',borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', fontSize:'18px'}}>Print</button> */}
                        {/* <button style={{marginLeft: '5px',marginBottom: '10px',marginRight: '10px',borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', fontSize:'16px'}}>Auto Status</button>
                        <button style={{marginRight: '10px',borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', fontSize:'16px'}}>Document</button>
                        <button style={{marginRight: '10px',borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', fontSize:'16px',}}>Dispatcher Notes</button>
                        <button style={{marginRight: '10px',borderRadius:'3px', color:'White', backgroundColor:'#244da1', border: 'none', fontSize:'16px'}}>Order Notes</button> */}
                    {/* </div>
                </div> */}
                <div className="form-row">
                    <div className="local-dispatch-section">
                        <div className="local-dispatch-items">
                            <div >
                                <div className="dispatch-items-header">
                                    <h5>Pick-up Information</h5>
                                </div>
                                <div className="dispatch-items-content">
                                         <div className="form-col grid-col-span-2">
                                            <label for='Name'>Contact</label>
                                            <input type="text" disabled={!edit} name='consignorName' value={selectedOrder?.consignorName || ''} onChange={(e) => handleEditDetails(e)}/>
                                        </div>
                                        <div className="form-col grid-col-span-2">
                                            <label for='City'>Phone #</label>
                                            <input type="text" disabled={!edit} name='pickupPhoneNumber' value={selectedOrder?.pickupPhoneNumber || ''} onChange={(e) => handleEditDetails(e)}/>
                                        </div>
                                        <div className="form-col grid-col-span-3" >
                                            <label for='Address'>Address</label>
                                            {/* <input type="text" disabled={!edit} name='pickupStreetNumber' value={selectedOrder?.pickupStreetNumber + " " + selectedOrder?.pickupStreetName + (" Unit " + selectedOrder?.pickupUnit || '')} onChange={(e) => handleEditDetails(e)}/> */}
                                            {edit ? (
                                            <>
                                                <input
                                                    type="text"
                                                    name='pickupStreetNumber'
                                                    placeholder="Street Number"
                                                    value={selectedOrder?.pickupStreetNumber || ''}
                                                    onChange={(e) => handleEditDetails(e)}
                                                />
                                                <input
                                                    type="text"
                                                    name='pickupStreetName'
                                                    placeholder="Street Name"
                                                    value={selectedOrder?.pickupStreetName || ''}
                                                    onChange={(e) => handleEditDetails(e)}
                                                />
                                                <input
                                                    type="text"
                                                    name='pickupUnit'
                                                    placeholder="Unit"
                                                    value={selectedOrder?.pickupUnit || ''}
                                                    onChange={(e) => handleEditDetails(e)}
                                                />
                                            </>
                                        ) : (
                                            <input
                                                type="text"
                                                value={`${selectedOrder?.pickupStreetNumber || ''} ${selectedOrder?.pickupStreetName || ''} ${selectedOrder?.pickupUnit ? ' Unit: ' + selectedOrder?.pickupUnit : ''}`}
                                                disabled
                                            />
                                        )}
                                        </div>
                                        <div className="form-col">
                                            <label for="City" >City</label>
                                            <input type="text" disabled name='pickupCity' value={selectedOrder?.pickupCity || ''} onChange={(e) => handleEditDetails(e)}/>
                                        </div>
                                        
                                        <div className="form-col">
                                            <label for='Province'>Province</label>
                                            <input type="text" disabled name='pickupProvince' value={selectedOrder?.pickupProvince || ''} onChange={(e) => handleEditDetails(e)}/>
                                        </div>
                                        <div className="form-col">
                                            <label for='Country'>Country</label>
                                            <input type="text" disabled name='pickupCountry' value={selectedOrder?.pickupCountry || ''} onChange={(e) => handleEditDetails(e)}/>
                                        </div>
                                        
                                        
                                        <div className="form-col grid-col-span-2">
                                            <label for='Postal'>Postal</label>
                                            <input type="text" disabled={!edit} name='pickupPostalCode' value={selectedOrder?.pickupPostalCode || ''} onChange={(e) => handleEditDetails(e)}/>
                                        </div>
                                        <div className="form-col grid-col-span-2">
                                            <label for='Open'>Open</label>
                                            {edit ? (
                                            <input
                                                type="time"
                                                name="pickupOpeningTime"
                                                value={
                                                    selectedOrder?.pickupOpeningTime
                                                        ? selectedOrder?.pickupOpeningTime.substring(0, 5) // Keep as HH:mm format for input
                                                        : ''
                                                }
                                                onChange={(e) => handleEditDetails(e)}
                                            />
                                            ) : (
                                                <input type="text" value={formatTime(selectedOrder?.pickupOpeningTime)} readOnly/> // Display formatted time in AM/PM
                                            )}
                                    </div>
                                    <div className="form-col grid-col-span-2">
                                        <label for='Close'>Close</label>
                                        {edit ? (
                                            <input
                                                type="time"
                                                name="pickupClosingTime"
                                                value={
                                                    selectedOrder?.pickupClosingTime
                                                        ? selectedOrder?.pickupClosingTime.substring(0, 5) // Keep as HH:mm format for input
                                                        : ''
                                                }
                                                onChange={(e) => handleEditDetails(e)}
                                            />
                                            ) : (
                                                <input type="text" value={formatTime(selectedOrder?.pickupClosingTime)} readOnly/> // Display formatted time in AM/PM
                                            )}
                                    </div>
                                    <div className="form-col">
                                        <label for='IN'>IN</label>
                                        <input type="text" disabled={!edit} />
                                    </div>
                                    <div className="form-col">
                                        <label for='OUT'>OUT</label>
                                        <input type="text" disabled={!edit} />
                                    </div>
                                    <div className="form-col">
                                        <label for='ETA'>ETA</label>
                                        <input type="text" disabled={!edit} />
                                    </div>
                                    <div className="form-col">
                                        <label for='Detention'>Detention</label>
                                        <input type="text" disabled={!edit} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="local-dispatch-items">
                            <div>
                                <div className="dispatch-items-header">
                                    <h5>Drop-off Information</h5>
                                </div>
                                <div className="dispatch-items-content">
                                        <div className="form-col grid-col-span-2">
                                            <label for='Name' >Contact</label>
                                            <input type="text" disabled={!edit} name='consigneeName' value={selectedOrder?.consigneeName || ''} onChange={(e) => handleEditDetails(e)}/>
                                        </div>
                                        {/* <div className="col">
                                            <label for='Name'style={{fontSize: '14px'}}>Customer Name</label>
                                            <input type="text" style={{width: '90%'}}/>
                                        </div> */}
                                        <div className="form-col grid-col-span-2">
                                            <label for="City" >Phone #</label>
                                            <input type="text" disabled={!edit} name='dropoffPhoneNumber' value={selectedOrder?.dropoffPhoneNumber || ''} onChange={(e) => handleEditDetails(e)}/>
                                        </div>
                                        <div className="form-col grid-col-span-3">
                                            <label for='Address'>Address</label>
                                            {/* <input type="text" disabled={!edit} name='dropoffStreetNumber' value={selectedOrder?.dropoffStreetNumber + " " + selectedOrder?.dropoffStreetName + (" Unit " + selectedOrder?.dropoffUnit || '') } onChange={(e) => handleEditDetails(e)}/> */}
                                            {edit ? (
                                            <>
                                                <input
                                                    type="text"
                                                    name='dropoffStreetNumber'
                                                    placeholder="Street Number"
                                                    value={selectedOrder?.dropoffStreetNumber || ''}
                                                    onChange={(e) => handleEditDetails(e)}
                                                />
                                                <input
                                                    type="text"
                                                    name='dropoffStreetName'
                                                    placeholder="Street Name"
                                                    value={selectedOrder?.dropoffStreetName || ''}
                                                    onChange={(e) => handleEditDetails(e)}
                                                />
                                                <input
                                                    type="text"
                                                    name='dropoffUnit'
                                                    placeholder="Unit"
                                                    value={selectedOrder?.dropoffUnit || ''}
                                                    onChange={(e) => handleEditDetails(e)}
                                                />
                                            </>
                                        ) : (
                                            <input
                                                type="text"
                                                value={`${selectedOrder?.dropoffStreetNumber || ''} ${selectedOrder?.dropoffStreetName || ''} ${selectedOrder?.dropoffUnit ? ' Unit: ' + selectedOrder?.dropoffUnit : ''}`}
                                                disabled
                                            />
                                        )}
                                        </div>
                                        <div className="form-col">
                                            <label for="City" >City</label>
                                            <input type="text" disabled name='dropOffCity' value={selectedOrder?.dropoffCity || ''} onChange={(e) => handleEditDetails(e)}/>
                                        </div>
                                        <div className="form-col">
                                            <label for="Province" >Province</label>
                                            <input type="text" disabled name='dropoffProvince' value={selectedOrder?.dropoffProvince || ''} onChange={(e) => handleEditDetails(e)}/>
                                        </div>
                                        <div className="form-col">
                                            <label for="Country" >Country</label>
                                            <input type="text" disabled name='dropoffCountry' value={selectedOrder?.dropoffCountry || ''} onChange={(e) => handleEditDetails(e)}/>
                                        </div>
                                        <div className="form-col grid-col-span-2">
                                            <label for="Postal Code" >Postal</label>
                                            <input type="text" disabled={!edit} name='dropoffPostalCode' value={selectedOrder?.dropoffPostalCode || ''} onChange={(e) => handleEditDetails(e)}/>
                                        </div>
                                        
                                        <div className="form-col grid-col-span-2">
                                        <label for="Open" >Open</label>
                                        {edit ? (
                                            <input
                                                type="time"
                                                name="dropoffOpeningTime"
                                                value={
                                                    selectedOrder?.dropoffOpeningTime
                                                        ? selectedOrder?.dropoffOpeningTime.substring(0, 5) // Keep as HH:mm format for input
                                                        : ''
                                                }
                                                onChange={(e) => handleEditDetails(e)}
                                            />
                                            ) : (
                                                <input type="text" value={formatTime(selectedOrder?.dropoffOpeningTime)} readOnly/> // Display formatted time in AM/PM
                                            )}
                                    </div>
                                    <div className="form-col grid-col-span-2">
                                        <label for="Close" >Close</label>
                                        {edit ? (
                                            <input
                                                type="time"
                                                name="dropoffClosingTime"
                                                value={
                                                    selectedOrder?.dropoffClosingTime
                                                        ? selectedOrder?.dropoffClosingTime.substring(0, 5) // Keep as HH:mm format for input
                                                        : ''
                                                }
                                                onChange={(e) => handleEditDetails(e)}
                                            />
                                            ) : (
                                                <input type="text" value={formatTime(selectedOrder?.dropoffClosingTime)} readOnly/> // Display formatted time in AM/PM
                                            )}
                                    </div>
                                    <div className="form-col">
                                        <label for="IN" >IN</label>
                                        <input type="text" disabled={!edit} />
                                    </div>
                                    <div className="form-col">
                                        <label for="OUT" >OUT</label>
                                        <input type="text" disabled={!edit} />
                                    </div>
                                    <div className="form-col">
                                        <label for="ETA" >ETA</label>
                                        <input type="text" disabled={!edit} />
                                    </div>
                                    <div className="form-col">
                                        <label for="Detention" >Detention</label>
                                        <input type="text" disabled={!edit} />
                                    </div>
                                </div>
                            </div>
                            
                            
                        </div>
                        <div className="local-dispatch-items">
                            <div className="dispatch-items-header">
                                <h5 >Order Information</h5>
                            </div>
                            <div className="dispatch-items-content">
                                    <div className="form-col grid-col-span-2">
                                        <label for='Name'>Customer</label>
                                        <input type="text" disabled={!edit} name='customer' value={selectedOrder?.customer || ''} onChange={(e) => handleEditDetails(e)}/>
                                    </div>
                                    <div className="form-col grid-col-span-2">
                                        <label for="Vehicle" >Vehicle </label>
                                        {/* <input type="text" disabled name='vehicle' value={selectedOrder?.vehicle || ''} onChange={(e) => handleEditDetails(e)}/> */}
                                        {!edit ? (
                                            <input type="text" disabled value={selectedOrder?.vehicle || ''} />
                                        ) : (
                                            <>
                                            <select
                                                name="vehicle"
                                                value={selectedVehicle}
                                                onChange={(e) => setSelectedVehicle(e.target.value)}
                                            >
                                                <option value={selectedOrder?.vehicle || ''}>{selectedOrder?.vehicle || 'Select Vehicle'}</option>
                                                {vehicleOptions.map((vehicle) => (
                                                    <option key={vehicle.vehicleID} value={vehicle.vehicleType}>
                                                    {vehicle.vehicleType}
                                                </option>
                                                ))}
                                            </select>
                                            <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                                                If the vehicle is changed, you need to select a new service based on the selected vehicle.
                                            </p>
                                            </>
                                        )}   

                                    </div>
                                    
                                    <div className="form-col grid-col-span-2">
                                        <label for="Service">Service </label>
                                        <input type="text" disabled name='service' value={selectedOrder?.service || ''} onChange={(e) => handleEditDetails(e)}/>
                                    </div>
                                    <div className="form-col grid-col-span-2">
                                        <label for="Pcs">Pcs </label>
                                        <input type="text" disabled={!edit} name='pcs' value={selectedOrder?.pcs || ''} onChange={(e) => handleEditDetails(e)}/>
                                    </div>
                                    {/* <div className="form-col ">
                                        <label for="SKD">Unit </label>
                                        <input type="text"/>
                                    </div> */}
                                    <div className="form-col grid-col-span-2">
                                        <label for='Spot'>Spot</label>
                                        <input type="text" disabled={!edit} name='spot' value={selectedOrder?.spot } onChange={(e) => handleEditDetails(e)}/>
                                    </div>
                                    <div className="form-col grid-col-span-2">
                                        <label for="weight">Weight </label>
                                        <input type="text" disabled={!edit} name='weight' value={selectedOrder?.weight || 0} onChange={(e) => handleEditDetails(e)}/>
                                    </div>
                                    <div className="form-col grid-col-span-2">
                                        <label for="size">Unit </label>
                                        <input type="text" disabled name='weightTypeId' value={selectedOrder?.weightTypeId ==1 ? 'L' : ''} onChange={(e) => handleEditDetails(e)}/>
                                    </div>
                                    {/* <div className="form-col ">
                                        <label for='Unit'>Unit</label>
                                        <input type="text" />
                                    </div> */}
                                    
                                    <div className="form-col grid-col-span-2">
                                        <label form='Ft'>Ft</label>
                                        <input type="text" disabled={!edit} name='ft' value={selectedOrder?.ft || ''} onChange={(e) => handleEditDetails(e)}/>
                                    </div>
                                    <div className="form-col grid-col-span-2">
                                        <label for='Dim. Wgt'>Dim. Weight</label>
                                        <input type="text" disabled={!edit} name='dimWgt' value={selectedOrder?.dimWgt || ''} onChange={(e) => handleEditDetails(e)}/>
                                    </div>
                                    <div className="form-col grid-col-span-2">
                                        <label for='Reference'>Reference</label>
                                        <input type="text" disabled={!edit} name='reference' value={selectedOrder?.reference || ''} onChange={(e) => handleEditDetails(e)}/>
                                    </div>
                            </div>
                        </div>
                    </div>
                    
                                
                    
                </div>
                {/* <div className=" local-dispatch-section">
                    <div className=" dispatch-accessorial">
                        <div className="dispatch-items-header-small">
                            <text className="dispatch-items-small"> Accessorials (Pickup)</text>
                        </div>
                        <textarea type="text" placeholder="Accessorials (Pickup)" value={selectedOrder?.pickupAccessorialCodes.join("\n")}/> 
                    </div>
                    <div className="dispatch-accessorial">
                        <div className="dispatch-items-header-small">
                            <text className="dispatch-items-small"> Accessorials (Delivery)</text>
                        </div>
                        <textarea type="text" placeholder="Accessorials (Delivery)" value={selectedOrder?.dropoffAccessorialCodes.join("\n")}/>
                    </div>
                    <div className="dispatch-accessorial">
                        <div className="dispatch-items-header-small">
                            <text className="dispatch-items-small"> Accessorials (Order)</text>
                        </div>
                        <textarea type="text" placeholder="Accessorials (Order)" value={selectedOrder?.orderLevelAccessorialCodes.join("\n")}/>
                    </div>
                </div> */}

                <div className="form-row">
                    <div className="form-col-buttons">
                    <button onClick={() => handleShowModal('Add Accessorial', <AddAccessorial selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder} divisionId ={divisionId}/>)}>Add Accessorial</button>
                    <button onClick={() => handleShowModal('Auto Status', <AutoStatus selectedOrder={selectedOrder} orderNotifData={orderNotifData}/>)}>Auto Status</button>
                    <button onClick={() => handleShowModal('Document', <Document selectedOrder={selectedOrder} orderNotifData={orderNotifData}/>)}>Document</button>
                    <button onClick={() => handleShowModal('SMS', <SmsNotification selectedOrder={selectedOrder} orderNotifData={orderNotifData}/>)}>SMS</button>
                    <button onClick={() => handleShowModal('Dispatcher Notes', <DispatcherNotes selectedOrder={selectedOrder} orderNotifData={orderNotifData} setSelectedOrder={setSelectedOrder} divisionId ={divisionId}/>)}>Dispatcher Notes</button>
                    <button onClick={() => handleShowModal('Order Notes', <OrderNotes selectedOrder={selectedOrder} orderNotifData={orderNotifData} setSelectedOrder={setSelectedOrder} divisionId ={divisionId}/>)}>Order Notes</button>
                        <button >Map</button>
                        <button >Return No Delivery</button>       
                        <button >Future</button>       
                               
                    </div>
                    <ButtonModals show={modalShow} title={modalTitle} content={modalContent} handleClose={handleCloseModal} />
                </div>
            </div>
        </div>  
    );
};
 
export default OrderDetails;