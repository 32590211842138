import { Delete, Edit } from "@material-ui/icons";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Table } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useCustomer from "../../../../../context/CustomerContext";
import { addCityDistance, useAddCustomerCityDistanceAfterEditMutation, useDeleteCityDistanceDataMutation, useUpdateCityDistanceDataMutation } from "../../../../../features/cityDistance/cityDistanceSlice";
import { useLazyGetStateQuery } from "../../../../../features/countryState/countryStateSlice";
import CountryProvince from "../../../../CountryProvince";
import { useCustomData } from "../../../../Sidebar/Permission";


const EditCityDistance = (props) => {
    const [permission] = useCustomData()
    const { city, province, handleProvince, newHandleCountry } = CountryProvince();
    const [fetchState] = useLazyGetStateQuery()
    const [fetchToState] = useLazyGetStateQuery()
    const cityList = city?.data.map((a) => { return { name: a.city, id: a.id } });
    const [updateCityDistance] = useUpdateCityDistanceDataMutation()
    const [deleteCityDistance] = useDeleteCityDistanceDataMutation()
    const [cityAfterEdit] = useAddCustomerCityDistanceAfterEditMutation();
    const { cityDistanceLists, getDefaultCard } = useCustomer();
    const [isEditItem, setIsEditItem] = useState(null);
    const [cityDistance, setCityDistance] = useState({})
    const [showDistanceSecondFields, setShowDistanceSecondFields] = useState(false);
    const [editId, setEditId] = useState('');
    const [isDelete, setIsDelete] = useState();
    const [selectState, setSelectState] = useState([]);
    const [selectToState, setSelectToState] = useState([]);
    const [distanceOpen, setDistanceOpen] = useState(false);
    const handleDistanceOpen = (data) => { setDistanceOpen(true); setIsDelete(data) };
    const handleDistanceClose = () => { setDistanceOpen(false); };
    const [isUpdate, setIsUpdate] = useState(false);
    const dispatch = useDispatch();
    const toCityList = cityList;
    const fromCityList = cityList;
    const [toCountryList, setToCountryList] = useState([]);
    const [fromCountry, setFromCountry] = useState([]);
    const [toSelection, setToSelection] = useState([]);
    const [fromSelection, setFromSelection] = useState([]);
    const [toProvince, setToProvince] = useState([]);
    const [fromProvince, setFromProvince] = useState([]);

    const addCitydata = {
        rateCardID: props?.selectedCard,
        fromCountryId: cityDistance?.FromCountry,
        toCountryId: cityDistance?.ToCountry,
        fromCityId: cityDistance?.FromCity,
        toCityId: cityDistance?.ToCity,
        fromProvinceId: cityDistance?.FromProvince,
        toProvinceId: cityDistance?.ToProvince,
        miles: cityDistance?.Miles,
        timeInMinutes: cityDistance?.TimeInMinutes
    }
    const updateCitydata = {
        citydistanceID: cityDistance?.CityDistanceId,
        fromCountry: cityDistance?.FromCountry,
        toCountry: cityDistance?.ToCountry,
        fromCity: cityDistance?.FromCity,
        toCity: cityDistance?.ToCity,
        fromProvince: cityDistance?.FromProvince,
        toProvince: cityDistance?.ToProvince,
        miles: cityDistance?.Miles,
        timeInMinutes: cityDistance?.TimeInMinutes
    }

    const addNewSection = async () => {
        setShowDistanceSecondFields(true);
        if (Object.keys(cityDistance).length === 0) {
            setShowDistanceSecondFields(true);
            return;
        }
        else {
            const cityDistanceAfterEdit = await cityAfterEdit(addCitydata);
            setCityDistance("");
            if (cityDistanceAfterEdit?.data?.statusCode === 200) {
                toast.success("City Distance record added successfully !", {
                    position: "top-right",
                })
                dispatch(addCityDistance(addCitydata));
                getDefaultCard(props.selectedCard)
                setFromSelection([]);
                setToSelection([]);
            }
        }
    }

    useEffect(() => {
        const handleSelection = async (selection, setProvince, setCountry, cityKey, provinceKey, countryKey) => {
            if (selection.length > 0) {
                const pList = await handleProvince(selection[0].id);
                setProvince(pList.data);
                setCityDistance(cityDistance => ({ ...cityDistance, [cityKey]: selection[0].id, }));
                if (pList.data.data.length === 1) {
                    const selectedProvince = pList.data.data[0];
                    const cList = await newHandleCountry(selectedProvince.provineStateID);
                    setCountry(cList.data);
                    setCityDistance(cityDistance => ({
                        ...cityDistance,
                        [provinceKey]: selectedProvince.provineStateID,
                        [countryKey]: cList.data.data[0]?.countryId || null,
                    }));
                }
            } else {
                setProvince([]);
                setCountry([]);
            }
        };
        handleSelection(fromSelection, setFromProvince, setFromCountry, 'FromCity', 'FromProvince', 'FromCountry');
        handleSelection(toSelection, setToProvince, setToCountryList, 'ToCity', 'ToProvince', 'ToCountry');
    }, [fromSelection, toSelection]);

    const setCountry = async (e) => {
        if (e.target.value !== 'Select') {
            let cList;
            if (e.target.name === "FromProvince") {
                cList = await newHandleCountry(fromProvince.data[0].provineStateID);
                setFromCountry(cList.data);
            }
            else if (e.target.name === "ToProvince") {
                cList = await newHandleCountry(toProvince.data[0].provineStateID);
                setToCountryList(cList.data);
            }
        }
        else {
            if (e.target.name === "FromProvince") {
                setFromCountry([]);
            }
            else {
                setToCountryList([]);
            }
        }
    }

    const getProvinceName = (id) => { return province.data[parseInt(id) - 1]?.provineSateName }

    const getData = async (selectState) => {
        fetchState(selectState)
    }
    const getStateData = async (selectToState) => {
        fetchToState(selectToState)
    }
    const handlecountry = async (e) => {
        const addressData = e.target.value;
        const selectedState = await getData(addressData)
        setSelectState(selectedState)
    }

    const handletocountry = async (e) => {
        const addressData = e.target.value;
        const selectedState = await getStateData(addressData)
        setSelectToState(selectedState)
    }
    const Cityupdate = (e) => { setCityDistance(cityDistance => ({ ...cityDistance, [e.target.name]: e.target.value })) }
    const EditcityItem = async (citydistanceID) => {
        setIsUpdate(true);
        setEditId(citydistanceID);
        let newEditItem = cityDistanceLists.find((data) => data.CityDistanceId === citydistanceID);
        if (!newEditItem) return;

        setCityDistance(newEditItem);
        setShowDistanceSecondFields(true);
        setIsEditItem(citydistanceID);
        setFromSelection(newEditItem.FromCity ? [{ name: newEditItem.FromCityName, id: newEditItem.FromCity }] : []);
        setToSelection(newEditItem.ToCity ? [{ name: newEditItem.ToCityName, id: newEditItem.ToCity }] : []);
        if (newEditItem.FromProvince) {
            const fromCountryList = await newHandleCountry(newEditItem.FromProvince);
            setFromCountry(fromCountryList?.data || []);
        } else {
            setFromCountry([]);
        }

        if (newEditItem.ToProvince) {
            const toCountryList = await newHandleCountry(newEditItem.ToProvince);
            setToCountryList(toCountryList?.data || []);
        } else {
            setToCountryList([]);
        }
    };

    const editCityCustomerList = async () => {
        let prevCityDistance = cityDistanceLists.find((data) => {
            return data.CityDistanceId === cityDistance.CityDistanceId
        });

        if (isEqual(prevCityDistance, cityDistance)) {
            setTimeout(function () {
                toast.info("No Changes found on this City distance section !", {
                    position: "top-right",
                })
            });
        }
        else {
            const updateCityDistanceData = await updateCityDistance(updateCitydata)
            setCityDistance({});
            setFromSelection([]);
            setToSelection([]);
            setFromProvince([]);
            setToProvince([]);
            setFromCountry([]);
            setToCountryList([]);


            if (updateCityDistanceData.data.statusCode === 200) {
                setTimeout(function () {
                    toast.success("City distance updated successfully !", {
                        position: "top-right",
                    })
                });
            }
            getDefaultCard(props.selectedCard)
        }
    }

    const deleteCityCustomerList = async () => {
        const deleteCityDistanceRecord = await deleteCityDistance(isDelete)
        if (deleteCityDistanceRecord.data.statusCode === 200) {
            setTimeout(function () {
                toast.success("City distance record deleted successfully !", {
                    position: "top-right",
                })
            });
            getDefaultCard(props.selectedCard)
        }
        // handleAccount(account?.account_No)
        handleDistanceClose();
    }

    return (
        <>
            {/* City Distance section */}
            <Row className="mb-4 mt-4">
                <Col sm={12}>
                    <fieldset className="mb-3">
                        <label className="label-heading">City Distance Table</label>
                        <Row className="mb-2 mt-2">
                            <Col sm={12}>
                                <fieldset className="pb-2">
                                    <div className="section_action_btn">
                                        {cityDistance?.CityDistanceId ?
                                            <>
                                                {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ?
                                                    <div className="save_btn"><button type="button" className="btn" onClick={e => { editCityCustomerList(e); }}>Update record</button></div>
                                                    : <div className="save_btn disable"><button type="button" className="btn">Update record</button></div>}
                                            </> :
                                            <>
                                                {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ?
                                                    <div className="add_btn"><button type="button" className="btn" onClick={e => { addNewSection(e); }}>Add new record</button></div>
                                                    : <div className="save_btn disable"><button type="button" className="btn">Add new record</button></div>}
                                            </>
                                        }
                                    </div>
                                    {showDistanceSecondFields ?
                                        <>
                                            <Row className="mt-2 mb-3">
                                                <Col sm={3}>
                                                    <Form.Group controlId="from" className="mb-2">
                                                        <Typeahead
                                                            id='cityAutocomplete'
                                                            labelKey="name"
                                                            onChange={setFromSelection}
                                                            options={fromCityList}
                                                            placeholder="Choose from city..."
                                                            selected={fromSelection}
                                                        />
                                                    </Form.Group>

                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="Province/State" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="From Province/State">
                                                            <Form.Select aria-label="Floating label select example" name="FromProvince" onChange={(e) => { setSelectState(e.target.value); Cityupdate(e); setCountry(e) }} value={cityDistance?.FromProvince || ""} required>

                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        fromProvince?.data?.map((data, index) => (
                                                                            <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                                                                        ))

                                                                    }
                                                                </>

                                                            </Form.Select>

                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="country" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="From Country">
                                                            <Form.Select aria-label="Floating label select example" onChange={e => { handlecountry(e); Cityupdate(e) }} placeholder="From Country" name="FromCountry" value={cityDistance?.FromCountry || ""} required>

                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        fromCountry?.data?.map((data, index) => (
                                                                            <option key={index} value={data.countryId}>{data.countryname}</option>
                                                                        ))

                                                                    }
                                                                </>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="Miles">
                                                        <FloatingLabel controlId="floatingInput" label="Miles" >
                                                            <Form.Control type="text" placeholder="Miles" name="Miles" onChange={e => Cityupdate(e)} value={cityDistance?.Miles || ""} min='0' />
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            {/* 2nd Row */}

                                            <Row className="mt-2 mb-3">
                                                <Col sm={3}>
                                                    <Form.Group controlId="from" className="mb-2">
                                                        <Typeahead
                                                            id='cityAutocomplete'
                                                            labelKey="name"
                                                            onChange={setToSelection}
                                                            options={toCityList}
                                                            placeholder="Choose to city..."
                                                            selected={toSelection}
                                                        />
                                                    </Form.Group>

                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="Province/State" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="To Province/State">
                                                            <Form.Select aria-label="Floating label select example" name="ToProvince" placeholder="To Province/Statey" onChange={(e) => { setSelectState(e.target.value); Cityupdate(e); setCountry(e) }} value={cityDistance?.ToProvince || ""} required>
                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        toProvince?.data?.map((data, index) => (
                                                                            <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                                                                        ))
                                                                    }
                                                                </>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="country" className="mb-2">
                                                        <FloatingLabel controlId="floatingSelectGrid" label="To Country">
                                                            <Form.Select aria-label="Floating label select example" onChange={e => { handletocountry(e); Cityupdate(e) }} placeholder="To Country" name="ToCountry" value={cityDistance?.ToCountry || ""} required>
                                                                <option>Select</option>:
                                                                <>
                                                                    {
                                                                        toCountryList?.data?.map((data, index) => (
                                                                            <option key={index} value={data.countryId}>{data.countryname}</option>
                                                                        ))
                                                                    }
                                                                </>
                                                            </Form.Select>
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={3}>
                                                    <Form.Group controlId="In Minutes">
                                                        <FloatingLabel controlId="floatingInput" label="In Minutes" >
                                                            <Form.Control type="text" placeholder="In Minutes" name="TimeInMinutes" onChange={e => Cityupdate(e)} value={cityDistance?.TimeInMinutes || ""} required min='0' />
                                                        </FloatingLabel>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </>
                                        : null}
                                    {/* table */}
                                    <Row>
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Action</th>
                                                    <th>From City</th>
                                                    <th>To City</th>
                                                    <th>From Province</th>
                                                    <th>To Province</th>
                                                    <th>From Country</th>
                                                    <th>To Country</th>
                                                    <th>Miles</th>
                                                    <th>In Minutes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cityDistanceLists?.length > 0 ? (
                                                    cityDistanceLists?.map((data) => {
                                                        return (
                                                            <tr key={data.citydistanceID}>
                                                                <td>
                                                                    <div className="action_btn">
                                                                        {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Update') !== -1 ?
                                                                            <span className="edit_btn" onClick={() => EditcityItem(data?.CityDistanceId)}><Edit /></span>
                                                                            : <span className="edit_btn disable"><Edit /></span>}
                                                                        {permission.find(event => event.moduleId === 45)?.permissionType?.indexOf('Delete') !== -1 ?
                                                                            <span className="del_btn" onClick={() => handleDistanceOpen(data?.CityDistanceId)}><Delete /></span>
                                                                            : <span className="del_btn disable" ><Delete /></span>}
                                                                        <div>
                                                                            <Dialog open={distanceOpen} onClose={handleDistanceClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                                                                <DialogTitle id="alert-dialog-title">
                                                                                    Delete Confirmation
                                                                                </DialogTitle>
                                                                                <DialogContent>
                                                                                    <DialogContentText id="alert-dialog-description">
                                                                                        Are you sure you want to delete this row?
                                                                                    </DialogContentText>
                                                                                </DialogContent>
                                                                                <DialogActions>
                                                                                    <Button className="btn-secondary" onClick={handleDistanceClose}>Cancel</Button>
                                                                                    <Button className="btn-primary" onClick={() => { deleteCityCustomerList(); }} autoFocus> Confirm</Button>
                                                                                </DialogActions>
                                                                            </Dialog>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>{data?.FromCityName || "N/A"}</td>
                                                                <td>{data?.ToCityName || "N/A"}</td>
                                                                <td>{getProvinceName(data?.FromProvince) || "N/A"}</td>
                                                                <td>{getProvinceName(data?.ToProvince) || "N/A"}</td>
                                                                <td>{data.FromCountry === 1 ? "CAN" : "USA"}</td>
                                                                <td>{data.ToCountry === 1 ? "CAN" : "USA"}</td>
                                                                <td>{data.Miles}</td>
                                                                <td>{data.TimeInMinutes}</td>
                                                            </tr>
                                                        )
                                                    })
                                                ) : (
                                                    <p>No data found </p>
                                                )}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </fieldset>
                            </Col>
                        </Row>



                    </fieldset>
                </Col>
            </Row>


        </>
    );
}
export default EditCityDistance;