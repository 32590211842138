import { Col, Row, Form, FloatingLabel, Button } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import { Save } from "@material-ui/icons";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useCustomer from "../../../context/CustomerContext";
import { useUpdateContractDataMutation } from "../../../features/contractInfo/contractInfoSlice";
import { useCustomData } from "../../Sidebar/Permission";
import { useModifyHistoryMutation } from "../../../features/customer/history/historySlice";
import { useGetCustomerQuery } from "../../../features/customer/customerSlice";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
const SaveContractdata = ({ showedithandler }) => {
  const { refetch } = useGetCustomerQuery()
  const [permission] = useCustomData()
  const [message, setMessage] = useState("Modified the contract info section")
  const [updateContractData] = useUpdateContractDataMutation()
  const [modifyHistory] = useModifyHistoryMutation()
  const { singleAccount, contractInfo, handleAccount } = useCustomer();
  const account = singleAccount && singleAccount[0]
  const [ratecardaccount, setRatecardaccount] = useState(contractInfo && contractInfo[0]);
  const toastId = React.useRef(null);

  const [open, setOpen] = useState(false);
  const Contracthandler = (e) => {
    setRatecardaccount(ratecardaccount => ({ ...ratecardaccount, [e.target.name]: e.target.value }));
  }

  const handleClick = (e) => {
    if (open) {
      toastId.current = toast.warn('Changing the Fuel Surcharge Method will affect the Fuel Surcharge Section', {
        position: "top-right"
      });
    }

    setOpen(!open);
  }

  const contractInfoData = {
    account: account?.account_No,
    rateCardId: ratecardaccount?.rateCardId,
    fuelSurchargeMethod: ratecardaccount?.fuelSurchargeMethod,
    distanceType: ratecardaccount?.distanceType,
    multiplier: ratecardaccount?.multiplier,
    lbsPerLinearFT: ratecardaccount?.lbsPerLinearFT,
    lbsPerCuFT: ratecardaccount?.lbsPerCuFT,
    longFreightThreshold: ratecardaccount?.longFreightThreshold,
    longFreightAmount: ratecardaccount?.longFreightAmount,
    maxHeightStart: ratecardaccount?.maxHeightStart,
    maxHeightOverride: ratecardaccount?.maxHeightOverride,
    description: ratecardaccount?.description

  }
  const prevCount = usePrevious(contractInfoData);
  const editContractList = async () => {
    if (JSON?.stringify(prevCount) === JSON?.stringify(contractInfoData)) {
      setTimeout(function () {
        toast.info("No Changes found on this contract section !", {
          position: "top-right",
        })
      });
    }
    else {
      const updateContractInfo = await updateContractData(contractInfoData)
      handleAccount(account?.account_No)
      if (updateContractInfo.data.statusCode === 200) {
        setTimeout(function () {
          toast.success("Contract info data updated successfully !", {
            position: "top-right",
          })
        });
      }
    }
    if (JSON?.stringify(prevCount) !== JSON?.stringify(contractInfoData)) {
      const modifieddata = {
        AccountNo: account.account_No,
        CustomerName: account.customer,
        Modifiedsection: message
      }
      await modifyHistory(modifieddata)
    }
    showedithandler();
    refetch()
  }

  console.log(permission.find(event => event.moduleId === 40)?.permissionType?.indexOf('Update') !== -1);


  return (
    <>
      {/* Contract info  section*/}
      {contractInfo?.length > 0 ? (
        <Row className="mb-2 mt-2">
          <Col sm={12}>
            <fieldset >
              <div className="button-section" style={{ justifyContent: "end" }}>
                {permission.find(event => event.moduleId === 40)?.permissionType?.indexOf('Update') !== -1 ?
                  <Button className="add-btn" onClick={e => { editContractList(e); }} style={{ width: "135px", paddingRight: "35px" }}>
                    Save{" "}<span style={{ padding: "6px" }}> <Save /></span></Button>
                  : <Button className="add-btn disable" style={{ width: "135px", paddingRight: "35px" }}>
                    Save{" "}<span style={{ padding: "6px" }}> <Save /></span></Button>}
              </div>
              <>
                <Row className="mt-2 mb-3">
                  <Col sm={3}>
                    <Form.Group controlId="fuelsurcharge">
                      <FloatingLabel controlId="floatingSelectGrid" label="Fuel Surcharge Method">
                        <Form.Select disabled={true} onFocus={() => setOpen(true)} onBlur={() => setOpen(false)} onClick={handleClick} aria-label="Floating label select example" name="fuelSurchargeMethod" onChange={e => Contracthandler(e)} value={ratecardaccount?.fuelSurchargeMethod} required>
                          <option>Select</option>
                          <option value="1">General</option>
                          <option value="2">Distance</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Distancetype">
                      <FloatingLabel controlId="floatingSelectGrid" label="Distance Type">
                        <Form.Select disabled={true} aria-label="Floating label select example" name="distanceType" onChange={e => Contracthandler(e)} value={ratecardaccount?.distanceType} required >
                          <option>Select</option>
                          <option value="Z">Zone</option>
                          <option value="R">Region</option>
                        </Form.Select>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Lbs/Linear FTn">
                      <FloatingLabel controlId="floatingInput" label="Lbs/Linear FT" style={{ fontSize: "13px" }}>
                        <Form.Control type="number" placeholder="Lbs/Linear FT" name="lbsPerLinearFT" onChange={e => Contracthandler(e)} value={ratecardaccount?.lbsPerLinearFT || ''} required />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Lbs/Cu FT">
                      <FloatingLabel controlId="floatingInput" label="Lbs/Cu FT" >
                        <Form.Control type="number" placeholder="Lbs/Cu FT" name="lbsPerCuFT" onChange={e => Contracthandler(e)} value={ratecardaccount?.lbsPerCuFT || ''} required />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-2 mb-3">
                  <Col sm={3}>
                    <Form.Group controlId="Max Height Start">
                      <FloatingLabel controlId="floatingInput" label="Max Height Start" >
                        <Form.Control type="number" placeholder="Max Height Start" name="maxHeightStart" onChange={e => Contracthandler(e)} value={ratecardaccount?.maxHeightStart || ''} required />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Max Height Override">
                      <FloatingLabel controlId="floatingInput" label="Max Height Override" >
                        <Form.Control type="number" placeholder="Max Height Override" name="maxHeightOverride" onChange={e => Contracthandler(e)} value={ratecardaccount?.maxHeightOverride || ''} required />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Long Freight Threshold">
                      <FloatingLabel controlId="floatingInput" label="Long Fright Threshold" >
                        <Form.Control type="number" placeholder="Long Fright Threshold" name="longFreightThreshold" onChange={e => Contracthandler(e)} value={ratecardaccount?.longFreightThreshold || ''} required />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Long Freight Amount">
                      <FloatingLabel controlId="floatingInput" label="Long Freight Amount" >
                        <Form.Control type="number" placeholder="Long Freight Amount" name="longFreightAmount" onChange={e => Contracthandler(e)} value={ratecardaccount?.longFreightAmount || ''} required />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3">
                  <Col sm={12}>
                    <Form.Control
                      as="textarea"
                      placeholder="Description"
                      style={{ height: '100px' }}
                      name="description"
                      value={ratecardaccount?.description || ""}
                      onChange={e => Contracthandler(e)}
                    />
                  </Col>
                </Row>
              </>
            </fieldset>
          </Col>
        </Row>
      ) : (
        <Row className="mb-2 mt-2">
          <Col sm={12}>
            <p className="text-center">No data Found</p>
          </Col>
        </Row>
      )}
    </>
  );
}
export default SaveContractdata