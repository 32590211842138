import { CloudUpload, Delete, Description, Image } from "@material-ui/icons";
import { Box } from "@mui/material";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Hint, Typeahead } from "react-bootstrap-typeahead";
import { Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDeleteDocumentMutation, useGetDriverNumberQuery, useLazyGetClassAccessQuery } from "../../../features/admin/employee/employeeSlice";
import { useGetCountryQuery, useLazyGetStateQuery } from "../../../features/countryState/countryStateSlice";
import CountryProvince from "../../CountryProvince";
import postalCodeMasking from "../../Customer/PostalCodeMasking";




const DetailsRecord = ({ register, errors, control, uploadedFiles, setUploadedFiles, employeeData, picture, setPicture, getEmployeeDetails,iddd , selectToState, setSelectToState, className, setClassName,setPostal,postal,doc,imag, getValues,setUsaDriver,usaDriver}) => {
    const { handlecountry, handleProvince, city, newHandleCountry, country } = CountryProvince()
    const cityList = city?.data.map((a) => { return { name: a.city, id: a.id } });
    const [deleteDocument] = useDeleteDocumentMutation()
    const { data: driverlist } = useGetDriverNumberQuery()
    const { data: toCountry } = useGetCountryQuery()
    const [fetchToState, toProvince] = useLazyGetStateQuery()
    const [getClassAccess, classNameByLicense] = useLazyGetClassAccessQuery()
    const driverSelected = getValues("driver")
    const [selectedCountry, setSelectedCountry] = useState(employeeData?.licenseByCountryId || ""); // to track selected country for license
    const [driver, setDriver] = useState(employeeData?.driver || false);
    const [provinceList, setProvinceList] = useState([])
    const [countryList, setCountryList] = useState([])
    const defaultCity =  employeeData?.city ? [{ id: employeeData.cityID, name: employeeData.city }] : [];
    //const [usaDriver, setUsaDriver] = useState(employeeData?.usaDriver || false);
    // select Province list
    const getStateData = async (selectState) => { fetchToState(selectState) }
    const getClassName = async (className) => { getClassAccess(className) }

    // adding local state so that they can be updated based on user input
    const [hireDate, setHireDate] = useState(moment(employeeData?.hireDate).utc().format('YYYY-MM-DD') || "");
    const [driverLicenseExpiryDate, setDriverLicenseExpiryDate] = useState(moment(employeeData?.driveringLicenseExpiryDate).utc().format('YYYY-MM-DD') || "");

    useEffect(() => {
        setDriver(employeeData?.driver || false);
        setUsaDriver(employeeData?.usaDriver || false);
      }, [employeeData]);

      // update local state when employeeData changes
      useEffect(() => {
        setHireDate(moment(employeeData?.hireDate).utc().format('YYYY-MM-DD') || "");
        setDriverLicenseExpiryDate(moment(employeeData?.driveringLicenseExpiryDate).utc().format('YYYY-MM-DD') || "");
    }, [employeeData]);

      useEffect(() => {
        const fetchClassesAndSetSelected = async () => {
            if (employeeData?.licenseByProvinceId) {
                // Fetch classes based on the employee's `licenseByProvinceId`
                await getClassAccess(employeeData.licenseByProvinceId);
    
                // Once the class data is fetched, find the class with ID equal to `classdropdownCanID`
                const selectedClass = classNameByLicense?.data?.data?.find(classData => 
                    classData.classInhancedID === employeeData.classdropdownCanID
                );
    
                // Set the `className` state to the selected class's ID
                setClassName(selectedClass?.classInhancedID || "");
            }
        };
    
        fetchClassesAndSetSelected();
    }, [employeeData?.licenseByProvinceId, classNameByLicense?.data]);

    const handletocountry = async (e) => {
        const addressData = e.target.value;
        await getStateData(addressData)
        setSelectToState("Select");
        setSelectedCountry(Number(addressData));  // Update selected country state
    }

    //ClassName API
    const classHandler = async (e) => {
        const addressData = e.target.value;
        await getClassName(addressData)
        setClassName("Select")
    }


    // Upload Image
    function handleChange(e) {
        let picture = e.target.files[0];
        if (picture.size > 10e5) {
            toast.info("Please upload a file smaller than 1 MB", {
                position: "top-center",
            })
        }
        else {
            let reader = new FileReader()
            reader.onload = function (e) {
                setPicture(e.target.result)
            }
            reader.readAsDataURL(e.target.files[0])
        }
    }


    //Upload Files
    const handleUploadFiles = (files) => {
        const uploaded = [...uploadedFiles]
        let limitExceeded = false;
        files.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
            }
        })
        if (!limitExceeded) setUploadedFiles(uploaded)
    }

    const handleFileEvent = (e) => {
        let uploadedFiles = e.target.files[0];
        if (uploadedFiles.size > 4903980) {
            toast.info("Please upload a file smaller than 5 MB", {
                position: "top-center",
            })
        }
        else {
            const chooseFiles = Array.prototype.slice.call(e.target.files)
            handleUploadFiles(chooseFiles)
        }
    }

    // Delete Files
    const deleteByIndex = index => {
        setUploadedFiles(oldValues => {
            return oldValues.filter((_, i) => i !== index)
        })
    }

    const handleInput = (e,onChange) => {
        const formattedPostalCode = postalCodeMasking(e.target.value)
        setPostal(formattedPostalCode);
        onChange(e); 
    };
    const productImageField = register("CountryId", { required: 'Field is required'});
    const productImageField1 = register("ProvineStateID", { required: 'Field is required', });
    const licenseProvince = register("LicenseByProvinceId", {});
    const licenseCountry = register("LicenseByCountryId", {});
    const classInhanced = register("ClassInhancedID", {});
  
    return (
        <>
            <Row className="mt-2" >
                <Col sm={10}>
                    {/* First row */}
                    <Row>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="number" autoComplete="off" placeholder="Pay No." name="PayNo" defaultValue={employeeData?.payNo || ""} disabled {...register('PayNo', {})} />
                                <label htmlFor="payno">Pay No.</label>
                                {/* <p className="error-message">{errors.PayNo?.message}</p> */}
                            </div>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="date" autoComplete="off" placeholder="Hire Date" name="HireDate" {...register('HireDate', { required: 'Field is required', })} value={hireDate} onChange={(e) => setHireDate(e.target.value)}/>
                                <label htmlFor="hireDate">Hire Date</label>
                            {errors.HireDate?.message && (<p className="error-message">{" * " + errors.HireDate?.message}</p>)}
                            </div>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Position" name="Position" {...register('Position', { required: 'Field is required', })} defaultValue={employeeData?.position} />
                                <label htmlFor="position">Position</label>
                                {errors.Position?.message && (<p className="error-message">{" * " + errors.Position?.message}</p>)}
                            </div>
                        </Col>
                        <Col style={{ width: "266px", marginTop: '1%' }} sm={2}>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" name="driver" {...register('driver', {})} checked={driver} onChange={(e) => {setDriver(e.target.checked); if (!e.target.checked) {setUsaDriver(false); }}}/>
                                <label className="form-check-label" htmlFor="inlineCheckbox1" style={{ fontSize: "15px" }}>Driver</label>
                                {/* {errors.USADriver?.message && (<p className="error-message">{" * " + errors.driver?.message}</p>)} */}
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="inlineCheckbox1" name="USADriver" {...register('USADriver', {})}  checked={usaDriver}  disabled={!driver}
                  onChange={(e) => setUsaDriver(e.target.checked)}  />
                                <label className="form-check-label" htmlFor="inlineCheckbox1" style={{ fontSize: "15px" }}>USA Driver</label>
                                {errors.USADriver?.message && (<p className="error-message">{" * " + errors.USADriver?.message}</p>)}
                            </div>
                        </Col>
                        <Col>
                            <Form.Group controlId="drivernumber">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select a driver number">
                                    <Form.Select aria-label="Floating label select example" name="Driverid" {...register('Driverid', { required: driverSelected ? 'Field is required' : false, })} >
                                        <option value={employeeData?.driverid ? employeeData?.driverid : ""}>{employeeData?.driverNumber || "Select"}</option>
                                        <>
                                            {
                                                driverlist?.data.map((data, index) => (
                                                    <option key={index} value={data.empDriverId} selected={data?.empDriverId === employeeData?.driverid ? true : false}>{data.driverNumber}</option>
                                                ))
                                            }
                                        </> 
                                    </Form.Select>
                                </FloatingLabel>
                                {errors.Driverid?.message && (<p className="error-message">{" * " + errors.Driverid?.message}</p>)}
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* Second Row */}
                    <Row className="mt-4">
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="First Name" name="Firstname" {...register('Firstname', { required: 'Field is required', pattern: { value: /^([a-zA-Z]+|[a-zA-Z]+\s{1}[a-zA-Z]{1,}|[a-zA-Z]+\s{1}[a-zA-Z]{3,}\s{1}[a-zA-Z]{1,})$/g, message: "Please enter a valid name" } })} defaultValue={employeeData?.firstname} />
                                <label htmlFor="firstname">First Name</label>
                                {errors.Firstname?.message && (<p className="error-message">{" * " + errors.Firstname?.message}</p>)}
                            </div>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Last Name" name="Lastname" {...register('Lastname', { required: 'Field is required', pattern: { value: /^([a-zA-Z]+|[a-zA-Z]+\s{1}[a-zA-Z]{1,}|[a-zA-Z]+\s{1}[a-zA-Z]{3,}\s{1}[a-zA-Z]{1,})$/g, message: "Please enter a valid name" } })} defaultValue={employeeData?.lastname} />
                                <label htmlFor="lastname">Last Name</label>
                                {errors.Lastname?.message && (<p className="error-message">{" * " + errors.Lastname?.message}</p>)}
                            </div>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="number" autoComplete="off" placeholder="Street No." name="StreetNo"  {...register('StreetNo', { required: 'Field is required', pattern: { value: /^[0-9]+$/, message: "Please enter a numeric value" } })} defaultValue={employeeData?.streetNo} min="1"/>
                                <label htmlFor="Street No.">Street No.</label>
                                {errors.StreetNo?.message && (<p className="error-message">{" * " + errors.StreetNo?.message}</p>)}
                            </div>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Street Name" name="StreetName" {...register('StreetName', { required: 'Field is required', pattern: { value: /^([a-zA-Z]+|[a-zA-Z]+\s{1}[a-zA-Z]{1,}|[a-zA-Z]+\s{1}[a-zA-Z]{3,}\s{1}[a-zA-Z]{1,})$/g, message: "Please enter a valid name" } })} defaultValue={employeeData?.streetName} />
                                <label htmlFor="Street Name">Street Name</label>
                                {errors.StreetName?.message && (<p className="error-message">{" *" + errors.StreetName?.message}</p>)}
                            </div>
                        </Col>
                        <Col>
                        <Controller
                                control={control}
                                name="CityID"
                                rules={{required: "Field is required"}}
                                render={({ field, formState}) => {
                              return <>
                                    <Typeahead
                                        placeholder="Choose a City..."
                                        {...field}
                                        name="CityID"
                                        labelKey="name"
                                        id="CityID"
                                        options={cityList || []}
                                        onChange={async(selected) => {
                                            field.onChange(selected)
                                            if(selected.length === 0) {
                                                setProvinceList([])
                                            }
                                                try {
                                                    const pList = await handleProvince(selected[0].id);
                                                    if (pList && pList.data && pList.data.data) {
                                                        setProvinceList(pList.data.data);
                                                    }
                                                } catch (error) {
                                                    console.error("Error fetching province data:", error);
                                                    setProvinceList([]); 
                                                }
                                        }}
                                        selected={field.value != null  ? field.value  : defaultCity} 
                                        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
                                          return (
                                            <Box sx={{">div":{flexDirection:"column"}}}>
                                            <Hint>
                                              <FloatingLabel controlId="floatingLabel" label="Choose a City...">
                                                <Form.Control
                                                  {...inputProps}
                                                  value={inputProps.value || ""}
                                                  ref={(node) => {
                                                    inputRef(node);
                                                    referenceElementRef(node);
                                                  }}
                                                />
                                              </FloatingLabel>
                                            </Hint>
                                            </Box>
                                          );
                                        }}
                                    />
                                    {formState?.errors?.CityID?.message && (<p className="error-message">{" *" + formState.errors.CityID.message}</p>)}
                                    </>
                                }}
                            />
                        </Col>
                    </Row>
                    {/* Third Row */}
                    <Row className="mt-4">
                        <Col>
                            <Form.Group controlId="province">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select Province">
                                    <Form.Select aria-label="Floating label select example" name="ProvineStateID"  {...productImageField1} 
                                    onChange={async (e) => {
                                        setSelectToState(e.target.value)
                                         productImageField1.onChange(e);  
                                         const cList = await newHandleCountry(e.target.value);
                                         setCountryList(cList.data.data);   
                                         }}>
                                        <option value={(provinceList?.length === 0 && employeeData?.provineStateID) ? employeeData.provineStateID : ""}>
                                            {(provinceList?.length === 0 && employeeData?.provineState) ? employeeData.provineState : "Select"}
                                        </option>
                                        {
                                            provinceList?.map((data, index) =>{ 
                                                return    <option key={index} value={data.provineStateID} selected={data?.provineStateID === getValues("ProvineStateID") }>{data.provineSateName}</option>
                                            })

                                        }
                                    </Form.Select>
                                </FloatingLabel>
                                {errors.ProvineStateID?.message && (<p className="error-message">{" * " + errors.ProvineStateID?.message}</p>)}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="country">
                                <FloatingLabel controlId="floatingSelectGrid" label="Select Country">
                                    <Form.Select aria-label="Floating label select example" name="CountryId" {...productImageField} onClick={(e) => { productImageField.onChange(e); handlecountry(e); }}>
                                       {(provinceList?.length === 0 && employeeData?.provineStateID) ? 
                                       <option value={employeeData?.countryId }>
                                        {country?.data?.find((c) => c.countryId === employeeData?.countryId).countryname }
                                       </option> : <option value="">Select</option>}
                                        {
                                            countryList?.map((data, index) => (
                                                <option key={index} value={data.countryId} selected={data?.countryId === employeeData?.countryId}>{data.countryname}</option>
                                            ))

                                        }
                                    </Form.Select>
                                </FloatingLabel>
                               {errors.CountryId?.message && (<p className="error-message">{" * " + errors.CountryId?.message}</p>)}
                            </Form.Group>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="text" autoComplete="off" placeholder="Postal Code" name="PostalCode" value={postal} {...register('PostalCode', { required: 'Field is required' })}                              
                                 onChange={(e) => handleInput(e, register('PostalCode').onChange)}/>
                                <label htmlFor="Postal Code">Postal Code</label>
                                {errors.PostalCode?.message && (<p className="error-message">{" * " + errors.PostalCode?.message}</p>)}
                            </div>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Controller type="text" placeholder="Primary Number"
                                    name="primaryNumber"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <InputMask mask="(999) - 999 9999" value={value || ""} onChange={onChange}>
                                            {(inputProps) => (
                                                <input
                                                    {...inputProps}
                                                    type="tel"
                                                    className="form-control"
                                                    placeholder="Primary Number"
                                                    name="primaryNumber"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                    {...register("primaryNumber", { required: 'Field is required', minLength: { value: 4, message: 'Please enter 10 digit contact number' } })} />
                                <label htmlFor="contact">Primary Number</label>
                            </div>
                            {errors.primaryNumber?.message && (<p className="error-message">{" * " + errors.primaryNumber?.message}</p>)}
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Controller type="text" placeholder="Secondary Number"
                                    name="secondaryNumber"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <InputMask mask="(999) - 999 9999" value={value || ""} onChange={onChange}>
                                            {(inputProps) => (
                                                <input
                                                    {...inputProps}
                                                    type="tel"
                                                    className="form-control"
                                                    placeholder="Secondary Number"
                                                    name="secondaryNumber"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                    {...register("secondaryNumber", {  minLength: { value: 4, message: 'Please enter 10 digit contact number' } })} />
                                <label htmlFor="contact">Secondary Number</label>
                            </div>
                            {errors.secondaryNumber?.message && (<p className="error-message">{" * " + errors.secondaryNumber?.message}</p>)}
                        </Col>
                    </Row>
                    {/* Fourth Row */}
                    <Row className="mt-4">
                    <Col>
                            <div className="form-floating">
                                <Controller type="text" placeholder="Work Phone"
                                    name="workPhone"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <InputMask mask="(999) - 999 9999" value={value || ""} onChange={onChange} >
                                            {(inputProps) => (
                                                <input
                                                    {...inputProps}
                                                    type="tel"
                                                    className="form-control"
                                                    placeholder="Work Phone"
                                                    name="workPhone"
                                                />
                                            )}
                                        </InputMask>
                                    )}
                                    {...register("workPhone", { required: driverSelected ? 'Field is required' : false, minLength: { value: 10, message: 'Please enter 10 digit contact number' } })} />
                                <label htmlFor="contact">Work Phone</label>
                            </div>
                            {errors.workPhone?.message && (<p className="error-message">{" * " + errors.workPhone?.message}</p>)}
                        </Col>
                        <Col >
                            <div className="form-floating">
                                <Form.Control type="email" autoComplete="off" placeholder="EmailAddress" name="EmailAddress" defaultValue={employeeData?.emailAddress}{...register('EmailAddress', {
                                    required: 'Field is required', pattern: { value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, message: "Please enter a valid email" }
                                })} />
                                <label htmlFor="Email Address">Email Address</label>
                               {errors.EmailAddress?.message && (<p className="error-message">{" * " + errors.EmailAddress?.message}</p>)}
                            </div>
                        </Col>
                        <Col>
                            <div className="form-floating">
                                <Form.Control type="email" autoComplete="off" placeholder="Work Email Address" name="WorkEmailAddress" defaultValue={employeeData?.workEmailAddress} {...register('WorkEmailAddress', {})} />
                                <label htmlFor="Work Email Address">Work Email Address</label>
                            </div>
                        </Col>
                    </Row>
                    {/* File upload */}
                    <div className="upload-section mt-4">
                        <div className="upload-file">
                            <h6>Upload your document here</h6>
                            <div className="drop_box">
                                <CloudUpload />
                                <p>Files Supported: PDF, TEXT, DOC , DOCX</p>
                                <input type="file" {...register('Documents')} name="Documents" accept=".doc,.docx,.pdf" id="fileUpload" multiple onChange={handleFileEvent} />
                                <button className="btn">Click here to upload</button>
                            </div>
                        </div>
                        {/* list of uploaded document */}
                        {uploadedFiles.map((file, index) => (
                            <div className="document-list mb-1" key={index}>
                                <div className="document-name"> <Description /> <p>{file.name} </p></div>
                                <div className="delete-role" onClick={() => { deleteByIndex(index) }}><Delete /></div>
                            </div>
                        ))}
                        {employeeData?.employeeId ?
                            <>
                                {doc?.map((file, index) => (
                                    <div className="document-list mb-1" key={index}>
                                        <a href={`${file?.documentPath}`}><div className="document-name"> <Description /> <p>{file?.documentName} </p></div></a>
                                        <div className="delete-role" onClick={async () => {                                             
                                            await deleteDocument(file.documentId); getEmployeeDetails(iddd) }}><Delete /></div>
                                    </div>
                                ))}
                            </>
                            : ""}
                    </div>
                    {/* operation section */}
                    <fieldset className="user-role-form mt-3" style={{ background: "#e1e1e1" }}>
                        <Row className="3">
                            <Col>
                                <div className="form-floating">
                                    <Form.Control type="text" autoComplete="off" placeholder="Opertor name (mandory for dispatcher)" name="OpertorName" {...register('OpertorName', {})} defaultValue={employeeData?.opertorName} />
                                    <label htmlFor="Opertor name (mandory for dispatcher)">Operator name (mandatory for dispatcher)</label>
                                </div>
                            </Col>
                            <Col>
                                <div className="form-floating">
                                    <Form.Control type="text" autoComplete="off" placeholder="GPS number" name="GPSNumber" {...register('GPSNumber', {})} defaultValue={employeeData?.gpsNumber} />
                                    <label htmlFor="GPS number">GPS number</label>
                                </div>
                            </Col>
                        </Row>
                    </fieldset>
                    {/* driver licence details */}
                    <fieldset className="user-role-form mt-4" style={{ background: "#3c65bb80" }}>
                        <label className="label-heading">Driver License Details </label>
                        {/* <Row>
                            <Col sm={3}></Col>
                            <Col sm={3}></Col>
                            <Col sm={3}>
                                <fieldset style={{ height: "48px", marginBottom: "10px" }}>
                                    <label className="label-heading">Used by border crossing by USA</label>
                                    <div className="form-check form-check-inline" style={{ position: "relative", top: "-20px" }}>
                                        <input className="form-check-input" type="checkbox" id="inlineCheckbox1" name="InhancedLicense" {...register('InhancedLicense', {})} defaultChecked={employeeData?.inhancedLicense} />
                                        <label className="form-check-label" htmlFor="inlineCheckbox1" style={{ fontSize: "15px" }}>USA Driver</label>
                                    </div>
                                </fieldset>
                            </Col>
                            <Col sm={3}>
                                <div className="form-floating">
                                    <Form.Control type="text" autoComplete="off" placeholder="Driving License" name="DrivingLicense" defaultValue={employeeData?.drivingLicense} {...register('DrivingLicense', { required: driverSelected ? 'Field is required' : false, })} />
                                    <label htmlFor="Driving License">Driving License</label>
                                    {errors.DrivingLicense?.message && (<p className="error-message">{" * " + errors.DrivingLicense?.message}</p>)}
                                </div>
                            </Col>
                        </Row> */}
                        <Row className="3">
                             <Col sm={3}>
                                <div className="form-floating">
                                    <Form.Control type="text" autoComplete="off" placeholder="Driving License" name="DrivingLicense" defaultValue={employeeData?.drivingLicense} {...register('DrivingLicense', { required: driverSelected ? 'Field is required' : false, })} />
                                    <label htmlFor="Driving License">Driving License</label>
                                    {errors.DrivingLicense?.message && (<p className="error-message">{" * " + errors.DrivingLicense?.message}</p>)}
                                </div>
                            </Col>
                            <Col sm={3}>
                                <div className="form-floating">
                                    <Form.Control type="date" autoComplete="off" placeholder="Expiry date" name="DriveringLicenseExpiryDate" value={driverLicenseExpiryDate} {...register('DriveringLicenseExpiryDate', { required: driverSelected ? 'Field is required' : false, })} onChange={(e) => setDriverLicenseExpiryDate(e.target.value)} min={new Date().toISOString().split('T')[0]}/>
                                    <label htmlFor="Expiry date">Expiry date</label>
                                    <p className="error-message">{errors.DriveringLicenseExpiryDate?.message}</p>
                                </div>
                            </Col>
                            <Col sm={3}>
                                <Form.Group controlId="drivernumber">
                                    <FloatingLabel controlId="floatingSelectGrid" label="License by country">
                                        <Form.Select 
                                            aria-label="Floating label select example" 
                                            name="LicenseByCountryId" 
                                            {...register('LicenseByCountryId', { required: driverSelected ? 'Field is required' : false })} 
                                            onChange={(e) => { licenseCountry.onChange(e); handletocountry(e); }}>
                                            <option value={employeeData?.licenseByCountryId || ""}>Select</option>
                                            {
                                                toCountry?.data?.map((data, index) => (
                                                    <option key={index} value={data.countryId} selected={data?.countryId === employeeData?.licenseByCountryId}>{data.countryname}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </FloatingLabel>
                                    <p className="error-message">{errors.LicenseByCountryId?.message}</p>
                                </Form.Group>
                            </Col>
                                                        <Col sm={3}>
                                <Form.Group controlId="drivernumber">
                                    <FloatingLabel controlId="floatingSelectGrid" label="License by province">
                                        <Form.Select aria-label="Floating label select example" name="LicenseByProvinceId" value={selectToState} {...licenseProvince} {...register("LicenseByProvinceId", {
                                        required: driverSelected ? "Field is required" : false, // Conditional validation
                                    })}onChange={(e) => { licenseProvince.onChange(e); setSelectToState(e.target.value); classHandler(e) }}>
                                            <option value={employeeData?.licenseByProvinceId ? employeeData?.licenseByProvinceId : ""}>{employeeData?.licenseByProvinceName || "Select"}</option>
                                            {
                                                toProvince?.data?.data?.map((data, index) => (
                                                    <option key={index} value={data.provineStateID} selected={data?.ProvineSateID === employeeData?.licenseByProvinceId ? true : false}>{data.provineSateName}</option>
                                                ))

                                            }
                                        </Form.Select>
                                    </FloatingLabel>
                                    <p className="error-message">{errors.LicenseByProvinceId?.message}</p>
                                </Form.Group>
                            </Col>
                            {selectedCountry === 1 && (
                                <Col sm={3}>
                                    <Form.Group controlId="drivernumber">
                                        <FloatingLabel controlId="floatingSelectGrid" label="ClassName">
                                            <Form.Select aria-label="Floating label select example" name="ClassInhancedID" value={className|| ""} {...classInhanced} onChange={(e) => { classInhanced.onChange(e); setClassName(e.target.value); }}>
                                            <option value="">Select</option>
                                        {classNameByLicense?.data?.data?.map((data, index) => (
                                            <option key={index} value={data?.classInhancedID}>
                                                {data?.classInhancedname}
                                            </option>
                                        ))}
                                         </Form.Select>
                                        </FloatingLabel>
                                        <p className="error-message">{errors.ClassInhancedID?.message}</p>
                                    </Form.Group>
                                </Col>
                            )}

                            {selectedCountry === 2 && (
                                <Col sm={3}>
                                    <div className="form-floating">
                                        <Form.Control type="text" autoComplete="off" placeholder="ClassName" name="ClassUSInhancedID" {...register('ClassUSInhancedID', { required: driverSelected ? 'Field is required' : false, })} defaultValue={employeeData?.classdropdownUS || ""} />
                                        <label htmlFor="ClassName">ClassName</label>
                                        <p className="error-message">{errors.ClassUSInhancedID?.message}</p>
                                    </div>
                                </Col>
                            )}
                            
                        </Row>
                    </fieldset>
                </Col>
                <Col sm={2}>
                    <div className="image-upload-section">
                        <div className="employee-image-upload">{picture ? <img src={picture && picture || ""} /> : employeeData?.profileImage ? <img src={`${imag || ""}`} /> : <Image />}</div>
                        <div className="upload-btn"> <p >Upload Image</p></div>
                        <input type="file" {...register('ProfileImage', {})} onChange={handleChange} accept=".jpg,.png" name="ProfileImage" className="image-upload-btn" />
                        {errors.ProfileImage && <p className="error-message">Please select an image</p>}
                    </div>
                </Col>
            </Row>
        </>
    )
}
export default DetailsRecord;