import { Col, Row, Form, FloatingLabel, Table, InputGroup, Button } from "react-bootstrap";
import { useEffect, useState, } from "react";
import { Delete } from "@material-ui/icons";
import { Edit } from "@material-ui/icons";
import { Save } from "@material-ui/icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useCustomer from "../../../context/CustomerContext";
import { useAddServiceSurchargeAfterEditMutation, useGetServiceQuery,useGetAllServiceQuery  } from "../../../features/service/serviceDetailSlice";
import { useUpdateServiceDataMutation, useDeleteServiceDataMutation } from "../../../features/service/serviceDetailSlice";
import { useModifyHistoryMutation } from "../../../features/customer/history/historySlice";
import { useRef } from "react";
import { permission } from "../../Sidebar/Permission";
import { useForm, Controller } from 'react-hook-form';
import { useCustomData } from "../../Sidebar/Permission";
import Select from 'react-select';

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

const EditServiceSurcharge = ({ }) => {
    const [permission] = useCustomData()
    const { selected, contractInfo } = useCustomer();
    const ratecardaccount = contractInfo && contractInfo[0]
    const { register, handleSubmit, watch, formState: { errors }, reset, onChange, control } = useForm();
    let subscribe = watch();
    const { data: serviceList } = useGetAllServiceQuery()
    const [updateService] = useUpdateServiceDataMutation()
    const [deleteService] = useDeleteServiceDataMutation()
    const [modifyHistory] = useModifyHistoryMutation()
    const [AddServiceSurchargeAfterEdit] = useAddServiceSurchargeAfterEditMutation()
    const { handleAccount, singleAccount, serviceSurchargeList, vehicleSurchargeList } = useCustomer();
    const account = singleAccount && singleAccount[0];
    const [message, setMessage] = useState("Modified the service surcharge section")
    const [serviceData, setServiceData] = useState({});
    const [serviceOpen, setServiceOpen] = useState(false);
    const [showServiceSecondFields, setServiceShowSecondFields] = useState(false);
    const [editId, setEditId] = useState('')
    const [isDelete, setIsDelete] = useState()
    const [isEditItem, setIsEditItem] = useState(null);
    const [selectedData, setSelectedData] = useState([])
    const [isEdit, setisEdit] = useState(false)
    const [selectedEditData, setSelectedEditData] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [serviceRateValue, setServiceRateValue] = useState(0);

    const { serviceLists } = useCustomer();

    const [hasPallet, setHasPallet] = useState(false);
    const [hasCWT, setHasCWT] = useState(false);

    const rateTypeMap = {1: 'LOCAL', 2:'CWT', 3:'PALLET',4:"DISTANCE-BASED"}
    const rateTypeMapReverse = {'LOCAL': 1,'CWT': 2, 'PALLET': 3, "DISTANCE-BASED": 4}

    const handleServiceOpen = (data) => {
        setServiceOpen(true);
        setIsDelete(data?.rateCardServiceID)
    };
    const handleServiceClose = () => { setServiceOpen(false); };
    const prevCount = usePrevious(serviceData);

    const [serviceType, setServiceType] = useState([])

    useEffect(() => {
        const types = serviceSurchargeList.map(a => a.rateType);
        if (types.includes("CWT")) {
          setHasCWT(true);
        } else {
          setHasCWT(false);
        }
        if (types.includes("PALLET")) {
          setHasPallet(true);
        } else {
          setHasPallet(false);
        }
      }, [serviceSurchargeList])

    useEffect(() => {
        setServiceData(serviceData)
        reset(serviceData)
    }, [serviceData])

    useEffect(() => {
        const vals = vehicleSurchargeList.filter(item => {
          const rateType = item.vehicleType.split(" ").pop().slice(1, -1);
          return rateType === rateTypeMap[serviceRateValue];
        }).map(item => {
          return { value: item.vehicleType, label: item.vehicleType };
        });
        setVehicles(vals);
      }, [serviceRateValue]);

    const arr = []
    vehicleSurchargeList?.map((user) => {
        return arr.push({ value: user.vehicleType, label: user.vehicleType });
    });

    const handleSelect = (e) => {
        if (isEdit) {
            const mapData = e?.map((item) => item?.value
            )
            if (mapData && mapData?.length > 0) {
                setSelectedEditData(mapData)
            }
        }
        const mapData = e?.map((item) => item?.value)
        if (mapData && mapData?.length > 0) {
            setSelectedData(mapData)
        }
    }

    const EditserviceItem = (rateCardServiceID) => {
        setisEdit(true)
        const obj = [];
        setEditId(rateCardServiceID)
        let newEditItem = serviceSurchargeList.find((data) => {
            return data.rateCardServiceID === rateCardServiceID
        });
        newEditItem.vehicleTypes.forEach((elem, i) => {
            obj.push({ value: elem, label: elem })
        })
        setServiceRateValue(rateTypeMapReverse[newEditItem.rateType]);
        setServiceData(newEditItem);
        setSelectedData(obj)
        setSelectedEditData(newEditItem?.vehicleTypes)
        setServiceShowSecondFields(showServiceSecondFields => !showServiceSecondFields)
        setIsEditItem(rateCardServiceID);
        reset(serviceData)
    }

    const editServiceCustomerList = async (serviceData) => {
        serviceData.vehicleTypes = selectedData
        if (JSON?.stringify(prevCount) === JSON?.stringify(serviceData)) {
            setServiceData("")
            setTimeout(function () {
                toast.info("No Changes found on this service surcharge section !", {
                    position: "top-right",
                })
            });

        }
        else {
            delete serviceData.vehicleTypes
            serviceData.vehicleTypes = selectedEditData
            const updateServiceData = await updateService({selectedEditData,...serviceData})
            if (updateServiceData.data.statusCode === 200) {
                setTimeout(function () {
                    toast.success("Service surcharge section updated successfully !", {
                        position: "top-right",
                    })
                });
                setServiceData("");
                handleAccount(account.account_No)
                setSelectedData('')
            }
        }

        if (JSON?.stringify(prevCount) !== JSON?.stringify(serviceData)) {
            const modifieddata = {
                AccountNo: account.account_No,
                CustomerName: account.customer,
                Modifiedsection: message
            }
            await modifyHistory(modifieddata)
        }
        reset({
            serviceID: "", afterHourPercent: "", costPerKM: "", baseRateType: '', baseRate: '', pieceInlcuded: "", baseRateType: "", crossoverRateType: "", crossoverRate: "", pieceRate: "", weightRate: "",
            weightIncluded: "", weightCalcType: "", weightRange: "", waitingTimeUnitRate: "", waitingtimeUnit: "", freeWaitingTime: "", pieceRateType: "", weightRateType: "", waitingTimeUnitRateType: ""
        });
        setServiceType([])
    }

    const deleteServiceCustomerList = async (rateCardServiceID) => {
        await deleteService(rateCardServiceID)
        handleAccount(account.account_No)
    }


    // Add New Record
    const addNewSection = async (data) => {
        if (Object.keys(data).length === 0) {
            setServiceShowSecondFields(true)
        }
        else {
            if (serviceRateValue === 0) {
                toast.error("Please select a rate type", {
                    position: "top-center",
                })
                return;
            }
            delete data.vehicleTypes;
            const newData = { VehicleTypes: selectedData, rateCardID: ratecardaccount?.rateCardId, ...data, RateType: rateTypeMap[serviceRateValue] }
            const addDataOnUpdate = await AddServiceSurchargeAfterEdit(newData)
            handleAccount(account.account_No)
            if (addDataOnUpdate?.data?.statusCode === 200) {
                setTimeout(function () {
                    toast.success("Service surcharge section Added successfully !", {
                        position: "top-right",
                    })
                });
                reset({
                    serviceID: "", afterHourPercent: "", costPerKM: "", baseRateType: '', vehicleTypes: "", baseRate: '', pieceInlcuded: "", baseRateType: "", crossoverRateType: "", crossoverRate: "", pieceRate: "", weightRate: "",
                    weightIncluded: "", weightCalcType: "", weightRange: "", waitingTimeUnitRate: "", waitingtimeUnit: "", freeWaitingTime: "", pieceRateType: "", weightRateType: "", waitingTimeUnitRateType: ""
                });
                setServiceData("");
                setSelectedData('')
                setServiceRateValue(0);
            }
        }
    }

    const deleteHandler = () => {
        deleteServiceCustomerList(isDelete);
        handleServiceClose();
    }

    return (
        <>
        {/* Radio Options */}
        <Form>
            <Col sm={12} className="mb-3">
                <Row>
                    <Col sm={4}><h6>Select service rate type</h6></Col>
                    <Col sm={8}>
                    <div className="floating-checkbox" name="RateType">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" id="inlineCheckbox1" value="LOCAL" name="RateType" checked={serviceRateValue === 1} onClick={() => setServiceRateValue(1)} />
                            <label className="form-check-label" htmlFor="inlineCheckbox1">LOCAL</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" id="inlineCheckbox2" disabled={hasPallet} value="CWT" name="RateType" checked={serviceRateValue === 2} onClick={() => setServiceRateValue(2)} />
                            <label className="form-check-label" htmlFor="inlineCheckbox2">CWT</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" id="inlineCheckbox3" disabled={hasCWT} value="PALLET" name="RateType" checked={serviceRateValue === 3} onClick={() => setServiceRateValue(3)} />
                            <label className="form-check-label" htmlFor="inlineCheckbox3">PALLET</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" id="inlineCheckbox4" value="DISTANCE-BASED" name="RateType" checked={serviceRateValue === 4} onClick={() => setServiceRateValue(4)} />
                            <label className="form-check-label" htmlFor="inlineCheckbox4">DISTANCE-BASED</label>
                        </div>
                    </div>
                    </Col>
                </Row>
                {/* <ServiceSurchargeOptions serviceRateValue={serviceRateValue} setServiceRateValue={setServiceRateValue} setHasCWT={setHasCWT} setHasPallet={setHasPallet}/> */}
            </Col>
        </Form>
            {/* Service surcharge section*/}
            <Row className="mb-2 mt-2">
                <Col sm={12}>
                    <fieldset className="pb-2">
                        <div className="d-flex justify-content-end">
                            <div className="section_action_btn">
                                <>
                                    {serviceData?.rateCardServiceID ?
                                        <div className="save_btn"><button type="button" className="btn" onClick={handleSubmit(editServiceCustomerList)}>Update record</button></div>
                                        :
                                        <>
                                            {permission.find(event => event.moduleId === 42)?.permissionType?.indexOf('Add') !== -1 ?
                                                <div className="add_btn"><button type="button" className="btn" onClick={handleSubmit(addNewSection)}>Add new record</button></div>
                                                :
                                                <div className="add_btn disable"><button type="button" className="btn">Add new record</button></div>
                                            }
                                        </>

                                    }
                                </>
                            </div>
                        </div>
                        {showServiceSecondFields ?
                            <>
                                <Row className="mt-2 mb-3">
                                <Col sm={3}>
                                        <Form.Group controlId="serviceid">
                                            <FloatingLabel controlId="floatingSelectGrid" label="Service">
                                                <Form.Select aria-label="Floating label select example" name="serviceID" {...register('serviceID', { required: 'Field is required' })}>
                                                    <option value="">Select</option>
                                                    <>
                                                        {
                                                            serviceList?.data?.map((data, index) => (
                                                                // <option key={index} value={data.serviceID}>{data.description}</option>
                                                                rateTypeMapReverse[data.rateType] === serviceRateValue && <option key={index} value={data.serviceId}>{data.description}</option>
                                                            ))

                                                        }
                                                    </>

                                                </Form.Select>
                                            </FloatingLabel>
                                            {errors.serviceID?.message && (<p className="error-message">{" * " + errors.serviceID?.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    <Col sm={3}>
                                        <Controller
                                            control={control}
                                            name="vehicleTypes"
                                            defaultValue={selectedData || ''}
                                            {...register('vehicleTypes')}
                                            render={({ field: { onChange, value, name, ref } }) => (
                                                <Select
                                                    isMulti
                                                    placeholder="Select Vehicle"
                                                    name={name}
                                                    defaultValue={selectedData || ''}
                                                    ref={ref}
                                                    options={vehicles}
                                                    // value = {serviceData.vehicleTypes?.map((d)=>d.value)?.join(' , ')}
                                                    value={selectedData ? selectedData?.find((e) => e.vehicleType === serviceRateValue) : ""}
                                                    onChange={(e) => handleSelect(e)}
                                                />
                                            )}
                                        />

                                        
                                        {errors.vehicleTypes?.message && (<p className="error-message">{" * " + errors.vehicleTypes?.message}</p>)}

                                    </Col>
                                    <Col sm={3}>
                                        <Form.Group controlId="Afterhour">
                                            <FloatingLabel controlId="floatingInput" label="After Hour Percent (%)" >
                                                <Form.Control type="number" placeholder="After Hour Percent" name="afterHourPercent" {...register('afterHourPercent', { required: 'Field is required' })} min='0' />
                                            </FloatingLabel>
                                            {errors.afterHourPercent?.message && (<p className="error-message">{" * " + errors.afterHourPercent?.message}</p>)}
                                        </Form.Group>
                                    </Col>
                                    {serviceRateValue === 4 &&
                                    <Col sm={3}>
                                        <div className="form-floating">
                                            <Form.Control type="number" placeholder="Cost Per KM" name="costPerKM" {...register('costPerKM', { required: 'Field is required' })} min='0' />
                                            <label>Cost Per KM</label>
                                        </div>
                                        {errors.costPerKM?.message && (<p className="error-message">{" * " + errors.costPerKM?.message}</p>)}
                                    </Col> }
                                </Row>
                                {serviceRateValue === 1 && 
                                <Row className="mt-4 mb-3">
                                    <Col sm={6}>
                                        <fieldset className="mb-3">
                                            <label className="label-heading">Base Rate Section</label>
                                            <Row>
                                                <Col sm={6}>
                                                    <div className="fieldset-inner">
                                                        <label className="label-heading">Base Rate Type</label>
                                                        <div className="floating-checbox">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="baseRateType" {...register("baseRateType", { required: 'Field is required' })} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="baseRateType" {...register("baseRateType", { required: 'Field is required' })} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {errors.baseRateType?.message && (<p className="error-message">{" * " + errors.baseRateType?.message}</p>)}
                                                </Col>
                                                <Col sm={6}>
                                                    {subscribe.baseRateType === "A" ? (
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text >$</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Base Rate" name="baseRate"  {...register('baseRate', { required: 'Field is required' })} min='0' />
                                                                <label>Base Rate ($)</label>
                                                            </div>
                                                            {errors.baseRate?.message && (<p className="error-message">{" * " + errors.baseRate?.message}</p>)}
                                                        </div>
                                                    ) : subscribe.baseRateType === "P" ? (
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text >%</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Base Rate" name="baseRate"  {...register('baseRate', { required: 'Field is required' })} min='0' />
                                                                <label>Base Rate (%)</label>
                                                            </div>
                                                            {errors.baseRate?.message && (<p className="error-message">{" * " + errors.baseRate?.message}</p>)}
                                                        </div>
                                                    ) : (
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text >$</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Base Rate" name="baseRate" {...register('baseRate', { required: 'Field is required' })} min='0' />
                                                                <label>Base Rate ($)</label>
                                                            </div>
                                                            {errors.baseRate?.message && (<p className="error-message">{" * " + errors.baseRate?.message}</p>)}
                                                        </div>
                                                    )
                                                    }

                                                </Col>
                                            </Row>
                                        </fieldset>
                                    </Col>
                                    <Col sm={6}>
                                        <fieldset className="mb-3">
                                            <label className="label-heading">Crossover Rate Section</label>
                                            <Row>
                                                <Col sm={6}>
                                                    <div className="fieldset-inner">
                                                        <label className="label-heading">Choose the crossover rate type</label>
                                                        <div className="floating-checbox">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="crossoverRateType" {...register("crossoverRateType", { required: 'Field is required' })} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="crossoverRateType" {...register("crossoverRateType", { required: 'Field is required' })} />
                                                                <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {errors.crossoverRateType?.message && (<p className="error-message">{" * " + errors.crossoverRateType?.message}</p>)}
                                                </Col>
                                                <Col sm={6}>
                                                    {subscribe?.crossoverRateType === "A" ? (
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text>$</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Crossover Rate" name="crossoverRateActive" {...register('crossoverRate', { required: 'Field is required' })} min='0' />
                                                                <label>Crossover Rate ($)</label>
                                                            </div>
                                                            {errors.crossoverRate?.message && (<p className="error-message">{" * " + errors.crossoverRate?.message}</p>)}
                                                        </div>
                                                    ) : subscribe?.crossoverRateType === "P" ? (
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text >%</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Crossover Rate" name="crossoverRate" {...register('crossoverRate', { required: 'Field is required' })} min='0' />
                                                                <label>Crossover Rate (%)</label>
                                                            </div>
                                                            {errors.crossoverRate?.message && (<p className="error-message">{" * " + errors.crossoverRate?.message}</p>)}
                                                        </div>
                                                    ) : (
                                                        <div className="btn-status">
                                                            <div className="form-floating">
                                                                <InputGroup.Text >$</InputGroup.Text>
                                                                <Form.Control type="number" placeholder="Crossover Rate" name="crossoverRate" {...register('crossoverRate', { required: 'Field is required' })} min='0' />
                                                                <label>Crossover Rate ($)</label>
                                                            </div>
                                                            {errors.crossoverRate?.message && (<p className="error-message">{" * " + errors.crossoverRate?.message}</p>)}
                                                        </div>
                                                    )
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                            </Row>
                                        </fieldset>
                                    </Col>
                                </Row> }
                                {serviceRateValue === 1 && 
                                <Row className="mt-4 mb-3">
                                    <fieldset className="mb-3">
                                        <label className="label-heading">Piece Rate Section</label>
                                        <Row>
                                            <Col sm={4}>
                                                <div className="fieldset-inner">
                                                    <label className="label-heading">Piece Rate Type</label>
                                                    <div className="floating-checbox">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="pieceRateType" {...register("pieceRateType", { required: 'Field is required' })} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="pieceRateType" {...register("pieceRateType", { required: 'Field is required' })} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {errors.pieceRateType?.message && (<p className="error-message">{" * " + errors.pieceRateType?.message}</p>)}
                                            </Col>
                                            <Col sm={4}>
                                                {subscribe?.pieceRateType === "A" ? (
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text >$</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Piece Rate" name="pieceRate" {...register('pieceRate', { required: 'Field is required' })} min='0' />
                                                            <label>Piece Rate ($)</label>
                                                        </div>
                                                        {errors.pieceRate?.message && (<p className="error-message">{" * " + errors.pieceRate?.message}</p>)}
                                                    </div>
                                                ) : subscribe?.pieceRateType === "P" ? (
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Piece Rate" name="pieceRate" {...register('pieceRate', { required: 'Field is required' })} min='0' />
                                                            <label>Piece Rate (%)</label>
                                                        </div>
                                                        {errors.pieceRate?.message && (<p className="error-message">{" * " + errors.pieceRate?.message}</p>)}
                                                    </div>
                                                ) : (
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text >$</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Piece Rate" name="pieceRate" {...register('pieceRate', { required: 'Field is required' })} min='0' />
                                                            <label>Piece Rate ($)</label>
                                                        </div>
                                                        {errors.pieceRate?.message && (<p className="error-message">{" * " + errors.pieceRate?.message}</p>)}
                                                    </div>
                                                )
                                                }
                                            </Col>
                                            <Col sm={4}>
                                                <div className="form-floating">
                                                    <Form.Control type="number" placeholder="Piece Included" name="pieceIncluded" {...register('pieceInlcuded', { required: 'Field is required' })} min='0' />
                                                    <label>Piece Included</label>
                                                </div>
                                                {errors.pieceInlcuded?.message && (<p className="error-message">{" * " + errors.pieceInlcuded?.message}</p>)}
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Row> }
                                {serviceRateValue === 1 && 
                                <Row className="mt-4 mb-3">
                                    <fieldset className="mb-3">
                                        <label className="label-heading">Weight Rate Section</label>
                                        <Row>
                                            <Col sm={3}>
                                                <div className="fieldset-inner">
                                                    <label className="label-heading">Weight Rate Type</label>
                                                    <div className="floating-checbox">
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox1" value="A" name="weightRateType" {...register("weightRateType", { required: 'Field is required' })} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox1">Amount</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio" id="inlineCheckbox2" value="P" name="weightRateType" {...register("weightRateType", { required: 'Field is required' })} />
                                                            <label className="form-check-label" htmlFor="inlineCheckbox2">Percentage</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {errors.weightRateType?.message && (<p className="error-message">{" * " + errors.weightRateType?.message}</p>)}
                                            </Col>
                                            <Col sm={3}>
                                                {subscribe?.weightRateType === "A" ? (
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Weight Rate" name="weightRate" {...register('weightRate', { required: 'Field is required' })} min='0' />
                                                            <label>Weight Rate ($)</label>
                                                        </div>
                                                        {errors.weightRate?.message && (<p className="error-message">{" * " + errors.weightRate?.message}</p>)}
                                                    </div>
                                                ) : subscribe?.weightRateType === "P" ? (
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Weight Rate" name="weightRate" {...register('weightRate', { required: 'Field is required' })} min='0' />
                                                            <label>Weight Rate (%)</label>
                                                        </div>
                                                        {errors.weightRate?.message && (<p className="error-message">{" * " + errors.weightRate?.message}</p>)}
                                                    </div>
                                                ) : (
                                                    <div className="btn-status">
                                                        <div className="form-floating">
                                                            <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                                                            <Form.Control type="number" placeholder="Weight Rate" name="weightRate" {...register('weightRate', { required: 'Field is required' })} min='0' />
                                                            <label>Weight Rate ($)</label>
                                                        </div>
                                                        {errors.weightRate?.message && (<p className="error-message">{" * " + errors.weightRate?.message}</p>)}
                                                    </div>
                                                )
                                                }
                                            </Col>
                                            <Col sm={2}>
                                                <div className="form-floating">
                                                    <Form.Control type="number" placeholder="Weight Included" name="weightIncluded" {...register('weightIncluded', { required: 'Field is required' })} required min='0' />
                                                    <label>Weight Included</label>
                                                </div>
                                                {errors.weightIncluded?.message && (<p className="error-message">{" * " + errors.weightIncluded?.message}</p>)}
                                            </Col>
                                            <Col sm={2}>
                                                <div className="form-floating">
                                                    <Form.Control type="number" placeholder="Weight Range" name="weightRange"
                                                        {...register('weightRange', { required: 'Field is required' })} required min='0' />
                                                    <label htmlFor="WeightRange">Weight Range</label>
                                                </div>
                                                {errors.weightRange?.message && (<p className="error-message">{" * " + errors.weightRange?.message}</p>)}
                                            </Col>
                                            <Col sm={2}>
                                                <div className="form-floating">
                                                    <Form.Select aria-label="Floating label select example" name="weightCalctype"  {...register('weightCalctype', { required: 'Field is required' })}>
                                                        <option>{subscribe?.weightCalcType ? subscribe?.weightCalcType : ""}</option>
                                                        <option value="Total">Total</option>
                                                        <option value="Average">Average</option>
                                                    </Form.Select>
                                                    <label htmlFor="WeightCalctype">Weight Calc Type</label>
                                                </div>
                                                {errors.weightCalctype?.message && (<p className="error-message">{" * " + errors.weightCalctype?.message}</p>)}
                                            </Col>
                                        </Row>
                                    </fieldset>
                                </Row> }
                            </>
                            : null}
                        {/* table */}
                        <Row>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th>Service Rate Type</th>
                                        <th>Service</th>
                                        <th>VehicleTypes</th>
                                        <th>After Hour Percent</th>
                                        <th>Cost Per KM</th>
                                        <th>BaseRateType</th>
                                        <th>BaseRate</th>
                                        <th>CrossoverRateType</th>
                                        <th>CrossoverRate</th>
                                        <th>PiecerateType</th>
                                        <th>Piecerate</th>
                                        <th>Piece included</th>
                                        <th>Weight Rate Type</th>
                                        <th>Weight Rate</th>
                                        <th>Weight Included</th>
                                        <th>Weight Range</th>
                                        <th>Weight Calc Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {serviceSurchargeList?.length > 0 ? (
                                        serviceSurchargeList?.map((data, index) => {
                                            return (
                                                <tr key={data?.rateCardServiceID}>
                                                    <td>
                                                        <div className="action_btn">
                                                            {permission.find(event => event.moduleId === 42)?.permissionType?.indexOf('Update') !== -1 ?
                                                                <span className="edit_btn" data-ind={data?.rateCardServiceID} onClick={() => EditserviceItem(data?.rateCardServiceID)}><Edit /></span>
                                                                : <span className="edit_btn disable" data-ind={data?.rateCardServiceID}><Edit /></span>}
                                                            {permission.find(event => event.moduleId === 42)?.permissionType?.indexOf('Delete') !== -1 ?
                                                                <span className="del_btn" onClick={() => handleServiceOpen(data)}><Delete /></span>
                                                                : <span className="del_btn disable"><Delete /></span>}
                                                            <div>
                                                                <Dialog open={serviceOpen} onClose={handleServiceClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                                                                    <DialogTitle id="alert-dialog-title">
                                                                        Delete Confirmation
                                                                    </DialogTitle>
                                                                    <DialogContent>
                                                                        <DialogContentText id="alert-dialog-description">
                                                                            Are you sure you want to delete this row?
                                                                        </DialogContentText>
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button className="btn-secondary" onClick={handleServiceClose}>Cancel</Button>
                                                                        <Button className="btn-primary" onClick={deleteHandler} autoFocus> Confirm</Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{data.rateType}</td>
                                                    <td>{data.serviceDescription}</td>
                                                    <td>{data.vehicleTypes?.join(' , ')}</td>
                                                    <td>{data.afterHourPercent}</td>
                                                    <td>{data.costPerKM}</td>
                                                    <td>{data.baseRateType}</td>
                                                    <td>{data.baseRate ? (data.baseRateType === 'A' ? (data.baseRate).toFixed(2) : data.baseRateType === 'P'? (data.baseRate) :data.baseRate):""}</td>
                                                    <td>{data.crossoverRateType}</td>
                                                    <td>{data.crossoverRate ? (data.crossoverRateType === 'A' ? (data.crossoverRate).toFixed(2) : data.crossoverRateType === 'P'? (data.crossoverRate) :data.crossoverRate):""}</td>
                                                    <td>{data.pieceRateType}</td>
                                                    <td>{data.pieceRate ? (data.pieceRateType === 'A' ? (data.pieceRate).toFixed(2) : data.pieceRateType === 'P'? (data.pieceRate) :data.pieceRate):""}</td>
                                                    <td>{data.pieceInlcuded}</td>
                                                    <td>{data.weightRateType}</td>
                                                    <td>{data.weightRate ? (data.weightRateType === 'A' ? (data.weightRate).toFixed(2) : data.weightRateType === 'P'? (data.weightRate) :data.weightRate):""}</td>
                                                    <td>{data.weightIncluded}</td>
                                                    <td>{data.weightRange}</td>
                                                    <td>{data.weightCalcType}</td>
                                                </tr>
                                            )
                                        })
                                    ) : (
                                        <p>No data found </p>
                                    )}
                                </tbody>
                            </Table>
                        </Row>
                    </fieldset>
                </Col>
            </Row>

            {/* <ToastContainer /> */}
        </>
    );
}
export default EditServiceSurcharge;