import { Save } from "@material-ui/icons";
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { useEffect, useRef, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row, Tab, Tabs } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import { Controller, useForm } from "react-hook-form";
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useCustomer from "../../../context/CustomerContext";
import { useGetCustomerQuery } from "../../../features/customer/customerSlice";
import { useUpdateNameAddressDataMutation } from "../../../features/nameAddress/nameAddressSlice";
import CountryProvince from "../../CountryProvince";
import phoneNumberMasking from "../PhoneNumberMasking";
import postalCodeMasking from "../PostalCodeMasking";
import EditContact from "./EditContactDetails";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, []);
  return ref.current;
};

const SaveCustomer = ({ showhandler }) => {
  const { setSelectState, handlecountry, handleCity, city, handleProvince, newHandleCountry } = CountryProvince()
  const { refetch } = useGetCustomerQuery()
  const [updateNameAddressData] = useUpdateNameAddressDataMutation()
  const { singleAccount, handleAccount } = useCustomer();
  const [key, setKey] = useState("Customerdetails");
  const [account, setAccount] = useState(singleAccount[0]);
  const accountData = singleAccount && singleAccount[0];
  const [phoneNumber, setPhoneNumber] = useState('');
  const [fax, setFax] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [postal, setPostal] = useState("")
  const [checkedData, setCheckedData] = useState(accountData.isNotesAppearOnOrder)
  const cityList = city?.data.map((a) => { return { name: a.city, id: a.id } });
  const [selection, setSelection] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const { control } = useForm();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selection.length > 0) {
          const pList = await handleProvince(selection[0].id);
          setProvinceList(pList.data);

          if (pList?.data?.data?.length === 1) {
            const selectedProvince = pList.data.data[0];
            const cList = await newHandleCountry(selectedProvince.provineStateID);
            const selectedCountry = cList.data.data[0];
            setCountryList(cList.data.data);
            setAccount(account => ({
              ...account,
              provineStateID: selectedProvince.provineStateID,
              provineSateName: selectedProvince.provineSateName,
              countryId: selectedCountry.countryId,
              countryname: selectedCountry.countryname,
            }));
          }
        } else {
          setProvinceList([]);
          setCountryList([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [selection]);

  const setCountry = async (e) => {
    if (e.target.value !== 'Select') {
      const cList = await newHandleCountry(provinceList.data[0].provineStateID);
      setCountryList(cList.data.data);
    }
    else {
      setCountryList([]);
    }
  }

  useEffect(() => {
    setPhoneNumber(account?.phone)
    setFax(account?.fax)
    setContactNumber(account?.contact)
    setPostal(account?.postalCode)
    setSelection([{ name: account?.cityName, id: account?.cityId }])
    const fetchCountryList = async () => {
      try {
        const cList = await newHandleCountry(account?.provineStateID);
        setCountryList(cList?.data?.data || []);
      } catch (error) {
        console.error("Error fetching country list:", error);
      }
    };
    fetchCountryList();
  }, [])


  const handleInput = e => {
    const formattedPhoneNumber = phoneNumberMasking(e.target.value);
    const formattedPostalCode = postalCodeMasking(e.target.value);
    if (e.target.name === "phone") {
      setPhoneNumber(e.target.value);
    }
    else if (e.target.name === "fax") {
      setFax(e.target.value);
    }
    else if (e.target.name === "contact") {
      setContactNumber(formattedPhoneNumber)
    }
    else if (e.target.name === "postalCode") {
      setPostal(formattedPostalCode)
    }
  };


  const officeDatepicker = new Date(account?.officeHour && account?.officeHour).toLocaleDateString("en-CA").split("-").join("-");
  const officeTimepicker = new Date(account?.officeHour && account?.officeHour).toLocaleTimeString("en-US");
  const openTimepicker = officeDatepicker?.concat(" ", officeTimepicker)

  const closeDatepicker = new Date(account?.closeTime && account?.closeTime).toLocaleDateString("en-CA").split("-").join("-");
  const closeTimepicker = new Date(account?.closeTime && account?.closeTime).toLocaleTimeString("en-US");
  const closeHour = closeDatepicker?.concat(" ", closeTimepicker)

  const Addresshandler = (e) => { setAccount(account => ({ ...account, [e.target.name]: e.target.value })) }

  useEffect(() => {
    if (selection.length > 0) {
      setAccount(account => ({ ...account, cityId: selection[0].id, cityName: selection[0].cityName }))
    }
  }, [selection])

  const checkHandler = (e) => {
    setCheckedData(!checkedData)
  }

  const prevCount = usePrevious(account);

  const saveAccount = {
    account_No: account.account_No,
    accountType: account.accountType,
    customer: account.customer,
    street: account.street,
    streetName: account.streetName,
    unit: account.unit,
    cityId: account.cityId,
    provineStateID: account.provineStateID,
    provineSateName: account.provineSateName,
    countryId: account.countryId,
    countryname: account.countryname,
    postalCode: postal,
    phone: phoneNumber,
    fax: fax,
    phone_Ext: account.phone_Ext,
    contact: account.contact,
    tittle: account.tittle,
    officeHour: openTimepicker,
    closeTime: closeHour,
    customerFrequencyVolumeID: account.customerFrequencyVolumeID,
    emailAddress: account.emailAddress,
    createdDate: account.createdDate,
    modifiedDate: account.modifiedDate,
    notes: account.notes,
    isNotesAppearOnOrder: checkedData
  }
  // create a functino that will set isNoteAppearOnOrder to !isNoteAppearOnOrder
  const setCheck = () => {
    setAccount(account => ({ ...account, isNotesAppearOnOrder: !checkedData }))
  }


  // Update nameAddress section
  const editCustomerList = async () => {
    if (JSON?.stringify(prevCount) === JSON?.stringify(account)) {
      setTimeout(function () {
        toast.info("No Changes found on this name/address section !", {
          position: "top-right",
        })
      });
    }
    else {
      const updateCustomer = await updateNameAddressData(saveAccount)
      handleAccount(account.account_No)
      if (updateCustomer.data.statusCode === 200) {
        setTimeout(function () {
          toast.success("Name/Address section updated successfully !", {
            position: "top-right",
          })
        });
      }
    }
    showhandler();
    refetch()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      console.log(openTimepicker)
    }, 2500)
    return () => clearInterval(interval)
  })

  const TimeConverter = (isoTimeString) => {
    if (!isoTimeString) return "";

    const date = new Date(isoTimeString);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    hours = hours.toString().padStart(2, '0');
    minutes = minutes.toString().padStart(2, '0');
    return `${hours}:${minutes} ${ampm}`;
  }


  return (
    <>
      <>
        <Row className="mb-4 mt-4">
          <Col sm={12}>
            <div className="name-sec">
              <Row className="mb-3">
                <div className="button-section" style={{ justifyContent: "end" }}>
                  <Button className="add-btn" onClick={e => { editCustomerList(account); }} style={{ width: "135px", paddingRight: "35px", height: "36px" }}>
                    Save{" "}
                    <span style={{ padding: "5px" }}>
                      <Save />
                    </span>
                  </Button>
                </div>
                <Col sm={4}>
                  <Form.Group controlId="Account">
                    <FloatingLabel controlId="floatingSelectGrid" label="Account type">
                      <Form.Select aria-label="Floating label select example" name="accountType" value={account.accountType} onChange={e => Addresshandler(e)}>
                        <option value="Inactive Account">Inactive Account</option>
                        <option value="CSA Account">CSA Account</option>
                        <option value="US Based Account">US Based Account</option>
                        <option value="Freight Broker">Freight Broker</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group controlId="accountnumber">
                    <FloatingLabel controlId="floatingInput" label="Account Number">
                      <Form.Control type="text" placeholder="Account Number" name="AccountName" value={account.account_No} onChange={e => Addresshandler(e)} disabled />
                    </FloatingLabel>
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <span className="last-order-update">
                    <p>Last order 20/11/2021 08:30:00PM</p>
                  </span>
                </Col>
              </Row>
              {/* 2nd Row */}
              <Row className="mb-4">
                <Col sm={4}>
                  <div className="timepicker">
                    <TimePickerComponent id="time" placeholder="Select office hour" onChange={e => Addresshandler(e)} name="officeHour" value={TimeConverter(accountData?.officeHour)} format={"hh:mm a"} />
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="timepicker">
                    <TimePickerComponent id="time1" placeholder="Select close time" value={TimeConverter(accountData?.closeTime)} onChange={e => Addresshandler(e)} name="closeTime" format={"hh:mm a"} />
                  </div>
                </Col>
                <Col sm={4}>
                  <Form.Group controlId="customernumber">
                    <FloatingLabel controlId="floatingSelectGrid" label="Customer frequency volume">
                      <Form.Select aria-label="Floating label select example" name="customerFrequencyVolumeID" value={account.customerFrequencyVolumeID || ''} onChange={e => Addresshandler(e)}>
                        <option>Select</option>
                        <option>Daily</option>
                        <option>Weekly</option>
                        <option>Semi-monthly</option>
                        <option>Monthly</option>
                        <option>Occasionally</option>
                        <option>Fledged</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {/* 2nd fieldset */}
        <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3 contact_listing" >
          <Tab eventKey="Customerdetails" title="Customer-Details" id="name_address">
            <Row className="mb-4">
              <Col sm={12}>
                <Row className="mb-3">
                  <Col sm={3}>
                    <Form.Group controlId="customername">
                      <FloatingLabel controlId="floatingInput" label="Customer Name" >
                        <Form.Control type="text" placeholder="Customer Name" value={account.customer} name="customer" onChange={e => Addresshandler(e)} />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Street">
                      <FloatingLabel controlId="floatingInput" label="Street#" >
                        <Form.Control type="text" placeholder="Street#" name="street" value={account.street || ''} onChange={e => Addresshandler(e)} />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Streetname">
                      <FloatingLabel controlId="floatingInput" label="Street Name" >
                        <Form.Control type="text" placeholder="Street Name" name="streetName" value={account.streetName} onChange={e => Addresshandler(e)} />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="unit">
                      <FloatingLabel controlId="floatingInput" label="Unit" >
                        <Form.Control type="text" placeholder="Unit" name="unit" value={account.unit} onChange={e => Addresshandler(e)} />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                {/* 2nd Row */}
                <Row className="mb-3">
                  <Col sm={3}>
                    <Form.Group controlId="City">
                      <Typeahead
                        id='cityAutocomplete'
                        labelKey="name"
                        onChange={setSelection}
                        options={cityList}
                        placeholder="Choose a city..."
                        selected={selection}
                      />

                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Province/State">
                      <FloatingLabel controlId="floatingSelectGrid" label="Province/State">
                        <Form.Select aria-label="Floating label select example" name="provineStateID" onChange={(e) => { setSelectState(e.target.value); Addresshandler(e); handleCity(e); setCountry(e); }} value={account.provineStateID} required>
                          <option>Select</option>:
                          <>
                            {
                              provinceList?.data?.map((data, index) => (
                                <option key={index} value={data.provineStateID}>{data.provineSateName}</option>
                              ))

                            }
                          </>

                        </Form.Select>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="country">
                      <FloatingLabel controlId="floatingSelectGrid" label="Country">
                        <Form.Select aria-label="Floating label select example" value={account?.countryId} name="countryId" onChange={e => { Addresshandler(e); handlecountry(e); }}>
                          <option>Select</option>:
                          <>
                            {
                              countryList?.map((data, index) => (
                                <option key={index} value={data.countryId}>{data.countryname}</option>
                              ))

                            }
                          </>



                        </Form.Select>
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Postalcode">
                      <FloatingLabel controlId="floatingInput" label="Postal code" >
                        <Form.Control type="text" placeholder="Postal code" value={postal} name="postalCode" onChange={e => { Addresshandler(e); handleInput(e); }} />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                {/* 3rd row */}
                <Row className="mb-3">
                  <Col sm={3}>
                    <Form.Group controlId="Phone">
                      <FloatingLabel controlId="floatingInput" label="Phone" >
                        {/* <Form.Control type="text" placeholder="Phone" name="phone" value={phoneNumber} onChange={e => { Addresshandler(e); handleInput(e); }} /> */}
                        <Controller type="text" placeholder="Phone"
                          name="Phone"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputMask mask="(999) 999 - 9999" maskChar="_" value={value || phoneNumber} onChange={e => { onChange(e.target.value); Addresshandler(e); handleInput(e); }}>
                              {(inputProps) => (
                                <input
                                  {...inputProps}
                                  type="tel"
                                  className={`form-control`}
                                  placeholder="Phone"
                                  name="phone"
                                />
                              )}
                            </InputMask>
                          )}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Fax">
                      <FloatingLabel controlId="floatingInput" label="Fax" >
                        {/* <Form.Control type="text" placeholder="Fax" name="fax" value={fax} onChange={e => { Addresshandler(e); handleInput(e); }} /> */}
                        <Controller type="text" placeholder="Fax"
                          name="Fax"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <InputMask mask="(999) 999 - 9999" maskChar="_" value={value || fax} onChange={e => { onChange(e.target.value); Addresshandler(e); handleInput(e); }}>
                              {(inputProps) => (
                                <input
                                  {...inputProps}
                                  type="tel"
                                  className={`form-control`}
                                  placeholder="Fax"
                                  name="fax"
                                />
                              )}
                            </InputMask>
                          )}
                        />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Phone Ext">
                      <FloatingLabel controlId="floatingInput" label="Phone Ext" >
                        <Form.Control type="text" placeholder="Phone Ext" name="phone_Ext" value={account.phone_Ext} onChange={e => Addresshandler(e)} />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group controlId="Contact">
                      <FloatingLabel controlId="floatingInput" label="Contact" >
                        <Form.Control type="text" placeholder="Contact" name="contact" value={account.contact} onChange={e => { Addresshandler(e) }} />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>
                {/* 4th row */}
                <Row className="mb-3">
                  <Col sm={12}>
                    <Form.Group controlId="email">
                      <FloatingLabel controlId="floatingInput" label="Email" >
                        <Form.Control type="email" placeholder="Email" name="emailAddress" value={account.emailAddress} onChange={e => Addresshandler(e)} />
                      </FloatingLabel>
                    </Form.Group>
                  </Col>
                </Row>

              </Col>
            </Row>
            {/* 3rd section */}
            <Row className="mb-4">
              <Col sm={12}>
                <div className="form-check">
                  <input type="checkbox" id="formBasicCheckbox" className="form-check-input" checked={checkedData}
                    onClick={(e) => { checkHandler(e); setCheck() }}

                    onChange={(e) => Addresshandler(e)} />
                  <label title="" htmlFor="formBasicCheckbox" className="form-check-label">Notes will appear at order taking system</label>
                </div>
                <FloatingLabel controlId="floatingTextarea2">
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    name="notes"
                    style={{ height: '100px' }}
                    value={account.notes}
                    onChange={e => Addresshandler(e)}
                  />
                </FloatingLabel>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="Contactdetails" title="Contact-Details">
            <EditContact />
          </Tab>
        </Tabs>
      </>
    </>
  );
}
export default SaveCustomer;