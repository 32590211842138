import { Delete, Visibility } from "@material-ui/icons";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import * as React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toggleShow, useDeleteEmployeeMutation, useGetEmployeeListQuery } from "../../../features/admin/employee/employeeSlice";
import Spinner from "../../Spinner";



export default function EmployeeList({getEmployeeDetails}) {
  const dispatch = useDispatch()
  const{data:getEmployeeList, isLoading, refetch} = useGetEmployeeListQuery()
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState()
  const handleClose = async() => {setOpen(false); await deleteEmployee(deleteId);refetch()};
  const toggleHandler = ()=>{dispatch(toggleShow())}
  const [deleteEmployee] = useDeleteEmployeeMutation()

  
  if (!getEmployeeList && isLoading) return <><Spinner /></>;
    const columns = [
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        width: 120,
        disableClickEventBubbling: true,
        renderCell: (data) => {
          const onClick = async(e) => { 
            e.stopPropagation();     
            await getEmployeeDetails(data.row.id)
            toggleHandler()   
          };
          const onRemove = (e) => {
           e.stopPropagation();
            setDeleteId(data?.id)
            setOpen(true);
          };
        
          return (
            <>
            <>
            <div className="action_btn">
            <span className="view_btn" onClick={onClick}><Visibility /></span> 
            <span className="del_btn" onClick={onRemove}><Delete/></span>
            </div>
            </>
            <>
              <div>
              <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                Delete Confirmation
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this row?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
            <Button className="btn-secondary" onClick={() => { setOpen(false); }}>Cancel</Button>
            <Button className="btn-primary" onClick={() => { handleClose(); }} autoFocus> Confirm</Button>
                </DialogActions>
              </Dialog>
            </div>
            </>
            </>
          )
          
        },
      },
      
      { field: "driverNumber", headerName: "Driver", width: 180 },
      { field: "firstname", headerName: "First Name", width: 180 },
      { field: "lastname", headerName: "Last Name", width: 180 },
      { field: "payrollName", headerName: "Payroll", width: 180 },
      { field: "primaryNumber", headerName: "Primary No.", width: 180 },
      { field: "secondaryNumber", headerName: "Secondary No.", width: 180 },
      { field: "hireDate", headerName: "Date Hired", width: 180 },
      { field: "payrollTypeName", headerName: "Payroll Type", width: 150 },
      

        
      ];
      const rows = getEmployeeList?.data.map((data,index) =>{
        return {
          id:data?.employeeId,
          payrollName: data?.payrollName,
          driverNumber:data?.driverNumber, 
          firstname:data?.firstname,
          lastname:data?.lastname,
          primaryNumber:data?.primaryNumber,
          secondaryNumber:data?.secondaryNumber,
          hireDate: new Date(data?.hireDate).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }),
          payrollTypeName:data?.payrollTypeName

        }
      })
  return (
    <>
    {getEmployeeList?.data.length > 0 ? (
    <div  className="user-role-table">
      <h6>List of Employee</h6>
      <DataGrid rows={rows} columns={columns} pageSize={15} rowsPerPageOptions={[1]} components={{ Toolbar: GridToolbar }} componentsProps={{
                    toolbar: { showQuickFilter: true, },}} />
  </div>
       ):(
        <div  className="user-role-table">
        <h6>List of Employee</h6>
        <p style={{textAlign: "center",marginTop: "20px",}}>No data found </p>
          </div>
    )}
    </>
    
  );
}
